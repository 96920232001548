/* eslint-disable array-callback-return */

import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { SORT_ORDERS } from "../../../query";
import { useMutation } from "@apollo/react-hooks";
import { UPDATE_ORDER_STATUS, DELETE_ORDER, UPDATE_ORDER_READ_STATUS } from "../../../mutation";
import Layout from "./layout";
import LinearProgress from "@material-ui/core/LinearProgress";

const Orders = ({ history }) => {

  // Form Values
  const [orderToChangeStatus, setOrderToChangeStatus] = useState({});
  const [statusToChangeAllOrders, setStatusToChangeAllOrders] = useState([]);
  const [checkboxMassiveDelete, setCheckboxMassiveDelete] = useState([]);
  const [allDeleteChecked, setAllDeleteChecked] = useState(false);
  const [errorStock, setErrorStock] = useState([]);
  const [year, setYear] = useState(parseInt(new Date().getFullYear()));

  // Search
  const [search, setSearch] = useState();
  const [filterStatus, setFilterStatus] = useState();
  // Loading
  const [loadingUpdateOrderStatus, setLoadingUpdateOrderStatus] = useState(false);
  const [loadingDeleteOrders, setLoadingDeleteOrders] = useState(false);
  // Modal
  const [openModalEditStatus, setOpenModalEditStatus] = useState(false);
  const [openModalConfirmDeleteOrders, setOpenModalConfirmDeleteOrders] = useState(false);
  const [openModalChangeAllStatus, setOpenModalChangeAllStatus] = useState(false);

  const [sort, setSort] = useState(null);
  //QUERY
  // const { loading: loadingOrders, error: errorOrders, data: dataOrders } = useQuery(SORT_ORDERS, {
  //   variables: {
  //     sort: sort ? sort : "created_at-desc",
  //     year: parseInt(new Date().getFullYear()),
  //   },
  // });

  const [orders, { loading: loadingOrders, error: errorOrders, data: dataOrders, refetch: refetchOrders }] = useLazyQuery(SORT_ORDERS, {
    fetchPolicy: "cache-and-network",
    variables: {
      sort: sort ? sort : "created_at-desc",
      year: year,
    },
  });

    useEffect(() => {
      orders();
  }, [year]);


  // MUTATION
  const [updateOrderStatus] = useMutation(UPDATE_ORDER_STATUS);
  const [deleteOrder] = useMutation(DELETE_ORDER);
  const [updateOrderReadStatus] = useMutation(UPDATE_ORDER_READ_STATUS);
  // Dropzone
  const [openDropzone, setOpenDropzone] = useState(false);
  // Upload
  const [pdfFile, setPdfFile] = useState(undefined);

  // Mutation on Update order status
  const onUpdateOrderStatus = () => {
    setLoadingUpdateOrderStatus(true);
    updateOrderStatus({
      variables: { id: parseInt(orderToChangeStatus.id), status: orderToChangeStatus.status, pdf: pdfFile },
      refetchQueries: ["sortOrdersAdmin"],
      context: {
        hasUpload: true,
      },
    }).then(response => {
      console.log(response)

      // If response of remove registration permission is 0 set error
      if (response.data.updateOrderStatus.status === 1) {
        setOrderToChangeStatus({});
        setOpenModalEditStatus(false);
        setLoadingUpdateOrderStatus(false);
        setAllDeleteChecked(false);
        setCheckboxMassiveDelete([]);
        setPdfFile(undefined);
        setErrorStock([])
      } else {
        setLoadingUpdateOrderStatus(false);
        setErrorStock(response.data.updateOrderStatus.productsIds);
      }
    }).catch(err => {
      console.log(err)
      // if get error of mutation set error of insert Vat number to true
      setLoadingUpdateOrderStatus(false);
    });
  };

  // Mutation on Update All orders status selected
  const onUpdateAllOrdersStatus = () => {
    setLoadingUpdateOrderStatus(true);
    checkboxMassiveDelete.map((item) => {
      updateOrderStatus({
        variables: { id: item, status: statusToChangeAllOrders },
        refetchQueries: ["sortOrdersAdmin"],
      }).then(response => {
        // If response of remove registration permission is 0 set error
        if (response.data.updateOrderStatus.status === 0) {
          setLoadingUpdateOrderStatus(false);
        } else if (response.data.updateOrderStatus.status === 1) {
          setOrderToChangeStatus({});
          setOpenModalEditStatus(false);
          setErrorStock([])
          setLoadingUpdateOrderStatus(false);
        }
      }).catch(err => {
        // if get error of mutation set error of insert Vat number to true
        setLoadingUpdateOrderStatus(false);
      });
    });
    setLoadingUpdateOrderStatus(false);
    setAllDeleteChecked(false);
    setCheckboxMassiveDelete([]);
    setStatusToChangeAllOrders({});
    setOpenModalChangeAllStatus(false);
  };

  // Mutation on Delete Orders
  const onConfirmOrdersDelete = () => {
    setLoadingDeleteOrders(true);
    checkboxMassiveDelete.forEach(function (element) {
      deleteOrder({
        variables: { id: parseInt(element) },
        refetchQueries: ["sortOrdersAdmin"],
      }).then(response => {
        if (response.data.deleteOrder.status === 0) {
          setLoadingDeleteOrders(false);
        } else if (response.data.deleteOrder.status === 1) {
          setCheckboxMassiveDelete([]);
          setAllDeleteChecked(false);
          setLoadingDeleteOrders(false);
          setOpenModalConfirmDeleteOrders(false);
        }
      }).catch(err => {
        setLoadingDeleteOrders(false);
      });
    });
  };

  // onClick view order details icon
  const onClickViewOrderDatail = (id) => {
    updateOrderReadStatus({
      variables: { id: id },
      refetchQueries: ["sortOrdersAdmin", "getNumberOfNewOrders"],
    });
    window.open("/admin/order-detail/" + id, "_blank");
  };

  // Apertura modale edit status order
  const onOpenModalEditStatus = (item) => {
    setOpenModalEditStatus(true);
    setErrorStock([])
    setOrderToChangeStatus({ ...orderToChangeStatus, id: item.id, status: item.status });
  };
  // Chiusura modale  edit status order
  const onCloseModalEditStatus = () => {
    setOpenModalEditStatus(false);
    setErrorStock([])
    setOrderToChangeStatus({});
  };
  // Apertura modale conferma eliminazione ordini
  const onOpenModalConfirmDeleteOrders = () => {
    setOpenModalConfirmDeleteOrders(true);
  };
  // Chiusura modale conferma eliminazione ordini
  const onCloseModalConfirmDeleteOrders = () => {
    setOpenModalConfirmDeleteOrders(false);
  };
  // Apertura modale cambio stato massivo
  const onOpenModalChangeAllStatus = () => {
    setOpenModalChangeAllStatus(true);
  };
  // Chiusura modale cambio stato massivo
  const onCloseModalChangeAllStatus = () => {
    setOpenModalChangeAllStatus(false);
  };
  // onChange radio for change order status
  const onChangeRadioChangeStatus = (event) => {
    setOrderToChangeStatus({ ...orderToChangeStatus, [event.target.name]: event.target.value });
    if (event.target.value === "Confermato") {
      setOpenDropzone(true);
    }
  };
  // set search value
  const onChangeSearch = event => {
    setSearch(event.target.value);
  };
  // onChange Select Status
  const onChangeSelectFilterStatus = event => {
    setFilterStatus(event.target.value);
  };
  // onChange status radio in modal from change all status order
  const onChangeRadioChangeAllStatus = event => {
    setStatusToChangeAllOrders(event.target.value);
  };

  // Close Dropzone Add PDF
  const onCloseDropzone = () => {
    setOpenDropzone(false);
    setOpenModalEditStatus(false);
    setErrorStock([])
  };

  // Close Dropzone Add PDF
  const onOpenDropzone = () => {
    setOpenDropzone(true);
  };

  // onSave Dropzone when upload pdf "Ordine Confermato"
  const onSavePDF = files => {
    setPdfFile(files[0]);
    setOpenDropzone(false);
  };

  // onDelete image uploaded in form add new color
  const deletePdfUploaded = () => {
    setPdfFile(undefined);
    setOrderToChangeStatus({});
  };

  const onChangeSort = event => {
    setSort(event.target.value);
  };

  if (loadingOrders) return <LinearProgress />;
  if (errorOrders) { console.log("err"); }

  // onChange Massive Delete
  const onChangeMassiveDelete = event => {
    if (event.target.checked) {
      if (event.target.name === "all") {
        setCheckboxMassiveDelete(undefined);
        setAllDeleteChecked(true);
        if (search && search !== null && filterStatus && filterStatus !== null) {
          dataOrders.sortOrdersAdmin.filter(item => item.status.includes(filterStatus) && (item.id.toLowerCase().includes(search) || item.email.toLowerCase().includes(search) || item.vat_number.toLowerCase().includes(search))).map((item, index) => (
            checkboxMassiveDelete.push(item.id)
          ));
        } else if (search && search !== null) {
          dataOrders.sortOrdersAdmin.filter(item => item.id.toLowerCase().includes(search) || item.email.toLowerCase().includes(search) || item.vat_number.toLowerCase().includes(search)).map((item, index) => (
            checkboxMassiveDelete.push(item.id)
          ));
        } else if (filterStatus && filterStatus !== null) {
          dataOrders.sortOrdersAdmin.filter(item => item.status.includes(filterStatus)).map((item, index) => (
            checkboxMassiveDelete.push(item.id)
          ));
        } else {
          dataOrders.sortOrdersAdmin.map((item, index) => (
            checkboxMassiveDelete.push(item.id)
          ));
        }
        setCheckboxMassiveDelete([...checkboxMassiveDelete]);
      } else {
        setCheckboxMassiveDelete([...checkboxMassiveDelete, event.target.name]);
        if (checkboxMassiveDelete.length === dataOrders.sortOrdersAdmin.length - 1) {
          setAllDeleteChecked(true);
        }
      }
    } else {
      if (event.target.name === "all") {
        setCheckboxMassiveDelete([]);
        setAllDeleteChecked(false);
      } else {
        let index = checkboxMassiveDelete.indexOf(event.target.name);
        if (index >= 0) {
          checkboxMassiveDelete.splice(index, 1);
          setCheckboxMassiveDelete([...checkboxMassiveDelete]);
        }
        if (checkboxMassiveDelete.length === 0) {
          setAllDeleteChecked(false);
        }
      }
    }
  };

  return (
    <Layout
      dataOrders={dataOrders}
      openModalEditStatus={openModalEditStatus}
      onOpenModalEditStatus={onOpenModalEditStatus}
      onCloseModalEditStatus={onCloseModalEditStatus}
      orderToChangeStatus={orderToChangeStatus}
      onChangeRadioChangeStatus={onChangeRadioChangeStatus}
      onUpdateOrderStatus={onUpdateOrderStatus}
      openModalConfirmDeleteOrders={openModalConfirmDeleteOrders}
      onCloseModalConfirmDeleteOrders={onCloseModalConfirmDeleteOrders}
      onOpenModalConfirmDeleteOrders={onOpenModalConfirmDeleteOrders}
      onConfirmOrdersDelete={onConfirmOrdersDelete}
      onClickViewOrderDatail={onClickViewOrderDatail}
      search={search}
      onChangeSearch={onChangeSearch}
      loadingUpdateOrderStatus={loadingUpdateOrderStatus}
      loadingDeleteOrders={loadingDeleteOrders}
      onChangeSelectFilterStatus={onChangeSelectFilterStatus}
      filterStatus={filterStatus}
      openModalChangeAllStatus={openModalChangeAllStatus}
      onOpenModalChangeAllStatus={onOpenModalChangeAllStatus}
      onCloseModalChangeAllStatus={onCloseModalChangeAllStatus}
      onUpdateAllOrdersStatus={onUpdateAllOrdersStatus}
      onChangeRadioChangeAllStatus={onChangeRadioChangeAllStatus}
      statusToChangeAllOrders={statusToChangeAllOrders}
      onChangeMassiveDelete={onChangeMassiveDelete}
      checkboxMassiveDelete={checkboxMassiveDelete}
      allDeleteChecked={allDeleteChecked}
      openDropzone={openDropzone}
      onCloseDropzone={onCloseDropzone}
      onSavePDF={onSavePDF}
      pdfFile={pdfFile}
      onOpenDropzone={onOpenDropzone}
      deletePdfUploaded={deletePdfUploaded}
      onChangeSort={onChangeSort}
      sort={sort}
      errorStock={errorStock}
      year={year}
      setYear={setYear}
    />
  );
};

export default withRouter(Orders);
