import React from "react";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import Style from "./style.js";


const Layout = ({
  dataUsers,
  onChangeForm,
  dataPriceLists,
  editUserFormValues,
  onClickSave,
  loadingEditUser,
  dataAgents
}) => {
  const style = Style();
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ paddingBottom: "0" }}>
          <Typography variant="h4">
            Utenti
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {
            dataUsers && dataUsers.users.length > 0 ? (
              loadingEditUser ? (
                <Grid className={style.modalActionContainer} container direction="row" justify="center" alignItems="center" spacing={2}>
                  <CircularProgress />
                </Grid>
              ) : (
                <Grid container item xs={12}>
                  <Button
                    className={style.saveButton}
                    style={{ marginBottom: "1.5rem", marginTop: "1rem" }}
                    onClick={onClickSave}
                    variant="contained"
                    size="small"
                    color="primary"
                  >
                    Salva
                  </Button>
                  <Button
                    className={style.saveButton}
                    style={{ margin: "1rem 0 1.5rem 1rem" }}
                    onClick={() => window.history.back()}
                    variant="contained"
                    size="small"
                    color="primary"
                  >
                    Annulla
                  </Button>
                  {
                    dataUsers.users.map((item, index) => {
                      return (
                        <Grid key={`userlist-${index}`} item container xs={12} spacing={2} className={style.rowWrapper}>
                          <Grid item sm={12} md={3} className={style.wrapperSingleElementTypography}>
                            <Typography className={style.textFieldElement}><b>Partita IVA: </b>{item.vat_number}</Typography>
                            <Typography className={style.textFieldElement}><b>Email: </b>{item.email}</Typography>
                            <Typography className={style.textFieldElement}><b>Regione: </b>{item.vat_number_nationality === 0 ? item.region : "Estero"}</Typography>
                          </Grid>
                          <Grid item xs={12} sm={4} md={3} className={style.wrapperSingleElement}>
                            <FormControl variant="outlined" margin="normal" size="small" className={style.selectElement} fullWidth>
                              <InputLabel>Listino Estivo</InputLabel>
                              <Select
                                value={editUserFormValues[item.id]?.list_type_0 !== undefined ? editUserFormValues[item.id].list_type_0 : item.list_type_0}
                                onChange={(e) => onChangeForm(item.id, e)}
                                name="list_type_0"
                                label="Listino Estivo"
                                required
                              >
                                <MenuItem value={null}><em>Nessuno</em></MenuItem>
                                {
                                  dataPriceLists.priceLists.filter(element => element.type === 0).map((item, index) => {
                                    return (
                                      <MenuItem key={`pricelist-2-${index}`} value={item.id}>{item.name}</MenuItem>
                                    );
                                  })
                                }
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={4} md={3} className={style.wrapperSingleElement}>
                            <FormControl variant="outlined" margin="normal" size="small" className={style.selectElement} fullWidth>
                              <InputLabel>Listino Invernale</InputLabel>
                              <Select
                                value={editUserFormValues[item.id]?.list_type_1 !== undefined ? editUserFormValues[item.id].list_type_1 : item.list_type_1}
                                onChange={(e) => onChangeForm(item.id, e)}
                                name="list_type_1"
                                label="Listino Invernale"
                                required
                              >
                                <MenuItem value={null}><em>Nessuno</em></MenuItem>
                                {
                                  dataPriceLists.priceLists.filter(element => element.type === 1).map((item, index) => {
                                    return (
                                      <MenuItem key={`pricelist-1-${index}`} value={item.id}>{item.name}</MenuItem>
                                    );
                                  })
                                }
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={4} md={3} className={style.wrapperSingleElement}>
                            {
                              dataAgents ? (
                                <FormControl variant="outlined" margin="normal" fullWidth size="small" >
                                  <InputLabel>Agente</InputLabel>
                                  <Select
                                    name="agent_id"
                                    value={editUserFormValues[item.id]?.agent_id !== undefined ? editUserFormValues[item.id].agent_id : (item.agent?.id ?? "")}
                                    onChange={(e) => onChangeForm(item.id, e)}
                                    label="Agente"
                                  >
                                    <MenuItem value=""><em>Nessun Agente</em></MenuItem>
                                    {
                                      dataAgents && dataAgents.agents.length > 0 ? (
                                        dataAgents.agents.map((agent, index) => (
                                          <MenuItem key={`agent_${index}`} value={agent.id}>{agent.nominative}</MenuItem>
                                        ))

                                      ) : null
                                    }
                                  </Select>
                                </FormControl>
                              ) : null
                            }
                            {/* <TextField
                              className={style.textFieldElement}
                              name="agent"
                              value={editUserFormValues[item.id]?.agent !== undefined ? editUserFormValues[item.id].agent?.nominative : item.agent?.nominative}
                              onChange={(e) => onChangeForm(item.id, e)}
                              size="small"
                              variant="outlined"
                              margin="normal"
                              label="Agente"
                            /> */}
                          </Grid>
                          <Divider className={style.divider} />
                        </Grid>
                      );
                    })
                  }
                  <Button
                    className={style.saveButton}
                    style={{ marginBottom: "1.5rem", marginTop: "1rem" }}
                    onClick={onClickSave}
                    variant="contained"
                    size="small"
                    color="primary"
                  >
                    Salva
                  </Button>
                  <Button
                    className={style.saveButton}
                    style={{ margin: "1rem 0 1.5rem 1rem" }}
                    onClick={() => window.history.back()}
                    variant="contained"
                    size="small"
                    color="primary"
                  >
                    Annulla
                  </Button>
                </Grid>
              )
            ) : (<p style={{ margin: "0" }}>Non ci sono utenti</p>)
          }
        </Grid>
      </Grid>
    </>
  );
};

export default Layout;
