import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Layout from "./layout";
import { useQuery } from "@apollo/react-hooks";
import { useMutation } from "@apollo/react-hooks";
import { USERS, PRICE_LIST, AGENTS } from "../../../query";
import { EDIT_MASSIVE_USER } from "../../../mutation";
import LinearProgress from "@material-ui/core/LinearProgress";

const UsersEdit = ({ history, match }) => {

  // Loading
  const [loadingEditUser, setLoadingEditUser] = useState(false);
  const [editUserFormValues, setEditUserFormValues] = useState([]);

  // FormValues
  //const editUserFormValues = [];

  // QUERY
  const { loading: loadingUsers, error: errorUsers, data: dataUsers } = useQuery(USERS);
  const { loading: loadingPriceLists, error: errorPriceLists, data: dataPriceLists } = useQuery(PRICE_LIST);
  const { loading: loadingAgents, error: errorAgents, data: dataAgents } = useQuery(AGENTS);


  // MUTATION
  const [editMassiveUser] = useMutation(EDIT_MASSIVE_USER);

  function onChangeForm(id, e) {
    let name = e.target.name;
    let value = e.target.value;
    setEditUserFormValues({...editUserFormValues, [id]: { ...editUserFormValues[id], [name]: value} });
  }

  const onClickSave = () => {
    setLoadingEditUser(true);
    editMassiveUser({
      variables: {data: JSON.stringify(editUserFormValues)},
      refetchQueries: ["users"],
    }).then(response => {
      if(response.data.editMassiveUser.status === 1){
        setLoadingEditUser(false);
        window.location.pathname ="/admin/users";
      }else if(response.data.editMassiveUser.status === 0){
        setLoadingEditUser(false);
      }
    });
  };

  // Verify query for registration permission
  if ( loadingUsers || loadingPriceLists || loadingAgents) return <LinearProgress />;
  if ( errorUsers || errorPriceLists || errorAgents) { history.push("/"); }

  return (
    <Layout
      dataUsers={dataUsers}
      editUserFormValues={editUserFormValues}
      dataPriceLists={dataPriceLists}
      onChangeForm={onChangeForm}
      onClickSave={onClickSave}
      loadingEditUser={loadingEditUser}
      dataAgents={dataAgents}
    />
  );
};

export default withRouter(UsersEdit);
