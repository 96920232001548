/* eslint-disable no-undef */
import React from "react";
import { withRouter } from "react-router-dom";
import { MY_FAVORITES } from "../../../query";
import { useQuery } from "@apollo/react-hooks";
import { useMutation } from "@apollo/react-hooks";
import { ADD_REMOVE_TO_FAVORITES } from "../../../mutation";
import Skeleton from "@material-ui/lab/Skeleton";
import Style from "./layout/style";
import Layout from "./layout";

const Favorites = ({ history, match }) => {
  const style = Style();
  const [addRemoveToFavorites] = useMutation(ADD_REMOVE_TO_FAVORITES);
  const { loading: loadingMyFavorites, error: errorMyFavorites, data: dataMyFavorites } = useQuery(MY_FAVORITES);

  const onFavorite = productId => {
    addRemoveToFavorites({
      variables: { product_id: productId },
      refetchQueries: ["me", "myFavorites"],
    });
  };

  const onGoToShop = () => {
    history.push("/shop");
  };

  const onViewProduct = productId => {
    history.push("/product/" + productId);
  };

  if(loadingMyFavorites){
    return (
      <div className={style.productsSkeleton} style={{ marginBottom: "-3rem"}} >
        <Skeleton className={style.skeleton} variant="rect" height={419.5} />
        <Skeleton className={style.skeleton} variant="rect" height={419.5} />
        <Skeleton className={style.skeleton} variant="rect" height={419.5} />
        <Skeleton className={style.skeleton} variant="rect" height={419.5} />
      </div>
    );
  }

  if(errorMyFavorites){
    return console.log("err");
  }

  return (
    <Layout
      dataMyFavorites={dataMyFavorites}
      onFavorite={onFavorite}
      onGoToShop={onGoToShop}
      onViewProduct={onViewProduct}
    />
  );
};

export default withRouter(Favorites);
