import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password){
      status
      access_token
      token_type
      expires_at
    }
  }
`;

export const LOGOUT = gql`
  mutation logout{
    logout{
      status
    }
  }
`;

export const REGISTER = gql`
  mutation register($vat_number_nationality: Int!, $business_name: String!, $vat_number: String!, $email: String!, $password: String! , $password_confirmation: String!, $region: String) {
    register(vat_number_nationality: $vat_number_nationality, business_name: $business_name, vat_number: $vat_number, email: $email, password: $password, password_confirmation: $password_confirmation, region: $region){
      status
    }
  }
`;

export const REGISTER_BY_ADMIN = gql`
  mutation registerByAdmin($vat_number_nationality: Int!, $business_name: String!, $vat_number: String!, $email: String!, $code: String!, $password: String! , $password_confirmation: String!, $region: String, $list_type_0: ID, $list_type_1: ID, $agent: String) {
    registerByAdmin(vat_number_nationality: $vat_number_nationality, business_name: $business_name, vat_number: $vat_number, email: $email, code: $code, password: $password, password_confirmation: $password_confirmation, region: $region, list_type_0: $list_type_0, list_type_1: $list_type_1, agent: $agent ){
      status
    }
  }
`;

export const PASSWORD_RESET_CREATE = gql`
  mutation passwordResetCreate($email: String!) {
    passwordResetCreate(email: $email){
      status
    }
  }
`;

export const PASSWORD_RESET_CHANGE = gql`
  mutation passwordResetChange($token: String!, $password: String! , $password_confirmation: String!) {
    passwordResetChange(token: $token, password: $password, password_confirmation: $password_confirmation){
      status
    }
  }
`;

/* Insert Vat Number in registrations table for permission user registration  */
export const CREATE_REGISTER = gql`
  mutation createRegister($vatNumber: String!, $code: String!, $list_type_0: ID!, $list_type_1: ID!) {
    createRegister(vat_number: $vatNumber, code: $code, list_type_0: $list_type_0, list_type_1: $list_type_1){
      status
    }
  }
`;

/* Delete registration permission by id */
export const REMOVE_REGISTER = gql`
  mutation removeRegister($id: ID!) {
    removeRegister(id: $id){
      status
    }
  }
`;

/* Delete user by id */
export const REMOVE_USER = gql`
  mutation removeUser($id: ID!) {
    removeUser(id: $id){
      status
    }
  }
`;

/* Update user by id */
export const UPDATE_USER = gql`
  mutation updateUser($id: ID!, $email: String!, $code: String, $business_name: String!, $vat_number: String!, $region: String, $list_type_0: ID, $list_type_1: ID, $agent: ID, $password: String) {
    updateUser(id: $id, email: $email, code: $code, business_name: $business_name, vat_number: $vat_number, region: $region, list_type_0: $list_type_0, list_type_1: $list_type_1, agent: $agent, password: $password){
      status
    }
  }
`;

/* Change or create registration token */
export const CREATE_UPDATE_REGISTRATION_TOKEN = gql`
  mutation createUpdateRegistrationToken($registration_token: String) {
    createUpdateRegistrationToken(registration_token: $registration_token){
      status
    }
  }
`;

/* CATEGORY   */
export const ADD_GENDER = gql`
  mutation addGender($name: String!) {
    addGender(name: $name){
      status
      id
    }
  }
`;

/* Delete genders checked by id */
export const REMOVE_GENDER = gql`
  mutation removeGender($id: ID!) {
    removeGender(gender_id: $id){
      status
    }
  }
`;

/* Update gender name  by id */
export const UPDATE_GENDER = gql`
  mutation updateGender($id: ID!, $name: String!) {
    updateGender(id: $id, name: $name){
      status
    }
  }
`;

export const ADD_SEASON = gql`
  mutation addSeason($name: String!) {
    addSeason(name: $name){
      status
      id
    }
  }
`;

/* Delete seasons checked by id */
export const REMOVE_SEASON = gql`
  mutation removeSeason($id: ID!) {
    removeSeason(season_id: $id){
      status
    }
  }
`;

/* Update season name  by id */
export const UPDATE_SEASON = gql`
  mutation updateSeason($id: ID!, $name: String!) {
    updateSeason(id: $id, name: $name){
      status
    }
  }
`;

export const ADD_FINENESS = gql`
  mutation addFineness($name: String!) {
    addFineness(name: $name){
      status
      id
    }
  }
`;

/* Delete fineness checked by id */
export const REMOVE_FINENESS = gql`
  mutation removeFineness($id: ID!) {
    removeFineness(fineness_id: $id){
      status
    }
  }
`;

/* Update fineness name  by id */
export const UPDATE_FINENESS = gql`
  mutation updateFineness($id: ID!, $name: String!) {
    updateFineness(id: $id, name: $name){
      status
    }
  }
`;

export const ADD_TAG = gql`
  mutation addTag($name: String!) {
    addTag(name: $name){
      status
      id
    }
  }
`;

/* Delete tags checked by id */
export const REMOVE_TAG = gql`
  mutation removeTag($id: ID!) {
    removeTag(tag_id: $id){
      status
    }
  }
`;

/* Update tag name  by id */
export const UPDATE_TAG = gql`
  mutation updateTag($id: ID!, $name: String!) {
    updateTag(id: $id, name: $name){
      status
    }
  }
`;

export const ADD_COMPOSITION = gql`
  mutation addComposition($name: String!) {
    addComposition(name: $name){
      status
      id
    }
  }
`;

/* Delete compositions checked by id */
export const REMOVE_COMPOSITION = gql`
  mutation removeComposition($id: ID!) {
    removeComposition(composition_id: $id){
      status
    }
  }
`;

/* Update tag composition  by id */
export const UPDATE_COMPOSITION = gql`
  mutation updateComposition($id: ID!, $name: String!) {
    updateComposition(id: $id, name: $name){
      status
    }
  }
`;

/* Delete compositions checked by id */
export const PRODUCTS_SEARCH = gql`
  mutation productsSearch($filter: String!, $gender_id: ID, $fineness_id: ID, $season_id: ID, $tags_id: ID, $compositions_id: ID,
) {
    productsSearch(filter: $filter, gender_id: $gender_id, fineness_id: $fineness_id, season_id: $season_id, tags_id: $tags_id, compositions_id: $compositions_id){
      id
      name
      code
      image
      price
      sale
      percentage_discount
      stock
      bestseller
      luxury
      discountColors
      fineness {
        name
      }
    }
  }
`;

/* Add Remove to favorite */
export const ADD_REMOVE_TO_FAVORITES = gql`
  mutation addRemoveToFavorites($product_id: ID!) {
    addRemoveToFavorites(product_id: $product_id){
      status
    }
  }
`;

/* Add SizeGroup */
export const ADD_GROUP_SIZE = gql`
  mutation addGroupSize($name: String!) {
    addGroupSize(name: $name){
      status
    }
  }
`;

/* Remove Size */
export const REMOVE_GROUP_SIZE = gql`
  mutation removeGroupSize($id: ID!) {
    removeGroupSize(id: $id){
      status
    }
  }
`;

/* Add Size */
export const ADD_SIZE = gql`
  mutation addSize($name: String!, $group_size_id: ID!) {
    addSize(name: $name, group_size_id: $group_size_id){
      status
    }
  }
`;

/* Remove Size */
export const REMOVE_SIZE = gql`
  mutation removeSize($id: ID!) {
    removeSize(id: $id){
      status
    }
  }
`;

/* Add to Cart */
export const ADD_ON_CART = gql`
  mutation addOnCart($product_id: ID!, $values: String, $color_id: ID) {
    addOnCart(product_id: $product_id, values: $values, color_id: $color_id){
      status
      warning
    }
  }
`;

/* Remove on Cart */
export const REMOVE_ON_CART = gql`
  mutation removeOnCart($id: ID!) {
    removeOnCart(id: $id ){
      status
    }
  }
`;

/* Edit on Cart */
export const EDIT_QUANTITY_ON_CART = gql`
  mutation editQuantityOnCart($id: ID!, $product_id: ID, $product_price: Float, $quantity: Int) {
    editQuantityOnCart(id: $id, product_id: $product_id, product_price: $product_price, quantity: $quantity ){
      status
    }
  }
`;

/* Remove on Cart */
export const CREATE_ORDER = gql`
  mutation createOrder($note: String) {
    createOrder(note: $note){
      status
      order_id
      failed_product
    }
  }
`;

/* Update Order Status */
export const UPDATE_ORDER_STATUS = gql`
  mutation updateOrderStatus($id: ID!, $status: String, $pdf: Upload) {
    updateOrderStatus(id: $id, status: $status, pdf: $pdf) {
      status
      productsIds
    }
  }
`;

/* Update Order Read Status after click first time view order detail */
export const UPDATE_ORDER_READ_STATUS = gql`
  mutation updateOrderReadStatus($id: ID!) {
    updateOrderReadStatus(id: $id) {
      status
    }
  }
`;

/* Delete Order Admin */
export const DELETE_ORDER = gql`
  mutation deleteOrder($id: ID!) {
    deleteOrder(id: $id) {
      status
    }
  }
`;

/* Remove PriceList by ID*/
export const REMOVE_PRICE_LIST = gql`
  mutation removePriceList($id_list_type_0: ID!, $id_list_type_1: ID!) {
    removePriceList(list_type_0: $id_list_type_0, list_type_1: $id_list_type_1 ){
      status
    }
  }
`;

/* Add PriceList*/
export const ADD_PRICE_LIST = gql`
  mutation addPriceList($name: String) {
    addPriceList(name: $name ){
      status
    }
  }
`;

/* Add New Color FOLDER */
export const ADD_NEW_COLOR_FOLDER = gql`
  mutation addNewColorFolder($name: String!) {
    addNewColorFolder(name: $name){
      status
      id
      name
    }
  }
`;

/* Edit Color FOLDER */
export const EDIT_COLOR_FOLDER = gql`
  mutation editColorFolder($id: ID!, $name: String!) {
    editColorFolder(id: $id, name: $name){
      status
    }
  }
`;

/* Delete Color FOLDER */
export const DELETE_COLOR_FOLDER = gql`
  mutation deleteColorFolder($id: ID!) {
    deleteColorFolder(id: $id){
      status
    }
  }
`;

/* Add New Color */
export const ADD_NEW_COLOR = gql`
  mutation addNewColor($file: [Upload]!, $hex: String!, $color_folder_id: ID! ) {
    addNewColor(file: $file, hex: $hex, color_folder_id: $color_folder_id){
      status
      error
    }
  }
`;

/* Remove Color */
export const REMOVE_COLOR = gql`
  mutation removeColor($id: ID! ) {
    removeColor(id: $id ){
      status
    }
  }
`;

/* Remove Color */
export const UPDATE_COLOR = gql`
  mutation updateColor($id: ID!, $file: Upload, $hex: String, $image: String,) {
    updateColor(id: $id, file: $file, hex: $hex, image: $image ){
      status
    }
  }
`;

/* Edit Price List Product Price */
export const EDIT_PRICE_LIST_PRODUCT_PRICE = gql`
  mutation editPriceListProductPrice($list_id: ID!, $product_id_price: String) {
    editPriceListProductPrice(list_id: $list_id, product_id_price: $product_id_price ){
      status
    }
  }
`;

/* Add Color Product */
export const ADD_REMOVE_COLOR_TO_PRODUCT = gql`
  mutation addRemoveColorToProduct($product_id: ID, $color_id: ID) {
    addRemoveColorToProduct(product_id: $product_id, color_id: $color_id ){
      status
    }
  }
`;

/* Remove all color from product id */
export const REMOVE_ALL_COLORS_PRODUCT = gql`
  mutation removeAllColorsProduct($product_id: ID) {
    removeAllColorsProduct(product_id: $product_id){
      status
    }
  }
`;

/* Edit Price List Product Price */
export const ADD_REMOVE_PRODUCT_GENDER = gql`
  mutation addRemoveProductGender($product_id: ID, $gender_id: ID) {
    addRemoveProductGender(product_id: $product_id, gender_id: $gender_id ){
      status
    }
  }
`;

/* Edit Price List Product Price */
export const ADD_REMOVE_PRODUCT_FINENESS = gql`
  mutation addRemoveProductFineness($product_id: ID, $fineness_id: ID) {
    addRemoveProductFineness(product_id: $product_id, fineness_id: $fineness_id ){
      status
    }
  }
`;

/* Edit Price List Product Price */
export const ADD_REMOVE_PRODUCT_SEASON = gql`
  mutation addRemoveProductSeason($product_id: ID, $season_id: ID) {
    addRemoveProductSeason(product_id: $product_id, season_id: $season_id ){
      status
    }
  }
`;

/* Edit Price List Product Price */
export const ADD_REMOVE_PRODUCT_TAG = gql`
  mutation addRemoveProductTag($product_id: ID, $tag_id: ID) {
    addRemoveProductTag(product_id: $product_id, tag_id: $tag_id ){
      status
    }
  }
`;

/* Remove all productTag by id product */
export const REMOVE_ALL_PRODUCT_TAG = gql`
  mutation removeAllProductTag($product_id: ID) {
    removeAllProductTag(product_id: $product_id){
      status
    }
  }
`;

/* Edit Price List Product Price */
export const ADD_REMOVE_PRODUCT_COMPOSITION = gql`
  mutation addRemoveProductComposition($product_id: ID, $composition_id: ID) {
    addRemoveProductComposition(product_id: $product_id, composition_id: $composition_id ){
      status
    }
  }
`;

/* Remove all productTag by id product */
export const REMOVE_ALL_PRODUCT_COMPOSITION = gql`
  mutation removeAllProductComposition($product_id: ID) {
    removeAllProductComposition(product_id: $product_id){
      status
    }
  }
`;

/* Add Product */
export const ADD_PRODUCT = gql`
  mutation addProduct($name: String, $code: String, $file: Upload, $type: Int!) {
    addProduct(name: $name, code: $code, file: $file, type: $type){
      status
      product_id
    }
  }
`;
/* Remove Product */
export const REMOVE_PRODUCT = gql`
  mutation removeProduct($id: ID!) {
    removeProduct(id: $id){
      status
    }
  }
`;

/* Edit Update Product Base */
export const UPDATE_PRODUCT = gql`
  mutation updateProduct($id: ID!, $name: String, $code: String, $fineness_description: String, $weight_description: String, $description: String, $file: Upload, $group_size_id: ID, $color_folder_id: ID, $stock: Int, $type: Int, $bestseller: Int, $luxury: Int, $not_visible: Int, $sale: Float, $percentage_discount: Float, $gallery_position: String) {
    updateProduct(id: $id, name: $name, code: $code, fineness_description: $fineness_description, weight_description: $weight_description, description: $description, file: $file, group_size_id: $group_size_id, color_folder_id: $color_folder_id, stock: $stock, type: $type, bestseller: $bestseller, luxury: $luxury, not_visible: $not_visible, sale: $sale, percentage_discount: $percentage_discount, gallery_position: $gallery_position ){
      status
    }
  }
`;

/* Edit Update Product Base */
export const ADD_PRODUCT_GALLERY_IMAGE = gql`
  mutation addProductGalleryImage($product_id: ID!, $file: Upload) {
    addProductGalleryImage(product_id: $product_id, file: $file ){
      status
    }
  }
`;

/* Edit Update Product Base */
export const REMOVE_PRODUCT_GALLERY_IMAGE = gql`
  mutation removeProductGalleryImage($id: ID!) {
    removeProductGalleryImage(id: $id){
      status
    }
  }
`;

/* Edit Update Product Tag in Product Edit page */
export const UPDATE_PRODUCT_TAG = gql`
  mutation updateProductTag($id: [ID]!, $product_id: ID) {
    updateProductTag(id: $id, product_id: $product_id ){
      status
    }
  }
`;

/* Edit Update Product Composition in Product Edit page */
export const UPDATE_PRODUCT_COMPOSITION = gql`
  mutation updateProductComposition($id: [ID]!, $product_id: ID) {
    updateProductComposition(id: $id, product_id: $product_id ){
      status
    }
  }
`;

/* Edit Update Product Color in Product Edit page */
export const ADD_SIZE_COLOR_STOCK = gql`
  mutation addSizeColorStock($stock: String, $product_id: ID, $color_discount: String) {
    addSizeColorStock(stock: $stock, product_id: $product_id, color_discount: $color_discount ){
      status
    }
  }
`;

/* Edit Update Product Color in Product Edit page */
export const UPDATE_PRODUCT_PRICE_LIST = gql`
  mutation updateProductPriceList($list_id_price: String, $product_id: ID) {
    updateProductPriceList(list_id_price: $list_id_price, product_id: $product_id ){
      status
    }
  }
`;

/* Edit Price List Product Price */
export const UPDATE_PASSWORD = gql`
  mutation updatePassword($password: String!, $password_confirmation: String!) {
    updatePassword(password: $password, password_confirmation: $password_confirmation ){
      status
    }
  }
`;

/* Delete abandoned user carts one month past */
export const DELETE_ABANDONED_CARTS = gql`
  mutation deleteAbandonedCarts {
    deleteAbandonedCarts{
      status
    }
  }
`;

/* Delete abandoned user carts one month past */
export const REFRESH_TOKEN = gql`
  mutation refreshToken($refresh_token: String) {
    refreshToken(refresh_token: $refresh_token){
      status
      access_token
      refresh_token
      token_type
      expires_at
    }
  }
`;

/* Edit massive user admin function */
export const EDIT_MASSIVE_USER = gql`
  mutation editMassiveUser($data: String) {
    editMassiveUser(data: $data){
      status
    }
  }
`;

/* Add Agent */
export const ADD_AGENT = gql`
  mutation addAgent($nominative: String) {
    addAgent(nominative: $nominative){
      status
    }
  }
`;

/* Remove Agent */
export const REMOVE_AGENT = gql`
  mutation removeAgent($id: ID) {
    removeAgent(id: $id){
      status
    }
  }
`;

/* Set preorder Agent */
export const SET_PREODER_AGENT = gql`
  mutation setPreorderAgent($id: ID) {
    setPreorderAgent(id: $id){
      status
    }
  }
`;

/* Remove Row Order Detail */
export const DELETE_ORDER_DETAIL = gql`
  mutation deleteOrderDetail($id: ID!) {
    deleteOrderDetail(id: $id){
      status
    }
  }
`;

/* Edit Row Order Detail */
export const EDIT_ORDER_DETAIL = gql`
  mutation editOrderDetail($id: ID!, $color_id: Int, $size_id: Int, $quantity: Int, $order_id: ID, $product_id: Int) {
    editOrderDetail(id: $id, color_id: $color_id, size_id: $size_id, quantity: $quantity, order_id: $order_id, product_id: $product_id){
      status
      error
    }
  }
`;

/* Add Row Order Detail */
export const ADD_ORDER_DETAIL = gql`
  mutation addOrderDetail($color_id: Int, $size_id: Int, $quantity: Int, $order_id: ID, $product_id: Int, $total: Float, $sub_total: Float, $product_price: Float, $original_price: Float ) {
    addOrderDetail(color_id: $color_id, size_id: $size_id, quantity: $quantity, order_id: $order_id, product_id: $product_id, total: $total, sub_total: $sub_total, product_price: $product_price, original_price: $original_price){
      status
      error
    }
  }
`;

/* Edit Order Note */
export const EDIT_ORDER_NOTE = gql`
  mutation editOrderNote($order_id: ID, $note: String ) {
    editOrderNote(order_id: $order_id, note: $note){
      status
    }
  }
`;

/* Update Show Banner */
export const UPDATE_SHOW_BANNER = gql`
  mutation updateShowBanner($name: String!, $show: Int!) {
    updateShowBanner(name: $name, show: $show){
      status
    }
  }
`;

export const EDIT_REGISTER = gql`
  mutation editRegister($permission_id: ID!, $vat_number: String, $code: String, $list_type_0: ID, $list_type_1: ID) {
    editRegister(permission_id: $permission_id, vat_number: $vat_number, code: $code, list_type_0: $list_type_0, list_type_1: $list_type_1){
      status
    }
  }
`;

export const HIDE_SEASON = gql`
  mutation hideSeason($season_id: ID!, $value: Int) {
    hideSeason(season_id: $season_id, value: $value){
      status
    }
  }
`;

export const PREORDER_SEASON = gql`
  mutation preorderSeason($season_id: ID!, $value: Int) {
    preorderSeason(season_id: $season_id, value: $value){
      status
    }
  }
`;

export const CREATE_REGISTRATION_REQUEST = gql`
  mutation createRegistrationRequest($email: String!, $business_name: String!, $vat_number: String!, $sdi: String!, $destination: String!, $registered_office: String!, $nation: String!, $phone: String!) {
    createRegistrationRequest(email: $email, business_name: $business_name, vat_number: $vat_number, sdi: $sdi, destination: $destination, registered_office: $registered_office, nation: $nation, phone: $phone){
      status
    }
  }
`;