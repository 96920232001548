import React, { useState } from "react";
import {withRouter} from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { LOGOUT } from "../../mutation";
import Layout from "./layout";
import { ME, GET_NUMBER_OF_NEW_ORDERS } from "../../query";
import { useQuery } from "@apollo/react-hooks";
import LinearProgress from "@material-ui/core/LinearProgress";
import { accessTokenIsExpired } from "../../utils";

const LayoutAdmin = ({ history, children }) => {
  //Responsive
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [userMenu, setUserMenu] = useState(false);
  const [anchorMenu, setAnchorMenu] = useState(null);
  // MUTATION
  const [logout] = useMutation(LOGOUT);
  // QUERY
  const { loading: loadingMe, error: errorMe, data: dataMe } = useQuery(ME);
  const { loading: loadingNumberNewOrders, error: errorNumberNewOrders, data: dataNumberNewOrders } = useQuery(GET_NUMBER_OF_NEW_ORDERS, {
    pollInterval: 5000,
  });

  const openUserMenu = (event) => {
    setAnchorMenu(event.currentTarget);
    setUserMenu(true);
  };

  const closeUserMenu = () => {
    setUserMenu(false);
  };

  const onLogout = () => {
    setUserMenu(false);
    logout({}).then(response => {
      // If response of login is 0 set error of login
      if(response.data.logout.status === 1){
        localStorage.removeItem("accessToken");
        history.push("/");
      }
    }).catch(err =>{});
  };
  // OnClick Burger Menu Icon
  const onClickMenu = () => {
    setMobileOpen(!mobileOpen);
  };

  if(accessTokenIsExpired()){
    localStorage.removeItem("accessToken");
    window.location.pathname = "/";
  }

  // Verify result query
  if (loadingMe || loadingNumberNewOrders) return <LinearProgress />;
  /*
  if (errorMe || errorNumberNewOrders) {
    localStorage.removeItem("accessToken");
    history.push("/");
  }
  */

  return (
    <Layout
      children={children}
      mobileOpen={mobileOpen}
      onClickMenu={onClickMenu}
      userMenu={userMenu}
      anchorMenu={anchorMenu}
      openUserMenu={openUserMenu}
      closeUserMenu={closeUserMenu}
      onLogout={onLogout}
      dataMe={dataMe}
      dataNumberNewOrders={dataNumberNewOrders}
      history={history}
    />
  );
};

export default withRouter(LayoutAdmin);
