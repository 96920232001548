import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import Style from "./style.js";

const Layout = ({
  dataGroupSize,
  onChangeSizeGroup,
  sizeGroupFormValues,
  onAddSizeGroup,
  onCloseModalEditSizeGroup,
  openModalEditSizeGroup,
  onOpenModalEditSizeGroup,
  sizeGroupToEdit,
  sizeFormValues,
  onChangeSize,
  onAddSize,
  sizeError,
  onRemoveSizes,
  onRemoveGroupSizes,
  onOpenConfirmDeleteGroupSize,
  onCloseConfirmDeleteGroupSize,
  openModalConfirmDeleteGroupSize,
  sizeGroupToDelete,
  loadingRemoveGroupSize,
  onClickSizeBox,
  sizesToDelete,
  loadingRemoveSizes,
  sizeGroupError,
  loadingAddGroupSize,
}) => {
  const style = Style();
  return (
    <>
      <Grid xl={12}>
        <Typography variant="h4">
          Taglie
        </Typography>
        <Grid>
          <TextField
            className={style.textFieldAddNew}
            onChange={onChangeSizeGroup}
            value={sizeGroupFormValues["name"]}
            name="name"
            label="Gruppo Taglie"
            variant="outlined"
            size="small"
            margin="normal"
            error={sizeGroupError}
          />
          <Button
            className={style.buttonAddNew}
            variant="contained"
            size="medium"
            color="primary"
            onClick={onAddSizeGroup}
            disabled={sizeGroupFormValues["name"] === undefined || sizeGroupFormValues["name"] === "" ? true : false}
          >
            Aggiungi
          </Button>
        </Grid>
        {
          sizeGroupError ? (
            <Typography variant="caption" color="error" style={{paddingLeft: "1rem"}}>Inserire un nome e verificare che non sia già esistente</Typography>
          ) : (
            <></>
          )
        }
        {
          dataGroupSize && dataGroupSize.groupSizes.length > 0 ? (
            <>
              {
                dataGroupSize.groupSizes.map((item, index) => {
                  return (
                    <>
                      <Grid container xs={12} key={index} spacing={0} className={style.wrapperTitleSizeGroup}>
                        <Grid item xs={11}>
                          <b>{item.name}</b>
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton aria-label="edit" className={style.margin} size="small" onClick={() => onOpenModalEditSizeGroup(item)}>
                            <AddIcon fontSize="default" style={{color: "#ffffff"}} />
                          </IconButton>
                          <IconButton aria-label="edit" className={style.margin} size="small" onClick={() => onOpenConfirmDeleteGroupSize(item)}>
                            <DeleteIcon color="error" fontSize="default" />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <Grid container xs={12} key={index} spacing={3} className={style.wrapperSizes}>
                        {
                          item.sizes.length > 0 ? (
                            <>
                              {
                                item.sizes.map((subitem) => {
                                  return(
                                    <Grid key={index} item className={sizesToDelete.includes(subitem.id) ? style.sizeBySizeGroupWrapperClicked : style.sizeBySizeGroupWrapper} onClick={() => onClickSizeBox(subitem.id)} >
                                      {subitem.name}
                                    </Grid>
                                  );
                                })
                              }
                            </>
                          ) : (
                            <em className={style.wrapperTextNoSizes}>Non ci sono taglie inserite</em>
                          )
                        }
                      </Grid>
                    </>
                  );
                })
              }
              {
                loadingAddGroupSize ? (
                  <Grid className={style.modalActionContainer} container direction="row" justify="center" alignItems="center" spacing={2}>
                    <CircularProgress />
                  </Grid>
                ) : (
                  <></>
                )
              }
            </>
          ) : (
            <em>Non ci sono gruppi taglie</em>
          )
        }
        {
          dataGroupSize && dataGroupSize.groupSizes.length > 0 && sizesToDelete.length > 0 ? (
            <Grid container xs={12} direction="row" justify="flex-start" alignItems="center" style={{margin: "2rem 0"}}>
              <Button
                variant="contained"
                onClick={onRemoveSizes}
                size="medium"
                color="primary"
                disabled={sizesToDelete.length > 0 ? false : true}
              >
                Rimuovi
              </Button>
            </Grid>
          ) : (
            <></>
          )
        }
      </Grid>
      <Modal
        open={openModalEditSizeGroup}
        onClose={onCloseModalEditSizeGroup}
      >
        <Grid container item xs={11} sm={11} md={5} lg={5} xl={5} className={style.paperModal}>
          <IconButton className={style.modalButtonCloseIcon} onClick={onCloseModalEditSizeGroup}>
            <CloseIcon className={style.modalCloseIcon} fontSize={"large"} />
          </IconButton>
          {
            sizeGroupToEdit !== undefined ? (
              <>
                <Grid container xs={12} direction="row" justify="center" alignItems="center" >
                  <Grid item xs={12}>
                    <Typography variant="h4" color="primary" align="center" gutterBottom >
                      {sizeGroupToEdit.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="name"
                      value={sizeFormValues.name}
                      onChange={onChangeSize}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item container direction="row" justify="center" alignItems="center" xs={12} style={{marginTop: "1rem"}}>
                    <Button
                      variant="contained"
                      onClick={onAddSize}
                      size="medium"
                      color="primary"
                      //disabled={disabledSaveEditUser}
                    >
                      Salva
                    </Button>
                  </Grid>
                </Grid>
              </>
            ) : (
              <></>
            )
          }
        </Grid>
      </Modal>
      <Modal
        open={openModalConfirmDeleteGroupSize}
        onClose={onCloseConfirmDeleteGroupSize}
      >
        <Grid container item xs={11} sm={11} md={5} lg={5} xl={5} className={style.paperModal}>
          <IconButton className={style.modalButtonCloseIcon} onClick={onCloseConfirmDeleteGroupSize}>
            <CloseIcon className={style.modalCloseIcon} fontSize={"large"} />
          </IconButton>
          <Grid container xs={12} direction="row" justify="center" alignItems="center" >
            <Typography variant="h4" color="error" align="center" gutterBottom >
              Attenzione
            </Typography>
          </Grid>
          <Grid container xs={12} direction="row" justify="center" alignItems="center" >
            <Typography variant="body1" align="center" gutterBottom >
              Sei sicuro di volere eliminare il gruppo taglie <b>{sizeGroupToDelete.name}</b> ?
            </Typography>
          </Grid>
          {
            loadingRemoveGroupSize ? (
              <Grid className={style.modalActionContainer} container direction="row" justify="center" alignItems="center" spacing={2}>
                <CircularProgress />
              </Grid>
            ) : (
              <Grid className={style.modalActionContainer} container direction="row" justify="center" alignItems="center" spacing={2}>
                <Grid item >
                  <Button
                    type='submit'
                    onClick={onCloseConfirmDeleteGroupSize}
                    variant="contained"
                    size="large"
                    color="primary"
                  >
                    Annulla
                  </Button>
                </Grid>
                <Grid item >
                  <Button
                    type='submit'
                    onClick={() => onRemoveGroupSizes(sizeGroupToDelete.id)}
                    variant="contained"
                    size="large"
                    color="primary"
                  >
                    Conferma
                  </Button>
                </Grid>
              </Grid>
            )
          }
        </Grid>
      </Modal>
    </>
  );
};

export default Layout;
