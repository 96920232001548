import { gql } from "@apollo/client";

// AUTHENTICATION QUERY //
export const GET_REGISTRATION_TOKEN = gql`
  query registration_token($id: ID){
    registration_token(id: $id) {
      registration_token
    }
  }
`;

// USER QUERY //
export const ME = gql`
  query me{
    me{
      email
      vat_number
      vat_number_nationality
      role
      list_id
      list_type_0
      list_type_1
      agent {
        id
        preorder
      }
      orders{
        id
      }
      favorites{
        product{
          id
          name
          code
          image
          type
        }
      }
      cart {
        id
        product{
            id
            name
            image
        }
        size{
            id
            name
        }
        color{
            id
            hex
            image
        }
        quantity
        total
      }
    }
  }
`;

// GET ALL REGISTRATION PERMISSION //
export const GET_ALL_REGISTRATION_PERMISSION = gql`
  query registrations{
    registrations {
      id
      list_type_0
      list_type_1
      vat_number
      code
      created_at
    }
  }
`;

export const SORT_REGISTRATION_PERMISSION = gql`
  query sortPermissionsAdmin($sort: String){
    sortPermissionsAdmin(sort: $sort) {
      id
      list_type_0
      list_type_1
      vat_number
      code
      created_at
    }
  }
`;

// GET ALL USER WITH ROLE CUSTOMER //
export const USERS = gql`
  query users{
    users(role: "customer") {
      id
      business_name
      vat_number
      vat_number_nationality
      email
      code
      list_type_0
      list_type_1
      role
      region
      agent{
        id
        nominative
        preorder
      }
      created_at
    }
  }
`;

// GET ALL USER WITH ROLE CUSTOMER //
export const SORT_USERS = gql`
  query sortUsersAdmin($sort: String){
    sortUsersAdmin(sort: $sort) {
      id
      business_name
      vat_number
      vat_number_nationality
      email
      code
      list_type_0
      list_type_1
      role
      region
      agent{
        id
        nominative
        preorder
      }
      created_at
    }
  }
`;

// GET USER BY ID
export const USER_BY_ID = gql`
  query userById($id: ID){
    userById(id: $id) {
      id
      vat_number
      email
      role
      region
      created_at
    }
  }
`;

// CATEGORY QUERY //
export const GET_BESTSELLER_PRODUCTS = gql`
  query getBestsellerProducts{
    getBestsellerProducts {
      id
      name
      code
      fineness_description
      weight_description
      image
      description
      price
      stock
      bestseller
      luxury
      sale
      percentage_discount
      discountColors
      fineness {
        name
      }
    }
  }
`;

// PREORDER SEASON QUERY //
export const GET_PREORDER_SEASON_PRODUCTS = gql`
  query getPreorderSeasonProducts{
    getPreorderSeasonProducts {
      id
      name
      code
      fineness_description
      weight_description
      image
      description
      price
      stock
      bestseller
      luxury
      sale
      percentage_discount
      discountColors
      fineness {
        name
      }
    }
  }
`;

export const GET_LUXURY_PRODUCTS = gql`
  query getLuxuryProducts{
    getLuxuryProducts {
      id
      name
      code
      fineness_description
      weight_description
      image
      description
      price
      stock
      bestseller
      luxury
      sale
      percentage_discount
      discountColors
      fineness {
        name
      }
    }
  }
`;

export const GET_SALE_PRODUCTS = gql`
  query getSaleProducts{
    getSaleProducts {
      id
      name
      code
      fineness_description
      weight_description
      image
      description
      price
      stock
      bestseller
      luxury
      sale
      percentage_discount
      discountColors
      fineness {
        name
      }
    }
  }
`;

export const GET_PRODUCTS_BY_CATEGORY = gql`
  query getProductsByCategory($gender_id: ID, $fineness_id: ID, $season_id: ID, $tags_id: String, $compositions_id: String, $filter: String){
    getProductsByCategory(gender_id: $gender_id, fineness_id: $fineness_id, season_id: $season_id, tags_id: $tags_id, compositions_id: $compositions_id, filter: $filter) {
      id
      name
      code
      fineness_description
      weight_description
      image
      description
      price
      stock
      bestseller
      luxury
      sale
      percentage_discount
      discountColors
      fineness{
        name
      }
    }
  }
`;

export const GENDER_CATEGORY = gql`
  query genders{
    genders{
      id
      name
      slug
    }
  }
`;

export const FINENESS_CATEGORY = gql`
  query fineness{
    fineness{
      id
      name
      slug
    }
  }
`;

export const SEASON_CATEGORY = gql`
  query seasons{
    seasons{
      id
      name
      not_visible
      is_preorder
    }
  }
`;

export const TAG_CATEGORY = gql`
  query tags{
    tags{
      id
      name
      slug
    }
  }
`;

export const COMPOSITION_CATEGORY = gql`
  query compositions{
    compositions{
      id
      name
      slug
    }
  }
`;

export const ALL_CATEGORIES = gql`
  query allCategories{
    genders{
      id
      name
    }
    fineness{
      id
      name
    }
    season{
      id
      name
    }
    tag{
      id
      name
    }
  }
`;

// Get all size group
export const GROUP_SIZE = gql`
  query groupSizes{
    groupSizes {
      id
      name
      slug
      sizes{
          id
          name
      }
    }
  }
`;

// PRODUCT QUERY //
export const PRODUCT = gql`
  query product($id: ID){
    product(id: $id){
      id
      name
      code
      fineness_description
      weight_description
      image
      description
      stock
      price
      stock
      outOfStock
      discountColors
      bestseller
      sale
      percentage_discount
      productsGallery{
        id
        image
      }
      gender{
        id
        name
        slug
      }
      fineness{
        id
        name
        slug
      }
      season{
        id
        name
        slug
      }
      tags{
        id
        name
        slug
      }
      compositions{
        id
        name
        slug
      }
      colorFolder {
        id
        name
        colors {
          id
          hex
          image
        }
      }
      groupSize{
        name
        sizes{
            id
            name
        }
    }
    }
  }
`;

// PRODUCT QUERY //
export const PRODUCT_ADMIN = gql`
  query productAdmin($id: ID){
    productAdmin(id: $id){
      id
      name
      code
      fineness_description
      weight_description
      image
      description
      type
      stock
      outOfStock
      discountColors
      luxury
      bestseller
      not_visible
      sale
      percentage_discount
      gender{
        id
        name
        slug
      }
      fineness{
        id
        name
        slug
      }
      season{
        id
        name
        slug
      }
      tags{
        id
        name
        slug
      }
      compositions{
        id
        name
        slug
      }
      colorFolder {
        id
        name
        colors {
          id
          hex
        }
      }
      groupSize{
        id
        name
        sizes{
          id
          name
        }
      }
    }
  }
`;

// GET SUMMARY OF PRODUCT ON CART
export const GET_PRODUCT_GALLERY = gql`
  query getProductGallery($product_id: ID){
    getProductGallery(product_id: $product_id) {
      id
      product_id
      image_position
      image
    }
  }
`;

// GET ALL IMAGE OF PRODUCT
export const GET_ALL_PRODUCT_IMAGE = gql`
  query getAllProductImage($product_id: ID){
    getAllProductImage(product_id: $product_id) {
      image
    }
  }
`;

// GET SUMMARY OF PRODUCT ON CART
export const SUMMARY_OF_PRODUCT_ON_CART = gql`
  query getSummaryProductOnCart($product_id: ID){
    getSummaryProductOnCart(product_id: $product_id) {
      id
      size
      color
      color_id
      hex
      quantity
      product_price
      total
    }
  }
`;

// LIST QUERY //
export const PRICE_LIST = gql`
  query priceLists{
    priceLists{
      id
      name
      type
      created_at
    }
  }
`;

// LIST QUERY by ID //
export const PRICE_LIST_BY_ID = gql`
  query priceListById($id: ID){
    priceListById(id: $id){
      id
      name
      type
      created_at
    }
  }
`;

// LIST PRICE LIST PRODUCTS //
export const PRICE_LIST_PRODUCTS = gql`
  query priceListProducts($list_id: ID){
    priceListProducts(list_id: $list_id){
      id
      list_id
      list_name
      type
      product_id
      price
      product_image
      product_name
      product_code
    }
  }
`;

// MY FAVORITES QUERY //
export const MY_FAVORITES = gql`
  query myFavorites{
    myFavorites{
      id
      product_id
      name
      code
      price
      image
      stock
      fineness_name
    }
  }
`;

// MY ORDERS QUERY  //
export const MY_ORDERS = gql`
  query me{
    me{
      email
      vat_number
      role
      list_id
      orders{
        id
        status
        read_status
        sub_total
        total
        admin_trash
        customer_trash
        created_at
        orderDetails{
          id
          order_id
          product_code
          product_name
          product_image
          product_price
          original_price
          discount
          color_image
          color_name
          size
          quantity
          sub_total
          total
        }
      }
    }
  }
`;

// MY ORDERS QUERY //
export const ORDER_DETAIL = gql`
  query myOrders($order_id: ID){
    myOrders(order_id: $order_id){
      id
      order_id
      product_id
      is_preorder_item
      product_code
      product_name
      product_image
      product_price
      original_price
      discount
      color_image
      color_name
      size
      color_id
      size_id
      quantity
      sub_total
      total
      status
      pdf
      note
    }
  }
`;

// GET ALL ORDERS USERS DATA //
export const GET_ORDERS_USERS = gql`
  query getOrdersUsers {
    getOrdersUsers{
      id
      user_id
      email
      vat_number
      code
      status
      read_status
      created_at
      time
      total
      sub_total
    }
  }
`;

export const SORT_ORDERS = gql`
  query sortOrdersAdmin($sort: String, $year: Int) {
    sortOrdersAdmin(sort: $sort, year: $year){
      id
      user_id
      email
      vat_number
      code
      status
      read_status
      created_at
      time
      total
      note
      sub_total
    }
  }
`;

// GET ORDER USER BY ID //
export const GET_ORDER_USER_BY_ID = gql`
  query getOrderUserById($id: ID) {
    getOrderUserById(id: $id){
      id
      user_id
      email
      vat_number
      vat_number_nationality
      code
      status
      read_status
      created_at
      time
      total
      sub_total
      note
    }
  }
`;

// GET NUMBER OF NEW ORDERS TO READ //
export const GET_NUMBER_OF_NEW_ORDERS = gql`
  query {
    getNumberOfNewOrders
  }
`;

// LIST COLOR FOLDER QUERY //
export const COLORS_FOLDER = gql`
  query colorsFolder{
    colorsFolder{
      id
      name
      colors{
        id
        hex
      }
    }
  }
`;

// LIST COLOR QUERY //
export const COLORS = gql`
  query colors{
    colors{
      id
      hex
      colorFolder{
        id
        name
      }
      image
    }
  }
`;

// PRODUCTS //
export const PRODUCTS = gql`
  query products{
    products{
      id
      image
      name
      code
      type
    }
  }
`;

// SORT PRODUCTS //
export const SORT_PRODUCTS = gql`
  query sortProductsAdmin($sort: String){
    sortProductsAdmin(sort: $sort){
      id
      image
      name
      code
      type
      number_of_media
      bestseller
      luxury
      stock
      total_quantity
      gender{
        id,
        name,
      }
      season{
        id,
        name,
      }
    }
  }
`;

// PRODUCTS //
export const ALL_PRICE_OF_PRODUCT = gql`
  query allPriceOfProduct($product_id: ID){
    allPriceOfProduct(product_id: $product_id){
      id
      name
      product_price
      type
    }
  }
`;

// ADMIN DASHBOARD //
export const ADMIN_DASHBOARD = gql`
  query adminDashboard{
    adminDashboard{
      users_numbers
      products_numbers
      price_lists_numbers
      orders_numbers
      orders_annullato
      orders_confermato
      orders_in_lavorazione
      orders_evaso
      orders_ricevuto
      registrations_permission_numbers
      abandoned_carts_numbers
      ai_products_revenue
      pe_products_revenue
      total_for_season
    }
  }
`;

// MY CART //
export const MY_CART = gql`
  query myCart{
  myCart{
    id
    product{
        id
        code
        name
        image
        season{
          id
          name
          is_preorder
        }
    }
    size{
        id
        name
    }
    color{
        id
        hex
        image
    }
    quantity
    quantity_max
    total
    product_price
    original_price
    discount
    }
}
`;

// GET AGENTS
export const AGENTS = gql`
  query agents{
    agents{
      id
      nominative
      preorder
    }
  }
`;

export const SORT_AGENTS = gql`
  query sortAgentsAdmin($sort: String){
    sortAgentsAdmin(sort: $sort){
      id
      nominative
      preorder
    }
  }
`;

export const GET_ALL_PRODUCTS_FOR_EDIT_ORDER_DETAIL = gql`
  query getAllProductsForEditOrderDetail($order_id: ID){
    getAllProductsForEditOrderDetail(order_id: $order_id) {
      id
      name
      code
      fineness_description
      weight_description
      image
      description
      price
      stock
    }
  }
`;

export const GET_PRODUCT_FOR_EDIT_ORDER_DETAIL = gql`
  query getProductForEditOrderDetail($order_id: ID, $product_id: ID){
    getProductForEditOrderDetail(order_id: $order_id, product_id: $product_id) {
      id
      name
      code
      fineness_description
      weight_description
      image
      description
      price
      stock
      discountColors
      percentage_discount
      sale
      productsGallery{
        id
        image
      }
      gender{
        id
        name
        slug
      }
      fineness{
        id
        name
        slug
      }
      season{
        id
        name
        slug
      }
      tags{
        id
        name
        slug
      }
      compositions{
        id
        name
        slug
      }
      colorFolder {
        id
        name
        colors {
          id
          hex
          image
        }
      }
      groupSize{
        name
        sizes{
            id
            name
        }
    }
    }
  }
`;

export const SHOW_BANNER = gql`
  query showBanner{
    showBanner{
      id
      name
      show
    }
  }
`;

export const REGISTRATION = gql`
   query registration($permission_id: ID){
    registration(permission_id: $permission_id) {
      id
      list_type_0
      list_type_1
      vat_number
      code
      created_at
    }
  }
`;

export const PRODUCTS_EXPORT = gql`
  query productsExport($skip: Boolean){
    productsExport(skip: $skip) {
      code
      product_name
      group_size_name
      size_name
      color_folder_name
      hex
      quantity
    }
  }
`;
