import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { REGISTER } from "../../../mutation";
import { GET_REGISTRATION_TOKEN } from "../../../query";
import Form from "./form";
import { useQuery } from "@apollo/react-hooks";
import LinearProgress from "@material-ui/core/LinearProgress";

const Register = ({ match, history }) => {
  // REGISTER mutation
  const [register] = useMutation(REGISTER);
  // formValues of login
  const [formValues, setFormValues] = useState({});
  const [vatNumberNationality, setVatNumberNationality] = useState(0);
  // Register Error boolean
  let [registerError, setRegisterError] = useState(false);
  let [passwordConfirmationError, setPasswordConfirmationError] = useState(false);
  let [emailError, setEmailError] = useState(false);
  let [emailConfirmationError, setEmailConfirmationError] = useState(false);
  let [vatNumberError, setVatNumberError] = useState(false);
  let [registrationErrorConfirm, setRegistrationErrorConfirm] = useState(false);
  const [loadingRegistration, setLoadingRegistration] = useState(false);
  /* Query for get param to registration url
  const { loading: loadingToken, error: errorToken, data: dataToken } = useQuery(GET_REGISTRATION_TOKEN, {
    variables: { id: 1 },
  });
  */
  const [successRegistration, setSuccessRegistration] = useState(false);

  // When the input of login change prepare the form values
  const onChangeForm = event => {
    const value = event.target.value;
    const name = event.target.name;
    setFormValues({ ...formValues, [name]: value });
    // on change form reset errors to false
    setRegisterError(false);
    setVatNumberError(false);
    setPasswordConfirmationError(false);
    setEmailError(false);
    setRegistrationErrorConfirm(false);
  };

  // When change the vat number nationality
  const onChangeVatNumberNationality = event => {
    const value = event.target.value;
    if(parseInt(value) === 1) {
      setFormValues({...formValues, region: ""});
    }
    setVatNumberNationality(parseInt(value));
    setRegisterError(false);
    setVatNumberError(false);
    setPasswordConfirmationError(false);
    setEmailError(false);
    setRegistrationErrorConfirm(false);
  };

  // When change region select
  const onChangeRegion = event => {
    const value = event.target.value;
    setFormValues({ ...formValues, region: value });
    setRegisterError(false);
    setVatNumberError(false);
    setPasswordConfirmationError(false);
    setEmailError(false);
    setRegistrationErrorConfirm(false);
  };

  // set the error and return boolean checkError, if false send registration mutation
  const checkRegistrationError = () => {
    let checkError = false;
    // if inputs are undefined or ""
    // eslint-disable-next-line max-len
    if( formValues["businessName"] === undefined || formValues["businessName"] === "" || formValues["vatNumber"] === undefined || formValues["vatNumber"] === "" || formValues["email"] === undefined ||formValues["email-confirmation"] === undefined || formValues["email"] === "" || formValues["password"] === undefined || formValues["password"] === "" || formValues["passwordConfirmation"] === undefined || formValues["passwordConfirmation"] === "" || ( formValues["region"] === undefined && vatNumberNationality === 0) || (formValues["region"] === "" && vatNumberNationality === 0) ){
      setRegisterError(true);
      checkError = true;
    }else{
      // Vat number verification
      if(vatNumberNationality === 0 ){
        /^[0-9a-zA-Z]+$/.test(formValues["vatNumber"]) && (formValues["vatNumber"].length === 11 ||  formValues["vatNumber"].length === 16) ? vatNumberError = false :  vatNumberError = true;
      } else {
        /^[0-9a-zA-Z]+$/.test(formValues["vatNumber"])  ? vatNumberError = false :  vatNumberError = true;
      }
      // Password confirm verification
      formValues["password"] === formValues["passwordConfirmation"] ? passwordConfirmationError = false : passwordConfirmationError = true;
      // Email input verification
      // eslint-disable-next-line no-useless-escape
      // /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formValues["email"]) ? emailError = false : emailError = true;
      formValues["email"] === formValues["email-confirmation"] ? emailConfirmationError = false : emailConfirmationError = true;
      // Setting error by verification
      setVatNumberError(vatNumberError);
      setPasswordConfirmationError(passwordConfirmationError);
      setEmailError(emailError);
      setEmailConfirmationError(emailConfirmationError);
    }
    if(vatNumberError === true || passwordConfirmationError === true || emailError === true || emailConfirmationError === true){
      checkError = true;
    }
    return checkError;
  };

  // When user press login button
  const onRegister = () => {
    setLoadingRegistration(true);
    if(checkRegistrationError() === false) {
      register({
        variables: {vat_number_nationality: vatNumberNationality, business_name: formValues["businessName"], vat_number: formValues["vatNumber"], email: formValues["email"], password: formValues["password"], password_confirmation: formValues["passwordConfirmation"], region: formValues["region"] },
      }).then(response => {
        // If response of login is 0 set error of login
        if(response.data.register.status === 0){
          setLoadingRegistration(false);
          setRegistrationErrorConfirm(true);
        }else if(response.data.register.status === 1){
          // else if user pass the login with status 1 push on shop and set the token of access
          setRegistrationErrorConfirm(false);
          setSuccessRegistration(true);
          setLoadingRegistration(false);
        }
      }).catch(err =>{
        // if get error of mutation set error of login to true
        setRegistrationErrorConfirm(true);
        setLoadingRegistration(false);
      });
    }else{
      setLoadingRegistration(false);
    }
  };

  const onGoToLogin = () => {
    history.push("/");
  };

  // Verify params in url
  //if (loadingToken) return <LinearProgress />;
  //if (errorToken || match.params.registrationToken !== dataToken.registration_token.registration_token ) { history.push("/"); }

  return (
    <>
      <Form
        formValues={formValues}
        onChangeForm={onChangeForm}
        onRegister={onRegister}
        onChangeRegion={onChangeRegion}
        registerError={registerError}
        passwordConfirmationError={passwordConfirmationError}
        emailError={emailError}
        vatNumberError={vatNumberError}
        registrationErrorConfirm={registrationErrorConfirm}
        loadingRegistration={loadingRegistration}
        successRegistration={successRegistration}
        onGoToLogin={onGoToLogin}
        onChangeVatNumberNationality={onChangeVatNumberNationality}
        vatNumberNationality={vatNumberNationality}
        emailConfirmationError={emailConfirmationError}
      />
    </>
  );
};

export default withRouter(Register);
