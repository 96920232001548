import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { SORT_AGENTS } from "../../../query";
import { ADD_AGENT, REMOVE_AGENT, SET_PREODER_AGENT } from "../../../mutation";
import LinearProgress from "@material-ui/core/LinearProgress";
import Layout from "./layout";

const Agents = ({ history }) => {
  const [nominative, setNominative] = useState("");
  const [errorExistentAgent, setErrorExistentAgent] = useState(false);

  // Search
  const [search, setSearch] = useState();

  const [addAgent] = useMutation(ADD_AGENT);
  const [removeAgent] = useMutation(REMOVE_AGENT);
  const [setPreorderAgent] = useMutation(SET_PREODER_AGENT);

  const [sort, setSort] = useState(null);
  const { loading: loadingAgents, error: errorAgents, data: dataAgents } = useQuery(SORT_AGENTS, {
    variables: {
      sort: sort ? sort : "id-asc",
    },
  });

  const onChangeNominative = event => {
    setErrorExistentAgent(false);
    setNominative(event.target.value);
  };

  // MUTATION Remove Registration Permission by id
  const onAddAgent = () => {
    addAgent({
      variables: { nominative: nominative },
      refetchQueries: ["sortAgentsAdmin"],
    }).then(response => {
      if(response.data.addAgent.status === 1){
        setNominative("");
      }else if(response.data.addAgent.status === 0){
        setErrorExistentAgent(true);
      }
    });
  };

  // MUTATION Remove Registration Permission by id
  const onRemoveAgent = (agentId) => {
    removeAgent({
      variables: { id: agentId },
      refetchQueries: ["sortAgentsAdmin", "users"],
    }).then(response => {
      console.log("ok");
    });
  };

  // MUTATION Set preoder item
  const onSetPreorderAgent = (agentId) => {
    setPreorderAgent({
      variables: { id: agentId },
      refetchQueries: ["sortAgentsAdmin", "users"],
    }).then(response => {
      console.log("ok");
    });
  };


  // onChange Search
  const onChangeSearch = event => {
    setSearch(event.target.value);
  };

  const onChangeSort = event => {
    setSort(event.target.value);
  };

  if (loadingAgents ) return <LinearProgress />;
  if (errorAgents) { console.log("error"); }

  return (
    <Layout
      dataAgents={dataAgents}
      nominative={nominative}
      onChangeNominative={onChangeNominative}
      onAddAgent={onAddAgent}
      errorExistentAgent={errorExistentAgent}
      onRemoveAgent={onRemoveAgent}
      onChangeSearch={onChangeSearch}
      search={search}
      onChangeSort={onChangeSort}
      sort={sort}
      onSetPreorderAgent={onSetPreorderAgent}
    />
  );
};

export default withRouter(Agents);
