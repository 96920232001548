import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import Divider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core/styles";
import Style from "./style.js";
import { Checkbox, FormControlLabel } from "@material-ui/core";

// Table responsive
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "rgba(130,70,51,1)",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const Layout = ({
  nominative,
  onChangeNominative,
  onAddAgent,
  dataAgents,
  errorExistentAgent,
  onRemoveAgent,
  onChangeSearch,
  search,
  onChangeSort,
  sort,
  onSetPreorderAgent,
}) => {
  const style = Style();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} style={{ paddingBottom: "0" }}>
        <Typography variant="h4">
          Agenti
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8} md={8} lg={8} xl={8} style={{ paddingBottom: "0", paddingTop: "0" }}>
        <TextField
          className={style.addNewAgentTextField}
          variant="outlined"
          margin="normal"
          id="search"
          label="Nome Agente"
          name="Nome Agente"
          size="small"
          error={errorExistentAgent}
          helperText={errorExistentAgent ? "Agente già esistente" : false}
          value={nominative}
          onChange={event => onChangeNominative(event)}
        />
        <Button
          className={style.AddNewAgent}
          variant="contained"
          size="medium"
          color="primary"
          onClick={onAddAgent}
          disabled={nominative === "" ? true : false}
        >
          Aggiungi Agente
        </Button>
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={4} xl={4} style={{ paddingBottom: "0", paddingTop: "0" }}>
        <TextField
          style={{ float: "right" }}
          className={style.searchField}
          variant="outlined"
          margin="normal"
          id="search"
          label="Cerca Utente"
          size="small"
          value={search}
          onChange={onChangeSearch}
          defaultValue={""}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingBottom: "0", paddingTop: "0" }}>
        <FormControl variant="outlined" margin="normal" fullWidth size="small" className={style.selectSort}>
          <InputLabel>Ordina per</InputLabel>
          <Select
            name="list_type_0"
            label="Listino Estivo"
            onChange={onChangeSort}
            value={sort}
            size="small"
          >
            <div style={{ textAlign: "center" }}><b>Ascendente</b></div>
            <MenuItem value="id-asc">ID <ArrowUpwardIcon style={{ fontSize: "1.1rem" }} /></MenuItem>
            <MenuItem value="nominative-asc">Nominativo <ArrowUpwardIcon style={{ fontSize: "1.1rem" }} /></MenuItem>
            <Divider />
            <div style={{ textAlign: "center" }}><b>Discendente</b></div>
            <MenuItem value="id-desc">ID <ArrowDownwardIcon style={{ fontSize: "1.1rem" }} /></MenuItem>
            <MenuItem value="nominative-desc">Nominativo <ArrowDownwardIcon style={{ fontSize: "1.1rem" }} /></MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        {
          dataAgents && dataAgents.sortAgentsAdmin.length > 0 ? (
            <TableContainer component={Paper}>
              <Table className={style.table} size="small" aria-label="a dense table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell align="center" >#</StyledTableCell>
                    <StyledTableCell align="center" >Nominativo</StyledTableCell>
                    <StyledTableCell align="center">Preorder</StyledTableCell>
                    <StyledTableCell align="center">Azioni</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {
                    search ? (
                      dataAgents.sortAgentsAdmin.filter(item => item.nominative.toLowerCase().includes(search.toLowerCase())).map((item, index) => (
                        <StyledTableRow key={index} >
                          <StyledTableCell align="center">{index + 1}</StyledTableCell>
                          <StyledTableCell align="center">{item.nominative}</StyledTableCell>
                          <StyledTableCell align="center">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!!item.preorder}
                                  onChange={() => onSetPreorderAgent(item.id)}
                                  name="preorder"
                                  color="primary"
                                  value={0}
                                />
                              }
                            />
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <IconButton aria-label="delete" className={style.margin} size="small" onClick={() => onRemoveAgent(item.id)}>
                              <DeleteIcon color="error" />
                            </IconButton>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    ) : (
                      dataAgents.sortAgentsAdmin.map((item, index) => {
                        return (
                          <StyledTableRow key={index} >
                            <StyledTableCell align="center">{index + 1}</StyledTableCell>
                            <StyledTableCell align="center">{item.nominative}</StyledTableCell>
                            <StyledTableCell align="center">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={!!item.preorder}
                                    onChange={() => onSetPreorderAgent(item.id)}
                                    name="preorder"
                                    color="primary"
                                    value={0}
                                  />
                                }
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <IconButton aria-label="delete" className={style.margin} size="small" onClick={() => onRemoveAgent(item.id)}>
                                <DeleteIcon color="error" />
                              </IconButton>
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
          ) : (<p style={{ margin: "0" }}>Non ci sono permessi di registrazione</p>)
        }
      </Grid>
    </Grid>

  );
};

export default Layout;
