/* eslint-disable array-callback-return */
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Layout from "./layout";
import { useQuery } from "@apollo/react-hooks";
import { useMutation } from "@apollo/react-hooks";
import { SORT_USERS, PRICE_LIST, AGENTS } from "../../../query";
import { REMOVE_USER, UPDATE_USER, REGISTER_BY_ADMIN } from "../../../mutation";
import LinearProgress from "@material-ui/core/LinearProgress";

const Users = ({ history, match }) => {
  // Error remove
  const [removeUserError, setRemoveUserError] = useState(false);
  const [updateUserError, setUpdateUserError] = useState(false);
  let [passwordConfirmationError, setPasswordConfirmationError] = useState(false);
  let [emailError, setEmailError] = useState(false);
  let [vatNumberError, setVatNumberError] = useState(false);
  let [registrationErrorConfirm, setRegistrationErrorConfirm] = useState(false);
  const [errorCreateUser, setErrorCreateUser] = useState();
  // Search
  const [search, setSearch] = useState();

  // Loading
  const [loadingEditUser, setLoadingEditUser] = useState(false);
  const [loadingAddNewUser, setLoadingAddNewUser] = useState(false);
  const [loadingRemoveUser, setLoadingRemoveUser] = useState(false);
  const [loadingRemoveAllUser, setLoadingRemoveAllUser] = useState(false);

  // Open Modal Edit User
  const [openModalEditUser, setOpenModalEditUser] = React.useState(false);
  const [openModalConfirmDeleteUser, setOpenModalConfirmDeleteUser] = React.useState(false);
  const [openModalAddNewUser, setOpenModalAddNewUser] = React.useState(false);
  const [openModalConfirmDeleteAllUser, setOpenModalConfirmDeleteAllUser] = useState(false);

  // FormValues
  const [editUserFormValues, setEditUserFormValues] = useState({});
  const [addNewUserformValues, setAddNewUserformValues] = useState({});
  const [checkboxMassiveDelete, setCheckboxMassiveDelete] = useState([]);
  const [allDeleteChecked, setAllDeleteChecked] = useState(false);
  const [vatNumberNationality, setVatNumberNationality] = useState(0);

  // Show save button when edit user
  const [disabledSaveEditUser, setDisabledSaveEditUser] = useState(true);
  const [disabledSaveNewUser, setDisabledSaveNewUser] = useState(true);
  // ID User to remove for cofimartion remove
  const [idUserToRemove, setIdUserToRemove] = useState();
  const [sort, setSort] = useState(null);
  // USER QUERY
  const { loading: loadingUsers, error: errorUsers, data: dataUsers } = useQuery(SORT_USERS, {
    variables: {
      sort: sort ? sort : "id-asc",
    },
  });
  // PRICE LIST QUERY
  const { loading: loadingPriceLists, error: errorPriceLists, data: dataPriceLists } = useQuery(PRICE_LIST);

  const { loading: loadingAgents, error: errorAgents, data: dataAgents } = useQuery(AGENTS);
  // USER MUTATION
  const [registerByAdmin] = useMutation(REGISTER_BY_ADMIN);
  const [removeUser] = useMutation(REMOVE_USER);
  const [updateUser] = useMutation(UPDATE_USER);

  // Check error in User Update Validation vat_number, email
  const checkUserError = () => {
    let checkErrorVatNumber = false;
    let checkErrorEmail = false;
    // if inputs are undefined or ""
    // eslint-disable-next-line max-len
    if( editUserFormValues["vat_number"] === undefined || editUserFormValues["vat_number"] === "" || editUserFormValues["code"] === undefined || editUserFormValues["code"] === "" || editUserFormValues["email"] === undefined || editUserFormValues["email"] === ""  ){
      return true;
    }else{
      // Vat number verification
      if(editUserFormValues["vat_number_nationality"] === 0) {
        /^[0-9a-zA-Z]+$/.test(editUserFormValues["vat_number"]) ? checkErrorVatNumber = false :  checkErrorVatNumber = true;

        ///^[0-9]+$/.test(editUserFormValues["vat_number"]) && editUserFormValues["vat_number"].length === 11 ? checkErrorVatNumber = false :  checkErrorVatNumber = true;
      } else {
        /^[0-9a-zA-Z]+$/.test(editUserFormValues["vat_number"]) ? checkErrorVatNumber = false :  checkErrorVatNumber = true;
      }
      // Email input verification
      // eslint-disable-next-line no-useless-escape
      // /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(editUserFormValues["email"]) ? checkErrorEmail = false : checkErrorEmail = true;
      // Setting error by verification
    }
    if(!checkErrorVatNumber && !checkErrorEmail){
      return false;
    }
    return true;
  };

  // Check error in User Create Validation vat_number, email, password confirm
  const checkRegistrationError = () => {
    // if inputs are undefined or ""
    // eslint-disable-next-line max-len
    if( addNewUserformValues["vatNumber"] === undefined || addNewUserformValues["vatNumber"] === "" || addNewUserformValues["code"] === undefined || addNewUserformValues["code"] === "" || addNewUserformValues["email"] === undefined || addNewUserformValues["email"] === "" || addNewUserformValues["password"] === undefined || addNewUserformValues["password"] === "" || addNewUserformValues["passwordConfirmation"] === undefined || addNewUserformValues["passwordConfirmation"] === "" ){
      return true;
    }else{
      // Vat number verification
      if(vatNumberNationality === 0) {
        /^[0-9a-zA-Z]+$/.test(addNewUserformValues["vatNumber"]) ? vatNumberError = false :  vatNumberError = true;
        ///^[0-9]+$/.test(addNewUserformValues["vatNumber"]) && addNewUserformValues["vatNumber"].length === 11 ? vatNumberError = false :  vatNumberError = true;
      } else {
        /^[0-9a-zA-Z]+$/.test(addNewUserformValues["vatNumber"]) ? vatNumberError = false :  vatNumberError = true;
      }
      // Password confirm verification
      addNewUserformValues["password"] === addNewUserformValues["passwordConfirmation"] ? passwordConfirmationError = false : passwordConfirmationError = true;
      // Email input verification
      // eslint-disable-next-line no-useless-escape
      // /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(addNewUserformValues["email"]) ? emailError = false : emailError = true;
      // Setting error by verification
      setVatNumberError(vatNumberError);
      setPasswordConfirmationError(passwordConfirmationError);
      setEmailError(emailError);
    }
    if(vatNumberError === true || passwordConfirmationError === true || emailError === true){
      return true;
    }
    return false;
  };

  // MUTATION Remove user by id
  const onAddNewUser = () => {
    setLoadingAddNewUser(true);
    if (addNewUserformValues["list_type_0"] === undefined ) {
      addNewUserformValues["list_type_0"] = "";
    }
    if (addNewUserformValues["list_type_1"] === undefined ) {
      addNewUserformValues["list_type_1"] = "";
    }
    if(checkRegistrationError() === false) {
      registerByAdmin({
        variables: {
          business_name: addNewUserformValues["businessName"],
          vat_number_nationality: vatNumberNationality,
          vat_number: addNewUserformValues["vatNumber"],
          email: addNewUserformValues["email"],
          code: addNewUserformValues["code"],
          password: addNewUserformValues["password"],
          password_confirmation: addNewUserformValues["passwordConfirmation"],
          region: addNewUserformValues["region"],
          list_type_0: addNewUserformValues["list_type_0"],
          list_type_1: addNewUserformValues["list_type_1"],
          agent: addNewUserformValues["agent"]  },
        refetchQueries: ["sortUsersAdmin", "registrations"],
      }).then(response => {
        if(response.data.registerByAdmin.status === 0){
          setRegistrationErrorConfirm(true);
          setLoadingAddNewUser(false);
        }else if(response.data.registerByAdmin.status === 1){
          setRegistrationErrorConfirm(false);
          setAddNewUserformValues({});
          setLoadingAddNewUser(false);
          setOpenModalAddNewUser(false);
        }
      }).catch(err =>{
        setRegistrationErrorConfirm(true);
        setLoadingAddNewUser(false);
        let { graphQLErrors } = err;
        if (graphQLErrors[0].extensions.category === "validation") {
          setErrorCreateUser(graphQLErrors[0].extensions.validation);
        }
      });
    }else{
      setLoadingAddNewUser(false);
      setRegistrationErrorConfirm(true);
    }
  };

  // MUTATION Remove user by id
  const onRemoveUser = ($id) => {
    setLoadingRemoveUser(true);
    removeUser({
      variables: { id: $id },
      refetchQueries: ["sortUsersAdmin"],
    }).then(response => {
      // If response of remove registration permission is 0 set error
      if(response.data.removeUser.status === 0){
        setRemoveUserError(true);
        setLoadingRemoveUser(false);
      }else if(response.data.removeUser.status === 1){
        setRemoveUserError(false);
        setOpenModalConfirmDeleteUser(false);
        setLoadingRemoveUser(false);
        setCheckboxMassiveDelete([]);
        setAllDeleteChecked(false);
      }
    }).catch(err =>{
      // if get error of mutation set error of insert Vat number to true
      setRemoveUserError(true);
      setLoadingRemoveUser(false);
    });
  };

  // MUTATION Save Edit user
  const onUpdateUser = () => {
    setLoadingEditUser(true);
    if(checkUserError() === false) {
      updateUser({
        variables: {
          id: editUserFormValues.id,
          email: editUserFormValues.email,
          code: editUserFormValues.code,
          business_name: editUserFormValues.business_name,
          vat_number: editUserFormValues.vat_number,
          region: editUserFormValues.region,
          list_type_0: editUserFormValues.list_type_0,
          list_type_1: editUserFormValues.list_type_1,
          agent: editUserFormValues.agent,
          password: editUserFormValues.password ? editUserFormValues.password : ""
        },
        refetchQueries: ["sortUsersAdmin"],
      }).then(response => {
        // If response of remove registration permission is 0 set error
        if(response.data.updateUser.status === 0){
          setUpdateUserError(true);
          setLoadingEditUser(false);
        }else if(response.data.updateUser.status === 1){
          setUpdateUserError(false);
          setLoadingEditUser(false);
          setOpenModalEditUser(false);
          setEditUserFormValues({});
        }
      }).catch(err =>{
        setUpdateUserError(true);
        setLoadingEditUser(false);
      });
    }else{
      setUpdateUserError(true);
      setLoadingEditUser(false);
    }
  };

  // Mutation Delete List of color
  const deleteAllSelected = () => {
    setLoadingRemoveAllUser(true);
    checkboxMassiveDelete.map((item, index) => {
      removeUser({
        variables: { id: item },
        refetchQueries: ["sortUsersAdmin"],
      }).then(response => {
        if(response.data.removeUser.status === 0){
          setLoadingRemoveAllUser(false);
        }else if(response.data.removeUser.status === 1){
          if(index === checkboxMassiveDelete.length -1){
            setCheckboxMassiveDelete([]);
            setLoadingRemoveAllUser(false);
            setAllDeleteChecked(false);
            setOpenModalConfirmDeleteAllUser(false);
          }
        }
      }).catch(err =>{
        setLoadingRemoveAllUser(false);
      });
    });
  };

  // onChange Edit User attiva il pulsante salva e setta il formValues
  const onChangeEditUser = event => {
    const value = event.target.value;
    const name = event.target.name;
    setEditUserFormValues({...editUserFormValues, [name]: value});
    setDisabledSaveEditUser(false);
    setUpdateUserError(false);
  };

  // When change the vat number nationality
  const onChangeVatNumberNationality = event => {
    const value = event.target.value;
    if(parseInt(value) === 1) {
      setAddNewUserformValues({...addNewUserformValues, region: ""});
    }
    setVatNumberNationality(parseInt(value));
    setRegistrationErrorConfirm(false);
  };

  // onChange aggiungi nuovo utente
  const onChangeFormAddNewUser = event => {
    const value = event.target.value;
    const name = event.target.name;
    setAddNewUserformValues({ ...addNewUserformValues, [name]: value });
    setRegistrationErrorConfirm(false);
    setDisabledSaveNewUser(false);
  };

  // When change region select
  const onChangeRegion = event => {
    const value = event.target.value;
    setAddNewUserformValues({ ...addNewUserformValues, region: value });
  };

  // Apertura modale edit user e inserimento nei textField dei valori dell'utente selezionato
  const onOpenModalEditUser = (item) => {
    setEditUserFormValues({
      "id": item.id,
      "business_name": item.business_name,
      "vat_number": item.vat_number,
      "email": item.email,
      "region": item.region,
      "list_type_0": item.list_type_0,
      "list_type_1": item.list_type_1,
      "vat_number_nationality": item.vat_number_nationality,
      "agent": item.agent ? item.agent.id : null,
      "code": item.code ? item.code : null,
    });
    setOpenModalEditUser(true);
  };

  // Chiusura modale edit user e azzeramento del formValues
  const onCloseModalEditUser = () => {
    setEditUserFormValues({});
    setDisabledSaveEditUser(true);
    setOpenModalEditUser(false);
  };

  // Apertura modale conferma eliminazione utente
  const onOpenConfirmDeleteUser = (id) => {
    setOpenModalConfirmDeleteUser(true);
    setIdUserToRemove(id);
  };

  // Cgiusura modale conferma eliminazione utente
  const onCloseConfirmDeleteUser = () => {
    setOpenModalConfirmDeleteUser(false);
    setIdUserToRemove();
  };
  // Open Modal Confirm delete all selected user
  const onOpenModalConfirmDeleteAllUser = (item) => {
    setOpenModalConfirmDeleteAllUser(true);
  };
  // Close Modal Confirm delete all selected user
  const onCloseModalConfirmDeleteAllUser = () => {
    setOpenModalConfirmDeleteAllUser(false);
    setCheckboxMassiveDelete([]);
    setAllDeleteChecked(false);
  };

  // Apertura modale aggiungi nuovo permesso di registrazione
  const onOpenModalAddNewUser = () => {
    setOpenModalAddNewUser(true);
  };

  // Chiusura modale aggiungi nuovo permesso di registrazione
  const onCloseModalAddNewUser = () => {
    setAddNewUserformValues({});
    setOpenModalAddNewUser(false);
  };

  const onChangeSearch = event => {
    setSearch(event.target.value);
  };

  const onChangeSort = event => {
    setSort(event.target.value);
  };

  // Verify query for registration permission
  if ( loadingUsers || loadingPriceLists || loadingAgents) return <LinearProgress />;
  if ( errorUsers || errorPriceLists || errorAgents) { console.log("error"); }

  // onChange Massive Delete
  const onChangeMassiveDelete = event => {
    if (event.target.checked){
      if(event.target.name === "all") {
        setCheckboxMassiveDelete(undefined);
        setAllDeleteChecked(true);
        if(search && search !== null){
          dataUsers.sortUsersAdmin.filter(item => item.email.toLowerCase().includes(search.toLowerCase()) || item.vat_number.toLowerCase().includes(search.toLowerCase()) ||  item.region?.toLowerCase().includes(search.toLowerCase()) || item.agent?.includes(search.toLowerCase())).map((item, index) => (
            checkboxMassiveDelete.push(item.id)
          ));
        }else{
          dataUsers.sortUsersAdmin.map((item) => {
            checkboxMassiveDelete.push(item.id);
          });
        }
        setCheckboxMassiveDelete([...checkboxMassiveDelete]);
      }else{
        setCheckboxMassiveDelete([ ...checkboxMassiveDelete, event.target.name ]);
        if(checkboxMassiveDelete.length ===  dataUsers.sortUsersAdmin.length - 1) {
          setAllDeleteChecked(true);
        }
      }
    } else {
      if(event.target.name === "all") {
        setCheckboxMassiveDelete([]);
        setAllDeleteChecked(false);
      }else{
        let index = checkboxMassiveDelete.indexOf(event.target.name);
        if(index >= 0) {
          checkboxMassiveDelete.splice(index, 1);
          setCheckboxMassiveDelete([...checkboxMassiveDelete]);
        }
        if(checkboxMassiveDelete.length === 0) {
          setAllDeleteChecked(false);
        }
      }
    }
  };

  return (
    <Layout
      dataUsers={dataUsers}
      dataAgents={dataAgents}
      onRemoveUser={onRemoveUser}
      removeUserError={removeUserError}
      updateUserError={updateUserError}
      onChangeEditUser={onChangeEditUser}
      editUserFormValues={editUserFormValues}
      onUpdateUser={onUpdateUser}
      openModalEditUser={openModalEditUser}
      onOpenModalEditUser={onOpenModalEditUser}
      onCloseModalEditUser={onCloseModalEditUser}
      disabledSaveEditUser={disabledSaveEditUser}
      onCloseConfirmDeleteUser={onCloseConfirmDeleteUser}
      onOpenConfirmDeleteUser={onOpenConfirmDeleteUser}
      openModalConfirmDeleteUser={openModalConfirmDeleteUser}
      idUserToRemove={idUserToRemove}
      dataPriceLists={dataPriceLists}
      openModalAddNewUser={openModalAddNewUser}
      onCloseModalAddNewUser={onCloseModalAddNewUser}
      onOpenModalAddNewUser={onOpenModalAddNewUser}
      addNewUserformValues={addNewUserformValues}
      onChangeFormAddNewUser={onChangeFormAddNewUser}
      onChangeRegion={onChangeRegion}
      onAddNewUser={onAddNewUser}
      search={search}
      onChangeSearch={onChangeSearch}
      loadingRemoveUser={loadingRemoveUser}
      loadingAddNewUser={loadingAddNewUser}
      loadingEditUser={loadingEditUser}
      disabledSaveNewUser={disabledSaveNewUser}
      onChangeMassiveDelete={onChangeMassiveDelete}
      checkboxMassiveDelete={checkboxMassiveDelete}
      deleteAllSelected={deleteAllSelected}
      allDeleteChecked={allDeleteChecked}
      openModalConfirmDeleteAllUser={openModalConfirmDeleteAllUser}
      onCloseModalConfirmDeleteAllUser={onCloseModalConfirmDeleteAllUser}
      onOpenModalConfirmDeleteAllUser={onOpenModalConfirmDeleteAllUser}
      loadingRemoveAllUser={loadingRemoveAllUser}
      onChangeVatNumberNationality={onChangeVatNumberNationality}
      vatNumberNationality={vatNumberNationality}
      onChangeSort={onChangeSort}
      sort={sort}
      errorCreateUser={errorCreateUser}
    />
  );
};

export default withRouter(Users);
