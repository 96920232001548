import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  gridContainer: {
    marginTop: "0",
    gridTemplateColumns: "440px 1fr",
    columnGap: "30px",
  },
  firstCol: {
    width: "400px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  secondCol: {
    width: "calc(100% - 440px)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  productImageContainer: {
    width: "100%",
    borderRadius: "4px",
    position: "relative",
    textAlign: "center",
    maxHeight: "550px",
  },
  productImage: {
    width: "100%",
    minHeight: "425px",
    maxHeight: "425px",
    borderRadius: "4px",
  },
  productImageButton: {
    position: "absolute",
    bottom: "9px",
    right: "10px",
  },
  paperProductInfo: {
    minHeight: "636px",
  },
  productTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  productTitle: {
    padding: "1rem",
    textTransform: "capitalize",
    fontWeight: "bold",
  },
  productDescription: {
    padding: "1rem 1rem 0 1rem",
    fontWeight: "bold",
  },
  productDescriptionText: {
    padding: "0.5rem 1rem 0 1rem",
  },
  productSize: {
    padding: "1rem 1rem 0 1rem",
    fontWeight: "bold",
  },
  productSizeContainer: {
    padding: "0.5rem 1rem 0 1rem",
  },
  productUseNoteContainer: {
    border: "1px solid #e5e5e5",
    padding: "1rem",
    borderRadius: "5px",
    boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
    width: "calc(100% - 60px)",
    margin: "1rem auto 0",
    fontWeight: "600",
  },
  productSizeText: {
    marginRight: "0.75rem",
    fontSize: "1.25rem",
  },
  productPrice: {
    padding: "1rem 1rem 0.5rem 1rem",
    fontWeight: "bold",
  },
  productPriceText: {
    padding: "0.5rem 1rem 1rem 1rem",
    paddingTop: 0,
    marginTop: 0,
  },
  saleBanner: {
    color: '#ffffff',
    padding: '0.25rem',
    background: '#3da63d',
    marginTop: '0',
    marginRight: '0.5rem',
    marginLeft: '16px',
    borderRadius: '4px',
    height: 'max-content',
  },
  productBuy: {
    padding: "1rem 1rem 0 1rem",
    fontWeight: "bold",
  },
  labelBuy: {
    padding: "0.5rem 1rem 0.5rem 1rem",
    fontWeight: "bold",
    background: "rgba(130,70,51,1)",
    color: "white",
    display: "flex",
  },
  tab: {
    marginTop: "1.5rem",
    backgroundColor: "#424242",
    borderRadius: "4px 4px 0 0",
  },
  paperTab: {
    marginBottom: "1.5rem",
    borderRadius: "0 0 4px 4px",
  },
  gridGallery: {
    marginTop: "1rem",
  },
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
    borderRadius: "4px",
  },
  galleryImage: {
    width: "max-content !important",
    marginRight: "1.25rem",
    borderRadius: "4px !important",
    "&:hover": {
      cursor: "pointer",
    },
  },
  productColorsContainer: {
    padding: "0.5rem 1rem 0 1rem",
    display: "grid",
    gridTemplateColumns: "repeat(10, 1fr)",
    [theme.breakpoints.only("xs")]: {
      gridTemplateColumns: "repeat(4, 1fr)",
      gridGap: "0.50rem",
    },
    [theme.breakpoints.only("sm")]: {
      gridTemplateColumns: "repeat(4, 1fr)",
      gridGap: "0.50rem",
    },
    [theme.breakpoints.only("md")]: {
      gridTemplateColumns: "repeat(6, 1fr)",
      gridGap: "0.50rem",
    },
    [theme.breakpoints.only("lg")]: {
      gridTemplateColumns: "repeat(6, 1fr)",
      gridGap: "1rem",
    },
    [theme.breakpoints.only("xl")]: {
      gridTemplateColumns: "repeat(12, 1fr)",
      gridGap: "1rem",
    },
  },
  gridContainerBuy: {
    padding: "0.5rem 1rem 0 1rem",
  },
  select: {
    width: "100%",
  },
  quantity: {
    margin: "0.5rem 0 1rem 0",
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "50%",
    },
    [theme.breakpoints.only("md")]: {
      width: "50%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.only("xl")]: {
      width: "50%",
    },
  },
  buyButton: {
    width: "100%",
  },
  productColor: {
    width: "70px",
    height: "70px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  productColorBuy: {
    width: "50px",
    height: "50px",
    marginRight: "0.5rem",
    border: "solid 2px transparent",
  },
  productColorBuySelected: {
    border: "solid 2px green",
    width: "66px",
    height: "66px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  productSummary: {
    padding: "1rem 1rem 0 1rem",
    fontWeight: "bold",
  },
  productSummaryContainer: {
    padding: "0.5rem 1rem 1rem 1rem",
  },
  test: {
    display: "inline-flex",
    width: "100%",
    marginBottom: "1rem",
  },
  productSummaryImage: {
    width: "20px",
    height: "20px",
    marginRight: "1rem",
  },
  deleteButton: {
    color: "var(--mate-red)",
  },
  breadCrumbsText: {
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
  cartButtonContainer: {
    textAlign: "right",
  },
  cartButton: {
    marginTop: "1rem",
    width: "100%",
    backgroundColor: "var(--mate-green)",
    color: "(--text-inverted)",
    "&:hover": {
      backgroundColor: "var(--mate-green)",
      opacity: 0.8,
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "33%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "24%",
    },
    [theme.breakpoints.only("xl")]: {
      width: "22%",
    },
  },
  thumbnailImage: {
    top: "50%",
    width: "auto",
    position: "relative",
    transform: "translateY(-50%)",
    height: "inherit",
  },
  containerCompositionBox: {
    display: "grid",
    gridGap: "0.50rem",
    [theme.breakpoints.only("xs")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    [theme.breakpoints.only("sm")]: {
      gridTemplateColumns: "repeat(4, 1fr)",
    },
    [theme.breakpoints.only("md")]: {
      gridTemplateColumns: "repeat(5, 1fr)",
    },
    [theme.breakpoints.only("lg")]: {
      gridTemplateColumns: "repeat(10, 1fr)",
    },
    [theme.breakpoints.only("xl")]: {
      gridTemplateColumns: "repeat(12, 1fr)",
    },
  },
  compositionsBox: {
    border: "1px solid rgb(222 222 222)",
    background: "#f7f6f6",
    padding: "0.25rem 0.5rem",
    margin: "0 1rem",
    borderRadius: "4px",
    textTransform: "capitalize",
    width: "max-content",
  },
  gridBuyColors: {

    display: "grid",
    gridTemplateColumns: "repeat(10, 1fr)",
    [theme.breakpoints.only("xs")]: {
      gridTemplateColumns: "repeat(4, 1fr)",
      gridGap: "0.50rem",
    },
    [theme.breakpoints.only("sm")]: {
      gridTemplateColumns: "repeat(4, 1fr)",
      gridGap: "0.50rem",
    },
    [theme.breakpoints.only("md")]: {
      gridTemplateColumns: "repeat(6, 1fr)",
      gridGap: "0.50rem",
    },
    [theme.breakpoints.only("lg")]: {
      gridTemplateColumns: "repeat(6, 1fr)",
      gridGap: "1rem",
    },
    [theme.breakpoints.only("xl")]: {
      gridTemplateColumns: "repeat(12, 1fr)",
      gridGap: "1rem",
    },
  },
  gridSize: {
    marginTop: "1rem",
  },
  gridAddToCart: {
    marginTop: "1rem",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalBody: {
    padding: "0.5rem 1rem",
    backgroundColor: "#f9f9f9",
    borderRadius: "5px",
    width: "450px",
  },
  modalTitle: {
    marginBottom: "1rem",
    position: "relative",
  },
  modalClose: {
    position: "absolute",
    top: "-0.25rem",
    display: "block",
    right: "0rem",
    fontSize: "1.75rem",
    cursor: "pointer",
  },
  modalColor: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },
  outOfStockColor: {
    cursor: "not-allowed",
    width: "max-content",
    position: "relative",
  },
  ribbon: {
    position: "absolute",
    right: "4px",
    top: "-6px",
    zIndex: 1,
    overflow: "hidden",
    width: "60px",
    height: "60px",
    textAlign: "right",
  },
  ribbonSpan: {
    fontSize: "10px",
    fontWeight: "bold",
    color: "#FFF",
    textTransform: "uppercase",
    textAlign: "center",
    lineHeight: "15px",
    transform: "rotate(45deg)",
    webkitTransform: "rotate(45deg)",
    width: "100px",
    display: "block",
    background: "#79A70A",
    background: "linear-gradient(#F70505 0%, #8F0808 100%)",
    boxShadow: "0 3px 10px -5px rgba(0, 0, 0, 1)",
    position: "absolute",
    top: "19px",
    right: "-21px",
  },
  ribbonSpanDiscount: {
    fontSize: "10px",
    fontWeight: "bold",
    color: "#FFF",
    textTransform: "uppercase",
    textAlign: "center",
    lineHeight: "15px",
    transform: "rotate(45deg)",
    webkitTransform: "rotate(45deg)",
    width: "100px",
    display: "block",
    background: "#79A70A",
    background: "linear-gradient(75.7deg, rgb(34, 126, 34) 3.8%, rgb(99, 162, 17) 87.1%)",
    boxShadow: "0 3px 10px -5px rgba(0, 0, 0, 1)",
    position: "absolute",
    top: "19px",
    right: "-21px",
  }
}));
