/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { ME, PRODUCT, SUMMARY_OF_PRODUCT_ON_CART, MY_FAVORITES, MY_CART } from "../../../query";
import { ADD_ON_CART, REMOVE_ON_CART, ADD_REMOVE_TO_FAVORITES } from "../../../mutation";
import { useQuery } from "@apollo/react-hooks";
import { useMutation } from "@apollo/react-hooks";
import LinearProgress from "@material-ui/core/LinearProgress";
import Layout from "./layout";

const Shop = ({ history, match }) => {
  const [addOnCart] = useMutation(ADD_ON_CART);
  const [removeOnCart] = useMutation(REMOVE_ON_CART);
  const [addRemoveToFavorites] = useMutation(ADD_REMOVE_TO_FAVORITES);
  const [tabValue, setTabValue] = useState(0);
  const [imageToView, setImageToView] = useState();
  const [colorId, setColorId] = useState("");
  const [total, setTotal] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [indexOfColor, setIndexOfColor] = useState();
  const [modalZoomColor, setModalZoomColor] = useState(false);
  const [colorToZoom, setColorToZoom] = useState({
    colorHex: null,
    colorImage: null,
  });
  const userFavorites = [];

  const [formAddToCart, setFormAddToCart] = useState({
    colorId: null,
    values: [],
  });

  const [successAddToCart, setSuccessAddToCart] = useState(false);
  const [warningAddToCart, setWarningAddToCart] = useState(false);
  const [errorAddToCart, setErrorAddToCart] = useState(false);
  const [successRemoveOnCart, setSuccessRemoveOnCart] = useState(false);

  const { loading: loadingMe, error: errorMe, data: dataMe } = useQuery(ME);

  // only used for refetch query
  // eslint-disable-next-line no-unused-vars
  const { loading: loadingMyCart, error: errorMyCart, data: dataMyCart } = useQuery(MY_CART);

  // import only for refetch query myFavorites
  // eslint-disable-next-line no-unused-vars
  const { loading: loadingMyFavorites, error: errorMyFavorites, data: dataMyFavorites } = useQuery(MY_FAVORITES);

  const { loading: loadingProduct, error: errorProduct, data: dataProduct } = useQuery(PRODUCT, {
    variables: { id: match.params.productId },
    pollInterval: 500,
  });

  const { loading: loadingSummaryProductOnCart, error: errorSummaryProductOnCart, data: dataSummaryProductOnCart } = useQuery(SUMMARY_OF_PRODUCT_ON_CART, {
    variables: { product_id: match.params.productId },
    pollInterval: 500,
  });

  useEffect(() => {
    if (window.innerWidth < 900) {
      setIsMobile(true);
    }
  }, []);

  const onChangeImageToView = (imageUrl) => {
    setImageToView(imageUrl);
  };

  const onChangeTab = tab => {
    setTabValue(tab);
  };

  const onChangeColor = colorId => {
    setColorId(colorId);
    setFormAddToCart({ ...formAddToCart, colorId: colorId, values: [] });
    var searchIndex = JSON.parse(dataProduct.product.outOfStock).findIndex(color => color.color.id === colorId);
    setIndexOfColor(searchIndex);
    document.querySelectorAll('[id=input]').forEach(element => {
      element.value = ""
    });

  };

  const onChangeQuantity = (event, sizeId, quantityMax) => {
    var newFormAddToCart = formAddToCart;

    var sizeExist = newFormAddToCart.values.findIndex(item => item.sizeId === Number(sizeId));
    console.log(newFormAddToCart)
    if (sizeExist >= 0) {
      if (Number(event.target.value) > quantityMax) {
        newFormAddToCart.values[sizeExist].quantity = Number(quantityMax);
      } else {
        newFormAddToCart.values[sizeExist].quantity = Number(event.target.value);
      }
    } else {
      if (Number(event.target.value) > quantityMax) {
        newFormAddToCart.values.push({ sizeId: Number(sizeId), quantity: Number(quantityMax) });
      } else {
        newFormAddToCart.values.push({ sizeId: Number(sizeId), quantity: Number(event.target.value) });
      }
    }
    setFormAddToCart(newFormAddToCart);
    var totalQuantity = newFormAddToCart.values.map(item => item.quantity).reduce((a, c) => a + c);
    // check if product is in sale before calculate the total
    if (dataProduct.product.sale && dataProduct.product.sale > 0) {
      setTotal((totalQuantity * dataProduct.product.sale).toFixed(2));
    } else if (dataProduct.product.percentage_discount && dataProduct.product.percentage_discount > 0) {
      setTotal((totalQuantity * (dataProduct.product.price - dataProduct.product.price * (dataProduct.product.percentage_discount / 100))).toFixed(2));
    } else if (JSON.parse(dataProduct.product.discountColors)?.[newFormAddToCart.colorId]) {
      setTotal((
        totalQuantity * (dataProduct.product.price - dataProduct.product.price * (parseInt(JSON.parse(dataProduct.product.discountColors)?.[newFormAddToCart.colorId]) / 100))
      ).toFixed(2));
    } else {
      setTotal((totalQuantity * dataProduct.product.price).toFixed(2));
    }

  };

  const onAddOnCart = () => {
    addOnCart({
      variables: { product_id: match.params.productId, color_id: colorId, values: JSON.stringify(formAddToCart.values) },
      refetchQueries: ["me", "getSummaryProductOnCart", "myCart"],
    }).then(response => {
      // If response of login is 0 set error of login
      if (response.data.addOnCart.status === 1) {
        setSuccessAddToCart(true);
        if (response.data.addOnCart.warning === 1) {
          setWarningAddToCart(true);

        }
        setTimeout(() => {
          window.location.reload()
        }, 1000);
      } else if (response.data.addOnCart.status === 1) {
        setErrorAddToCart(true);
      }
    });
  };

  const onRemoveOnCart = cartId => {
    removeOnCart({
      variables: { id: cartId },
      refetchQueries: ["me", "getSummaryProductOnCart", "myCart"],
    }).then(response => {
      // If response of login is 0 set error of login
      if (response.data.removeOnCart.status === 1) {
        setSuccessRemoveOnCart(true);
      }
    });
  };

  const onFavorite = productId => {
    addRemoveToFavorites({
      variables: { product_id: productId },
      refetchQueries: ["me", "myFavorites"],
    });
  };

  const onGoToShop = () => {
    history.push("/shop");
  };

  const onGoToCart = () => {
    history.push("/cart");
  };

  const onCloseSuccessAddToCart = () => {
    setSuccessAddToCart(false);
    setWarningAddToCart(false);
  };

  const onCloseErrorAddToCart = () => {
    setErrorAddToCart(false);
  };

  const onCloseSuccessRemoveOnCart = () => {
    setSuccessRemoveOnCart(false);
  };

  const openModalZoomColor = (colorHex, colorImage) => {
    setColorToZoom({ colorHex: colorHex, colorImage: colorImage });
    setModalZoomColor(true);
  };

  const closeModalZoomColor = () => {
    setModalZoomColor(false);
  };

  if (loadingMe || loadingProduct || loadingSummaryProductOnCart || loadingMyFavorites || loadingMyCart) {
    return <LinearProgress />;
  }

  if (errorMe || errorProduct || errorSummaryProductOnCart || errorMyFavorites || errorMyCart) {
    return console.log("err");
  }
  // prepare the array with user favorites
  dataMe.me.favorites.map((item) => (
    userFavorites.push(item.product.id)
  ));

  console.log(dataProduct)
  return (
    <Layout
      dataProduct={dataProduct}
      userFavorites={userFavorites}
      onFavorite={onFavorite}
      onChangeTab={onChangeTab}
      tabValue={tabValue}
      onChangeImageToView={onChangeImageToView}
      imageToView={imageToView}
      onChangeColor={onChangeColor}
      colorId={colorId}
      onChangeQuantity={onChangeQuantity}
      onAddOnCart={onAddOnCart}
      successAddToCart={successAddToCart}
      warningAddToCart={warningAddToCart}
      errorAddToCart={errorAddToCart}
      onCloseSuccessAddToCart={onCloseSuccessAddToCart}
      onCloseErrorAddToCart={onCloseErrorAddToCart}
      total={total}
      dataSummaryProductOnCart={dataSummaryProductOnCart}
      onRemoveOnCart={onRemoveOnCart}
      successRemoveOnCart={successRemoveOnCart}
      onCloseSuccessRemoveOnCart={onCloseSuccessRemoveOnCart}
      onGoToShop={onGoToShop}
      onGoToCart={onGoToCart}
      isMobile={isMobile}
      formAddToCart={formAddToCart}
      indexOfColor={indexOfColor}
      modalZoomColor={modalZoomColor}
      openModalZoomColor={openModalZoomColor}
      closeModalZoomColor={closeModalZoomColor}
      colorToZoom={colorToZoom}
    />
  );
};

export default withRouter(Shop);
