import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(16),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  submit: {
    margin: "1rem",
    backgroundColor: "var(--blue)",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "var(--blue)",
      opacity: 0.8,
    },
  },
  textField: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--blue)",
    },
    "& label.Mui-focused": {
      color: "var(--blue)",
    },
  },
}));
