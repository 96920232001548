import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  header: {
    flexGrow: 1,
    marginBottom: "1rem",
  },
  appBar:{
    position: "relative",
    backgroundColor: "#D6B290",
  },
  menuButton: {
    marginRight: "1rem",
  },
  title: {
    flexGrow: 1,
  },
  titleText: {
    fontFamily: "Cormorant Garamond, serif",
    fontWeight: 600,
    display: "inline-block",
    marginLeft: "0.2rem",
    color: "#301610",

  },
  titleImage: {
    verticalAlign: "middle",
    width: "220px",
  },
  titleImageNoWrite: {
    verticalAlign: "middle",
    width: "40px",
  },
  badge: {
    backgroundColor: "var(--mate-red)",
  },
  micWithe: {
  },
  micGreen: {
    color: "var(--mate-green)",
    animationName: "$blinker",
    animationDuration: "1s",
    animationTimingFunction: "linear",
    animationIterationCount:"infinite",
  },
  "@keyframes blinker": {
    from: {opacity: 1},
    to: {opacity: 0.3},
  },
  paperModal: {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(6, 4, 3),
    marginTop: theme.spacing(7),
    margin: "auto",
  },
  modalButtonCloseIcon: {
    padding: 0,
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
}));
