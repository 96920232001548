/* eslint-disable array-callback-return */

import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { useQuery } from "@apollo/react-hooks";
import { GENDER_CATEGORY, SEASON_CATEGORY, FINENESS_CATEGORY, TAG_CATEGORY, COMPOSITION_CATEGORY } from "../../../query";
import { ADD_GENDER, REMOVE_GENDER, UPDATE_GENDER, ADD_SEASON, REMOVE_SEASON, UPDATE_SEASON, ADD_FINENESS, REMOVE_FINENESS, UPDATE_FINENESS, ADD_TAG, REMOVE_TAG, UPDATE_TAG, ADD_COMPOSITION, REMOVE_COMPOSITION, UPDATE_COMPOSITION  } from "../../../mutation";

import LinearProgress from "@material-ui/core/LinearProgress";

import Layout from "./layout";

const Categories = ({ history }) => {
  // Category Mutation
  const [addGender] = useMutation(ADD_GENDER);
  const [addSeason] = useMutation(ADD_SEASON);
  const [addFineness] = useMutation(ADD_FINENESS);
  const [addTag] = useMutation(ADD_TAG);
  const [addComposition] = useMutation(ADD_COMPOSITION);
  const [removeGender] = useMutation(REMOVE_GENDER);
  const [removeSeason] = useMutation(REMOVE_SEASON);
  const [removeFineness] = useMutation(REMOVE_FINENESS);
  const [removeTag] = useMutation(REMOVE_TAG);
  const [removeComposition] = useMutation(REMOVE_COMPOSITION);
  const [updateGender] = useMutation(UPDATE_GENDER);
  const [updateSeason] = useMutation(UPDATE_SEASON);
  const [updateFineness] = useMutation(UPDATE_FINENESS);
  const [updateTag] = useMutation(UPDATE_TAG);
  const [updateComposition] = useMutation(UPDATE_COMPOSITION);

  // formValues for insert one for all category
  const [addCategoriesFormValues, setAddCategoriesFormValues] = useState({});
  // Loading
  const [loadingGender, setLoadingGender] = useState(false);
  const [loadingRemoveGender, setLoadingRemoveGender] = useState(false);
  const [loadingSeason, setLoadingSeason] = useState(false);
  const [loadingRemoveSeason, setLoadingRemoveSeason] = useState(false);
  const [loadingFineness, setLoadingFineness] = useState(false);
  const [loadingRemoveFineness, setLoadingRemoveFineness] = useState(false);
  const [loadingTag, setLoadingTag] = useState(false);
  const [loadingRemoveTag, setLoadingRemoveTag] = useState(false);
  const [loadingComposition, setLoadingComposition] = useState(false);
  const [loadingRemoveComposition, setLoadingRemoveComposition] = useState(false);
  // Error
  const [errorRemoveGender, setErrorRemoveGender] = useState(false);
  const [errorRemoveSeason, setErrorRemoveSeason] = useState(false);
  const [errorRemoveFineness, setErrorRemoveFineness] = useState(false);
  const [errorRemoveTag, setErrorRemoveTag] = useState(false);
  const [errorRemoveComposition, setErrorRemoveComposition] = useState(false);
  const [errorAddGender, setErrorAddGender] = useState(false);
  const [errorAddSeason, setErrorAddSeason] = useState(false);
  const [errorAddFineness, setErrorAddFineness] = useState(false);
  const [errorAddTag, setErrorAddTag] = useState(false);
  const [errorAddComposition, setErrorAddComposition] = useState(false);
  const [errorUpdateGender, setErrorUpdateGender] = useState(false);
  const [errorUpdateSeason, setErrorUpdateSeason] = useState(false);
  const [errorUpdateFineness, setErrorUpdateFineness] = useState(false);
  const [errorUpdateTag, setErrorUpdateTag] = useState(false);
  const [errorUpdateComposition, setErrorUpdateComposition] = useState(false);
  // Edit useState
  const [openEditGender, setOpenEditGender] = useState({});
  const [openEditSeason, setOpenEditSeason] = useState({});
  const [openEditFineness, setOpenEditFineness] = useState({});
  const [openEditTag, setOpenEditTag] = useState({});
  const [openEditComposition, setOpenEditComposition] = useState({});
  const [editGenderFormValues, setEditGenderFormValues] = useState({});
  const [editSeasonFormValues, setEditSeasonFormValues] = useState({});
  const [editFinenessFormValues, setEditFinenessFormValues] = useState({});
  const [editTagFormValues, setEditTagFormValues] = useState({});
  const [editCompositionFormValues, setEditCompositionFormValues] = useState({});
  const [saveEditGender, setSaveEditGender] = useState({});
  const [saveEditSeason, setSaveEditSeason] = useState({});
  const [saveEditFineness, setSaveEditFineness] = useState({});
  const [saveEditTag, setSaveEditTag] = useState({});
  const [saveEditComposition, setSaveEditComposition] = useState({});

  // Checkbox obj slug = id for remove
  const [checkboxStateGender, setCheckboxStateGender] = useState({});
  const [checkboxStateSeason, setCheckboxStateSeason] = useState({});
  const [checkboxStateFineness, setCheckboxStateFineness] = useState({});
  const [checkboxStateTag, setCheckboxStateTag] = useState({});
  const [checkboxStateComposition, setCheckboxStateComposition] = useState({});

  // Accordition
  const [expanded, setExpanded] = React.useState(false);

  // QUERY for get all categories
  const { loading: queryLoadingGenders, error: errorGenders, data: dataGenders } = useQuery(GENDER_CATEGORY);
  const { loading: queryLoadingSeasons, error: errorSeasons, data: dataSeasons } = useQuery(SEASON_CATEGORY);
  const { loading: queryLoadingFineness, error: errorFineness, data: dataFineness } = useQuery(FINENESS_CATEGORY);
  const { loading: queryLoadingTags, error: errorTags, data: dataTags } = useQuery(TAG_CATEGORY);
  const { loading: queryLoadingCompositions, error: errorCompositions, data: dataCompositions } = useQuery(COMPOSITION_CATEGORY);

  // Add gender category
  const onAddGender = () => {
    setLoadingGender(true);
    let array = addCategoriesFormValues["gender"].split(",");
    array.map((item, index) => {
      addGender({
        variables: { name: item},
        refetchQueries: ["genders"],
      }).then(response => {
        if(response.data.addGender.status === 0){
          setErrorAddGender(true);
          setLoadingGender(false);
        }else if(response.data.addGender.status === 1 && array.length === index + 1){
          setErrorAddGender(false);
          setLoadingGender(false);
          setAddCategoriesFormValues({gender: ""});
        }
      }).catch(err =>{
        setErrorAddGender(true);
        setLoadingGender(false);
      });
    });
  };

  // Add Season category
  const onAddSeason = () => {
    setLoadingSeason(true);
    let array = addCategoriesFormValues["season"].split(",");
    array.map((item, index) => {
      addSeason({
        variables: { name: item},
        refetchQueries: ["seasons"],
      }).then(response => {
        if(response.data.addSeason.status === 0){
          setErrorAddSeason(true);
          setLoadingSeason(false);
        }else if(response.data.addSeason.status === 1 && array.length === index + 1){
          setErrorAddSeason(false);
          setAddCategoriesFormValues({season: ""});
          setLoadingSeason(false);
        }
      }).catch(err =>{
        setErrorAddSeason(true);
        setLoadingSeason(false);
      });
    });
  };

  // Add Fineness category
  const onAddFineness = () => {
    setLoadingFineness(true);
    let array = addCategoriesFormValues["fineness"].split(",");
    array.map((item, index) => {
      addFineness({
        variables: { name: item},
        refetchQueries: ["fineness"],
      }).then(response => {
        if(response.data.addFineness.status === 0){
          setErrorAddFineness(true);
          setLoadingFineness(false);
        }else if(response.data.addFineness.status === 1 && array.length === index + 1){
          setErrorAddFineness(false);
          setAddCategoriesFormValues({fineness: ""});
          setLoadingFineness(false);
        }
      }).catch(err =>{
        setErrorAddFineness(true);
        setLoadingFineness(false);
      });
    });
  };

  // Add Tag category
  const onAddTag = () => {
    setLoadingTag(true);
    let array = addCategoriesFormValues["tag"].split(",");
    array.map((item, index) => {
      addTag({
        variables: { name: item},
        refetchQueries: ["tags"],
      }).then(response => {
        if(response.data.addTag.status === 0){
          setErrorAddTag(true);
          setLoadingTag(false);
        }else if(response.data.addTag.status === 1 && array.length === index + 1){
          setErrorAddTag(false);
          setAddCategoriesFormValues({tag: ""});
          setLoadingTag(false);
        }
      }).catch(err =>{
        setErrorAddTag(true);
        setLoadingTag(false);
      });
    });
  };

  // Add Composition category
  const onAddComposition = () => {
    setLoadingComposition(true);
    let array = addCategoriesFormValues["composition"].split(",");
    array.map((item, index) => {
      addComposition({
        variables: { name: item},
        refetchQueries: ["compositions"],
      }).then(response => {
        if(response.data.addComposition.status === 0){
          setErrorAddComposition(true);
          setLoadingComposition(false);
        }else if(response.data.addComposition.status === 1 && array.length === index + 1){
          setErrorAddComposition(false);
          setAddCategoriesFormValues({composition: ""});
          setLoadingComposition(false);
        }
      }).catch(err =>{
        setErrorAddComposition(true);
        setLoadingComposition(false);
      });
    });
  };

  // Remove Gender with loop of json and mutation for every ID
  const onRemoveGenders = () => {
    if(Object.keys(checkboxStateGender).length > 0) {
      setLoadingRemoveGender(true);
      Object.keys(checkboxStateGender).forEach(function(key, index) {
        removeGender({
          variables: { id: checkboxStateGender[key]},
          refetchQueries: ["genders"],
        }).then(response => {
          if(response.data.removeGender.status === 0){
            setErrorRemoveGender(true);
            setLoadingRemoveGender(false);
          }else if(response.data.removeGender.status === 1){
            if(index+1 === Object.keys(checkboxStateGender).length){
              setErrorRemoveGender(false);
              setLoadingRemoveGender(false);
            }
          }
        }).catch(err =>{
          setErrorRemoveGender(true);
          setLoadingRemoveGender(false);
        });
      });
    }
    setCheckboxStateGender({});
  };

  // Remove Season with loop of json and mutation for every ID
  const onRemoveSeasons = () => {
    if(Object.keys(checkboxStateSeason).length > 0) {
      setLoadingRemoveSeason(true);
      Object.keys(checkboxStateSeason).forEach(function(key, index) {
        removeSeason({
          variables: { id: checkboxStateSeason[key]},
          refetchQueries: ["seasons"],
        }).then(response => {
          if(response.data.removeSeason.status === 0){
            setErrorRemoveSeason(true);
            setLoadingRemoveSeason(false);
          }else if(response.data.removeSeason.status === 1){
            if(index+1 === Object.keys(checkboxStateSeason).length){
              setErrorRemoveSeason(false);
              setLoadingRemoveSeason(false);
            }
          }
        }).catch(err =>{
          setErrorRemoveSeason(true);
          setLoadingRemoveSeason(false);
        });
      });
    }
    setCheckboxStateSeason({});
  };

  // Remove Fineness with loop of json and mutation for every ID
  const onRemoveFineness = () => {
    if(Object.keys(checkboxStateFineness).length > 0) {
      setLoadingRemoveFineness(true);
      Object.keys(checkboxStateFineness).forEach(function(key, index) {
        removeFineness({
          variables: { id: checkboxStateFineness[key]},
          refetchQueries: ["fineness"],
        }).then(response => {
          if(response.data.removeFineness.status === 0){
            setErrorRemoveFineness(true);
            setLoadingRemoveFineness(false);
          }else if(response.data.removeFineness.status === 1){
            if(index+1 === Object.keys(checkboxStateFineness).length){
              setErrorRemoveFineness(false);
              setLoadingRemoveFineness(false);
            }
          }
        }).catch(err =>{
          setErrorRemoveFineness(true);
          setLoadingRemoveFineness(false);
        });
      });
    }
    setCheckboxStateFineness({});
  };

  // Remove Tags with loop of json and mutation for every ID
  const onRemoveTags = () => {
    if(Object.keys(checkboxStateTag).length > 0) {
      setLoadingRemoveTag(true);
      Object.keys(checkboxStateTag).forEach(function(key, index) {
        removeTag({
          variables: { id: checkboxStateTag[key]},
          refetchQueries: ["tags"],
        }).then(response => {
          if(response.data.removeTag.status === 0){
            setErrorRemoveTag(true);
            setLoadingRemoveTag(false);
          }else if(response.data.removeTag.status === 1){
            if(index+1 === Object.keys(checkboxStateTag).length){
              setErrorRemoveTag(false);
              setLoadingRemoveTag(false);
            }
          }
        }).catch(err =>{
          setErrorRemoveTag(true);
          setLoadingRemoveTag(false);
        });
      });
    }
    setCheckboxStateTag({});
  };

  // Remove Compositions with loop of json and mutation for every ID
  const onRemoveCompositions = () => {
    if(Object.keys(checkboxStateComposition).length > 0) {
      setLoadingRemoveComposition(true);
      Object.keys(checkboxStateComposition).forEach(function(key, index) {
        removeComposition({
          variables: { id: checkboxStateComposition[key]},
          refetchQueries: ["compositions"],
        }).then(response => {
          if(response.data.removeComposition.status === 0){
            setErrorRemoveComposition(true);
            setLoadingRemoveComposition(false);
          }else if(response.data.removeComposition.status === 1){
            if(index+1 === Object.keys(checkboxStateComposition).length){
              setErrorRemoveComposition(false);
              setLoadingRemoveComposition(false);
            }
          }
        }).catch(err =>{
          setErrorRemoveComposition(true);
          setLoadingRemoveComposition(true);
        });
      });
    }
    setCheckboxStateComposition({});
  };

  // Update Gender
  const onSaveUpdateGender = () => {
    setLoadingGender(true);
    for (var key in editGenderFormValues) {
      updateGender({
        variables: { id: key, name: editGenderFormValues[key]},
        refetchQueries: ["genders"],
      }).then(response => {
        if(response.data.updateGender.status === 0){
          setErrorUpdateGender(true);
        }else if(response.data.updateGender.status === 1){
          setErrorUpdateGender(false);
          setOpenEditGender({});
          setEditGenderFormValues({});
        }
      }).catch(err =>{
        setErrorUpdateGender(true);
      });
    }
    setLoadingGender(false);
  };

  // Update Season
  const onSaveUpdateSeason = () => {
    setLoadingSeason(true);
    for (var key in editSeasonFormValues) {
      updateSeason({
        variables: { id: key, name: editSeasonFormValues[key]},
        refetchQueries: ["seasons"],
      }).then(response => {
        if(response.data.updateSeason.status === 0){
          setErrorUpdateSeason(true);
        }else if(response.data.updateSeason.status === 1){
          setErrorUpdateSeason(false);
          setOpenEditSeason({});
          setEditSeasonFormValues({});
        }
      }).catch(err =>{
        setErrorUpdateSeason(true);
      });
    }
    setLoadingSeason(false);
  };

  // Update Fineness
  const onSaveUpdateFineness = () => {
    setLoadingFineness(true);
    for (var key in editFinenessFormValues) {
      updateFineness({
        variables: { id: key, name: editFinenessFormValues[key]},
        refetchQueries: ["fineness"],
      }).then(response => {
        if(response.data.updateFineness.status === 0){
          setErrorUpdateFineness(true);
        }else if(response.data.updateFineness.status === 1){
          setErrorUpdateFineness(false);
          setOpenEditFineness({});
          setEditFinenessFormValues({});
        }
      }).catch(err =>{
        setErrorUpdateFineness(true);
      });
    }
    setLoadingFineness(false);
  };

  // Update Tag
  const onSaveUpdateTag = () => {
    setLoadingTag(true);
    for (var key in editTagFormValues) {
      updateTag({
        variables: { id: key, name: editTagFormValues[key]},
        refetchQueries: ["tags"],
      }).then(response => {
        if(response.data.updateTag.status === 0){
          setErrorUpdateTag(true);
        }else if(response.data.updateTag.status === 1){
          setErrorUpdateTag(false);
          setOpenEditTag({});
          setEditTagFormValues({});
        }
      }).catch(err =>{
        setErrorUpdateTag(true);
      });
    }
    setLoadingTag(false);
  };

  // Update Composition
  const onSaveUpdateComposition = () => {
    setLoadingComposition(true);
    for (var key in editCompositionFormValues) {
      updateComposition({
        variables: { id: key, name: editCompositionFormValues[key]},
        refetchQueries: ["compositions"],
      }).then(response => {
        if(response.data.updateComposition.status === 0){
          setErrorUpdateComposition(true);
        }else if(response.data.updateComposition.status === 1){
          setErrorUpdateComposition(false);
          setOpenEditComposition({});
          setEditCompositionFormValues({});
        }
      }).catch(err =>{
        setErrorUpdateComposition(true);
      });
    }
    setLoadingComposition(false);
  };

  // Edit Gender
  const onOpenEditGender = (id, name) => {
    setOpenEditGender({open: id});
    setEditGenderFormValues({name: name});
    setErrorUpdateGender(false);
    setCheckboxStateGender({});
  };
  // Close Edit Gender
  const onCloseEditGender = () => {
    setOpenEditGender({});
    setEditGenderFormValues({});
    setSaveEditGender({});
  };

  // Edit Season
  const onOpenEditSeason = (id, name) => {
    setOpenEditSeason({open: id});
    setEditSeasonFormValues({name: name});
    setErrorUpdateSeason(false);
    setCheckboxStateSeason({});
  };
  // Close Edit Season
  const onCloseEditSeason = () => {
    setOpenEditSeason({});
    setEditSeasonFormValues({});
    setSaveEditSeason({});

  };

  // Edit Fineness
  const onOpenEditFineness = (id, name) => {
    setOpenEditFineness({open: id});
    setEditFinenessFormValues({name: name});
    setErrorUpdateFineness(false);
    setCheckboxStateFineness({});
  };
  // Close Edit Fineness
  const onCloseEditFineness = () => {
    setOpenEditFineness({});
    setEditFinenessFormValues({});
    setSaveEditFineness({});

  };

  // Edit Tag
  const onOpenEditTag = (id, name) => {
    setOpenEditTag({open: id});
    setEditTagFormValues({name: name});
    setErrorUpdateTag(false);
    setCheckboxStateTag({});
  };
  // Close Edit Tag
  const onCloseEditTag = () => {
    setOpenEditTag({});
    setEditTagFormValues({});
    setSaveEditTag({});
  };

  // Edit Composition
  const onOpenEditComposition = (id, name) => {
    setOpenEditComposition({open: id});
    setEditCompositionFormValues({name: name});
    setErrorUpdateComposition(false);
    setCheckboxStateComposition({});
  };
  // Close Edit Composition
  const onCloseEditComposition = () => {
    setOpenEditComposition({});
    setEditCompositionFormValues({});
    setSaveEditComposition({});
  };

  // onChange Edit Gender Form Values
  const onChangeEditGender = event => {
    const value = event.target.value;
    const name = event.target.name;
    setEditGenderFormValues({ [name]: value });
    setErrorUpdateGender(false);
    setSaveEditGender({ [name]: name });
  };

  // onChange Edit Season Form Values
  const onChangeEditSeason = event => {
    const value = event.target.value;
    const name = event.target.name;
    setEditSeasonFormValues({ [name]: value });
    setErrorUpdateSeason(false);
    setSaveEditSeason({ [name]: name });
  };

  // onChange Edit Fineness Form Values
  const onChangeEditFineness = event => {
    const value = event.target.value;
    const name = event.target.name;
    setEditFinenessFormValues({ [name]: value });
    setErrorUpdateFineness(false);
    setSaveEditFineness({ [name]: name });
  };

  // onChange Edit Tag Form Values
  const onChangeEditTag = event => {
    const value = event.target.value;
    const name = event.target.name;
    setEditTagFormValues({ [name]: value });
    setErrorUpdateTag(false);
    setSaveEditTag({ [name]: name });
  };

  // onChange Edit Composition Form Values
  const onChangeEditComposition = event => {
    const value = event.target.value;
    const name = event.target.name;
    setEditCompositionFormValues({ [name]: value });
    setErrorUpdateComposition(false);
    setSaveEditComposition({ [name]: name });
  };

  // When write in Textfield of any category add the category name and the value to the addCategoriesFormValues
  const onChangeAddCategories = event => {
    const value = event.target.value;
    const name = event.target.name;
    setAddCategoriesFormValues({ ...addCategoriesFormValues, [name]: value });
  };

  // on Change state checkbox GENDER for delete, if checked get value of ID else set value undefined
  const onChangeCheckboxGender = (event) => {
    setOpenEditGender({});
    if (event.target.checked){
      setCheckboxStateGender({ ...checkboxStateGender, [event.target.name]: event.target.value });
    } else {
      delete checkboxStateGender[event.target.name];
      setCheckboxStateGender({ ...checkboxStateGender});
    }
  };
  // on Change state checkbox SEASON for delete, if checked get value of ID else set value undefined
  const onChangeCheckboxSeason = (event) => {
    setOpenEditSeason({});
    if (event.target.checked){
      setCheckboxStateSeason({ ...checkboxStateSeason, [event.target.name]: event.target.value });
    } else {
      delete checkboxStateSeason[event.target.name];
      setCheckboxStateSeason({ ...checkboxStateSeason});
    }
  };
  // on Change state checkbox FINENESS for delete, if checked get value of ID else set value undefined
  const onChangeCheckboxFineness = (event) => {
    setOpenEditFineness({});
    if (event.target.checked){
      setCheckboxStateFineness({ ...checkboxStateFineness, [event.target.name]: event.target.value });
    } else {
      delete checkboxStateFineness[event.target.name];
      setCheckboxStateFineness({ ...checkboxStateFineness});
    }
  };
  // on Change state checkbox TAG for delete, if checked get value of ID else set value undefined
  const onChangeCheckboxTag = (event) => {
    setOpenEditTag({});
    if (event.target.checked){
      setCheckboxStateTag({ ...checkboxStateTag, [event.target.name]: event.target.value });
    } else {
      delete checkboxStateTag[event.target.name];
      setCheckboxStateTag({ ...checkboxStateTag});
    }
  };
  // on Change state checkbox COMPOSITION for delete, if checked get value of ID else set value undefined
  const onChangeCheckboxComposition = (event) => {
    setOpenEditComposition({});
    if (event.target.checked){
      setCheckboxStateComposition({ ...checkboxStateComposition, [event.target.name]: event.target.value });
    } else {
      delete checkboxStateComposition[event.target.name];
      setCheckboxStateComposition({ ...checkboxStateComposition});
    }
  };
  // onChange Accoirdition Tab
  const onChangeAccordition = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // Verify query for registration permission
  if (queryLoadingGenders || queryLoadingSeasons || queryLoadingFineness || queryLoadingTags || queryLoadingCompositions) return <LinearProgress />;
  if (errorGenders || errorSeasons || errorFineness || errorTags || errorCompositions) { history.push("/"); }

  return (
    <Layout
      dataGenders={dataGenders}
      dataSeasons={dataSeasons}
      dataFineness={dataFineness}
      dataTags={dataTags}
      dataCompositions={dataCompositions}
      onAddGender={onAddGender}
      onAddSeason={onAddSeason}
      onAddFineness={onAddFineness}
      onAddTag={onAddTag}
      onAddComposition={onAddComposition}
      onOpenEditGender={onOpenEditGender}
      onOpenEditSeason={onOpenEditSeason}
      onOpenEditFineness={onOpenEditFineness}
      onOpenEditTag={onOpenEditTag}
      onOpenEditComposition={onOpenEditComposition}
      openEditGender={openEditGender}
      openEditSeason={openEditSeason}
      openEditFineness={openEditFineness}
      openEditTag={openEditTag}
      openEditComposition={openEditComposition}
      onCloseEditGender={onCloseEditGender}
      onCloseEditSeason={onCloseEditSeason}
      onCloseEditFineness={onCloseEditFineness}
      onCloseEditTag={onCloseEditTag}
      onCloseEditComposition={onCloseEditComposition}
      editGenderFormValues={editGenderFormValues}
      editSeasonFormValues={editSeasonFormValues}
      editFinenessFormValues={editFinenessFormValues}
      editTagFormValues={editTagFormValues}
      editCompositionFormValues={editCompositionFormValues}
      onChangeEditGender={onChangeEditGender}
      onChangeEditSeason={onChangeEditSeason}
      onChangeEditFineness={onChangeEditFineness}
      onChangeEditTag={onChangeEditTag}
      onChangeEditComposition={onChangeEditComposition}
      saveEditGender={saveEditGender}
      saveEditSeason={saveEditSeason}
      saveEditFineness={saveEditFineness}
      saveEditTag={saveEditTag}
      saveEditComposition={saveEditComposition}
      onSaveUpdateGender={onSaveUpdateGender}
      onSaveUpdateSeason={onSaveUpdateSeason}
      onSaveUpdateFineness={onSaveUpdateFineness}
      onSaveUpdateTag={onSaveUpdateTag}
      onSaveUpdateComposition={onSaveUpdateComposition}
      onRemoveGenders={onRemoveGenders}
      onRemoveSeasons={onRemoveSeasons}
      onRemoveFineness={onRemoveFineness}
      onRemoveTags={onRemoveTags}
      onRemoveCompositions={onRemoveCompositions}
      addCategoriesFormValues={addCategoriesFormValues}
      onChangeAddCategories={onChangeAddCategories}
      onChangeCheckboxGender={onChangeCheckboxGender}
      onChangeCheckboxSeason={onChangeCheckboxSeason}
      onChangeCheckboxFineness={onChangeCheckboxFineness}
      onChangeCheckboxTag={onChangeCheckboxTag}
      onChangeCheckboxComposition={onChangeCheckboxComposition}
      checkboxStateGender={checkboxStateGender}
      checkboxStateSeason={checkboxStateSeason}
      checkboxStateFineness={checkboxStateFineness}
      checkboxStateTag={checkboxStateTag}
      checkboxStateComposition={checkboxStateComposition}
      loadingGender={loadingGender}
      loadingSeason={loadingSeason}
      loadingFineness={loadingFineness}
      loadingTag={loadingTag}
      loadingComposition={loadingComposition}
      errorAddGender={errorAddGender}
      errorAddSeason={errorAddSeason}
      errorAddFineness={errorAddFineness}
      errorAddTag={errorAddTag}
      errorAddComposition={errorAddComposition}
      errorRemoveGender={errorRemoveGender}
      errorRemoveSeason={errorRemoveSeason}
      errorRemoveFineness={errorRemoveFineness}
      errorRemoveTag={errorRemoveTag}
      errorRemoveComposition={errorRemoveComposition}
      errorUpdateGender={errorUpdateGender}
      errorUpdateSeason={errorUpdateSeason}
      errorUpdateFineness={errorUpdateFineness}
      errorUpdateTag={errorUpdateTag}
      errorUpdateComposition={errorUpdateComposition}
      expanded={expanded}
      onChangeAccordition={onChangeAccordition}
      loadingRemoveGender={loadingRemoveGender}
      loadingRemoveSeason={loadingRemoveSeason}
      loadingRemoveFineness={loadingRemoveFineness}
      loadingRemoveComposition={loadingRemoveComposition}
      loadingRemoveTag={loadingRemoveTag}
    />
  );
};

export default withRouter(Categories);
