import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { PRICE_LIST } from "../../../query";
import { useMutation } from "@apollo/react-hooks";
import { REMOVE_PRICE_LIST, ADD_PRICE_LIST } from "../../../mutation";
import Layout from "./layout";
import LinearProgress from "@material-ui/core/LinearProgress";

const Lists = ({ history }) => {
  // Modal
  const [openModalConfirmDeletePriceList, setOpenModalConfirmDeletePriceList] = useState(false);
  // Loading
  const [loadingRemovePriceList, setLoadingRemovePriceList] = useState(false);
  // Error

  const [errorAddPriceList, setErrorAddPriceList] = useState(false);
  //Form Values
  const [newListName, setNewListName] = useState("");
  const [nameListToDelete, setNameListToDelete] = useState("");
  const [idPriceListToRemove, setIdPriceListToRemove] = useState();

  // QUERY
  const { loading: loadingPriceList, error: errorPriceList, data: dataPriceList } = useQuery(PRICE_LIST);
  // MUTATION
  const [removePriceList] = useMutation(REMOVE_PRICE_LIST);
  const [addPriceList] = useMutation(ADD_PRICE_LIST);

  // MUTATION Remove user by id
  const onRemovePriceList = ($id_list_type_0, $id_list_type_1) => {
    setLoadingRemovePriceList(true);
    removePriceList({
      variables: { id_list_type_0: $id_list_type_0, id_list_type_1: $id_list_type_1, },
      refetchQueries: ["priceLists"],
    }).then(response => {
      // If response of remove registration permission is 0 set error
      if(response.data.removePriceList.status === 0){
        setLoadingRemovePriceList(false);
      }else if(response.data.removePriceList.status === 1){
        setOpenModalConfirmDeletePriceList(false);
        setLoadingRemovePriceList(false);
      }
    }).catch(err =>{
      // if get error of mutation set error of insert Vat number to true
      setLoadingRemovePriceList(false);
    });
  };

  // MUTATION Remove user by id
  const onAddPriceList = () => {
    addPriceList({
      variables: { name: newListName },
      refetchQueries: ["priceLists"],
    }).then(response => {
      // If response of remove registration permission is 0 set error
      if(response.data.addPriceList.status === 0){
        setErrorAddPriceList(true);
      }else if(response.data.addPriceList.status === 1){
        setErrorAddPriceList(false);
        setNewListName("");
      }
    });
  };

  // Open modal confirmation delete price list
  const onOpenConfirmDeletePriceList = (item) => {
    setIdPriceListToRemove(parseInt(item.id));
    setNameListToDelete(item.name);
    setOpenModalConfirmDeletePriceList(true);
  };

  // Close modal confirmation delete price list
  const onCloseConfirmDeletePriceList = () => {
    setOpenModalConfirmDeletePriceList(false);
  };

  const onChangeForm = (event) => {
    setNewListName(event.target.value);
    setErrorAddPriceList(false);
  };

  const onViewPriceList = (id) => {
    history.push("price-list-products/" + id);
  };

  if (loadingPriceList) return <LinearProgress />;
  if (errorPriceList) { history.push("/"); }

  return (
    <Layout
      dataPriceList={dataPriceList}
      openModalConfirmDeletePriceList={openModalConfirmDeletePriceList}
      onCloseConfirmDeletePriceList={onCloseConfirmDeletePriceList}
      onOpenConfirmDeletePriceList={onOpenConfirmDeletePriceList}
      onRemovePriceList={onRemovePriceList}
      idPriceListToRemove={idPriceListToRemove}
      onChangeForm={onChangeForm}
      newListName={newListName}
      onAddPriceList={onAddPriceList}
      errorAddPriceList={errorAddPriceList}
      onViewPriceList={onViewPriceList}
      loadingRemovePriceList={loadingRemovePriceList}
      nameListToDelete={nameListToDelete}
    />
  );
};

export default withRouter(Lists);
