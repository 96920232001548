/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { COLORS, COLORS_FOLDER, SEASON_CATEGORY } from "../../../query";
import { useMutation } from "@apollo/react-hooks";
import { ADD_NEW_COLOR, REMOVE_COLOR, UPDATE_COLOR, ADD_NEW_COLOR_FOLDER, EDIT_COLOR_FOLDER, DELETE_COLOR_FOLDER } from "../../../mutation";
import Layout from "./layout";
import LinearProgress from "@material-ui/core/LinearProgress";

const Colors = ({ history }) => {

  // Form Values
  const [addNewColorFolderFormValues, setAddNewColorFolderFormValues] = useState({});
  const [editColorFolderFormValues, setEditColorFolderFormValues] = useState({});
  const [colorFolder, setColorFolder] = useState({});
  const [colorFolderSeason, setColorFolderSeason] = useState();
  const [addNewColorFormValues, setAddNewColorFormValues] = useState({});
  const [fileColorImage, setFileColorImage] = useState();
  const [colorToDelete, setColorToDelete] = useState({});
  const [colorToUpdate, setColorToUpdate] = useState({});
  const [search, setSearch] = useState();
  const [idColorImageUpdated, setIdColorImageUpdated] = useState(undefined);
  const [checkboxMassiveDelete, setCheckboxMassiveDelete] = useState([]);
  const [allDeleteChecked, setAllDeleteChecked] = useState(false);
  const [successDeleteFolder, setSuccessDeleteFolder] = useState(false);

  // Open Modal e Dropzone
  const [openModalEditColorFolder, setOpenModalEditColorFolder] = useState(false);
  const [openModalConfirmDeleteColorFolder, setOpenModalConfirmDeleteColorFolder] = useState(false);
  const [openModalConfirmDeleteColor, setOpenModalConfirmDeleteColor] = useState(false);
  const [openModalUpdateColor, setOpenModalUpdateColor] = useState(false);
  const [openDropzone, setOpenDropzone] = useState(false);
  const [openModalConfirmDeleteAllColor, setOpenModalConfirmDeleteAllColor] = useState(false);

  // loading
  const [loadingAddNewColor, setLoadingAddNewColor] = useState(false);
  const [loadingRemoveColor, setLoadingRemoveColor] = useState(false);
  const [loadingUpdateColor, setLoadingUpdateColor] = useState(false);
  const [loadingRemoveAllColor, setLoadingRemoveAllColor] = useState(false);
  const [loadingAddNewColorFolder, setLoadingAddNewColorFolder] = useState(false);

  // Error
  const [errorAddNewColor, setErrorAddNewColor] = useState(false);
  const [errorUpdateColor, setErrorUpdateColor] = useState(false);
  const [errorAddNewColorFolder, setErrorAddNewColorFolder] = useState(false);
  const [errorEditColorFolder, setErrorEditColorFolder] = useState(false);
  const [errorConfirmDeleteColorFolder, setErrorConfirmDeleteColorFolder] = useState(false);
  const [errorMessageAddNewColor, setErrorMessageAddNewColor] = useState();

  // QUERY
  const { loading: loadingColors, error: errorColors, data: dataColors } = useQuery(COLORS);
  const { loading: loadingColorsFolder, error: errorColorsFolder, data: dataColorsFolder } = useQuery(COLORS_FOLDER);
  const { loading: loadingSeasons, error: errorSeasons, data: dataSeasons } = useQuery(SEASON_CATEGORY);

  // MUTATION
  const [addNewColor] = useMutation(ADD_NEW_COLOR);
  const [removeColor] = useMutation(REMOVE_COLOR);
  const [updateColor] = useMutation(UPDATE_COLOR);
  const [addNewColorFolder] = useMutation(ADD_NEW_COLOR_FOLDER);
  const [editColorFolder] = useMutation(EDIT_COLOR_FOLDER);
  const [deleteColorFolder] = useMutation(DELETE_COLOR_FOLDER);

  useEffect(() => {
    // const track = document.getElementsByClassName('div.css-1uccc91-singleValue');
    const track = document.querySelector('.css-1uccc91-singleValue')
    console.log(track);
  }, [colorFolderSeason]);

  // Mutation Add New Folder
  const onAddColorFolder = () => {
    setLoadingAddNewColorFolder(true);
    addNewColorFolder({
      variables: { name: addNewColorFolderFormValues.colorFolder},
      refetchQueries: ["colorsFolder"],
    }).then(response => {
      // If response of remove registration permission is 0 set error
      if(response.data.addNewColorFolder.status === 0){
        setErrorAddNewColorFolder(true);
        setLoadingAddNewColorFolder(false);
      }else if(response.data.addNewColorFolder.status === 1){
        setErrorAddNewColorFolder(false);
        setLoadingAddNewColorFolder(false);
        setAddNewColorFolderFormValues({colorFolder: ""});
        setColorFolder(null);
      }
    }).catch(err =>{
      setErrorAddNewColorFolder(true);
      setLoadingAddNewColorFolder(false);
    });
  };

  // Edit Color Folder
  const editColorFolderById = ($id) => {
    editColorFolder({
      variables: { id: $id, name: editColorFolderFormValues.name},
      refetchQueries: ["colorsFolder"],
    }).then(response => {
      // If response of remove registration permission is 0 set error
      if(response.data.editColorFolder.status === 0){
        setErrorEditColorFolder(true);
      }else if(response.data.editColorFolder.status === 1){
        setErrorEditColorFolder(false);
        setEditColorFolderFormValues({name: ""});
        setColorFolder(null);
        setOpenModalEditColorFolder(false);
      }
    }).catch(err =>{
      setErrorEditColorFolder(true);
    });
  };

  // Delete Color Folder
  const deleteColorFolderById  = () => {
    deleteColorFolder({
      variables: { id: colorFolder.id},
      refetchQueries: ["colorsFolder"],
    }).then(response => {
      // If response of remove registration permission is 0 set error
      if(response.data.deleteColorFolder.status === 0){
        setErrorConfirmDeleteColorFolder(true);
      }else if(response.data.deleteColorFolder.status === 1){
        setErrorConfirmDeleteColorFolder(false);
        setSuccessDeleteFolder(true);
        setTimeout(() => {
          setOpenModalConfirmDeleteColorFolder(false);
          setColorFolder(null);
        }, 700);

      }
    }).catch(err =>{
      setErrorConfirmDeleteColorFolder(true);
    });
  };

  // Mutation Add New Color
  const onSaveNewColor = () => {
    setLoadingAddNewColor(true);
    addNewColor({
      variables: { file: fileColorImage, hex: JSON.stringify(addNewColorFormValues), color_folder_id: colorFolder.id },
      context: {
        hasUpload: true,
      },
      refetchQueries: ["colors"],
    }).then(response => {
      // If response of remove registration permission is 0 set error
      if(response.data.addNewColor.status === 0){
        setErrorAddNewColor(true);
        setLoadingAddNewColor(false);
      }else if(response.data.addNewColor.status === 1){
        setErrorAddNewColor(false);
        setLoadingAddNewColor(false);
        setFileColorImage(undefined);
        setAddNewColorFormValues(undefined);
        if(response.data.addNewColor.error !== ""){
          setErrorMessageAddNewColor(response.data.addNewColor.error);
        }
      }
    }).catch(err =>{
      setErrorAddNewColor(false);
      setLoadingAddNewColor(false);
    });
  };

  // Mutation Remove Color
  const onRemoveColor = ($id) => {
    setLoadingRemoveColor(true);
    removeColor({
      variables: { id: $id },
      refetchQueries: ["colors"],
    }).then(response => {
      // If response of remove registration permission is 0 set error
      if(response.data.removeColor.status === 0){
        setLoadingRemoveColor(false);
      }else if(response.data.removeColor.status === 1){
        setLoadingRemoveColor(false);
        setOpenModalConfirmDeleteColor(false);
        setCheckboxMassiveDelete([]);
        setAllDeleteChecked(false);
      }
    }).catch(err =>{
      // if get error of mutation set error of insert Vat number to true
      setLoadingRemoveColor(false);
    });
  };

  // Mutation Add New Color
  const onSaveUpdateColor = () => {
    setLoadingUpdateColor(true);
    updateColor({
      variables: { id: colorToUpdate.id, file: fileColorImage, hex: colorToUpdate.hex, image: colorToUpdate.image },
      context: {
        hasUpload: true,
      },
      refetchQueries: ["colors"],
    }).then(response => {
      // If response of remove registration permission is 0 set error
      if(response.data.updateColor.status === 0){
        setErrorUpdateColor(true);
        setLoadingUpdateColor(false);
      }else if(response.data.updateColor.status === 1){
        setErrorUpdateColor(false);
        setColorToUpdate({});
        setLoadingUpdateColor(false);
        setOpenModalUpdateColor(false);
        setIdColorImageUpdated(colorToUpdate.id);
        setFileColorImage(undefined);
      }
    }).catch(err =>{
      // if get error of mutation set error of insert Vat number to true
      setErrorUpdateColor(true);
      setLoadingUpdateColor(false);
    });
  };

  // Mutation Delete List of color
  const deleteAllSelected = () => {
    setLoadingRemoveAllColor(true);
    checkboxMassiveDelete.map((item, index) => {
      removeColor({
        variables: { id: item },
        refetchQueries: ["colors"],
      }).then(response => {
        if(response.data.removeColor.status === 0){
          setLoadingRemoveAllColor(false);
        }else if(response.data.removeColor.status === 1){
          if(index === checkboxMassiveDelete.length -1){
            setCheckboxMassiveDelete([]);
            setLoadingRemoveAllColor(false);
            setAllDeleteChecked(false);
            setOpenModalConfirmDeleteAllColor(false);
          }
        }
      }).catch(err =>{
        setLoadingRemoveAllColor(false);
      });
    });
  };

  // onChange search
  const onChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  // Open Modal Add new color
  const onOpenModalEditColorFolder = () => {
    setOpenModalEditColorFolder(true);
    setErrorEditColorFolder(false);
  };
  // Close Modal Add new color
  const onCloseModalEditColorFolder = () => {
    setOpenModalEditColorFolder(false);
    setEditColorFolderFormValues({name: ""});
  };

  // Open Modal delete color folder
  const onOpenModalConfirmDeleteColorFolder = () => {
    setOpenModalConfirmDeleteColorFolder(true);
    setErrorConfirmDeleteColorFolder(false);
    setSuccessDeleteFolder(false);
  };
  // Close Modal delete color folder
  const onCloseModalConfirmDeleteColorFolder = () => {
    setOpenModalConfirmDeleteColorFolder(false);
  };

  // Open Dropzone Add Image
  const onOpenDropzone = () => {
    setOpenDropzone(true);
    setErrorAddNewColor(false);
    setErrorUpdateColor(false);
  };
  // Close Dropzone Add Image
  const onCloseDropzone = () => {
    setOpenDropzone(false);
  };
  // Open Modal Confirm delete color
  const onOpenConfirmDeleteColor = (item) => {
    setColorToDelete({...colorToDelete, id: item.id, hex: item.hex});
    setOpenModalConfirmDeleteColor(true);
  };
  // Close Modal Confirm delete color
  const onCloseConfirmDeleteColor = () => {
    setOpenModalConfirmDeleteColor(false);
    setColorToDelete({});
    setFileColorImage(undefined);
  };
  // Open Modal Confirm delete all selected color
  const onOpenModalConfirmDeleteAllColor = (item) => {
    setOpenModalConfirmDeleteAllColor(true);
  };
  // Close Modal Confirm delete all selected color
  const onCloseModalConfirmDeleteAllColor = () => {
    setOpenModalConfirmDeleteAllColor(false);
    setCheckboxMassiveDelete([]);
    setAllDeleteChecked(false);
  };
  // Open Modal Update color
  const onOpenUpdateColor = (item) => {
    setColorToUpdate({...colorToUpdate, id: item.id, name: item.name, hex: item.hex, image: item.image, folder: item.folder, season: item.season});
    setOpenModalUpdateColor(true);
  };
  // Close Modal Update color
  const onCloseUpdateColor = () => {
    setOpenModalUpdateColor(false);
    setColorToUpdate({});
    setFileColorImage(undefined);
  };

  // onChange Add new Color Folder form values
  const onChangeAddColorFolderFormValues = event => {
    const value = event.target.value;
    const name = event.target.name;
    setErrorAddNewColorFolder(false);
    setAddNewColorFolderFormValues({...addNewColorFolderFormValues, [name]: value});
  };

  // onChange Edit Color Folder form values
  const onChangeEditColorFolderFormValues = event => {
    const value = event.target.value;
    const name = event.target.name;
    setErrorEditColorFolder(false);
    setEditColorFolderFormValues({...editColorFolderFormValues, [name]: value});
  };

  // onChange Add new Color form values
  const onChangeAddColorFormValues = event => {
    const value = event.target.value;
    const name = event.target.name;
    setErrorAddNewColor(false);
    setAddNewColorFormValues({...addNewColorFormValues, [name]: value});
  };

  // onChange Update Color form values
  const onChangeUpdateColorFormValues = event => {
    const value = event.target.value;
    const name = event.target.name;
    setErrorUpdateColor(false);
    setColorToUpdate({...colorToUpdate, [name]: value});
  };

  // onChange Folder
  const onChangeFolder = event => {
    if(event) {
      setColorFolder({id: event.value, name:  event.label});
    }else{
      setColorFolder({id: "", name: ""});
    }
    setAllDeleteChecked(false);
    setCheckboxMassiveDelete([]);
    setAddNewColorFormValues({});
    setErrorAddNewColor(false);
    setErrorUpdateColor(false);
    setFileColorImage(undefined);
  };


  // onChange Folder
  const onChangeFolderSeason = event => {
    const value = event.target.value;
    if(value !== null) {
      setColorFolderSeason(value);
    }else{
      setColorFolderSeason(null);
    }
    setColorFolder({id: "", name: ""});
    const track = document.querySelector('.css-1uccc91-singleValue');
    if(track) track.innerHTML = "";
    setAllDeleteChecked(false);
    setCheckboxMassiveDelete([]);
    setAddNewColorFormValues({});
    setErrorAddNewColor(false);
    setErrorUpdateColor(false);
    setFileColorImage(undefined);
  };

  // onSave Dropzone when add new color
  const onSaveImage = files => {
    setFileColorImage(files);
    setOpenDropzone(false);
  };
  // onSave Dropzone when edit color
  const onSaveUpdateImage = files => {
    setFileColorImage(files[0]);
    setOpenDropzone(false);
  };

  // onDelete image uploaded in form add new color
  const deleteImageUploadedToAddImage = () => {
    setFileColorImage(undefined);
  };

  // error add new color diplicated hex
  const onRemoveMessageAddNewColor = () => {
    setErrorMessageAddNewColor();
  };

  if (loadingColors || loadingUpdateColor || loadingColorsFolder || loadingAddNewColor) return <LinearProgress />;
  if (errorColors || errorColorsFolder) { console.log(errorColorsFolder); }

  // onChange Massive Delete
  const onChangeMassiveDelete = event => {
    if (event.target.checked){
      if(event.target.name === "all") {
        setCheckboxMassiveDelete(undefined);
        setAllDeleteChecked(true);
        if(search && search !== null){
          dataColors.colors.filter(item => item.colorFolder.id === colorFolder.id && item.hex?.toLowerCase().includes(search)).map((item, index) => (
            checkboxMassiveDelete.push(item.id)
          ));
        }else{
          dataColors.colors.filter(item => item.colorFolder.id === colorFolder.id ).map((item) => {
            checkboxMassiveDelete.push(item.id);
          });
        }
        setCheckboxMassiveDelete([...checkboxMassiveDelete]);
      }else{
        setCheckboxMassiveDelete([ ...checkboxMassiveDelete, event.target.name ]);
        if(checkboxMassiveDelete.length ===  dataColors.colors.length - 1) {
          setAllDeleteChecked(true);
        }
      }
    } else {
      if(event.target.name === "all") {
        setCheckboxMassiveDelete([]);
        setAllDeleteChecked(false);
      }else{
        let index = checkboxMassiveDelete.indexOf(event.target.name);
        if(index >= 0) {
          checkboxMassiveDelete.splice(index, 1);
          setCheckboxMassiveDelete([...checkboxMassiveDelete]);
        }
        if(checkboxMassiveDelete.length === 0) {
          setAllDeleteChecked(false);
        }
      }
    }
  };

  return (
    <Layout
      dataColors={dataColors}
      addNewColorFormValues={addNewColorFormValues}
      onChangeAddColorFormValues={onChangeAddColorFormValues}
      onSaveNewColor={onSaveNewColor}
      openDropzone={openDropzone}
      onOpenDropzone={onOpenDropzone}
      onCloseDropzone={onCloseDropzone}
      onSaveImage={onSaveImage}
      fileColorImage={fileColorImage}
      deleteImageUploadedToAddImage={deleteImageUploadedToAddImage}
      onRemoveColor={onRemoveColor}
      openModalConfirmDeleteColor={openModalConfirmDeleteColor}
      onCloseConfirmDeleteColor={onCloseConfirmDeleteColor}
      onOpenConfirmDeleteColor={onOpenConfirmDeleteColor}
      colorToDelete={colorToDelete}
      openModalUpdateColor={openModalUpdateColor}
      onOpenUpdateColor={onOpenUpdateColor}
      onCloseUpdateColor={onCloseUpdateColor}
      colorToUpdate={colorToUpdate}
      onChangeUpdateColorFormValues={onChangeUpdateColorFormValues}
      loadingAddNewColor={loadingAddNewColor}
      onSaveUpdateImage={onSaveUpdateImage}
      onSaveUpdateColor={onSaveUpdateColor}
      errorAddNewColor={errorAddNewColor}
      errorUpdateColor={errorUpdateColor}
      loadingUpdateColor={loadingUpdateColor}
      loadingRemoveColor={loadingRemoveColor}
      search={search}
      onChangeSearch={onChangeSearch}
      idColorImageUpdated={idColorImageUpdated}
      onChangeMassiveDelete={onChangeMassiveDelete}
      checkboxMassiveDelete={checkboxMassiveDelete}
      deleteAllSelected={deleteAllSelected}
      allDeleteChecked={allDeleteChecked}
      openModalConfirmDeleteAllColor={openModalConfirmDeleteAllColor}
      onCloseModalConfirmDeleteAllColor={onCloseModalConfirmDeleteAllColor}
      onOpenModalConfirmDeleteAllColor={onOpenModalConfirmDeleteAllColor}
      loadingRemoveAllColor={loadingRemoveAllColor}
      addNewColorFolderFormValues={addNewColorFolderFormValues}
      onChangeAddColorFolderFormValues={onChangeAddColorFolderFormValues}
      errorAddNewColorFolder={errorAddNewColorFolder}
      onAddColorFolder={onAddColorFolder}
      dataColorsFolder={dataColorsFolder}
      onChangeFolder={onChangeFolder}
      colorFolder={colorFolder}
      editColorFolderById={editColorFolderById}
      errorEditColorFolder={errorEditColorFolder}
      onOpenModalEditColorFolder={onOpenModalEditColorFolder}
      onCloseModalEditColorFolder={onCloseModalEditColorFolder}
      openModalEditColorFolder={openModalEditColorFolder}
      editColorFolderFormValues={editColorFolderFormValues}
      onChangeEditColorFolderFormValues={onChangeEditColorFolderFormValues}
      openModalConfirmDeleteColorFolder={openModalConfirmDeleteColorFolder}
      onOpenModalConfirmDeleteColorFolder={onOpenModalConfirmDeleteColorFolder}
      onCloseModalConfirmDeleteColorFolder={onCloseModalConfirmDeleteColorFolder}
      deleteColorFolderById={deleteColorFolderById}
      successDeleteFolder={successDeleteFolder}
      errorMessageAddNewColor={errorMessageAddNewColor}
      onRemoveMessageAddNewColor={onRemoveMessageAddNewColor}
      onChangeFolderSeason={onChangeFolderSeason}
      colorFolderSeason={colorFolderSeason}
      dataSeasons={dataSeasons}
      loadingSeasons={loadingSeasons}
      errorSeasons={errorSeasons}
    />
  );
};

export default withRouter(Colors);
