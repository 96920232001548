import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    title: {
        margin: theme.spacing(0, 0, 2, 0),
    },
    heading: {
        fontSize: theme.typography.pxToRem(18),
        flexBasis: '33.33%',
        flexShrink: 0,
      },
      wrapperMaxContentWidth: {
        width: "max-content",
        [theme.breakpoints.down('sm')]: {
            width: "auto",
          },
      },
      addNewCategoryButton: {
        margin: theme.spacing(0, 2),
      },
      buttonEditCategory: {
          float: "right",
      },
      rowCategoryItem: {
        margin: theme.spacing(1, 0),
      },
      wrapperNoCategoryText: {
        padding: theme.spacing(3, 1),
      },
      wrapperLoadingRemove: {
        margin: "2rem auto",
        width: "max-content",
      },
}));
