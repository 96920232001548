import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  paperNotFoundContent: {
    padding: "70px 0",
    textAlign: "center",
  },
  paperNotFoundEmoji: {
    fontSize:"3rem",
  },
  paperNotFoundText: {
    fontWeight: "bold",
  },
  breadCrumbsText:{
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
}));
