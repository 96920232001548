import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  productsSkeleton: {
    marginTop:"1.5rem",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    gridGap: "2rem",
    [theme.breakpoints.only("xs")]: {
      gridTemplateColumns: "1fr",
    },
    [theme.breakpoints.only("sm")]: {
      gridTemplateColumns: "1fr 1fr",
    },
    [theme.breakpoints.only("lg")]: {
      gridTemplateColumns: "1fr 1fr 1fr 1fr",
    },
    [theme.breakpoints.only("xl")]: {
      gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    },
  },
  skeleton:{
    borderRadius: "4px",
    [theme.breakpoints.only("xs")]: {
      width :"343",
    },
    [theme.breakpoints.only("sm")]: {
      width :"344",

    },
    [theme.breakpoints.only("lg")]: {
      width :"280.25px",

    },
    [theme.breakpoints.only("md")]: {
      width :"345.8px",

    },
  },
  productsContainer: {
    marginTop: "1rem",
    marginBottom: "2rem",
  },
  productsList: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    gridGap: "2rem",
    [theme.breakpoints.only("xs")]: {
      gridTemplateColumns: "1fr",
    },
    [theme.breakpoints.only("sm")]: {
      gridTemplateColumns: "1fr 1fr",
    },
    [theme.breakpoints.only("lg")]: {
      gridTemplateColumns: "1fr 1fr 1fr",
    },
    [theme.breakpoints.only("xl")]: {
      gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    },
  },
  productCard: {
    textAlign: "center",
    position: "relative",
  },
  productImage: {
    minHeight: "300px",
    maxHeight: "300px",
    borderRadius: "4px 4px 0px 0px",
  },
  productNameContainer:{
    margin: "1rem",
    display: "flex",
    justifyContent: "space-between",
  },
  productInfo: {
    textAlign: "left",
  },
  productName:{
    fontWeight: "bold",
    margin: 0,
  },
  productCode: {
    fontWeight: "400",
    color: "var(--menu-link)",
    margin: 0,
  },
  productPrice: {
    color: "#ffffff",
    padding: "0.25rem",
    marginTop: "-0.25rem",
    marginRight: "0.5rem",
    borderRadius: "4px",
    background: "#3da63d",
  },
  productFooter:{
    margin: "1rem 1rem 1rem 1rem",
    maxHeight: "30px",
    display: "flex",
    justifyContent: "space-between",
  },
  productButton: {
    backgroundColor: "var(--mate-green)",
    color: "(--text-inverted)",
    "&:hover": {
      backgroundColor: "var(--mate-green)",
      opacity: 0.8,
    },
  },
  paperNotFoundContent: {
    padding: "70px 0",
    textAlign: "center",
  },
  paperNotFoundEmoji: {
    fontSize:"3rem",
  },
  paperNotFoundText: {
    fontWeight: "bold",
  },
  breadCrumbsText:{
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
  stockWrapper: {
    position: "absolute",
    top: "1rem",
    right: "1rem",
  },
  stockText: {
    backgroundColor: "var(--mate-red)",
    padding:"0.25rem",
    borderRadius:"4px",
    color:"#ffffff",
  },
}));
