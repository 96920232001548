/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { ME, GET_PRODUCTS_BY_CATEGORY, GENDER_CATEGORY, FINENESS_CATEGORY, SEASON_CATEGORY, TAG_CATEGORY, COMPOSITION_CATEGORY, MY_FAVORITES } from "../../../query";
import { useQuery } from "@apollo/react-hooks";
import { useMutation } from "@apollo/react-hooks";
import { PRODUCTS_SEARCH, ADD_REMOVE_TO_FAVORITES } from "../../../mutation";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import Layout from "./layout";
import Style from "./layout/style";

const Shop = ({ history, match }) => {
  const style = Style();
  const [formValues, setFormValues] = useState({});
  const [genderId, setGenderId] = useState(match.params.genderId === "nullGenderIdSearch" ? undefined : match.params.genderId);
  const [finenessId, setFinenessId] = useState();
  const [seasonId, setSeasonId] = useState(match.params.seasonId === "nullSeasonIdSearch" ? undefined : match.params.seasonId);
  const [tagsId, setTagsId] = useState([]);
  const [compositionsId, setCompositionsId] = useState([]);

  const [productsSearch] = useMutation(PRODUCTS_SEARCH);
  const [productsBySearch, setProductsBySearch] = useState();
  const [searchState, setSearchState] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  // Search Text when user search an item on header
  const [searchOnHeader, setSearchOnHeader] = useState("");

  const [addRemoveToFavorites] = useMutation(ADD_REMOVE_TO_FAVORITES);
  const userFavorites = [];

  const { loading: loadingMe, error: errorMe, data: dataMe } = useQuery(ME);
  // import only for refetch query myFavorites
  const { loading: loadingMyFavorites, error: errorMyFavorites, data: dataMyFavorites } = useQuery(MY_FAVORITES);
  const { loading: loadingProductsByCategory, error: errorProductsByCategory, data: dataProductsByCategory } = useQuery(GET_PRODUCTS_BY_CATEGORY, {
    variables: { gender_id: genderId, fineness_id: finenessId, season_id: seasonId, tags_id: tagsId.toString(), compositions_id: compositionsId.toString(), filter: searchText },
  });

  const { loading: loadingGenders, error: errorGenders, data: dataGenders } = useQuery(GENDER_CATEGORY);
  const { loading: loadingFineness, error: errorFineness, data: dataFineness } = useQuery(FINENESS_CATEGORY);
  const { loading: loadingSeason, error: errorSeason, data: dataSeason } = useQuery(SEASON_CATEGORY);
  const { loading: loadingTag, error: errorTag, data: dataTag } = useQuery(TAG_CATEGORY);
  const { loading: loadingComposition, error: errorComposition, data: dataComposition } = useQuery(COMPOSITION_CATEGORY);

  useEffect(() => {
    if (match.params.headerSearch && match.params.headerSearch !== "nullHeaderSearch") {
      setSearchState(true);
      setSearchLoading(true);
      setSearchOnHeader(match.params.headerSearch);
      productsSearch({
        variables: { filter: String(match.params.headerSearch) },
      }).then(response => {
        setProductsBySearch(response.data.productsSearch);
        setSearchLoading(false);
      }).catch(err => {
        console.log("error");
      });
    } else {
      setProductsBySearch();
    }
  }, [match.params.headerSearch, productsSearch]);

  const onResetFilter = () => {
    setFormValues({});
    setGenderId();
    setFinenessId();
    setSeasonId();
    setTagsId([]);
    setCompositionsId([]);
  };

  const onGoBack = () => {
    setSearchState(false);
    setSearchText("");
    setFormValues({});
    setGenderId();
    setFinenessId();
    setSeasonId();
    setTagsId([]);
    setCompositionsId([]);
  };

  const onChangeCheckboxGenders = id => {
    if (id === genderId) {
      setGenderId();
    } else {
      setGenderId(id);
    }
    setSearchState(false);
    // setSearchText("");
  };

  const onChangeCheckboxFineness = id => {
    if (id === finenessId) {
      setFinenessId();
    } else {
      setFinenessId(id);
    }
    setSearchState(false);
    // setSearchText("");
  };

  const onChangeCheckboxSeason = id => {
    if (id === seasonId) {
      setSeasonId();
    } else {
      setSeasonId(id);
    }
    setSearchState(false);
    // setSearchText("");
  };

  const onChangeCheckboxTag = (id, name) => {
    const index = tagsId.indexOf(id);
    if (index > -1) {
      tagsId.splice(index, 1);
      setTagsId(tagsId);
      setFormValues({ ...formValues, [name]: false });
    } else {
      setTagsId([...tagsId, id]);
      setFormValues({ ...formValues, [name]: true });
    }
    setSearchState(false);
    // setSearchText("");
  };

  const onChangeCheckboxComposition = (id, name) => {
    const index = compositionsId.indexOf(id);
    if (index > -1) {
      compositionsId.splice(index, 1);
      setCompositionsId(compositionsId);
      setFormValues({ ...formValues, [name]: false });
    } else {
      setCompositionsId([...compositionsId, id]);
      setFormValues({ ...formValues, [name]: true });
    }
    setSearchState(false);
    // setSearchText("");
  };

  const onChangeSearch = event => {
    setSearchText(event.target.value);
    if (event.target.value === "") {
      setSearchState(false);
    }
  };

  const onClickSearch = (e) => {
    e.preventDefault();

    // onResetFilter();
    if (searchText) {
      setSearchState(true);
      setSearchLoading(true);
      productsSearch({
        variables: {
          filter: searchText,
          gender_id: genderId,
          fineness_id: finenessId,
          season_id: seasonId,
          tags_id: tagsId.toString(),
          compositions_id: compositionsId.toString()
        },
      }).then(response => {
        setProductsBySearch(response.data.productsSearch);
        setSearchLoading(false);
      }).catch(err => {
        console.log("error");
      });
    }
  };

  const onFavorite = productId => {
    addRemoveToFavorites({
      variables: { product_id: productId },
      refetchQueries: ["me", "myFavorites"],
    });
  };

  const onViewProduct = productId => {
    history.push("/product/" + productId);
  };

  const onScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const resetHeaderSearch = () => {
    setSearchOnHeader("");
    setSearchState(false);
    setSearchText("");
    history.push("/shop");
  };

  // Verify params in url
  if (loadingMe || loadingGenders || loadingFineness || loadingSeason || loadingTag || loadingComposition || loadingMyFavorites) {
    return (
      <>
        <Grid container spacing={3} style={{ marginTop: "1rem" }}>
          <Grid item xs={12} sm={12} md={8}>
            <Skeleton className={style.skeletonTextFieldBig} variant="rect" height={48} />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Skeleton className={style.skeletonTextFieldSmall} variant="rect" height={48} />
          </Grid>
        </Grid>
        <div className={style.productsSkeleton} style={{ marginBottom: "-3rem" }} >
          <Skeleton className={style.skeleton} variant="rect" height={419.5} />
          <Skeleton className={style.skeleton} variant="rect" height={419.5} />
          <Skeleton className={style.skeleton} variant="rect" height={419.5} />
          <Skeleton className={style.skeleton} variant="rect" height={419.5} />
        </div>
      </>
    );
  }

  if (errorMe || errorProductsByCategory || errorGenders || errorFineness || errorSeason || errorTag || errorComposition || errorMyFavorites) {
    console.log("error");
  }

  // prepare the array with user favorites
  dataMe.me.favorites.map((item) => (
    userFavorites.push(item.product.id)
  ));


  return (
    <Layout
      dataMe={dataMe}
      onChangeCheckboxGenders={onChangeCheckboxGenders}
      onChangeCheckboxFineness={onChangeCheckboxFineness}
      onChangeCheckboxSeason={onChangeCheckboxSeason}
      onChangeCheckboxTag={onChangeCheckboxTag}
      onChangeCheckboxComposition={onChangeCheckboxComposition}
      onChangeSearch={onChangeSearch}
      onClickSearch={onClickSearch}
      searchText={searchText}
      genderId={genderId}
      finenessId={finenessId}
      seasonId={seasonId}
      compositionsId={compositionsId}
      onResetFilter={onResetFilter}
      formValues={formValues}
      dataProductsByCategory={dataProductsByCategory}
      loadingProductsByCategory={loadingProductsByCategory}
      dataGenders={dataGenders}
      dataFineness={dataFineness}
      dataSeason={dataSeason}
      dataTag={dataTag}
      dataComposition={dataComposition}
      productsBySearch={productsBySearch}
      searchState={searchState}
      searchLoading={searchLoading}
      onGoBack={onGoBack}
      userFavorites={userFavorites}
      onFavorite={onFavorite}
      onViewProduct={onViewProduct}
      onScrollToTop={onScrollToTop}
      searchOnHeader={searchOnHeader}
      resetHeaderSearch={resetHeaderSearch}
    />
  );
};

export default withRouter(Shop);
