import React from "react";
import Carousel from "react-material-ui-carousel";
import Style from "./style.js";

const Layout = ({ dataAllProductImage }) => {
  const style = Style();
  return (
    <div className={style.wrapperImageFullScreenProduct}>
      {
        dataAllProductImage ? (
          <Carousel
            indicators={true}
            autoPlay={false}
            animation="slide"
            navButtonsAlwaysVisible={true}
          >
            {
              dataAllProductImage.getAllProductImage.map((item, index) => (
                <img className={style.imageFullScreenProduct} key={index} src={item.image} alt={"product-image-" + index}  />
              ))
            }
          </Carousel>
        ) : null
      }

    </div>
  );
};

export default Layout;
