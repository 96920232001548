import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { PRICE_LIST_PRODUCTS, PRICE_LIST_BY_ID } from "../../../query";
import { useMutation } from "@apollo/react-hooks";
import { EDIT_PRICE_LIST_PRODUCT_PRICE } from "../../../mutation";
import Layout from "./layout";
import LinearProgress from "@material-ui/core/LinearProgress";

const PriceListProducts = ({ history, match }) => {
  // Form Values
  const [productPricesFormValues, setProductPricesFormValues] = useState({});

  // Loading
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState();

  // QUERY
  const { loading: loadingPriceListProducts, error: errorPriceListProducts, data: dataPriceListProducts } = useQuery(PRICE_LIST_PRODUCTS,{
    variables: {list_id: match.params.listId},
  });
  // QUERY
  const { loading: loadingPriceListById, error: errorPriceListById, data: dataPriceListById } = useQuery(PRICE_LIST_BY_ID,{
    variables: {id: match.params.listId},
  });
  const [editPriceListProductPrice] = useMutation(EDIT_PRICE_LIST_PRODUCT_PRICE);

  const onChangeForm = (event) => {
    if(event.target.value <= 999999){
      setProductPricesFormValues({...productPricesFormValues, [event.target.name]: [event.target.value]});
    }
  };

  const onChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  const onSaveListPrice = () => {
    setLoading(true);
    editPriceListProductPrice({
      variables: { list_id: match.params.listId, product_id_price: JSON.stringify(productPricesFormValues) },
      refetchQueries: ["priceListProducts"],
    }).then(response => {
      // If response of remove registration permission is 0 set error
      if(response.data.editPriceListProductPrice.status === 1){
        setProductPricesFormValues({});
        setLoading(false);
      }
    });
  };

  if (loadingPriceListProducts || loadingPriceListById || loading) return <LinearProgress />;
  if (errorPriceListProducts || errorPriceListById) { history.push("/"); }

  return (
    <Layout
      dataPriceListProducts={dataPriceListProducts}
      onChangeForm={onChangeForm}
      search={search}
      onChangeSearch={onChangeSearch}
      dataPriceListById={dataPriceListById}
      productPricesFormValues={productPricesFormValues}
      onSaveListPrice={onSaveListPrice}
      loading={loading}
    />
  );
};

export default withRouter(PriceListProducts);
