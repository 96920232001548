import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { useMutation } from "@apollo/react-hooks";
import { useLazyQuery } from "@apollo/react-hooks";
import { ORDER_DETAIL, GET_ORDER_USER_BY_ID, ME, PRODUCT, GET_ALL_PRODUCTS_FOR_EDIT_ORDER_DETAIL, GET_PRODUCT_FOR_EDIT_ORDER_DETAIL } from "../../../query";
import { DELETE_ORDER_DETAIL, EDIT_ORDER_DETAIL, ADD_ORDER_DETAIL, EDIT_ORDER_NOTE } from "../../../mutation";
import Layout from "./layout";
import LinearProgress from "@material-ui/core/LinearProgress";

const OrderDetail = ({ history, match }) => {

  const [openModalEditRow, setOpenModalEditRow] = useState(false);
  const [openModalAddRow, setOpenModalAddRow] = useState(false);
  const [openModalDeleteRow, setOpenModalDeleteRow] = useState(false);
  const [editRowFormValues, setEditRowFormValues] = useState({});
  const [addRowFormValues, setAddRowFormValues] = useState({});
  const [rowToDelete, setRowToDelete] = useState({});
  const [successEditDetail, setSuccessEditDetail] = useState(false);
  const [errorStock, setErrorStock] = useState();
  const [openEditNote, setOpenEditNote] = useState(false);
  const [discountColors, setDiscountColors] = useState({});
  const [discountItem, setDiscountItem] = useState(false);

  // QUERY
  const { loading: loadingOrderDetail, error: errorOrderDetail, data: dataOrderDetail } = useQuery(ORDER_DETAIL, {
    variables: { order_id: match.params.orderId },
  });
  const { loading: loadingOrder, error: errorOrder, data: dataOrder } = useQuery(GET_ORDER_USER_BY_ID, {
    variables: { id: match.params.orderId },
  });
  // QUERY
  const { loading: loadingMe, error: errorMe, data: dataMe } = useQuery(ME);
  const [getProduct, { loading: loadingProduct, data: dataProduct, error: errorProduct }] = useLazyQuery(PRODUCT);

  const { loading: loadingAllProducts, error: errorAllProducts, data: dataAllProducts } = useQuery(GET_ALL_PRODUCTS_FOR_EDIT_ORDER_DETAIL, {
    variables: { order_id: match.params.orderId },
  });
  const [getProductForEditOrderDetail, { loading: loadingProductForEditOrderDetail, data: dataProductForEditOrderDetail, error: errorProductForEditOrderDetail }] = useLazyQuery(GET_PRODUCT_FOR_EDIT_ORDER_DETAIL);

  // MUTATION
  const [deleteOrderDetail] = useMutation(DELETE_ORDER_DETAIL);
  const [editOrderDetail] = useMutation(EDIT_ORDER_DETAIL);
  const [addOrderDetail] = useMutation(ADD_ORDER_DETAIL);
  const [editOrderNote] = useMutation(EDIT_ORDER_NOTE);

  useEffect(() => {
    if (dataProductForEditOrderDetail && dataProductForEditOrderDetail.getProductForEditOrderDetail?.discountColors) {
      const parsedColor = JSON.parse(dataProductForEditOrderDetail.getProductForEditOrderDetail.discountColors);
      if (Object.keys(parsedColor).length > 0) {
        setDiscountColors(parsedColor);
        console.log(parsedColor);
      }
    }
  }, [dataProductForEditOrderDetail]);

  const downloadPDF = (id, vat_number) => {
    window.location.href = process.env.REACT_APP_BACKEND_PDF + id + "/" + vat_number;
  };

  const onDeleteRow = () => {
    deleteOrderDetail({
      variables: { id: rowToDelete.id },
      refetchQueries: ["myOrders", "orders"],
    });
    setOpenModalDeleteRow(false);
    setSuccessEditDetail(true);
  };

  // Edit Row
  const onEditOrderDetail = () => {
    editOrderDetail({
      variables: { id: editRowFormValues.id, color_id: editRowFormValues.color, size_id: editRowFormValues.size, quantity: Number(editRowFormValues.quantity), order_id: editRowFormValues.order_id, product_id: editRowFormValues.product_id },
      refetchQueries: ["myOrders", "orders"],
    }).then(response => {
      // If response of remove registration permission is 0 set error
      if (response.data.editOrderDetail.status === 0) {
        console.log("err");
        setErrorStock(response.data.editOrderDetail.error)
      } else if (response.data.editOrderDetail.status === 1) {
        setErrorStock()
        setOpenModalEditRow(false);
        setEditRowFormValues({});
        setSuccessEditDetail(true);
      }
    }).catch(err => {
    });
  };

  // Add Row
  const onAddRow = () => {
    addOrderDetail({
      variables: {
        original_price: dataProductForEditOrderDetail.getProductForEditOrderDetail.price,
        product_price: Number(
          (discountItem || dataProductForEditOrderDetail.getProductForEditOrderDetail.percentage_discount) ?
            addRowFormValues.sub_total / addRowFormValues.quantity
            : (dataProductForEditOrderDetail.getProductForEditOrderDetail.sale ? dataProductForEditOrderDetail.getProductForEditOrderDetail.sale : dataProductForEditOrderDetail.getProductForEditOrderDetail.price)
        ),
        total: dataOrder.getOrderUserById.vat_number_nationality === 0 ? addRowFormValues.total : addRowFormValues.sub_total,
        sub_total: addRowFormValues.sub_total,
        product_id: addRowFormValues.product,
        color_id: addRowFormValues.color,
        size_id: addRowFormValues.size,
        quantity: Number(addRowFormValues.quantity),
        order_id: match.params.orderId
      },
      refetchQueries: ["myOrders"],
    }).then(response => {
      // If response of remove registration permission is 0 set error
      if (response.data.addOrderDetail.status === 0) {
        console.log("err:", response.data.addOrderDetail);
        setErrorStock(response.data.addOrderDetail.error)
      } else if (response.data.addOrderDetail.status === 1) {
        setErrorStock()
        setOpenModalAddRow(false);
        setAddRowFormValues({});
        setSuccessEditDetail(true);
      }
    }).catch(err => {
    });
  };

  // Add Row
  const onEditNote = (newNote) => {
    editOrderNote({
      variables: { order_id: match.params.orderId, note: newNote },
      refetchQueries: ["getOrderUserById"],
    }).then(response => {
      // If response of remove registration permission is 0 set error
      if (response.data.editOrderNote.status === 1) {
        setOpenEditNote(false)
      }
    }).catch(err => {
    });
  };


  // Open Modal Edit Row
  const onOpenModalEditRow = (item) => {
    setOpenModalEditRow(true);
    setEditRowFormValues(item);
    getProductForEditOrderDetail({ variables: { order_id: match.params.orderId, product_id: item.product_id } });
    setEditRowFormValues({ ...editRowFormValues, "id": item.id, "product_id": item.product_id, "order_id": item.order_id, "color": item.color_id, "size": item.size_id, "quantity": item.quantity, "product_code": item.product_code, "product_name": item.product_name });
  };

  // Close Modal Edit Row
  const onCloseModalEditRow = () => {
    setErrorStock()
    setOpenModalEditRow(false);
    setEditRowFormValues({});
  };

  // Open Modal Add Row
  const onOpenModalAddRow = () => {
    setOpenModalAddRow(true);
    setDiscountItem(false);
  };
  // Close Modal Add Row
  const onCloseModalAddRow = () => {
    setOpenModalAddRow(false);
    setAddRowFormValues({});
  };

  // Open Modal Delete Row
  const onOpenModalDeleteRow = (item) => {
    setOpenModalDeleteRow(true);
    setRowToDelete(item);
  };
  // Close Modal Delete Row
  const onCloseModalDeleteRow = () => {
    setOpenModalDeleteRow(false);
    setRowToDelete({});
  };

  // on Change Edit row
  const onChangeEditRow = event => {
    const value = event.target.value;
    const name = event.target.name;
    setEditRowFormValues({ ...editRowFormValues, [name]: value });
  };

  // on Change Add row
  const onChangeAddRow = event => {
    const value = event.target.value;
    const name = event.target.name;
    var total = 0;
    var subTotal = 0;
    if (name === "product") {
      getProductForEditOrderDetail({ variables: { order_id: match.params.orderId, product_id: value } });
      // setAddRowFormValues({ product: value });
    }
    if (name === "quantity" || (addRowFormValues.quantity && addRowFormValues.quantity > 0)) {
      if (discountColors && Object.keys(discountColors).length > 0 && discountColors.hasOwnProperty(name === "color" ? value : addRowFormValues?.color)) {
        var subTotal = Number((dataProductForEditOrderDetail?.getProductForEditOrderDetail?.price - (dataProductForEditOrderDetail?.getProductForEditOrderDetail?.price * (discountColors[name === "color" ? value : addRowFormValues.color] / 100))) * (name === "quantity" ? value : addRowFormValues.quantity));
        setDiscountItem(true);
      } else if(dataProductForEditOrderDetail?.getProductForEditOrderDetail?.sale) {
        var subTotal = Number(dataProductForEditOrderDetail?.getProductForEditOrderDetail?.sale);
        setDiscountItem(true);
      } else if(dataProductForEditOrderDetail?.getProductForEditOrderDetail?.percentage_discount) {
        var subTotal = Number((dataProductForEditOrderDetail?.getProductForEditOrderDetail?.price - (dataProductForEditOrderDetail?.getProductForEditOrderDetail?.price * (dataProductForEditOrderDetail?.getProductForEditOrderDetail?.percentage_discount / 100))) * (name === "quantity" ? value : addRowFormValues.quantity));
        setDiscountItem(true);
      } else {
        subTotal = Number((
          dataProductForEditOrderDetail?.getProductForEditOrderDetail?.price * (name === "quantity" ? value : addRowFormValues.quantity)
        ).toFixed(2));
        setDiscountItem(false);
      }
      total = (Number((subTotal * 0.22).toFixed(2)) + subTotal).toFixed(2);
    }
    setAddRowFormValues({
      ...addRowFormValues,
      [name]: value,
      sub_total: subTotal,
      total: total,
    });

    // if(discountColors && Object.keys(discountColors).length > 0 && discountColors.hasOwnProperty(addRowFormValues?.color) ) {
    //   console.log(discountColors[addRowFormValues.color]);
    //   var subTotal = Number(dataProductForEditOrderDetail?.getProductForEditOrderDetail?.price - (dataProductForEditOrderDetail?.getProductForEditOrderDetail?.price * (discountColors[addRowFormValues.color] / 100)));
    //   setAddRowFormValues({
    //     ...addRowFormValues,
    //     [name]: Number(value),
    //     sub_total: subTotal,
    //     total: (Number((subTotal * 0.22).toFixed(2)) + subTotal).toFixed(2),
    //   });
    // }
  };

  const onCloseSuccessEditDetail = () => {
    setSuccessEditDetail(false);
  };

  if (loadingProduct || loadingOrderDetail || loadingOrder || loadingMe || loadingAllProducts) return <LinearProgress />;
  if (errorOrderDetail || errorOrder || errorMe || errorProduct || errorAllProducts || errorProductForEditOrderDetail) { console.log("error"); }
  console.log(dataProduct)


  return (
    <Layout
      dataOrderDetail={dataOrderDetail}
      dataOrder={dataOrder}
      downloadPDF={downloadPDF}
      dataMe={dataMe}
      onOpenModalEditRow={onOpenModalEditRow}
      onCloseModalEditRow={onCloseModalEditRow}
      openModalEditRow={openModalEditRow}
      editRowFormValues={editRowFormValues}
      onChangeEditRow={onChangeEditRow}
      onOpenModalDeleteRow={onOpenModalDeleteRow}
      onCloseModalDeleteRow={onCloseModalDeleteRow}
      openModalDeleteRow={openModalDeleteRow}
      rowToDelete={rowToDelete}
      onOpenModalAddRow={onOpenModalAddRow}
      onCloseModalAddRow={onCloseModalAddRow}
      openModalAddRow={openModalAddRow}
      addRowFormValues={addRowFormValues}
      onChangeAddRow={onChangeAddRow}
      onDeleteRow={onDeleteRow}
      dataProduct={dataProduct}
      onEditOrderDetail={onEditOrderDetail}
      successEditDetail={successEditDetail}
      onCloseSuccessEditDetail={onCloseSuccessEditDetail}
      dataAllProducts={dataAllProducts}
      dataProductForEditOrderDetail={dataProductForEditOrderDetail}
      loadingProductForEditOrderDetail={loadingProductForEditOrderDetail}
      onAddRow={onAddRow}
      errorStock={errorStock}
      onEditNote={onEditNote}
      openEditNote={openEditNote}
      setOpenEditNote={setOpenEditNote}
      discountItem={discountItem}
    />
  );
};

export default withRouter(OrderDetail);
