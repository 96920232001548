/* eslint-disable array-callback-return */

import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Layout from "./layout";
import { useQuery } from "@apollo/react-hooks";
import { GROUP_SIZE } from "../../../query";
import { useMutation } from "@apollo/react-hooks";
import { ADD_GROUP_SIZE, REMOVE_GROUP_SIZE, ADD_SIZE, REMOVE_SIZE } from "../../../mutation";
import LinearProgress from "@material-ui/core/LinearProgress";

const Sizes = ({ history }) => {
  const { loading: loadingGroupSize, error: errorGroupSize, data: dataGroupSize } = useQuery(GROUP_SIZE);
  const [sizeGroupFormValues, setSizeGroupFormValues] = useState({});
  const [sizeFormValues, setSizeFormValues] = useState({});
  const [sizeGroupError, setSizeGroupError] = useState(false);
  const [sizeError, setSizeError] = useState(false);
  const [sizeGroupToEdit, setSizeGroupToEdit] = useState();

  //Loading
  const [loadingRemoveGroupSize, setLoadingRemoveGroupSize] = useState(false);
  const [loadingRemoveSizes, setLoadingRemoveSizes] = useState(false);
  const [loadingAddGroupSize, setLoadingAddGroupSize] = useState(false);

  // Form Values
  const [sizeGroupToDelete, setSizeGroupToDelete] = useState({});
  const [sizesToDelete, setSizesToDelete] = useState([]);

  // Modal
  const [openModalConfirmDeleteGroupSize, setOpenModalConfirmDeleteGroupSize] = useState(false);
  const [openModalEditSizeGroup, setOpenModalEditSizeGroup] = React.useState(false);

  const [addGroupSize] = useMutation(ADD_GROUP_SIZE);
  const [removeGroupSize] = useMutation(REMOVE_GROUP_SIZE);
  const [addSize] = useMutation(ADD_SIZE);
  const [removeSize] = useMutation(REMOVE_SIZE);

  // MUTATION add SizeGroup
  const onAddSizeGroup = () => {
    setLoadingAddGroupSize(true);
    addGroupSize({
      variables: { name: sizeGroupFormValues["name"]},
      refetchQueries: ["groupSizes"],
    }).then(response => {
      // If response of remove registration permission is 0 set error
      if(response.data.addGroupSize.status === 0){
        setSizeGroupError(true);
        setLoadingAddGroupSize(false);
      }else if(response.data.addGroupSize.status === 1){
        setSizeGroupError(false);
        setLoadingAddGroupSize(false);
        setSizeGroupFormValues({name: ""});
      }
    }).catch(err =>{
      // if get error of mutation set error of insert Vat number to true
      setSizeGroupError(true);
      setLoadingAddGroupSize(false);
    });
  };

  // MUTATION Add Size
  const onAddSize = () => {
    // setLoading(true);
    let arrayId = sizeFormValues["name"].split(",");
    arrayId.map((item, index) => {
      addSize({
        variables: { name: item, group_size_id:  sizeFormValues["group_size_id"]},
        refetchQueries: ["groupSizes"],
      }).then(response => {
        // If response of remove registration permission is 0 set error
        if(response.data.addSize.status === 0){
          setSizeError(true);
        }else if(response.data.addSize.status === 1){
          setSizeError(false);
        }
      }).catch(err =>{
        // if get error of mutation set error of insert Vat number to true
        setSizeError(true);
      });
    });
    //setLoading(false);
    setOpenModalEditSizeGroup(false);
    setSizeGroupToEdit();
    setSizeFormValues({});
  };

  // MUTATION Delete SizeGroup
  const onRemoveGroupSizes = (id) => {
    setLoadingRemoveGroupSize(true);
    removeGroupSize({
      variables: { id: id},
      refetchQueries: ["groupSizes"],
    }).then(response => {
      if(response.data.removeGroupSize.status === 0){
        setLoadingRemoveGroupSize(false);
      }else if(response.data.removeGroupSize.status === 1){
        setSizeGroupToDelete({});
        setSizesToDelete([]);
        setLoadingRemoveGroupSize(false);
        setOpenModalConfirmDeleteGroupSize(false);
      }
    }).catch(err =>{
      setLoadingRemoveGroupSize(false);
    });
  };

  // MUTATION Delete Size
  const onRemoveSizes = () => {
    setLoadingRemoveSizes(true);
    sizesToDelete.forEach(function (id, index) {
      removeSize({
        variables: { id: id},
        refetchQueries: ["groupSizes"],
      }).then(response => {
        if(response.data.removeSize.status === 0){
        }else if(response.data.removeSize.status === 1){
          setSizesToDelete([]);
          if(index === sizesToDelete.length - 1){
            setLoadingRemoveSizes(false);
            setOpenModalEditSizeGroup(false);
          }
        }
      }).catch(err =>{
        setLoadingRemoveSizes(false);
      });
    });
  };

  // onChange Edit User attiva il pulsante salva e setta il formValues
  const onChangeSizeGroup = event => {
    const value = event.target.value;
    const name = event.target.name;
    setSizeGroupFormValues({...sizeGroupFormValues, [name]: value});
    setSizeGroupError(false);
  };

  // onChange Edit User attiva il pulsante salva e setta il formValues
  const onChangeSize = event => {
    const value = event.target.value;
    const name = event.target.name;
    setSizeFormValues({...sizeFormValues, [name]: value});
  };

  // Apertura modale edit size group
  const onOpenModalEditSizeGroup = (item) => {
    setOpenModalEditSizeGroup(true);
    setSizeGroupToEdit(item);
    setSizeFormValues({...sizeFormValues, group_size_id: item.id});
  };
  // Chiusura modale  edit size group
  const onCloseModalEditSizeGroup = () => {
    setOpenModalEditSizeGroup(false);
    setSizeGroupToEdit();
    setSizeFormValues({});
  };

  const onClickSizeBox = (id) => {
    const index = sizesToDelete.indexOf(id);
    if (index > -1) {
      sizesToDelete.splice(index, 1);
      setSizesToDelete([...sizesToDelete]);
    }else{
      setSizesToDelete([...sizesToDelete, id]);
    }
  };

  // Open Modal Confirm delete group size
  const onOpenConfirmDeleteGroupSize = (item) => {
    setSizeGroupToDelete({id: item.id, name: item.name});
    setOpenModalConfirmDeleteGroupSize(true);
  };
  // Close Modal Confirm delete group size
  const onCloseConfirmDeleteGroupSize = () => {
    setSizeGroupToDelete({});
    setOpenModalConfirmDeleteGroupSize(false);
  };

  if (loadingGroupSize) return <LinearProgress />;
  if (errorGroupSize) { history.push("/"); }

  return (
    <Layout
      dataGroupSize={dataGroupSize}
      onChangeSizeGroup={onChangeSizeGroup}
      sizeGroupFormValues={sizeGroupFormValues}
      onAddSizeGroup={onAddSizeGroup}
      sizeGroupError={sizeGroupError}
      onCloseModalEditSizeGroup={onCloseModalEditSizeGroup}
      openModalEditSizeGroup={openModalEditSizeGroup}
      onOpenModalEditSizeGroup={onOpenModalEditSizeGroup}
      sizeGroupToEdit={sizeGroupToEdit}
      onChangeSize={onChangeSize}
      sizeFormValues={sizeFormValues}
      onAddSize={onAddSize}
      sizeError={sizeError}
      onRemoveSizes={onRemoveSizes}
      onRemoveGroupSizes={onRemoveGroupSizes}
      openModalConfirmDeleteGroupSize={openModalConfirmDeleteGroupSize}
      onCloseConfirmDeleteGroupSize={onCloseConfirmDeleteGroupSize}
      onOpenConfirmDeleteGroupSize={onOpenConfirmDeleteGroupSize}
      sizeGroupToDelete={sizeGroupToDelete}
      loadingRemoveGroupSize={loadingRemoveGroupSize}
      onClickSizeBox={onClickSizeBox}
      sizesToDelete={sizesToDelete}
      loadingRemoveSizes={loadingRemoveSizes}
      loadingAddGroupSize={loadingAddGroupSize}
    />
  );
};

export default withRouter(Sizes);
