import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  paperModal: {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 4, 3),
    marginTop: theme.spacing(5),
    margin: "auto",
  },
  modalButtonCloseIcon: {
    padding: 0,
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  modalActionContainer: {
    padding: theme.spacing(2, 0, 0),
  },
  closeIconImage: {
    marginLeft: "-17px",
    marginBottom: "-14px",
    background: "red",
    borderRadius: "50%",
    height: "35px",
    width: "35px",
    color: "white",
    padding: "0.5rem",
    "&:hover": {
      cursor: "pointer",
    },
  },
  searchField: {
    width: "auto",
    marginBottom: "1rem",
    float: "right",
    [theme.breakpoints.down("xs")]: {
      float: "left",
      width: "100%",
    },
  },
  selectFilterType: {
    marginLeft: "1rem",
    minWidth: "160px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "1rem 0 0",
    },
  },
  buttonAddNew: {
    marginTop:"1.2rem",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  selectAllWrapper: {
    paddingLeft: "1.75rem",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0",
      marginBottom: "1rem",
      verticalAlign: "top",
    },
  },
  selectSort: {
    width: "auto",
    float: "right",
    marginTop: "0",
    minWidth: "194px",
    [theme.breakpoints.down('xs')]: {
      width: "100%",
      marginBottom: "1rem",
    }
  },
  pointGreen: {
    width: "20px",
    height: "20px",
    background: "green",
    borderRadius: "50%",
    margin: "auto"
  },
  pointRed: {
    width: "20px",
    height: "20px",
    background: "red",
    borderRadius: "50%",
    margin: "auto"
  }
}));
