/* eslint-disable array-callback-return */
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { useMutation } from "@apollo/react-hooks";
import { SORT_PRODUCTS, GENDER_CATEGORY, SEASON_CATEGORY } from "../../../query";
import { ADD_PRODUCT, REMOVE_PRODUCT } from "../../../mutation";
import LinearProgress from "@material-ui/core/LinearProgress";
import Layout from "./layout";

const Products = ({ history }) => {
  // Form Values
  const [search, setSearch] = useState(localStorage.getItem("productSearch") ? localStorage.getItem("productSearch") : null);
  const [newProductName, setNewProductName] = useState();
  const [newProductCode, setNewProductCode] = useState();
  const [productSeason, setProductSeason] = useState();
  const [productToDelete, setProductToDelete] = useState({});
  const [filterType, setFilterType] = useState("");
  const [checkboxMassiveDelete, setCheckboxMassiveDelete] = useState([]);
  const [allDeleteChecked, setAllDeleteChecked] = useState(false);
  const [sort, setSort] = useState(null);
  const [viewBestseller, setViewBestseller] = useState(0);
  const [viewLuxury, setViewLuxury] = useState(0);
  const [filterGender, setFilterGender] = useState("");

  // Modal
  const [modalAddProduct, setModalAddProduct] = useState(false);
  const [openModalConfirmDeleteProduct, setOpenModalConfirmDeleteProduct] = useState(false);
  const [openModalConfirmDeleteAllProduct, setOpenModalConfirmDeleteAllProduct] = useState(false);

  // Error
  const [errorProductExistent, setErrorProductExistent] = useState(false);
  const [errorZeroPriceLists, setErrorZeroPriceLists] = useState(false);

  // Loading
  const [loadingRemoveProduct, setLoadingRemoveProduct] = useState(false);
  const [loadingRemoveAllProduct, setLoadingRemoveAllProduct] = useState(false);

  // Query
  const { loading: loadingProducts, error: errorProducts, data: dataProducts } = useQuery(SORT_PRODUCTS, {
    variables: {
      sort: sort ? sort : "code-asc",
    },
  });
  // Query
  const { loading: loadingGenders, error: errorGenders, data: dataGenders } = useQuery(GENDER_CATEGORY);
  const { loading: loadingSeasons, error: errorSeasons, data: dataSeasons } = useQuery(SEASON_CATEGORY);


  // Mutation
  const [addProduct] = useMutation(ADD_PRODUCT);
  const [removeProduct] = useMutation(REMOVE_PRODUCT);
  // Dropzone
  const [openDropzone, setOpenDropzone] = useState(false);
  // Upload
  const [fileMainImage, setFileMainImage] = useState(undefined);

  const onChangeSearch = (event) => {
    setSearch(event.target.value);
    if (!event.target.value) {
      localStorage.removeItem("productSearch");
    } else {
      localStorage.setItem("productSearch", event.target.value);
    }
  };

  const onViewProduct = (productId) => {
    history.push("product-detail/" + productId);
  };
  const openModalAddProduct = () => {
    setModalAddProduct(true);
  };

  const closeModalAddProduct = () => {
    setFileMainImage(undefined);
    setNewProductCode("");
    setNewProductName("");
    setModalAddProduct(false);
  };
  // Open modal confirm delete product
  const onOpenModalConfirmDeleteProduct = (product) => {
    setProductToDelete({ ...productToDelete, name: product.name, id: product.id, code: product.code });
    setOpenModalConfirmDeleteProduct(true);
  };
  // Clsoe modal confirm delete product
  const onCloseModalConfirmDeleteProduct = () => {
    setOpenModalConfirmDeleteProduct(false);
  };

  // Open modal confirm delete product selected
  const onOpenModalConfirmDeleteAllProduct = () => {
    setOpenModalConfirmDeleteAllProduct(true);
  };
  // Clsoe modal confirm delete product selected
  const onCloseModalConfirmDeleteAllProduct = () => {
    setOpenModalConfirmDeleteAllProduct(false);
    setCheckboxMassiveDelete([]);
    setAllDeleteChecked(false);
  };

  const onAddProduct = () => {
    addProduct({
      variables: { name: newProductName, code: newProductCode, file: fileMainImage, type: productSeason },
      context: {
        hasUpload: true,
      },
      refetchQueries: ["sortProductsAdmin"],
    }).then(response => {
      // If response of remove registration permission is 0 set error
      if (response.data.addProduct.status === 1) {
        history.push("product-detail/" + response.data.addProduct.product_id);
      } else if (response.data.addProduct.status === 0) {
        setErrorProductExistent(true);
      } else if (response.data.addProduct.status === -1) {
        setErrorZeroPriceLists(true);
      }
    });
  };
  // Remove Producr
  const onRemoveProduct = (id) => {
    setLoadingRemoveProduct(true);
    removeProduct({
      variables: { id: id },
      refetchQueries: ["sortProductsAdmin"],
    }).then(response => {
      // If response of remove registration permission is 0 set error
      if (response.data.removeProduct.status === 0) {
        setLoadingRemoveProduct(false);
      } else if (response.data.removeProduct.status === 1) {
        setLoadingRemoveProduct(false);
        setOpenModalConfirmDeleteProduct(false);
      }
    }).catch(err => {
      // if get error of mutation set error of insert Vat number to true
      setLoadingRemoveProduct(false);
    });
  };

  // Mutation Delete List of Product
  const deleteAllSelected = () => {
    setLoadingRemoveAllProduct(true);
    checkboxMassiveDelete.map((item, index) => {
      removeProduct({
        variables: { id: item },
        refetchQueries: ["sortProductsAdmin"],
      }).then(response => {
        if (response.data.removeProduct.status === 0) {
          setLoadingRemoveAllProduct(false);
        } else if (response.data.removeProduct.status === 1) {
          if (index === checkboxMassiveDelete.length - 1) {
            setCheckboxMassiveDelete([]);
            setLoadingRemoveAllProduct(false);
            setOpenModalConfirmDeleteAllProduct(false);
            setAllDeleteChecked(false);
          }
        }
      }).catch(err => {
        setLoadingRemoveAllProduct(false);
      });
    });
  };

  const onChangeForm = event => {
    if (event.target.name === "name") {
      setNewProductName(event.target.value);
    } else if (event.target.name === "code") {
      setNewProductCode(event.target.value);
    }
  };

  // Open Dropzone Add Image
  const onOpenDropzone = () => {
    setOpenDropzone(true);
  };
  // Close Dropzone Add Image
  const onCloseDropzone = () => {
    setOpenDropzone(false);
  };

  // onSave Dropzone when edit color
  const onSaveImage = files => {
    setFileMainImage(files[0]);
    setOpenDropzone(false);
  };

  // onDelete image uploaded in form add new color
  const deleteImageUploaded = () => {
    setFileMainImage(undefined);
  };
  // onChange radio type product (season)
  const onChangeRadioSeason = (event) => {
    setProductSeason(event.target.value);
  };
  // onChange filter type 0/1 Estivo/Invernale
  const onChangeSelectFilterType = (event) => {
    setFilterType(event.target.value);
  };

  const onChangeSort = event => {
    setSort(event.target.value);
  };

  const onChangeViewBestseller = event => {
    if (event.target.checked) {
      setViewBestseller(1);
    } else {
      setViewBestseller(0);
    }
  };

  const onChangeViewLuxury = event => {
    if (event.target.checked) {
      setViewLuxury(1);
    } else {
      setViewLuxury(0);
    }
  };

  const onChangeSelectFilterGender = event => {
    setFilterGender(event.target.value);
  };


  if (loadingProducts || loadingGenders) return <LinearProgress />;
  if (errorProducts || errorGenders) { console.log("error"); }

  // onChange Massive Delete
  const onChangeMassiveDelete = event => {
    if (event.target.checked) {
      if (event.target.name === "all") {
        setCheckboxMassiveDelete(undefined);
        setAllDeleteChecked(true);
        const newCheckBoxMassiveDelete = [];
        // FILTER
        filterType === "" ? (
          search ? (
            viewBestseller === 1 ? (
              filterGender === "" ? (
                viewLuxury === 1 ? (
                  dataProducts.sortProductsAdmin.filter(item => (item.name?.toLowerCase().includes(search.toLowerCase()) || item.code.toLowerCase().includes(search.toLowerCase())) && item.bestseller === 1 && item.luxury === 1).map((item, index) => (
                    newCheckBoxMassiveDelete.push(item.id)
                  ))
                ) : (
                  dataProducts.sortProductsAdmin.filter(item => (item.name?.toLowerCase().includes(search.toLowerCase()) || item.code.toLowerCase().includes(search.toLowerCase())) && item.bestseller === 1).map((item, index) => (
                    newCheckBoxMassiveDelete.push(item.id)
                  ))
                )
              ) : (
                viewLuxury === 1 ? (
                  dataProducts.sortProductsAdmin.filter(item => (item.name?.toLowerCase().includes(search.toLowerCase()) || item.code.toLowerCase().includes(search.toLowerCase())) && item.bestseller === 1 && item.luxury === 1 && item?.gender?.id === filterGender).map((item, index) => (
                    newCheckBoxMassiveDelete.push(item.id)
                  ))
                ) : (
                  dataProducts.sortProductsAdmin.filter(item => (item.name?.toLowerCase().includes(search.toLowerCase()) || item.code.toLowerCase().includes(search.toLowerCase())) && item.bestseller === 1 && item?.gender?.id === filterGender).map((item, index) => (
                    newCheckBoxMassiveDelete.push(item.id)
                  ))
                )
              )
            ) : (
              filterGender === "" ? (
                viewLuxury === 1 ? (
                  dataProducts.sortProductsAdmin.filter(item => (item.name?.toLowerCase().includes(search.toLowerCase()) || item.code.toLowerCase().includes(search.toLowerCase())) && item.luxury === 1).map((item, index) => (
                    newCheckBoxMassiveDelete.push(item.id)
                  ))
                ) : (
                  dataProducts.sortProductsAdmin.filter(item => item.name?.toLowerCase().includes(search.toLowerCase()) || item.code.toLowerCase().includes(search.toLowerCase())).map((item, index) => (
                    newCheckBoxMassiveDelete.push(item.id)
                  ))
                )
              ) : (
                viewLuxury === 1 ? (
                  dataProducts.sortProductsAdmin.filter(item => (item.name?.toLowerCase().includes(search.toLowerCase()) || item.code.toLowerCase().includes(search.toLowerCase())) && item.gender?.id === filterGender && item.luxury === 1).map((item, index) => (
                    newCheckBoxMassiveDelete.push(item.id)
                  ))
                ) : (
                  dataProducts.sortProductsAdmin.filter(item => (item.name?.toLowerCase().includes(search.toLowerCase()) || item.code.toLowerCase().includes(search.toLowerCase())) && item.gender?.id === filterGender).map((item, index) => (
                    newCheckBoxMassiveDelete.push(item.id)
                  ))
                )
              )
            )
          ) : (
            viewBestseller === 1 ? (
              filterGender === "" ? (
                viewLuxury === 1 ? (
                  dataProducts.sortProductsAdmin.filter(item => item.bestseller === 1 && item.luxury === 1).map((item, index) => (
                    newCheckBoxMassiveDelete.push(item.id)
                  ))
                ) : (
                  dataProducts.sortProductsAdmin.filter(item => item.bestseller === 1).map((item, index) => (
                    newCheckBoxMassiveDelete.push(item.id)
                  ))
                )
              ) : (
                viewLuxury === 1 ? (
                  dataProducts.sortProductsAdmin.filter(item => item.bestseller === 1 && item.gender?.id === filterGender && item.luxury === 1).map((item, index) => (
                    newCheckBoxMassiveDelete.push(item.id)
                  ))
                ) : (
                  dataProducts.sortProductsAdmin.filter(item => item.bestseller === 1 && item.gender?.id === filterGender).map((item, index) => (
                    newCheckBoxMassiveDelete.push(item.id)
                  ))
                )
              )
            ) : (
              filterGender === "" ? (
                viewLuxury === 1 ? (
                  dataProducts.sortProductsAdmin.filter(item => item.luxury === 1).map((item, index) => (
                    newCheckBoxMassiveDelete.push(item.id)
                  ))
                ) : (
                  dataProducts.sortProductsAdmin.map((item, index) => (
                    newCheckBoxMassiveDelete.push(item.id)
                  ))
                )
              ) : (
                viewLuxury === 1 ? (
                  dataProducts.sortProductsAdmin.filter(item => item.gender?.id === filterGender && item.luxury === 1).map((item, index) => (
                    newCheckBoxMassiveDelete.push(item.id)
                  ))
                ) : (
                  dataProducts.sortProductsAdmin.filter(item => item.gender?.id === filterGender).map((item, index) => (
                    newCheckBoxMassiveDelete.push(item.id)
                  ))
                )
              )
            )
          )
        ) : (
          search ? (
            viewBestseller === 1 ? (
              filterGender === "" ? (
                viewLuxury === 1 ? (
                  dataProducts.sortProductsAdmin.filter(item => (item.name?.toLowerCase().includes(search.toLowerCase()) || item.code.toLowerCase().includes(search.toLowerCase())) && item.season.name === filterType && item.bestseller === 1 && item.luxury === 1).map((item, index) => (
                    newCheckBoxMassiveDelete.push(item.id)
                  ))
                ) : (
                  dataProducts.sortProductsAdmin.filter(item => (item.name?.toLowerCase().includes(search.toLowerCase()) || item.code.toLowerCase().includes(search.toLowerCase())) && item.season.name === filterType && item.bestseller === 1).map((item, index) => (
                    newCheckBoxMassiveDelete.push(item.id)
                  ))
                )
              ) : (
                viewLuxury === 1 ? (
                  dataProducts.sortProductsAdmin.filter(item => (item.name?.toLowerCase().includes(search.toLowerCase()) || item.code.toLowerCase().includes(search.toLowerCase())) && item.season.name === filterType && item.bestseller === 1 && item.gender?.id === filterGender && item.luxury === 1).map((item, index) => (
                    newCheckBoxMassiveDelete.push(item.id)
                  ))
                ) : (
                  dataProducts.sortProductsAdmin.filter(item => (item.name?.toLowerCase().includes(search.toLowerCase()) || item.code.toLowerCase().includes(search.toLowerCase())) && item.season.name === filterType && item.bestseller === 1 && item.gender?.id === filterGender).map((item, index) => (
                    newCheckBoxMassiveDelete.push(item.id)
                  ))
                )
              )
            ) : (
              filterGender === "" ? (
                viewLuxury === 1 ? (
                  dataProducts.sortProductsAdmin.filter(item => (item.name?.toLowerCase().includes(search.toLowerCase()) || item.code.toLowerCase().includes(search.toLowerCase())) && item.season.name === filterType && item.luxury === 1).map((item, index) => (
                    newCheckBoxMassiveDelete.push(item.id)
                  ))
                ) : (
                  viewLuxury === 1 ? (
                    dataProducts.sortProductsAdmin.filter(item => (item.name?.toLowerCase().includes(search.toLowerCase()) || item.code.toLowerCase().includes(search.toLowerCase())) && item.season.name === filterType && item.luxury === 1).map((item, index) => (
                      newCheckBoxMassiveDelete.push(item.id)
                    ))
                  ) : (
                    dataProducts.sortProductsAdmin.filter(item => (item.name?.toLowerCase().includes(search.toLowerCase()) || item.code.toLowerCase().includes(search.toLowerCase())) && item.season.name === filterType).map((item, index) => (
                      newCheckBoxMassiveDelete.push(item.id)
                    ))
                  )
                )
              ) : (
                viewLuxury === 1 ? (
                  dataProducts.sortProductsAdmin.filter(item => (item.name?.toLowerCase().includes(search.toLowerCase()) || item.code.toLowerCase().includes(search.toLowerCase())) && item.season.name === filterType && item.gender?.id === filterGender && item.luxury === 1).map((item, index) => (
                    newCheckBoxMassiveDelete.push(item.id)
                  ))
                ) : (
                  dataProducts.sortProductsAdmin.filter(item => (item.name?.toLowerCase().includes(search.toLowerCase()) || item.code.toLowerCase().includes(search.toLowerCase())) && item.season.name === filterType && item.gender?.id === filterGender).map((item, index) => (
                    newCheckBoxMassiveDelete.push(item.id)
                  ))
                )
              )
            )
          ) : (
            viewBestseller === 1 ? (
              filterGender === "" ? (
                viewLuxury === 1 ? (
                  dataProducts.sortProductsAdmin.filter(item => item.season?.name === filterType && item.bestseller === 1 && item.luxury === 1).map((item, index) => (
                    newCheckBoxMassiveDelete.push(item.id)
                  ))
                ) : (
                  dataProducts.sortProductsAdmin.filter(item => item.season?.name === filterType && item.bestseller === 1).map((item, index) => (
                    newCheckBoxMassiveDelete.push(item.id)
                  ))
                )
              ) : (
                viewLuxury === 1 ? (
                  dataProducts.sortProductsAdmin.filter(item => item.season?.name === filterType && item.bestseller === 1 && item.gender?.id === filterGender && item.luxury === 1).map((item, index) => (
                    newCheckBoxMassiveDelete.push(item.id)
                  ))
                ) : (
                  dataProducts.sortProductsAdmin.filter(item => item.season?.name === filterType && item.bestseller === 1 && item.gender?.id === filterGender).map((item, index) => (
                    newCheckBoxMassiveDelete.push(item.id)
                  ))
                )
              )
            ) : (
              filterGender === "" ? (
                viewLuxury === 1 ? (
                  dataProducts.sortProductsAdmin.filter(item => item.season?.name === filterType && item.luxury === 1).map((item, index) => (
                    newCheckBoxMassiveDelete.push(item.id)
                  ))
                ) : (
                  dataProducts.sortProductsAdmin.filter(item => item.season?.name === filterType).map((item, index) => (
                    newCheckBoxMassiveDelete.push(item.id)
                  ))
                )
              ) : (
                viewLuxury === 1 ? (
                  dataProducts.sortProductsAdmin.filter(item => item.season?.name === filterType && item.gender?.id === filterGender && item.luxury === 1).map((item, index) => (
                    newCheckBoxMassiveDelete.push(item.id)
                  ))
                ) : (
                  dataProducts.sortProductsAdmin.filter(item => item.season?.name === filterType && item.gender?.id === filterGender).map((item, index) => (
                    newCheckBoxMassiveDelete.push(item.id)
                  ))
                )
              )
            )
          )
        )
        setCheckboxMassiveDelete(newCheckBoxMassiveDelete);
      } else {
        setCheckboxMassiveDelete([...checkboxMassiveDelete, event.target.name]);
        if (checkboxMassiveDelete.length === dataProducts.sortProductsAdmin.length - 1) {
          setAllDeleteChecked(true);
        }
      }
    } else {
      if (event.target.name === "all") {
        setCheckboxMassiveDelete([]);
        setAllDeleteChecked(false);
      } else {
        let index = checkboxMassiveDelete.indexOf(event.target.name);
        if (index >= 0) {
          checkboxMassiveDelete.splice(index, 1);
          setCheckboxMassiveDelete([...checkboxMassiveDelete]);
        }
        if (checkboxMassiveDelete.length === 0) {
          setAllDeleteChecked(false);
        }
      }
    }
  };

  return (
    <Layout
      dataProducts={dataProducts}
      onChangeSearch={onChangeSearch}
      search={search}
      onViewProduct={onViewProduct}
      modalAddProduct={modalAddProduct}
      openModalAddProduct={openModalAddProduct}
      closeModalAddProduct={closeModalAddProduct}
      onAddProduct={onAddProduct}
      newProductName={newProductName}
      newProductCode={newProductCode}
      onChangeForm={onChangeForm}
      errorProductExistent={errorProductExistent}
      errorZeroPriceLists={errorZeroPriceLists}
      openDropzone={openDropzone}
      onOpenDropzone={onOpenDropzone}
      onCloseDropzone={onCloseDropzone}
      onSaveImage={onSaveImage}
      fileMainImage={fileMainImage}
      deleteImageUploaded={deleteImageUploaded}
      onChangeRadioSeason={onChangeRadioSeason}
      productSeason={productSeason}
      onOpenModalConfirmDeleteProduct={onOpenModalConfirmDeleteProduct}
      onCloseModalConfirmDeleteProduct={onCloseModalConfirmDeleteProduct}
      openModalConfirmDeleteProduct={openModalConfirmDeleteProduct}
      productToDelete={productToDelete}
      onRemoveProduct={onRemoveProduct}
      loadingRemoveProduct={loadingRemoveProduct}
      onChangeSelectFilterType={onChangeSelectFilterType}
      filterType={filterType}
      onChangeMassiveDelete={onChangeMassiveDelete}
      checkboxMassiveDelete={checkboxMassiveDelete}
      deleteAllSelected={deleteAllSelected}
      allDeleteChecked={allDeleteChecked}
      loadingRemoveAllProduct={loadingRemoveAllProduct}
      onOpenModalConfirmDeleteAllProduct={onOpenModalConfirmDeleteAllProduct}
      onCloseModalConfirmDeleteAllProduct={onCloseModalConfirmDeleteAllProduct}
      openModalConfirmDeleteAllProduct={openModalConfirmDeleteAllProduct}
      onChangeSort={onChangeSort}
      sort={sort}
      onChangeViewBestseller={onChangeViewBestseller}
      onChangeViewLuxury={onChangeViewLuxury}
      viewBestseller={viewBestseller}
      viewLuxury={viewLuxury}
      dataGenders={dataGenders}
      filterGender={filterGender}
      onChangeSelectFilterGender={onChangeSelectFilterGender}
      dataSeasons={dataSeasons}
    />
  );
};

export default withRouter(Products);
