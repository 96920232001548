/* eslint-disable no-undef */
import React from "react";
import { withRouter } from "react-router-dom";
import { ORDER_DETAIL, ME } from "../../../query";
import { useQuery } from "@apollo/react-hooks";
import LinearProgress from "@material-ui/core/LinearProgress";
import Layout from "./layout";

const OrderDetail = ({ history, match }) => {
  const { loading: loadingOrderDetail, error: errorOrderDetail, data: dataOrderDetail } = useQuery(ORDER_DETAIL,{
    variables: {order_id: match.params.orderId},
  });

  const { loading: loadingMe, error: errorMe, data: dataMe } = useQuery(ME);

  const onGoToOrders = () =>{
    history.push("/orders");
  };

  const onGoToShop = () =>{
    history.push("/shop");
  };

  if(loadingOrderDetail){
    return <LinearProgress/>;
  }

  if(errorOrderDetail){
    console.log("err");
  }


  return (
    <Layout
      dataOrderDetail={dataOrderDetail}
      onGoToOrders={onGoToOrders}
      onGoToShop={onGoToShop}
      dataMe={dataMe}
    />
  );
};

export default withRouter(OrderDetail);
