import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { BatchHttpLink } from "apollo-link-batch-http";
require("dotenv").config();

//const httpLink = new HttpLink({ uri: process.env.REACT_APP_BACKEND_URL });
const options = {
  uri: process.env.REACT_APP_BACKEND_URL,
  headers: {
    authorization: `Bearer ${localStorage.getItem("accessToken")}` || null,
  },
};

const httpLink = ApolloLink.split(
  operation => operation.getContext().hasUpload,
  createUploadLink(options),
  new BatchHttpLink(options),
);

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Product: {
        fields: {
          tags: {
            merge(existing, incoming) {
              return [...incoming];
            },
          },
        },
      },
      Query: {
        fields: {
          getSummaryProductOnCart: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          myCart: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          me: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          myFavorites: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          tags: {
            merge(existing, incoming) {
              return [...incoming];
            },
          },
        },
      },
    },
  }),
  link: httpLink,
});

export default client;
