import React, { useState } from "react";
import { GENDER_CATEGORY,  SEASON_CATEGORY, SHOW_BANNER, GET_SALE_PRODUCTS, GET_BESTSELLER_PRODUCTS, GET_LUXURY_PRODUCTS, ME } from "../../../query";
import { useQuery } from "@apollo/react-hooks";
import { withRouter } from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";
import Layout from "./layout";

const Home = ({ history, match }) => {
  const [filterShop, setFilterShop] = useState({
    gender: null,
    season: null,
  });
  const [modalFilterShop, setModalFilterShop] = useState(false);
  const { loading: loadingMe, error: errorMe, data: dataMe } = useQuery(ME);

  const { loading: loadingGenders, error: errorGenders, data: dataGenders } = useQuery(GENDER_CATEGORY);
  const { loading: loadingSeason, error: errorSeason, data: dataSeason } = useQuery(SEASON_CATEGORY);
  const { loading: loadingShowBanner, error: errorShowBanner, data: dataShowBanner } = useQuery(SHOW_BANNER);

  const { loading: loadingSaleProducts, error: errorSaleProducts, data: dataSaleProducts } = useQuery(GET_SALE_PRODUCTS);
  const { loading: loadingBestsellerProducts, error: errorBestsellerProducts, data: dataBestsellerProducts } = useQuery(GET_BESTSELLER_PRODUCTS);
  const { loading: loadingLuxuryProducts, error: errorLuxuryProducts, data: dataLuxuryProducts } = useQuery(GET_LUXURY_PRODUCTS);

  const openModal = (valueGender) => {
    if(valueGender === "no-gender"){
      setFilterShop({...filterShop, gender: null});
    }else{
      setFilterShop({...filterShop, gender: valueGender});
    }
    setModalFilterShop(true);
  };

  const closeModal = () => {
    setFilterShop({
      gender: null,
      season: null,
    });
    setModalFilterShop(false);
  };

  const onChangeSeason = event => {
    setFilterShop({...filterShop, season: event.target.value});
  };

  const onGo = () => {
    const genderID = filterShop.gender ? filterShop.gender : "nullGenderIdSearch";
    const seasonID = filterShop.season ? filterShop.season : "nullSeasonIdSearch";
    history.push("/shop/" + genderID + "/" + seasonID + "/nullHeaderSearch");
  };

  if (loadingGenders || loadingSeason || loadingShowBanner || loadingSaleProducts || loadingBestsellerProducts || loadingLuxuryProducts){
    return <LinearProgress/>;
  }

  if ( errorGenders ||  errorSeason || errorShowBanner || errorSaleProducts || errorBestsellerProducts || errorLuxuryProducts) {
    console.log("error");
  }

  return (
    <Layout
      dataGenders={dataGenders}
      onChangeSeason={onChangeSeason}
      dataSeason={dataSeason}
      filterShop={filterShop}
      modalFilterShop={modalFilterShop}
      openModal={openModal}
      closeModal={closeModal}
      onGo={onGo}
      dataShowBanner={dataShowBanner}
      dataSaleProducts={dataSaleProducts}
      dataBestsellerProducts={dataBestsellerProducts}
      dataLuxuryProducts={dataLuxuryProducts}
      history={history}
      dataMe={dataMe}
    />
  );
};

export default withRouter(Home);
