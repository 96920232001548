/* eslint-disable no-undef */
import React from "react";
import { withRouter } from "react-router-dom";
import { MY_ORDERS } from "../../../query";
import { useQuery } from "@apollo/react-hooks";
import LinearProgress from "@material-ui/core/LinearProgress";
import Layout from "./layout";

const Orders = ({ history, match }) => {
  const { loading: loadingMyOrders, error: errorMyOrders, data: dataMyOrders } = useQuery(MY_ORDERS);

  const onViewOrder = (orderId) => {
    history.push("/order-detail/" + orderId);
  };

  const onGoToShop = () => {
    history.push("/shop");
  };

  if(loadingMyOrders){
    return <LinearProgress/>;
  }

  if(errorMyOrders){
    console.log("err");
  }

  return (
    <Layout
      dataMyOrders={dataMyOrders}
      onViewOrder={onViewOrder}
      onGoToShop={onGoToShop}
    />
  );
};

export default withRouter(Orders);
