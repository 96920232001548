import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  imageFullScreenProduct: {
    height: "100vh",
    display: "inherit",
    margin: "auto",
    [theme.breakpoints.only("xs")]: {
      width: "100%",
      height: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "auto",
    },
    [theme.breakpoints.only("md")]: {
      width: "auto",
    },
    [theme.breakpoints.only("lg")]: {
      width: "auto",
    },
    [theme.breakpoints.only("xl")]: {
      width: "auto",
    },
  },
  wrapperImageFullScreenProduct: {
    background: "#FFFFFF",
  },
}));
