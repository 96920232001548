import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { PASSWORD_RESET_CHANGE } from "../../../mutation";
import { withRouter } from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";
import Form from "./form";

const PasswordReset = ({ history, match }) => {
  const [passwordResetChange] = useMutation(PASSWORD_RESET_CHANGE);
  const [formValues, setFormValues] = useState({});
  const [errors, setErrors] = useState(false);
  const [secondStep, setSecondStep] = useState(false);
  const [tokenError, setTokenError] = useState(false);
  const [loading, setLoading] = useState(false);

  const onChangeForm = event => {
    const value = event.target.value;
    const name = event.target.name;
    setFormValues({ ...formValues, [name]: value });
    setErrors(false);
  };

  const onPasswordResetChange = () => {
    setLoading(true);
    if(formValues["password"] !== formValues["password-confirmation"]){
      setErrors(true);
      setLoading(false);
    }else{
      passwordResetChange({
        variables: { token: match.params.token, password:formValues["password"], password_confirmation: formValues["password-confirmation"] },
      }).then(response => {
        if(response.data.passwordResetChange.status === 0){
          setLoading(false);
          setTokenError(true);
        }else{
          setLoading(false);
          setSecondStep(true);
        }
      });
    }
  };

  const onGoToLogin = () => {
    history.push("/");
  };

  if(loading) return <LinearProgress/>;

  return (
    <>
      <Form
        formValues={formValues}
        onChangeForm={onChangeForm}
        onPasswordResetChange={onPasswordResetChange}
        errors={errors}
        secondStep={secondStep}
        tokenError={tokenError}
        onGoToLogin={onGoToLogin}
      />
    </>
  );
};

export default withRouter(PasswordReset);
