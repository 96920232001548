import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  wrapperSingleElement: {
    [theme.breakpoints.down('md')]: {
      paddingBottom: "0px !important",
      paddingTop: "0px !important",
    },
  },
  wrapperSingleElementTypography: {
    [theme.breakpoints.down('sm')]: {
     display: "flex",
    },
    [theme.breakpoints.down('xs')]: {
      display: "block",
     },
  },
  textFieldElement: {
    width: "100%",
  },
  selectElement: {
  },
  rowWrapper: {
    [theme.breakpoints.down('md')]: {
      marginBottom: "1rem",
    },
  },
  divider: {
    backgroundColor: "rgba(130,70,51,1)",
    width: "100%",
    marginBottom: "1.5rem",
    display: "block",
},
}));
