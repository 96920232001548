import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isLoggedIn, userRole } from "../../utils";

const getRedirectByRole = () => {
  if(userRole() === "admin"){
    return <Redirect to="/admin"/>;
  }
  return <Redirect to="/home"/>;
};

const PublicRoute = ({component: Component, ...rest}) => {
  return (
    <Route {...rest} render={props => (
      isLoggedIn() ?
        getRedirectByRole()
        : <Component {...props} />
    )}
    />
  );
};

export default PublicRoute;
