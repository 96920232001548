import { makeStyles } from "@material-ui/core/styles";
import Diamond from "../../../../assets/images/diamond.png";

export default makeStyles((theme) => ({
  shop:{
    marginTop: "1rem",
  },
  paperSearch: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
  },
  input: {
    marginLeft: "0.75rem",
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  accordionDetails: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    gridGap: "2rem",
    [theme.breakpoints.only("xs")]: {
      gridTemplateColumns: "1fr 1fr",
      gridGap: "0rem",
    },
    [theme.breakpoints.only("sm")]: {
      gridTemplateColumns: "1fr 1fr 1fr 1fr",
    },
    [theme.breakpoints.only("lg")]: {
      gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    },
  },
  column: {
    flexBasis: "100%",
    [theme.breakpoints.only("xs")]: {
      margin: "0rem",
    },
  },
  columnTitle: {
    fontWeight: "bold",
  },
  productsSkeleton: {
    marginTop:"1.5rem",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    gridGap: "2rem",
    [theme.breakpoints.only("xs")]: {
      gridTemplateColumns: "1fr",
    },
    [theme.breakpoints.only("sm")]: {
      gridTemplateColumns: "1fr 1fr",
    },
    [theme.breakpoints.only("lg")]: {
      gridTemplateColumns: "1fr 1fr 1fr 1fr",
    },
    [theme.breakpoints.only("xl")]: {
      gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    },
  },
  skeleton:{
    borderRadius: "4px",
    [theme.breakpoints.only("xs")]: {
      width :"343",
    },
    [theme.breakpoints.only("sm")]: {
      width :"344",

    },
    [theme.breakpoints.only("lg")]: {
      width :"280.25px",

    },
    [theme.breakpoints.only("md")]: {
      width :"345.8px",

    },
  },
  skeletonTextFieldBig: {
    borderRadius: "4px",
    width :"100%",
  },
  skeletonTextFieldSmall: {
    borderRadius: "4px",
    width :"100%",
  },
  productsContainer: {
    marginTop: "2rem",
    marginBottom: "2rem",
  },
  productsList: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    gridGap: "2rem",
    [theme.breakpoints.only("xs")]: {
      gridTemplateColumns: "1fr",
    },
    [theme.breakpoints.only("sm")]: {
      gridTemplateColumns: "1fr 1fr",
    },
    [theme.breakpoints.only("lg")]: {
      gridTemplateColumns: "1fr 1fr 1fr",
    },
    [theme.breakpoints.only("xl")]: {
      gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    },
  },
  productCard: {
    textAlign: "center",
    position: "relative",
  },
  productImage: {
    //width: "100%",
    minHeight: "300px",
    maxHeight: "300px",
    borderRadius: "4px 4px 0px 0px",
  },
  productNameContainer:{
    margin: "1rem",
    display: "flex",
    justifyContent: "space-between",
  },
  productInfo: {
    textAlign: "left",
  },
  productName:{
    fontWeight: "bold",
    margin: 0,
  },
  productCode: {
    fontWeight: "400",
    color: "var(--menu-link)",
    margin: 0,
  },
  productPrice: {
    color: "#ffffff",
    padding: "0.25rem",
    marginTop: "-0.25rem",
    marginRight: "0.5rem",
    borderRadius: "4px",
    background: "#3da63d",
  },
  productFooter:{
    margin: "1rem 1rem 1rem 1rem",
    maxHeight: "30px",
    display: "flex",
    justifyContent: "space-between",
  },
  paperNotFoundContent: {
    padding: "70px 0",
    textAlign: "center",
  },
  paperNotFoundEmoji: {
    fontSize:"3rem",
  },
  paperNotFoundText: {
    fontWeight: "bold",
  },
  scrollEndMessage: {
    textAlign: "center",
    marginTop: "2.5rem",
  },
  scrollEndMessageText: {
    fontWeight: "bold",
  },
  stockWrapper: {
    position: "absolute",
    top: "1rem",
    right: "1rem",
  },
  stockText: {
    backgroundColor: "var(--mate-red)",
    padding:"0.25rem",
    borderRadius:"4px",
    color:"#ffffff",
  },
  bestsellerWrapper: {
    position: "absolute",
    top: "1rem",
    right: "1rem",
  },
  bestsellerText: {
    backgroundColor: "#D4AF37",
    padding:"0.25rem",
    borderRadius:"4px",
    color:"#ffffff",
  },
  luxuryWrapper: {
    position: "absolute",
    top: "0px",
    left: "10px",
    width: "40px",
    height: "40px",
    backgroundImage: `url(${Diamond})`,
    backgroundSize: "cover",
    backgroundPosition: "unset", //center
    backgroundRepeat: "no-repeat",
  },
}));
