import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { CREATE_REGISTRATION_REQUEST } from "../../../mutation";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Style from "./style"
import countries from "../../../utils/countries";
import countriesFlag from "../../../utils/countriesFlag";


const RequestRegister = ({ match, history }) => {
  const style = Style();

  const [createRegistrationRequest] = useMutation(CREATE_REGISTRATION_REQUEST);

  const [emailError, setEmailError] = useState(false)
  const [business_nameError, setBusinessNameError] = useState(false)
  const [vat_numberError, setVatNumberError] = useState(false)
  const [sdiError, setSdiError] = useState(false)
  const [destinationError, setDestinationError] = useState(false)
  const [registered_officeError, setRegisteredOfficeError] = useState(false)
  const [nationError, setNationError] = useState(false)
  const [phoneError, setPhoneError] = useState(false)

  const [errors, setErrors] = useState(false)

  const [success, setSuccess] = useState(false)
  const [mutationError, setMutationError] = useState(false)

  const [form, setForm] = useState({
    email: "",
    business_name: "",
    vat_number: "",
    sdi: "",
    destination: "",
    registered_office: "",
    nation: "",
    phone: ""
  })

  const checkErrors = (form) => {
    let error = false;

    // if(form.email === "" || !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(form.email) ){
      if(form.email === "" ){
      setEmailError(true)
      error = true
    }else{
      setEmailError(false)
    }

    if(form.business_name === ""){
      setBusinessNameError(true)
      error = true
    }else{
      setBusinessNameError(false)
    }

    if(form.vat_number === "" || !/^[0-9a-zA-Z]+$/.test(form.vat_number) || form.vat_number.length !== 11){
      setVatNumberError(true)
      error = true
    }else{
      setVatNumberError(false)
    }

    if(form.sdi === "" || !/^[0-9]+$/.test(form.sdi)|| form.sdi.length !== 6){
      setSdiError(true)
      error = true
    }else{
      setSdiError(false)
    }

    if(form.destination === ""){
      setDestinationError(true)
      error = true
    }else{
      setDestinationError(false)
    }

    if(form.registered_office === ""){
      setRegisteredOfficeError(true)
      error = true
    }else{
      setRegisteredOfficeError(false)
    }

    if(form.nation === ""){
      setNationError(true)
      error = true
    }else{
      setNationError(false)
    }

    if(form.phone === "" || !/^[0-9]+$/.test(form.phone)|| form.phone.length !== 10){
      setPhoneError(true)
      error = true
    }else{
      setPhoneError(false)
    }
    return error
  }

  const handleChangeForm = event => {
    setForm({...form, [event.target.name]: event.target.value})
  }

  const onRegister = () => {
    const errors = checkErrors(form)
    if(errors){
      setErrors(true)
    }else{
      setErrors(false)
      createRegistrationRequest({variables: form})
      .then(response => {
        if(response.data.createRegistrationRequest.status === 1){
          setSuccess(true)
        }else{
          setMutationError(true)
        }
      })
    }
  }

  return (
    <>
      <Container component="main" maxWidth="xs">
        <div className={style.paper}>
          <Typography component="h1" variant="h5">
            Registrazione
          </Typography>
          <TextField
            className={style.textField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Email"
            name="email"
            value={form.email}
            onChange={event => handleChangeForm(event)}
          />
          <TextField
            className={style.textField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Ragione Sociale"
            name="business_name"
            value={form.business_name}
            onChange={event => handleChangeForm(event)}
          />
          <TextField
            className={style.textField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Partita Iva"
            name="vat_number"
            value={form.vat_number}
            onChange={event => handleChangeForm(event)}
          />
          <TextField
            className={style.textField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="SDI"
            name="sdi"
            value={form.sdi}
            onChange={event => handleChangeForm(event)}
          />
          <TextField
            className={style.textField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Destinazione"
            name="destination"
            value={form.destination}
            onChange={event => handleChangeForm(event)}
          />
          <TextField
            className={style.textField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Sede Legale"
            name="registered_office"
            value={form.registered_office}
            onChange={event => handleChangeForm(event)}
          />
          <FormControl variant="outlined" margin="normal" fullWidth className={style.selectSort}>
            <InputLabel>Nazione</InputLabel>
            <Select
              name="nation"
              label="Nazione"
              onChange={event => handleChangeForm(event)}
              value={form.nation}
            >
              {countries.map((country, index) => (
                <MenuItem key={index} value={country.alpha2}> <img src={countriesFlag[country.alpha2]} />{country.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        <TextField
            className={style.textField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Telefono"
            name="phone"
            value={form.phone}
            onChange={event => handleChangeForm(event)}
          />
          {errors && (
            <Paper style={{padding: "5px", width: "100%"}}>
              {emailError && (<Typography color="error">Inserire una Email valida</Typography>)}
              {business_nameError && (<Typography color="error">Inserire una Ragione Sociale valida</Typography>)}
              {vat_numberError && (<Typography color="error">Inserire una Partita Iva valida</Typography>)}
              {sdiError && (<Typography color="error">Inserire uno Sdi valido</Typography>)}
              {destinationError && (<Typography color="error">Inserire una Destinazione valido</Typography>)}
              {registered_officeError && (<Typography color="error">Inserire una Sede Legale valida</Typography>)}
              {nationError && (<Typography color="error">Inserire una Nazione</Typography>)}
              {phoneError && (<Typography color="error">Inserire un Telefono valido</Typography>)}
            </Paper>
          )}
          {success && (
            <Paper style={{padding: "5px", width: "100%"}}>
              <Typography>Richiesta di Registrazione avvenuta con successo</Typography>
            </Paper>
          )}
          {mutationError && (
            <Paper style={{padding: "5px", width: "100%"}}>
              <Typography>Impossibile effetuare la richiesta di registrazione puo essera dato dal fatto che tu abbia gia una richiesta di registrazione effetuata precedentemente, contattare l'amministrazione.</Typography>
            </Paper>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className={style.submit}
            size="large"
            onClick={() => onRegister()}
          >
            Registrati
          </Button>
        </div>
      </Container>
    </>
  );
};

export default withRouter(RequestRegister);
