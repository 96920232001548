import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  paperModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalBody:{
    padding: "0.5rem 1rem",
    backgroundColor: "#f9f9f9",
    borderRadius: "5px",
    width: "450px",
  },
  modalSubTitle:{
    marginTop: "1rem",
  },
  modalFooter:{
    marginTop: "1rem",
    marginBottom: "0.5rem",
  },
  modalSubmit: {
    backgroundColor: "var(--blue)",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "var(--blue)",
      opacity: 0.8,
    },
  },
  buttonActionContainer: {
    padding: theme.spacing(3, 0, 2),
  },
  textFieldPriceProduct: {
    marginBottom:"1rem",
    width: "100%",
    [theme.breakpoints.down('xs')]: {
      width: "120px",
    },
  },
}));
