/* eslint-disable array-callback-return */
import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Modal from "@material-ui/core/Modal";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Style from "./style.js";
import MultiSelect from "../../../../components/multiSelect";
import { DropzoneDialog } from "material-ui-dropzone";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import InputAdornment from "@material-ui/core/InputAdornment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Badge from "@material-ui/core/Badge";
import { withStyles } from "@material-ui/core/styles";

const Layout = ({
  dataProduct,
  dataGroupSize,
  dataColors,
  dataGender,
  dataFineness,
  dataSeason,
  dataTag,
  dataComposition,
  dataAllPriceOfProduct,
  editProductFormValues,
  genderFormValues,
  finenessFormValues,
  seasonFormValues,
  editPriceListFormValues,
  onChangeEditProductFormValues,
  onChangePriceList,
  onChangeGender,
  onChangeFineness,
  onChangeSeason,
  onChangeTag,
  onChangeComposition,
  onSaveProduct,
  openDropzone,
  onOpenDropzone,
  onCloseDropzone,
  onSaveMainImage,
  fileMainImage,
  onChangeRadioSeason,
  errorUpdateProductPriceListEmpty,
  openDropzoneGallery,
  onSaveImagesGallery,
  onCloseDropzoneGallery,
  onOpenDropzoneGallery,
  fileImagesGallery,
  dataProductGallery,
  deleteMainImageGalley,
  onDeleteImageOfGallery,
  imagesGalleryToRemove,
  tagsFormValues,
  compositionsFormValues,
  dataPriceLists,
  openModalConfirm,
  dataColorFolder,
  addOutOfStock,
  outOfStock,
  setOutOfStock,
  defaultStocksValue,
  onChangeLuxuryFormValues,
  onChangeBestsellerFormValues,
  onChangeSaleFormValues,
  onChangePercentageDiscountFormValues,
  errorProductGalleryOrder,
  onChangeNotVisibleFormValues,
  setDefaultStocksValue,
  colorDiscountFormValues,
  onAddColorDiscountFormValues,
  onChangeColorDiscountFormValues,
  setDiscountColors,
  discountColors,

  discountBaseColor,
  setDiscountBaseColor,
  onChangeDiscountBaseColor,
  setColorDiscountFormValues
}) => {

  let productTotalQuantity = 0;
  Object.entries(JSON.parse(dataProduct.productAdmin?.outOfStock)).forEach((entry) => {
    const [key, value] = entry;
    productTotalQuantity = productTotalQuantity + value;
  });

  const style = Style();
  const [colorFolderFormValues, setColorFolderFormValues] = React.useState({});
  const [colorFolderSeason, setColorFolderSeason] = React.useState(0);
  const [groupSizeFormValues, setGroupSizeFormValues] = React.useState({});

  // Table responsive
  const StyledTableRow = withStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.common.white,
      padding: 0,
    },
  }))(TableRow);

  const StyledTableCell = withStyles((theme) => ({
    head: {
      color: theme.palette.common.black,
      fontWeight: 600,
      padding: "0.5rem 0",
    },
    body: {
      fontSize: 14,
      padding: "0.25rem 0",
    },
  }))(TableCell);

  useEffect(() => {

    if (Object.keys(JSON.parse(dataProduct.productAdmin.outOfStock)).length > 0) {
      setOutOfStock(JSON.parse(dataProduct.productAdmin.outOfStock));
    }
    if (dataProduct.productAdmin.colorFolder !== null) {
      setColorFolderFormValues({ ...colorFolderFormValues, colorFolder: dataProduct.productAdmin.colorFolder.id });
    } else {
      setColorFolderFormValues({ ...colorFolderFormValues, colorFolder: 0 });
    }

    if (dataProduct.productAdmin.groupSize !== null) {
      setGroupSizeFormValues({ ...groupSizeFormValues, groupSize: dataProduct.productAdmin.groupSize.id });
    } else {
      setGroupSizeFormValues({ ...groupSizeFormValues, groupSize: 0 });
    }

    // setDiscountBaseColor(Object.keys(JSON.parse(dataProduct.productAdmin.discountColors)).length > 0 ? true : false)
    if (dataProduct.productAdmin.discountColors && Object.keys(JSON.parse(dataProduct.productAdmin.discountColors)).length > 0) {
      setDiscountBaseColor(true);
      setDiscountColors(JSON.parse(dataProduct.productAdmin.discountColors));
    }


  }, []);

  const getFilesLimitGallery = () => {
    let count = 0;
    if (dataProductGallery !== undefined && dataProductGallery.getProductGallery.length > 0) {
      count = count + dataProductGallery.getProductGallery.length;
    }
    if (fileImagesGallery !== undefined && fileImagesGallery.length > 0) {
      count = count + fileImagesGallery.length;
    }
    if (imagesGalleryToRemove !== undefined && imagesGalleryToRemove.length > 0) {
      count = count - imagesGalleryToRemove.length;
    }
    return 5 - count;
  };

  // Options SelectCreatable Tag
  const tagOptions = dataTag.tags.map((tag) => {
    return {
      value: tag.id,
      label: tag.name,
    };
  });
  // Default value SelectCreatable Tag
  let defaultTagOptions = {};
  if (dataProduct.productAdmin.tags !== null && dataProduct.productAdmin.tags !== undefined) {
    defaultTagOptions = dataProduct.productAdmin.tags.map((tag) => {
      return {
        value: tag.id,
        label: tag.name,
      };
    });
  }
  if (tagsFormValues !== undefined) {
    defaultTagOptions = tagsFormValues.map((id) => {
      const tag = dataTag.tags.find(item => item.id === id);
      return {
        value: id,
        label: tag.name,
      };
    });
  }
  // Options SelectCreatable Composition
  const compositionOptions = dataComposition.compositions.map(composition => {
    return {
      value: composition.id,
      label: composition.name,
    };
  });
  // Default value SelectCreatable Composition
  let defaultCompositionOptions = {};
  if (dataProduct.productAdmin.compositions !== null && dataProduct.productAdmin.compositions !== undefined) {
    defaultCompositionOptions = dataProduct.productAdmin.compositions.map((composition) => {
      return {
        value: composition.id,
        label: composition.name,
      };
    });
  }
  if (dataProduct.productAdmin.compositions !== null && dataProduct.productAdmin.compositions !== undefined) {
    defaultCompositionOptions = dataProduct.productAdmin.compositions.map((composition) => {
      return {
        value: composition.id,
        label: composition.name,
      };
    });
  }
  if (compositionsFormValues !== undefined) {
    defaultCompositionOptions = compositionsFormValues.map((id) => {
      const composition = dataComposition.compositions.find(item => item.id === id);
      return {
        value: id,
        label: composition.name,
      };
    });
  }

  // onChange Color Folder Season
  const onChangeColorFolderSeason = event => {
    const value = event.target.value;
    if (value !== null) {
      setColorFolderSeason(value);
    } else {
      setColorFolderSeason(null);
    }
    setColorFolderFormValues({ ...colorFolderFormValues, colorFolder: "" });
    const track = document.querySelector('.css-1uccc91-singleValue');
    if (track) track.innerHTML = "";
    setColorDiscountFormValues({});
    setDiscountColors({});
    setDiscountBaseColor(false);
  };

  // onChange Color Folder
  const onChangeColorFolder = event => {
    if (event) {
      setColorFolderFormValues({ ...colorFolderFormValues, colorFolder: event.value });
    }
    setOutOfStock({});
    setColorDiscountFormValues({});
    setDiscountColors({});
    setDiscountBaseColor(false);

  };
  // onChange Group Size Select
  const onChangeGroupSize = event => {
    setGroupSizeFormValues({ ...groupSizeFormValues, groupSize: event.target.value });
    setOutOfStock({});
    setColorDiscountFormValues({});
    setDiscountColors({});
    setDiscountBaseColor(false);
  };

  // onChange radio type product (season)
  const onClickSetAllStock = event => {
    if (parseInt(event.target.value) !== 0 && !isNaN(parseInt(event.target.value))) {
      if (dataGroupSize && dataGroupSize.groupSizes.length > 0 && dataColorFolder && dataColorFolder.colorsFolder.length > 0 && dataGroupSize.groupSizes.findIndex(item => item.id === groupSizeFormValues.groupSize) >= 0 && dataColors && dataColors.colors.length > 0) {
        var newObj = {}
        dataGroupSize.groupSizes[dataGroupSize.groupSizes.findIndex(item => item.id === groupSizeFormValues.groupSize)].sizes.map((size, index) => (
          dataColors.colors.filter(element => element.colorFolder.id === colorFolderFormValues.colorFolder).map((color, index) => (
            newObj[size.id + "-" + color.id] = parseInt(event.target.value)
          ))
        ))
        setOutOfStock(newObj)
      } else {
        setOutOfStock({})
      }
    } else {
      setOutOfStock({})
    }
  };

  var newObj = discountColors;
  const onChangeDiscountForColor = (event) => {
    if (isNaN(parseInt(event.target.value))) {
      delete newObj[event.target.name]
    } else {
      newObj[event.target.name] = parseInt(event.target.value)
    }
    setDiscountColors(newObj);

  };

  return (
    <>
      <div style={{ marginBottom: "1rem", marginTop: "1rem" }}>
        <Grid container className={style.gridContainer} spacing={3}>
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <div className={style.productImageContainer}>
              <div>
                {
                  fileMainImage && fileMainImage !== undefined ? (
                    <>
                      <EditIcon className={style.buttonEditUploadedImage} color="primary" fontSize="large" onClick={onOpenDropzone} />
                      <img key={new Date()} src={URL.createObjectURL(fileMainImage)} className={style.productImage} alt={dataProduct.productAdmin.name} />
                    </>
                  ) : (
                    <>
                      <EditIcon className={style.buttonEditUploadedImage} color="primary" fontSize="large" onClick={onOpenDropzone} />
                      <img key={new Date()} src={dataProduct.productAdmin.image} className={style.productImage} alt={dataProduct.productAdmin.name} />
                    </>
                  )
                }
              </div>
              <DropzoneDialog
                open={openDropzone}
                onSave={onSaveMainImage}
                acceptedFiles={["image/jpeg", "image/jpg", "image/png", "image/bmp"]}
                showPreviews={true}
                maxFileSize={5000000}
                onClose={onCloseDropzone}
                showFileNames={true}
                showFileNamesInPreview={true}
                showAlerts={true}
                filesLimit={1}
              />
            </div>
            {
              getFilesLimitGallery() !== 5 ? (
                <div className={style.gridGallery}>
                  <Grid container xs={12} spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <Typography variant="h6" className={style.productDescription}>
                        Gallery
                      </Typography>
                    </Grid>
                    {
                      dataProductGallery.getProductGallery.filter(element => imagesGalleryToRemove.includes(element.id) === false).map((item, index) => {
                        return (
                          <Grid item key={`${index}-gallery`}>
                            <Badge key={index} badgeContent={item.image_position || index + 1} color="primary" />
                            <img src={item.image} alt={dataProduct.productAdmin.name} width="80px" height="100px" />
                            <CloseIcon className={style.closeIconGalleryImage} onClick={() => onDeleteImageOfGallery(item.id)} />
                          </Grid>
                        );
                      })
                    }
                    {
                      fileImagesGallery && fileImagesGallery !== undefined && fileImagesGallery.length > 0 && (
                        fileImagesGallery.map((item, index) => {
                          return (
                            <Grid item key={`${index}-gallery-2`}>
                              <img src={URL.createObjectURL(item)} alt={item.id} width="80px" height="100px" />
                              <CloseIcon className={style.closeIconGalleryImage} onClick={() => deleteMainImageGalley(index)} />
                            </Grid>
                          );
                        })
                      )
                    }
                    {
                      dataProductGallery !== undefined && (dataProductGallery.getProductGallery.length - imagesGalleryToRemove.length) + (fileImagesGallery !== undefined ? fileImagesGallery.length : 0) < 5 && (
                        <Grid item>
                          <AddIcon onClick={onOpenDropzoneGallery} className={style.galleryAddIcon} />
                        </Grid>
                      )
                    }
                    <Grid item xs={12}>
                      <TextField
                        id="outlined-basic"
                        name="gallery_position"
                        size="small"
                        label="Ordina Gallery"
                        variant="outlined"
                        value={editProductFormValues.gallery_position}
                        onChange={onChangeEditProductFormValues}
                        error={errorProductGalleryOrder}
                        helperText={errorProductGalleryOrder && "controllare che non siano presenti numeri duplicati e che inumeri inseriti siano uguali al numero delle foto."}
                      />
                    </Grid>
                  </Grid>
                </div>
              ) : (
                <div className={style.addNewGalleryButtonWrapper}>
                  <Button
                    color="primary"
                    size="large"
                    onClick={onOpenDropzoneGallery}
                    variant="contained"
                  >
                    Aggiungi Gallery
                  </Button>
                </div>
              )
            }
            <DropzoneDialog
              open={openDropzoneGallery}
              onSave={onSaveImagesGallery}
              acceptedFiles={["image/jpeg", "image/jpg", "image/png", "image/bmp"]}
              showPreviews={true}
              maxFileSize={5000000}
              onClose={onCloseDropzoneGallery}
              showFileNames={true}
              showFileNamesInPreview={true}
              showAlerts={true}
              filesLimit={getFilesLimitGallery()}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
            <Paper elevation={3} className={style.paperProductInfo}>
              <Grid container xs={12}>
                <Grid item sm={12} md={6}>
                  <div className={style.productTitleContainer}>
                    <Typography variant="h5" className={style.productTitle}>{dataProduct.productAdmin.code} - {dataProduct.productAdmin.name}</Typography>
                  </div>
                </Grid>
                {/* <Grid className={style.productStockCheckboxWrapper} item container sm={12} md={6} alignItems="flex-start" direction="row" style={{ alignItems: "center"}}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        //checked={editProductFormValues.stock === undefined ? ( dataProduct.productAdmin.stock === 0 ? false : true) : (editProductFormValues.stock === 0 ? false: true) }
                        checked={ Object.keys(outOfStock).length < 1 }
                        onChange={onChangeStockCheckboxFormValues}
                        name="stock"
                        color="primary"
                        value="inStock"
                      />
                    }
                    label={<span style={{ fontWeight: "600" }}>Prodotto Esaurito</span>}
                  />
                </Grid> */}
                <Grid className={style.productStockCheckboxWrapper} item container sm={12} alignItems="flex-end" direction="row" style={{ alignItems: "center" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={editProductFormValues.luxury === undefined ? (dataProduct.productAdmin.luxury === 1 ? true : false) : (editProductFormValues.luxury === 0 ? false : true)}
                        onChange={onChangeLuxuryFormValues}
                        name="luxury"
                        color="primary"
                        value="luxury"
                      />
                    }
                    label={<span style={{ fontWeight: "600" }}>NOOS</span>}
                  />
                </Grid>
                <Grid className={style.productStockCheckboxWrapper} item container sm={12} alignItems="flex-end" direction="row" style={{ alignItems: "center" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={editProductFormValues.bestseller === undefined ? (dataProduct.productAdmin.bestseller === 1 ? true : false) : (editProductFormValues.bestseller === 0 ? false : true)}
                        onChange={onChangeBestsellerFormValues}
                        name="bestseller"
                        color="primary"
                        value="bestseller"
                      />
                    }
                    label={<span style={{ fontWeight: "600" }}>Made in Italy</span>}
                  />
                </Grid>
                <Grid className={style.productStockCheckboxWrapper} item container sm={12} alignItems="flex-end" direction="row" style={{ alignItems: "center" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={editProductFormValues.not_visible === undefined ? (dataProduct.productAdmin.not_visible === 1 ? true : false) : (editProductFormValues.not_visible === 0 ? false : true)}
                        onChange={onChangeNotVisibleFormValues}
                        name="not_visible"
                        color="primary"
                        value="not_visible"
                      />
                    }
                    label={<span style={{ fontWeight: "600" }}>Non Visibile</span>}
                  />
                </Grid>
              </Grid>
              <Divider />
              <Grid container sm={12}>
                <Grid item sm={12} md={6}>
                  <div>
                    <Typography variant="subtitle1" className={style.productDescription}>Nome</Typography>
                  </div>
                  <div className={style.productDescriptionText}>
                    <TextField
                      name="name"
                      style={{ width: "100%" }}
                      variant="outlined"
                      size="small"
                      placeholder="Nome Prodotto"
                      value={editProductFormValues.name !== undefined ? editProductFormValues.name : dataProduct.productAdmin.name}
                      onChange={onChangeEditProductFormValues}
                    />
                  </div>
                </Grid>
                <Grid item sm={12} md={6}>
                  <div>
                    <Typography variant="subtitle1" className={style.productDescription}>Codice</Typography>
                  </div>
                  <div className={style.productDescriptionText}>
                    <TextField
                      name="code"
                      style={{ width: "100%" }}
                      variant="outlined"
                      size="small"
                      placeholder="Codice Prodotto"
                      value={editProductFormValues.code !== undefined ? editProductFormValues.code : dataProduct.productAdmin.code}
                      onChange={onChangeEditProductFormValues}
                    />
                  </div>
                </Grid>
              </Grid>
              <div>
                <Typography variant="subtitle1" className={style.productDescription}>Note</Typography>
              </div>
              <div className={style.productDescriptionText}>
                <TextareaAutosize
                  name="description"
                  style={{ width: "100%" }}
                  rowsMax={15}
                  rowsMin={5}
                  placeholder="Descrizione"
                  //defaultValue={dataProduct.productAdmin.description}
                  value={editProductFormValues.description !== undefined ? editProductFormValues.description : dataProduct.productAdmin.description}
                  onChange={onChangeEditProductFormValues}
                />
              </div>
              <Grid container sm={12}>
                <Grid item sm={12} >
                  <div>
                    <Typography variant="subtitle1" className={style.productDescription}>Finezza</Typography>
                  </div>
                  <div className={style.productDescriptionText}>
                    <TextField
                      name="fineness_description"
                      style={{ width: "100%" }}
                      variant="outlined"
                      size="small"
                      placeholder="1 FR"
                      value={editProductFormValues.fineness_description !== undefined ? editProductFormValues.fineness_description : dataProduct.productAdmin.fineness_description}
                      onChange={onChangeEditProductFormValues}
                    />
                  </div>
                </Grid>
              </Grid>
              <Divider style={{ marginTop: "2rem" }} />
              <div>
                <Typography variant="subtitle1" className={style.productSize}>Gruppo Taglie</Typography>
              </div>
              <div className={style.productSizeSelect}>
                {
                  dataGroupSize && dataGroupSize.groupSizes.length > 0 ? (
                    <>
                      <FormControl variant="outlined" margin="normal" size="small" fullWidth>
                        <Select
                          value={groupSizeFormValues.groupSize !== undefined && groupSizeFormValues !== null ? groupSizeFormValues.groupSize : dataProduct.productAdmin.groupSize !== null ? dataProduct.productAdmin.groupSize.id : ""}
                          onChange={onChangeGroupSize}
                          variant="outlined"
                          name="groupSize"
                        >
                          <MenuItem value={0}><em>Nessuno</em></MenuItem>
                          {
                            dataGroupSize.groupSizes.map((item) => {
                              return (
                                <MenuItem value={item.id} key={`${item.id}-lo1`}>{item.name}</MenuItem>
                              );
                            })
                          }
                        </Select>
                      </FormControl>
                    </>
                  ) : (
                    <em style={{ marginTop: "1rem" }}>Non ci sono gruppi taglie</em>
                  )
                }
              </div>
              <div>
                <Typography variant="subtitle1" className={style.productDescription}>Cartella Colori</Typography>
              </div>
              <div className={style.productSizeSelect}>
                {
                  dataColorFolder && dataColorFolder.colorsFolder.length > 0 ? (
                    <>
                      <FormControl variant="outlined" margin="normal" size="small" fullWidth>
                        <InputLabel id="demo-simple-select-outlined-label">Seleziona Stagione</InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          onChange={onChangeColorFolderSeason}
                          value={colorFolderSeason}
                          name="folderSeason"
                          label="Stagine"
                          size="small"
                        >
                          <MenuItem value={null}><em>Nessuno</em></MenuItem>
                          {dataSeason?.seasons.map(season =>
                            <MenuItem value={season.name}>{season.name}</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                      <FormControl variant="outlined" margin="normal" size="small" fullWidth>
                        <MultiSelect
                          onChange={onChangeColorFolder}
                          options={
                            colorFolderSeason ?
                              dataColorFolder.colorsFolder.filter(colorFolder => colorFolder.name.includes(colorFolderSeason)).map(colorFolder => ({ value: colorFolder.id, label: colorFolder.name }))
                              : dataColorFolder.colorsFolder.map(colorFolder => ({ value: colorFolder.id, label: colorFolder.name }))
                          }
                          name="folder"
                          label="Cartella"
                          placeholder="Cartella"
                          isMulti={false}
                          value={dataColorFolder.colorsFolder.filter(colorFolder => colorFolder.id === colorFolderFormValues.colorFolder).map(colorFolder => ({ value: colorFolder.id, label: colorFolder.name }))}
                          selectOption={colorFolderFormValues.colorFolder}
                        >
                          <MenuItem value={null}><em>Nessuno</em></MenuItem>
                        </MultiSelect>
                      </FormControl>
                    </>
                  ) : (
                    <em style={{ marginTop: "1rem" }}>Non ci sono cartelle colori</em>
                  )
                }
              </div>
              <Grid container xs={12} justify="center">
                {
                  dataGroupSize && dataGroupSize.groupSizes.length > 0 && dataColorFolder && dataColorFolder.colorsFolder.length > 0 ?
                    (
                      colorFolderFormValues.colorFolder !== undefined && colorFolderFormValues.colorFolder !== 0 && groupSizeFormValues.groupSize !== undefined && groupSizeFormValues.groupSize !== 0 ? (
                        dataGroupSize.groupSizes[dataGroupSize.groupSizes.findIndex(item => item.id === groupSizeFormValues.groupSize)].sizes.length > 0 ? (
                          dataColorFolder.colorsFolder[dataColorFolder.colorsFolder.findIndex(item => item.id === colorFolderFormValues.colorFolder)]?.colors.length > 0 ? (
                            <>
                              <div>
                                <Typography variant="subtitle1" className={style.productDescription}>Stock Combinazioni ({productTotalQuantity})</Typography>
                              </div>
                              <TableContainer style={{ padding: "1rem" }}>
                                <Table className={style.table} size="small" aria-label="a dense table">
                                  <TableHead>
                                    <StyledTableRow>
                                      <StyledTableCell style={{ textAlign: "center", border: "1px solid black", padding: "0 1rem" }} colSpan={1}>COLORI</StyledTableCell>
                                      <StyledTableCell style={{ textAlign: "center", border: "1px solid black" }} colSpan={dataGroupSize.groupSizes[dataGroupSize.groupSizes.findIndex(item => item.id === groupSizeFormValues.groupSize)].sizes.length + (discountBaseColor ? 0 : 1)}>TAGLIE</StyledTableCell>
                                      {discountBaseColor &&
                                        <StyledTableCell style={{ textAlign: "center", border: "1px solid black", padding: "0 1rem" }} colSpan={1}>SCONTO</StyledTableCell>
                                      }
                                    </StyledTableRow>
                                    <StyledTableRow>
                                      <StyledTableCell style={{ textAlign: "center", border: "1px solid black" }} colSpan={1}></StyledTableCell>
                                      {
                                        dataGroupSize.groupSizes[dataGroupSize.groupSizes.findIndex(item => item.id === groupSizeFormValues.groupSize)].sizes.map((item, index) => {
                                          return (
                                            <StyledTableCell style={{ textAlign: "center", border: "1px solid black" }} key={`${item.name}-group-size`}>
                                              <span style={{ textAlign: "center", display: "block" }}>{item.name}</span>
                                            </StyledTableCell>
                                          );
                                        })
                                      }
                                      {discountBaseColor &&
                                        <StyledTableCell style={{ textAlign: "center", border: "1px solid black" }} colSpan={1}></StyledTableCell>
                                      }
                                    </StyledTableRow>
                                  </TableHead>
                                  <TableBody>
                                    {
                                      dataColors.colors.filter(element => element.colorFolder.id === colorFolderFormValues.colorFolder).map((color, index) => (
                                        <StyledTableRow key={index + "a1"} >
                                          <StyledTableCell style={{ border: "1px solid black", textAlign: "center", padding: "0 1rem" }}>{color.hex}</StyledTableCell>
                                          {
                                            dataGroupSize.groupSizes[dataGroupSize.groupSizes.findIndex(item => item.id === groupSizeFormValues.groupSize)].sizes.map((item, index) => {
                                              console.log("GROUPSIZE", dataGroupSize)
                                              console.log("COLORS", dataColors)
                                              console.log("ITEM", item)
                                              console.log("OUT OF STOCK", outOfStock)
                                              return (
                                                <StyledTableCell style={{ border: "1px solid black", width: `calc(100% / ${dataGroupSize.groupSizes[dataGroupSize.groupSizes.findIndex(item => item.id === groupSizeFormValues.groupSize)].sizes.length + 1})` }} key={`${item.id}-group-size-1a`}>
                                                  <TextField
                                                    type="number"
                                                    id={item.id + color.id}
                                                    name={item.id + "-" + color.id}
                                                    size="small"
                                                    defaultValue={outOfStock[item.id + "-" + color.id] && outOfStock[item.id + "-" + color.id]}
                                                    placeholder={!outOfStock[item.id + "-" + color.id] && "0"}
                                                    value={outOfStock[item.id + "-" + color.id] && outOfStock[item.id + "-" + color.id].quantity}
                                                    onChange={(e) => addOutOfStock(e)}
                                                  />
                                                </StyledTableCell>
                                              );
                                            })
                                          }
                                          {discountBaseColor &&
                                            <StyledTableCell style={{ border: "1px solid black", textAlign: "center" }} key={`discount-${color.id}-${index}`}>
                                              <TextField
                                                type="number"
                                                name={color.id}
                                                id={`txt-${color.id}`}
                                                size="small"
                                                placeholder={0}
                                                defaultValue={discountColors[color.id]}
                                                onChange={(e) => onChangeDiscountForColor(e)}
                                                InputProps={{
                                                  startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                                  readOnly: !discountBaseColor,
                                                  inputProps: { min: 0, max: 100 }
                                                }}
                                              />
                                            </StyledTableCell>
                                          }
                                        </StyledTableRow>
                                      ))
                                    }
                                  </TableBody>
                                </Table>
                                <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                                  <Grid item >
                                    <TextField
                                      type="number"
                                      id="setAllStock"
                                      name="setAllStock"
                                      size="small"
                                      value={undefined}
                                      onChange={(e) => onClickSetAllStock(e)}
                                    />
                                  </Grid>
                                </Grid>

                              </TableContainer>
                            </>
                          ) : (
                            <em style={{ marginTop: "1rem" }}>Non esistono colori per questa cartella colori</em>
                          )
                        ) : (
                          <em style={{ marginTop: "1rem" }}>Non esistono taglie per questo gruppo taglie</em>
                        )
                      ) : (
                        <em>Cartella colori o gruppo taglie non selezionato</em>
                      )
                    ) : (
                      <></>
                    )
                }
              </Grid>
              <Divider style={{ marginTop: "1rem" }} />
              <div>
                <Typography variant="subtitle1" className={style.productDescription}>Genere</Typography>
              </div>
              <div className={style.productSizeSelect}>
                {
                  dataGender && dataGender.genders.length > 0 ? (
                    <FormControl variant="outlined" margin="normal" size="small" fullWidth>
                      <Select
                        value={genderFormValues.gender !== undefined && genderFormValues !== null ? genderFormValues.gender : dataProduct.productAdmin.gender !== null ? dataProduct.productAdmin.gender.id : ""}
                        onChange={onChangeGender}
                        variant="outlined"
                        name="gender"
                      >
                        <MenuItem value={null}><em>Nessuno</em></MenuItem>
                        {
                          dataGender.genders.map((item) => {
                            return (
                              <MenuItem value={item.id} key={`${item.id}-gender`}>{item.name}</MenuItem>
                            );
                          })
                        }
                      </Select>
                    </FormControl>
                  ) : (
                    <em>Non ci sono generi.</em>
                  )
                }
              </div>
              <div>
                <Typography variant="subtitle1" className={style.productDescription}>Finezza</Typography>
              </div>
              <div className={style.productSizeSelect}>
                {
                  dataFineness && dataFineness.fineness.length > 0 ? (
                    <FormControl variant="outlined" margin="normal" size="small" fullWidth>
                      <Select
                        value={finenessFormValues.fineness !== undefined && finenessFormValues !== null ? finenessFormValues.fineness : dataProduct.productAdmin.fineness !== null ? dataProduct.productAdmin.fineness.id : ""}
                        onChange={onChangeFineness}
                        variant="outlined"
                        name="fineness"
                      >
                        <MenuItem value={null}><em>Nessuno</em></MenuItem>
                        {
                          dataFineness.fineness.map((item) => {
                            return (
                              <MenuItem value={item.id} key={`${item.id}-fineness`}>{item.name}</MenuItem>
                            );
                          })
                        }
                      </Select>
                    </FormControl>
                  ) : (
                    <em>Non ci sono finezze.</em>
                  )
                }
              </div>
              <div>
                <Typography variant="subtitle1" className={style.productDescription}>Stagione</Typography>
              </div>
              <div className={style.productSizeSelect}>
                {
                  dataSeason && dataSeason.seasons.length > 0 ? (
                    <FormControl variant="outlined" margin="normal" size="small" fullWidth>
                      <Select
                        value={seasonFormValues.season !== undefined && seasonFormValues !== null ? seasonFormValues.season : dataProduct.productAdmin.season !== null ? dataProduct.productAdmin.season.id : ""}
                        onChange={onChangeSeason}
                        variant="outlined"
                        name="season"
                      >
                        <MenuItem value={null}><em>Nessuno</em></MenuItem>
                        {
                          dataSeason.seasons.map((item) => {
                            return (
                              <MenuItem value={item.id} key={`${item.id}-season`}>{item.name}</MenuItem>
                            );
                          })
                        }
                      </Select>
                    </FormControl>
                  ) : (
                    <em>Non ci sono stagioni.</em>
                  )
                }
              </div>
              <div>
                <Typography variant="subtitle1" className={style.productDescription}>Tags</Typography>
              </div>
              <div className={style.productSizeSelect}>
                <Grid container xs={12}>
                </Grid>
                {
                  dataTag && dataTag.tags.length > 0 ? (
                    <MultiSelect
                      options={tagOptions}
                      onChange={onChangeTag}
                      defaultValue={defaultTagOptions}
                      isMulti={true}
                    />
                  ) : (
                    <em>Non ci sono tag</em>
                  )
                }
              </div>
              <div>
                <Typography variant="subtitle1" className={style.productDescription}>Composizione</Typography>
              </div>
              <div className={style.productSizeSelect}>
                {
                  dataComposition && dataComposition.compositions.length > 0 ? (
                    <MultiSelect
                      options={compositionOptions}
                      onChange={onChangeComposition}
                      defaultValue={defaultCompositionOptions} dataSeason
                      isMulti={true}
                    />
                  ) : (
                    <em>Non ci sono composizioni</em>
                  )
                }
              </div>
              <Divider style={{ marginTop: "1rem" }} />
              <Grid item container xs={12}>
                <Typography variant="h6" className={style.productPrice}>Listini Prezzo</Typography>
              </Grid>
              <div className={style.productSizeSelect}>
                <FormControl variant="outlined" margin="dense" size="small" fullWidth>
                  <Select
                    value={editProductFormValues.type !== undefined ? parseInt(editProductFormValues.type) : parseInt(dataProduct.productAdmin.type)}
                    onChange={onChangeRadioSeason}
                    variant="outlined"
                    name="type"
                  >
                    <MenuItem value={0}>Estivo</MenuItem>
                    <MenuItem value={1}>Invernale</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <Grid container xm={12}>
                {
                  editProductFormValues.type === undefined || editProductFormValues.type === dataProduct.productAdmin.type ? (
                    dataAllPriceOfProduct.allPriceOfProduct.map((item, index) => {
                      let type = editProductFormValues.type !== undefined ? editProductFormValues.type : dataProduct.productAdmin.type;
                      if (item.type === parseInt(type)) {
                        return (
                          <Grid item sm={12} md={6} key={index + "bab1"}>
                            <Typography variant="subtitle1" className={style.productDescription}>{item.name}</Typography>
                            <div className={style.productDescriptionText}>
                              <TextField
                                error={errorUpdateProductPriceListEmpty}
                                type="number"
                                variant="outlined"
                                required
                                style={{ width: "100%" }}
                                name={item.id}
                                size="small"
                                value={editPriceListFormValues !== undefined && editPriceListFormValues[item.id] !== undefined ? editPriceListFormValues[item.id] : item.product_price}
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">€ </InputAdornment>,
                                }}
                                onChange={onChangePriceList}
                              />
                            </div>
                          </Grid>
                        );
                      } else {
                        return <></>;
                      }
                    })

                  ) : (
                    <>
                      <Typography align="center" color="error" varuabt="caption" paragraph="true" style={{ width: "100%", margin: "1rem" }}>
                        <b>ATTENZIONE</b> - Si sta modificando la stagionalità del prodotto
                      </Typography>
                      {
                        dataPriceLists.priceLists.map((item, index) => {
                          if (item.type === editProductFormValues.type) {
                            return (
                              <Grid item sm={12} md={6} key={index + "mn1"}>
                                <Typography variant="subtitle1" className={style.productDescription}>{item.name}</Typography>
                                <div className={style.productDescriptionText}>
                                  <TextField
                                    error={errorUpdateProductPriceListEmpty}
                                    type="number"
                                    variant="outlined"
                                    required
                                    style={{ width: "100%" }}
                                    name={item.id}
                                    size="small"
                                    value={editPriceListFormValues === undefined ? "" : editPriceListFormValues[item.id]}
                                    InputProps={{
                                      startAdornment: <InputAdornment position="start">€ </InputAdornment>,
                                    }}
                                    onChange={onChangePriceList}
                                  />
                                </div>
                              </Grid>
                            );
                          }
                        })
                      }
                    </>
                  )
                }
                <Grid item container xs={12}>
                  <Grid item xs={12} >
                    <Typography variant="subtitle1" className={style.productDescription} fullWidth>SCONTISTICA</Typography>
                  </Grid>
                  <Grid className={style.productDescription} item container sm={12} alignItems="flex-start" direction="row" style={{ alignItems: "center" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={discountBaseColor}
                          onChange={(e) => onChangeDiscountBaseColor(e.target.checked)}
                          name="colorDiscount"
                          color="primary"
                          value="colorDiscount"
                        />
                      }
                      label="SCONTISTICA IN BASE AL COLORE"
                    />
                  </Grid>
                  {!discountBaseColor &&
                    // <>
                    //   {dataColorFolder &&
                    //     dataColorFolder.colorsFolder.length > 0 &&
                    //     colorFolderFormValues.colorFolder !== undefined &&
                    //     colorFolderFormValues.colorFolder !== 0 && dataColorFolder.colorsFolder[dataColorFolder.colorsFolder.findIndex(item => item.id === colorFolderFormValues.colorFolder)]?.colors.length > 0 ?
                    //     <>
                    //       <Grid item sm={12} md={6}>
                    //         <Typography variant="subtitle1" className={style.productSubDescription}>SELEZIONA COLORE</Typography>
                    //         <div className={style.productDescriptionText}>
                    //           <FormControl variant="outlined" margin="none" size="small" fullWidth>
                    //             <Select
                    //               value={colorDiscountFormValues.colorDiscountId ?? ""}
                    //               onChange={onChangeColorDiscountFormValues}
                    //               variant="outlined"
                    //               name="colorDiscountId"
                    //             >
                    //               <MenuItem value={0}><em>Nessuno</em></MenuItem>
                    //               {
                    //                 dataColors.colors.filter(element => element.colorFolder.id === colorFolderFormValues.colorFolder).map((color, index) => {
                    //                   for (var key in outOfStock) {
                    //                     if (outOfStock.hasOwnProperty(key) && key.split("-")[1] === color.id) {
                    //                       return (
                    //                         <MenuItem value={color.id} key={`${color.id}`}>{color.hex}</MenuItem>
                    //                       );
                    //                     }
                    //                   }

                    //                 })
                    //               }
                    //             </Select>
                    //           </FormControl>
                    //         </div>
                    //       </Grid>
                    //       <Grid item sm={12} md={3}>
                    //         <Typography variant="subtitle1" className={style.productSubDescription}>SCONTO IN PERCENTUALE</Typography>
                    //         <div className={style.productDescriptionText}>
                    //           <TextField
                    //             //error={errorUpdateProductPriceListEmpty}
                    //             type="number"
                    //             variant="outlined"
                    //             required
                    //             style={{ width: "100%" }}
                    //             name="colorDiscountPercentage"
                    //             size="small"
                    //             value={colorDiscountFormValues.colorDiscountPercentage ?? 0}
                    //             InputProps={{
                    //               startAdornment: <InputAdornment position="start">% </InputAdornment>,
                    //             }}
                    //             onChange={onChangeColorDiscountFormValues}
                    //           />
                    //         </div>
                    //       </Grid>
                    //       <Grid item container sm={12} md={3} alignItems="end" className={style.addColorDiscountBtnWrapper}>
                    //         <Button
                    //           variant="contained"
                    //           disabled={!colorDiscountFormValues?.colorDiscountId || !colorDiscountFormValues?.colorDiscountPercentage}
                    //           onClick={() => onAddColorDiscountFormValues()}>
                    //           Aggiungi
                    //         </Button>
                    //       </Grid>
                    //     </>
                    //     : <>Non ci sono colori</>
                    //   }
                    //   {discountColors && Object.keys(discountColors).length > 0 &&
                    //     <>
                    //       <Grid
                    //         item
                    //         container
                    //         alignItems="center"
                    //         xs={12}
                    //         style={{ border: "1px solid lightgray", margin: "1rem 1rem 0" }}
                    //         className={style.productDescription}>
                    //         <Grid item xs={7} >COLORE</Grid>
                    //         <Grid item xs={3} >SCONTO</Grid>
                    //         <Grid item xs={2}></Grid>
                    //       </Grid>
                    //       {Object.keys(discountColors).map((key, index) => {
                    //         const color = dataColors.colors.find(color => parseInt(color.id) === parseInt(key));
                    //         if (color) {
                    //           return (
                    //             <Grid
                    //               item
                    //               container
                    //               alignItems="center"
                    //               xs={12}
                    //               style={{ border: "1px solid lightgray", margin: "0 1rem", BorderTop: 0 }}
                    //               className={style.productDescription}>
                    //               <Grid item xs={7}>{color.hex}</Grid>
                    //               <Grid item xs={3}>{discountColors[key]}%</Grid>
                    //               <Grid item xs={2}>
                    //                 <IconButton onClick={() => onRemoveColorDiscount(key)}>
                    //                   <CloseIcon color="primary" fontSize={"small"} />
                    //                 </IconButton>
                    //               </Grid>
                    //             </Grid>
                    //           );
                    //         }
                    //       })}
                    //     </>
                    //   }
                    // </>

                    <>
                      <Grid item sm={12} md={6}>
                        <Typography variant="subtitle1" className={style.productSubDescription}>PREZZO IN SALDO</Typography>
                        <div className={style.productDescriptionText}>
                          <TextField
                            //error={errorUpdateProductPriceListEmpty}
                            type="number"
                            variant="outlined"
                            required
                            style={{ width: "100%" }}
                            name="sale"
                            size="small"
                            value={editProductFormValues.sale !== undefined ? editProductFormValues.sale : dataProduct.productAdmin.sale}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">€ </InputAdornment>,
                            }}
                            onChange={onChangeSaleFormValues}
                          />
                        </div>
                      </Grid>
                      <Grid item sm={12} md={6}>
                        <Typography variant="subtitle1" className={style.productSubDescription}>SCONTO IN PERCENTUALE</Typography>
                        <div className={style.productDescriptionText}>
                          <TextField
                            //error={errorUpdateProductPriceListEmpty}
                            type="number"
                            variant="outlined"
                            required
                            style={{ width: "100%" }}
                            name="percentageDiscount"
                            size="small"
                            value={editProductFormValues.percentageDiscount !== undefined ? editProductFormValues.percentageDiscount : dataProduct.productAdmin.percentage_discount}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">% </InputAdornment>,
                            }}
                            onChange={onChangePercentageDiscountFormValues}
                          />
                        </div>
                      </Grid>
                    </>
                  }
                </Grid>
                {
                  errorUpdateProductPriceListEmpty ? (
                    <Typography align="center" color="error" varuabt="caption" paragraph="true" style={{ width: "100%", margin: "1rem" }}>
                      Inserire almeno il prezzo per un listino
                    </Typography>
                  ) : (
                    <></>
                  )
                }
              </Grid>
              <Divider style={{ marginTop: "1rem" }} />
              <Grid className={style.buttonActionContainer} container direction="row" justify="center" alignItems="center" spacing={2}>
                <Grid item >
                  <Button
                    onClick={() => window.history.back()}
                    variant="contained"
                    size="medium"
                    color="primary"
                  >
                    Annulla
                  </Button>
                </Grid>
                <Grid item >
                  <Button
                    onClick={() => onSaveProduct(groupSizeFormValues.groupSize, colorFolderFormValues.colorFolder)}
                    //disabled={disabledSaveEditUser}
                    variant="contained"
                    size="medium"
                    color="primary"
                  >
                    Salva
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
      <Modal
        open={openModalConfirm}
      //onClose={onCloseModalConfirmDeleteAllColor}
      >
        <Grid container item xs={11} sm={11} md={5} lg={5} xl={5} className={style.paperModal}>
          <IconButton className={style.modalButtonCloseIcon}>
            <CloseIcon className={style.modalCloseIcon} fontSize={"large"} onClick={() => window.location.pathname = "/admin/products"} />
          </IconButton>
          <Grid container xs={12} direction="row" justify="center" alignItems="center" >
            <Typography variant="h5" color="primary" align="center" gutterBottom >
              Prodotto salvato con successo.
            </Typography>
          </Grid>
          <Grid container xs={12} direction="row" justify="center" alignItems="center" >
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={() => window.location.pathname = "/admin/products"}
            >
              Avanti
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default Layout;
