/* eslint-disable no-undef */
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { ME } from "../../../query";
import { UPDATE_PASSWORD } from "../../../mutation";
import { useQuery, useMutation } from "@apollo/react-hooks";
import LinearProgress from "@material-ui/core/LinearProgress";
import Layout from "./layout";

const MyAccount = ({ history, match }) => {
  const [passwordFormValues, setPasswordFormValues] = useState({
    password: "",
    passwordConfirmation: "",
  });
  const [successUpdatePassword, setSuccessUpdatePassword] = useState(false);
  const [updatePassword] = useMutation(UPDATE_PASSWORD);
  const { loading: loadingMe, error: errorMe, data: dataMe } = useQuery(ME);

  const onChangePasswordForm = event => {
    if(event.target.name === "password"){
      setPasswordFormValues({...passwordFormValues, password: event.target.value});
    }
    if(event.target.name === "passwordConfirmation"){
      setPasswordFormValues({...passwordFormValues, passwordConfirmation: event.target.value});
    }
  };

  const onUpdatePassword = () => {
    updatePassword({
      variables: { password: passwordFormValues["password"], password_confirmation: passwordFormValues["passwordConfirmation"]},
    }).then(response => {
      if(response.data.updatePassword.status === 1){
        setSuccessUpdatePassword(true);
        setPasswordFormValues({
          password: "",
          passwordConfirmation: "",
        });
      }else if(response.data.updatePassword.status === 1){
        setSuccessUpdatePassword(false);
      }
    });
  };

  const closeSuccessUpdatePassword = () =>{
    setSuccessUpdatePassword(false);
  };

  const onGoToShop = () =>{
    history.push("/shop");
  };

  const onGoToOrders = () =>{
    history.push("/orders");
  };

  const onGoToFavorites = () =>{
    history.push("/favorites");
  };

  if(loadingMe){
    return <LinearProgress />;
  }

  if(errorMe){
    return console.log("err");
  }

  return (
    <Layout
      dataMe={dataMe}
      onChangePasswordForm={onChangePasswordForm}
      passwordFormValues={passwordFormValues}
      onUpdatePassword={onUpdatePassword}
      successUpdatePassword={successUpdatePassword}
      closeSuccessUpdatePassword={closeSuccessUpdatePassword}
      onGoToShop={onGoToShop}
      onGoToOrders={onGoToOrders}
      onGoToFavorites={onGoToFavorites}
    />
  );
};

export default withRouter(MyAccount);
