/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { ME, GET_SALE_PRODUCTS, MY_FAVORITES } from "../../../query";
import { useQuery } from "@apollo/react-hooks";
import { useMutation } from "@apollo/react-hooks";
import { ADD_REMOVE_TO_FAVORITES } from "../../../mutation";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import Layout from "./layout";
import Style from "./layout/style";

const Sale = ({ history, match }) => {
  const style = Style();

  const [addRemoveToFavorites] = useMutation(ADD_REMOVE_TO_FAVORITES);
  const userFavorites = [];

  const { loading: loadingMe, error: errorMe, data: dataMe } = useQuery(ME);
  // import only for refetch query myFavorites
  const { loading: loadingMyFavorites, error: errorMyFavorites, data: dataMyFavorites } = useQuery(MY_FAVORITES);

  const { loading: loadingSaleProducts, error: errorSaleProducts, data: dataSaleProducts } = useQuery(GET_SALE_PRODUCTS);

  const onFavorite = productId => {
    addRemoveToFavorites({
      variables: { product_id: productId },
      refetchQueries: ["me", "myFavorites"],
    });
  };

  const onViewProduct = productId => {
    history.push("/product/" + productId);
  };

  const onScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Verify params in url
  if (loadingMe || loadingSaleProducts || loadingMyFavorites){
    return (
      <>
        <div className={style.productsSkeleton} style={{ marginBottom: "-3rem"}} >
          <Skeleton className={style.skeleton} variant="rect" height={419.5} />
          <Skeleton className={style.skeleton} variant="rect" height={419.5} />
          <Skeleton className={style.skeleton} variant="rect" height={419.5} />
          <Skeleton className={style.skeleton} variant="rect" height={419.5} />
        </div>
      </>
    );
  }

  if (errorMe || errorSaleProducts || errorMyFavorites) {
    console.log("error");
  }

  // prepare the array with user favorites
  dataMe.me.favorites.map((item) => (
    userFavorites.push(item.product.id)
  ));

    return(
    <Layout
      dataMe={dataMe}
      dataSaleProducts={dataSaleProducts}
      dataMe={dataMe}
      userFavorites={userFavorites}
      onViewProduct={onViewProduct}
      onScrollToTop={onScrollToTop}
      onFavorite={onFavorite}
    />
    )
};

export default withRouter(Sale);
