import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Style from "./style.js";

const Layout  = ({
  dataGenders,
  dataSeasons,
  dataFineness,
  dataTags,
  dataCompositions,
  onAddGender,
  onAddSeason,
  onAddFineness,
  onAddTag,
  onAddComposition,
  onOpenEditGender,
  onOpenEditSeason,
  onOpenEditFineness,
  onOpenEditTag,
  onOpenEditComposition,
  onCloseEditGender,
  onCloseEditSeason,
  onCloseEditFineness,
  onCloseEditTag,
  onCloseEditComposition,
  openEditGender,
  openEditSeason,
  openEditFineness,
  openEditTag,
  openEditComposition,
  editGenderFormValues,
  editSeasonFormValues,
  editFinenessFormValues,
  editTagFormValues,
  editCompositionFormValues,
  onChangeEditGender,
  onChangeEditSeason,
  onChangeEditFineness,
  onChangeEditTag,
  onChangeEditComposition,
  saveEditGender,
  saveEditSeason,
  saveEditFineness,
  saveEditTag,
  saveEditComposition,
  onSaveUpdateGender,
  onSaveUpdateSeason,
  onSaveUpdateFineness,
  onSaveUpdateTag,
  onSaveUpdateComposition,
  addCategoriesFormValues,
  onChangeAddCategories,
  onChangeCheckboxGender,
  onChangeCheckboxSeason,
  onChangeCheckboxFineness,
  onChangeCheckboxTag,
  onChangeCheckboxComposition,
  checkboxStateGender,
  checkboxStateSeason,
  checkboxStateFineness,
  checkboxStateTag,
  checkboxStateComposition,
  onRemoveGenders,
  onRemoveSeasons,
  onRemoveFineness,
  onRemoveTags,
  onRemoveCompositions,
  loadingGender,
  loadingSeason,
  loadingFineness,
  loadingTag,
  loadingComposition,
  errorAddGender,
  errorAddSeason,
  errorAddFineness,
  errorAddTag,
  errorAddComposition,
  errorRemoveGender,
  errorRemoveSeason,
  errorRemoveFineness,
  errorRemoveTag,
  errorRemoveComposition,
  errorUpdateGender,
  errorUpdateSeason,
  errorUpdateFineness,
  errorUpdateTag,
  errorUpdateComposition,
  expanded,
  onChangeAccordition,
  loadingRemoveGender,
  loadingRemoveSeason,
  loadingRemoveFineness,
  loadingRemoveComposition,
  loadingRemoveTag,
}) => {
  const style = Style();
  return (
    <>
      <Grid xl={12}>
        <Typography variant="h4" className={style.title}>
          Categorie
        </Typography>
          <Accordion expanded={expanded === 'panel1'} onChange={onChangeAccordition('panel1')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography className={style.heading}>Genere</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item xs={12}>
                <div className={style.wrapperMaxContentWidth}>
                  <TextField
                    error={errorAddGender}
                    onChange={onChangeAddCategories}
                    value={addCategoriesFormValues["gender"]}
                    name="gender"
                    helperText={errorAddGender ? "Errore verificare il nome e riprovare" : "Aggiungi nuovo genere"}
                    variant="outlined"
                    size="small"
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    onClick={onAddGender}
                    className={style.addNewCategoryButton}
                    disabled={addCategoriesFormValues["gender"] && addCategoriesFormValues["gender"] !== "" && addCategoriesFormValues['gender'] !== undefined ? false : true}
                  >
                    Aggiungi
                  </Button>
                  {
                    loadingRemoveGender || loadingGender ? ( <div className={style.wrapperLoadingRemove}><CircularProgress /></div> ) : (
                      dataGenders.genders.length > 0 ? (
                        dataGenders.genders.map((item, index) => {
                          return(
                            <Typography variant="body1" fullWidth className={style.rowCategoryItem}>
                              {
                                Object.values(openEditGender).indexOf(item.id) >= 0 ?
                                  <>
                                    <TextField
                                      key={index}
                                      error={errorUpdateGender}
                                      onChange={onChangeEditGender}
                                      value={editGenderFormValues["name"]}
                                      name={item.id}
                                      helperText={errorUpdateGender ? "Errore verificare il nome e riprovare" : "Modifica nome"}
                                      variant="outlined"
                                      size="small"
                                    />
                                    <IconButton key={index} color="primary" onClick={() => onCloseEditGender()}>
                                      <CloseIcon />
                                    </IconButton>
                                    { Object.values(saveEditGender).indexOf(item.id) >= 0 ? <Button variant="contained" color="primary" size="medium" onClick={onSaveUpdateGender}>Salva</Button> : <></> }
                                  </>
                                  :
                                  <>
                                    <FormControlLabel
                                      key={index}
                                      control={
                                        <Checkbox
                                          checked={Object.values(checkboxStateGender).indexOf(item.id) >= 0 ? true : false}
                                          name={item.name}
                                          color="primary"
                                          onChange={onChangeCheckboxGender}
                                          value={item.id}
                                        />
                                      }
                                      label={item.name}
                                    />
                                    <IconButton key={index} color="primary" onClick={() => onOpenEditGender(item.id, item.name)} className={style.buttonEditCategory}>
                                      <EditIcon />
                                    </IconButton>
                                  </>
                              }
                            </Typography>
                          );
                        })
                      ) : (
                        <div className={style.wrapperNoCategoryText}>
                          <em>Non ci sono categorie genere</em>
                        </div>
                      )
                    )
                }
              </div>
              {
                dataGenders.genders.length > 0 ? (
                  <Button
                    variant="contained"
                    size="medium"
                    onClick={onRemoveGenders}
                    disabled={Object.keys(checkboxStateGender).length > 0 ? false : true}
                  >
                    Rimuovi
                  </Button>
                ) : (
                  <></>
                )
              }
            </Grid>
          </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel2'} onChange={onChangeAccordition('panel2')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography className={style.heading}>Stagione</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item xs={12}>
                <div className={style.wrapperMaxContentWidth}>
                  <TextField
                    error={errorAddSeason}
                    onChange={onChangeAddCategories}
                    value={addCategoriesFormValues["season"]}
                    name="season"
                    helperText={errorAddSeason ? "Errore verificare il nome e riprovare" : "Aggiungi nuova stagione"}
                    variant="outlined"
                    size="small"
                  />
                  <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    onClick={onAddSeason}
                    className={style.addNewCategoryButton}
                    disabled={addCategoriesFormValues["season"] && addCategoriesFormValues["season"] !== "" && addCategoriesFormValues['season'] !== undefined ? false : true}
                  >
                    {loadingSeason ? <CircularProgress /> : "Aggiungi"}
                  </Button>
                  {
                    loadingRemoveSeason || loadingSeason ? ( <div className={style.wrapperLoadingRemove}><CircularProgress /></div> ) : (
                      dataSeasons.seasons.length > 0 ? (
                        dataSeasons.seasons.map((item, index) => {
                          return(
                            <Typography variant="body1" className={style.rowCategoryItem}>
                              {
                                Object.values(openEditSeason).indexOf(item.id) >= 0 ?
                                  <>
                                    <TextField
                                      key={index}
                                      error={errorUpdateSeason}
                                      onChange={onChangeEditSeason}
                                      value={editSeasonFormValues["name"]}
                                      name={item.id}
                                      helperText={errorUpdateSeason ? "Errore verificare il nome e riprovare" : "Modifica nome"}
                                      variant="outlined"
                                      size="small"
                                    />
                                    <IconButton key={index} color="primary" onClick={() => onCloseEditSeason()}>
                                      <CloseIcon />
                                    </IconButton>
                                    { Object.values(saveEditSeason).indexOf(item.id) >= 0 ? <Button variant="contained" color="primary" size="medium" onClick={onSaveUpdateSeason}>Salva</Button> : <></> }
                                  </>
                                  :
                                  <>
                                    <FormControlLabel
                                      key={index}
                                      control={
                                        <Checkbox
                                          checked={Object.values(checkboxStateSeason).indexOf(item.id) >= 0 ? true : false}
                                          name={item.name}
                                          color="primary"
                                          onChange={onChangeCheckboxSeason}
                                          value={item.id}
                                        />
                                      }
                                      label={item.name}
                                    />
                                    <IconButton key={index} color="primary" onClick={() => onOpenEditSeason(item.id, item.name)} className={style.buttonEditCategory}>
                                      <EditIcon />
                                    </IconButton>
                                  </>
                              }
                            </Typography>
                          );
                        })
                      ) : (
                        <div className={style.wrapperNoCategoryText}>
                          <em>Non ci sono categorie stagioni</em>
                        </div>
                      )
                    )
                  }
                  {
                    dataSeasons.seasons.length > 0 ? (
                      <Button
                        variant="contained"
                        size="medium"
                        onClick={onRemoveSeasons}
                        disabled={Object.keys(checkboxStateSeason).length > 0 ? false : true}
                      >
                        Rimuovi
                      </Button>
                    ) : (
                      <></>
                    )
                  }
                </div>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel3'} onChange={onChangeAccordition('panel3')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography className={style.heading}>Finezza</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item xs={12}>
                <div className={style.wrapperMaxContentWidth}>
                  <TextField
                    error={errorAddFineness}
                    onChange={onChangeAddCategories}
                    value={addCategoriesFormValues["fineness"]}
                    name="fineness"
                    helperText={errorAddFineness ? "Errore verificare il nome e riprovare" : "Aggiungi nuova finezza"}
                    variant="outlined"
                    size="small"
                  />
                  <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    onClick={onAddFineness}
                    className={style.addNewCategoryButton}
                    disabled={addCategoriesFormValues["fineness"] && addCategoriesFormValues["fineness"] !== "" && addCategoriesFormValues['fineness'] !== undefined ? false : true}

                  >
                    Aggiungi
                  </Button>
                  {
                    loadingRemoveFineness || loadingFineness ? ( <div className={style.wrapperLoadingRemove}><CircularProgress /></div> ) : (
                      dataFineness.fineness.length > 0 ? (
                        dataFineness.fineness.map((item, index) => {
                          return(
                            <Typography variant="body1" className={style.rowCategoryItem}>
                              {
                                Object.values(openEditFineness).indexOf(item.id) >= 0 ?
                                  <>
                                    <TextField
                                      key={index}
                                      error={errorUpdateFineness}
                                      onChange={onChangeEditFineness}
                                      value={editFinenessFormValues["name"]}
                                      name={item.id}
                                      helperText={errorUpdateFineness ? "Errore verificare il nome e riprovare" : "Modifica nome"}
                                      variant="outlined"
                                      size="small"
                                    />
                                    <IconButton key={index} color="primary" onClick={() => onCloseEditFineness()}>
                                      <CloseIcon />
                                    </IconButton>
                                    { Object.values(saveEditFineness).indexOf(item.id) >= 0 ? <Button variant="contained" color="primary" size="medium" onClick={onSaveUpdateFineness}>Salva</Button> : <></> }
                                  </>
                                  :
                                  <>
                                    <FormControlLabel
                                      key={index}
                                      control={
                                        <Checkbox
                                          checked={Object.values(checkboxStateFineness).indexOf(item.id) >= 0 ? true : false}
                                          name={item.name}
                                          color="primary"
                                          onChange={onChangeCheckboxFineness}
                                          value={item.id}
                                        />
                                      }
                                      label={item.name}
                                    />
                                    <IconButton key={index} color="primary" onClick={() => onOpenEditFineness(item.id, item.name)} className={style.buttonEditCategory}>
                                      <EditIcon />
                                    </IconButton>
                                  </>
                              }
                            </Typography>
                          );
                        })
                      ) : (
                        <div className={style.wrapperNoCategoryText}>
                          <em>Non ci sono categorie finezze</em>
                        </div>
                      )
                    )
                  }
                  {
                    dataFineness.fineness.length > 0 ? (
                      <Button
                        variant="contained"
                        size="medium"
                        onClick={onRemoveFineness}
                        disabled={Object.keys(checkboxStateFineness).length > 0 ? false : true}
                      >
                        Aggiungi
                      </Button>
                    ) : (
                      <></>
                    )
                  }
                </div>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel4'} onChange={onChangeAccordition('panel4')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography className={style.heading}>Composizione</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item xs={12}>
                <div className={style.wrapperMaxContentWidth}>
                  <TextField
                    error={errorAddComposition}
                    onChange={onChangeAddCategories}
                    value={addCategoriesFormValues["composition"]}
                    name="composition"
                    helperText={errorAddFineness ? "Errore verificare il nome e riprovare" : "Aggiungi nuova composizione"}
                    variant="outlined"
                    size="small"
                  />
                  <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    onClick={onAddComposition}
                    className={style.addNewCategoryButton}
                    disabled={addCategoriesFormValues["composition"] && addCategoriesFormValues["composition"] !== "" && addCategoriesFormValues['composition'] !== undefined ? false : true}

                  >
                    Aggiungi
                  </Button>
                  {
                    loadingRemoveComposition ? ( <div className={style.wrapperLoadingRemove}><CircularProgress /></div> ) : (
                      dataCompositions.compositions.length > 0 ? (
                        dataCompositions.compositions.map((item, index) => {
                          return(
                            <Typography variant="body1" className={style.rowCategoryItem}>
                              {
                                Object.values(openEditComposition).indexOf(item.id) >= 0 ?
                                  <>
                                    <TextField
                                      key={index}
                                      error={errorUpdateComposition}
                                      onChange={onChangeEditComposition}
                                      value={editCompositionFormValues["name"]}
                                      name={item.id}
                                      helperText={errorUpdateComposition ? "Errore verificare il nome e riprovare" : "Modifica nome"}
                                      variant="outlined"
                                      size="small"
                                    />
                                    <IconButton key={index} color="primary" onClick={() => onCloseEditComposition()}>
                                      <CloseIcon />
                                    </IconButton>
                                    { Object.values(saveEditComposition).indexOf(item.id) >= 0 ? <Button variant="contained" color="primary" size="medium" onClick={onSaveUpdateComposition}>Salva</Button> : <></> }
                                  </>
                                  :
                                  <>
                                    <FormControlLabel
                                      key={index}
                                      control={
                                        <Checkbox
                                          checked={Object.values(checkboxStateComposition).indexOf(item.id) >= 0 ? true : false}
                                          name={item.name}
                                          color="primary"
                                          onChange={onChangeCheckboxComposition}
                                          value={item.id}
                                        />
                                      }
                                      label={item.name}
                                    />
                                    <IconButton key={index} color="primary" onClick={() => onOpenEditComposition(item.id, item.name)} className={style.buttonEditCategory}>
                                      <EditIcon />
                                    </IconButton>
                                  </>
                              }
                            </Typography>
                          );
                        })
                      ) : (
                        <div className={style.wrapperNoCategoryText}>
                          <em>Non ci sono categorie composizione</em>
                        </div>
                      )
                    )
                  }
                  {
                    dataCompositions.compositions.length > 0 ? (
                      <Button
                        variant="contained"
                        size="medium"
                        onClick={onRemoveCompositions}
                        disabled={Object.keys(checkboxStateComposition).length > 0 ? false : true}

                      >
                        Aggiungi
                      </Button>
                    ) : (
                      <></>
                    )
                  }
                </div>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel5'} onChange={onChangeAccordition('panel5')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography className={style.heading}>Tag</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container item xs={12}>
                <div className={style.wrapperMaxContentWidth}>
                  <TextField
                    error={errorAddTag}
                    onChange={onChangeAddCategories}
                    value={addCategoriesFormValues["tag"]}
                    name="tag"
                    helperText={errorAddFineness ? "Errore verificare il nome e riprovare" : "Aggiungi nuovo tag"}
                    variant="outlined"
                    size="small"
                  />
                  <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    onClick={onAddTag}
                    className={style.addNewCategoryButton}
                    disabled={addCategoriesFormValues["tag"] && addCategoriesFormValues["tag"] !== "" && addCategoriesFormValues['tag'] !== undefined ? false : true}
                  >
                    Aggiungi
                  </Button>
                  {
                    loadingRemoveTag || loadingTag ? ( <div className={style.wrapperLoadingRemove}><CircularProgress /></div> ) : (
                      dataTags.tags.length > 0 ? (
                        dataTags.tags.map((item, index) => {
                          return(
                            <Typography variant="body1" className={style.rowCategoryItem}>
                              {
                                Object.values(openEditTag).indexOf(item.id) >= 0 ?
                                  <>
                                    <TextField
                                      key={index}
                                      error={errorUpdateTag}
                                      onChange={onChangeEditTag}
                                      value={editTagFormValues["name"]}
                                      name={item.id}
                                      helperText={errorUpdateTag ? "Errore verificare il nome e riprovare" : "Modifica nome"}
                                      variant="outlined"
                                      size="small"
                                    />
                                    <IconButton key={index} color="primary" onClick={() => onCloseEditTag()}>
                                      <CloseIcon />
                                    </IconButton>
                                    { Object.values(saveEditTag).indexOf(item.id) >= 0 ? <Button variant="contained" color="primary" size="medium" onClick={onSaveUpdateTag}>Salva</Button> : <></> }
                                  </>
                                  :
                                  <>
                                    <FormControlLabel
                                      key={index}
                                      control={
                                        <Checkbox
                                          checked={Object.values(checkboxStateTag).indexOf(item.id) >= 0 ? true : false}
                                          name={item.name}
                                          color="primary"
                                          onChange={onChangeCheckboxTag}
                                          value={item.id}
                                        />
                                      }
                                      label={item.name}
                                    />
                                    <IconButton key={index} color="primary" onClick={() => onOpenEditTag(item.id, item.name)} className={style.buttonEditCategory}>
                                      <EditIcon />
                                    </IconButton>
                                  </>
                              }
                            </Typography>
                          );
                        })
                      ) : (
                        <div className={style.wrapperNoCategoryText}>
                          <em>Non ci sono categorie tag</em>
                      </div>
                      )
                    )
                  }
                   {
                    dataTags.tags.length > 0 ? (
                      <Button
                        variant="contained"
                        size="medium"
                        onClick={onRemoveTags}
                        disabled={Object.keys(checkboxStateTag).length > 0 ? false : true}
                      >
                        Rimuovi
                      </Button>
                    ) : (
                      <></>
                    )
                  }
                </div>
              </Grid>
            </AccordionDetails>
          </Accordion>
      </Grid>
    </>
  );
};

export default Layout;
