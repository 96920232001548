import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isLoggedIn } from "../../utils";
import LayoutCustomer from "../layoutCustomer";

//Maintenance
//import Maintenance from "../../pages/maintenance/";

const PrivateRouteCustomer = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={props => (
      isLoggedIn() ?
        rest.path.includes("product-image") ?
          <Component {...props} />
          :
          <LayoutCustomer>
            <Component {...props}  />
          </LayoutCustomer>
        : <Redirect to="/" />
    )}
    />
  );
};

export default PrivateRouteCustomer;
