import React from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { withStyles } from "@material-ui/core/styles";
import Style from "./style.js";

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const downloadPDF = (id, vat_number) => {
  window.location.href = process.env.REACT_APP_BACKEND_PDF + id + "/" + vat_number;
};

const Layout = ({ dataOrderDetail, onGoToOrders, onGoToShop, dataMe }) => {
  const style = Style();
  var totalQuantity = 0;
  return (
    <>
      <div style={{ marginBottom: "1rem", marginTop: "1rem" }}>
        <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: "1rem" }}>
          <Typography color="inherit" className={style.breadCrumbsText} onClick={onGoToShop}>Shop</Typography>
          <Typography color="inherit" className={style.breadCrumbsText} onClick={onGoToOrders}>I Miei Ordini</Typography>
          <Typography color="textPrimary">Dettaglio Ordine #{dataOrderDetail && dataOrderDetail.myOrders[0] !== undefined ? dataOrderDetail.myOrders[0].order_id : null}</Typography>
        </Breadcrumbs>
        {
          dataOrderDetail && dataOrderDetail.myOrders.length > 0 ? (
            <>
              {
                dataOrderDetail.myOrders[0].status === "Confermato" && (
                  <Button
                    className={style.buttonAddNew}
                    variant="contained"
                    size="small"
                    color="primary"
                    style={{ marginBottom: "1rem", marginRight: "1rem" }}
                    onClick={() => window.open(dataOrderDetail.myOrders[0].pdf)}
                  >
                    Scarica Conferma Ordine
                  </Button>
                )
              }
              <Button
                className={style.buttonAddNew}
                variant="contained"
                size="small"
                color="primary"
                style={{ marginBottom: "1rem" }}
                onClick={() => downloadPDF(dataOrderDetail.myOrders[0].order_id, dataMe.me.vat_number)}
              >
                Scarica Riepilogo Ordine
              </Button>
              {
                dataOrderDetail.myOrders[0].status === "Evaso" && (
                  <Button
                    className={style.buttonAddNew}
                    variant="contained"
                    size="small"
                    color="primary"
                    style={{ marginBottom: "1rem" }}
                    onClick={() => window.open(dataOrderDetail.myOrders[0].pdf)}
                  >
                    Scarica PDF
                  </Button>
                )
              }
              {
                dataOrderDetail.myOrders[0].status === "In lavorazione" && dataOrderDetail.myOrders[0].pdf && (
                  <Button
                    className={style.buttonAddNew}
                    variant="contained"
                    size="small"
                    color="primary"
                    style={{ marginBottom: "1rem" }}
                    onClick={() => window.open(dataOrderDetail.myOrders[0].pdf)}
                  >
                    Scarica PDF
                  </Button>
                )
              }
              <TableContainer component={Paper} className={style.tableContainer}>
                <Table className={style.table} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left"><b>Immagine</b></StyledTableCell>
                      <StyledTableCell align="left"><b>Nome</b></StyledTableCell>
                      <StyledTableCell align="left"><b>Codice</b></StyledTableCell>
                      <StyledTableCell align="left"><b>Taglia</b></StyledTableCell>
                      <StyledTableCell align="left"><b>Colore</b></StyledTableCell>
                      <StyledTableCell align="left"><b>Codice Colore</b></StyledTableCell>
                      <StyledTableCell align="left"><b>Quantita</b></StyledTableCell>
                      <StyledTableCell align="left"><b>Prezzo Unitario</b></StyledTableCell>
                      <StyledTableCell align="left"><b>Sconto</b></StyledTableCell>
                      <StyledTableCell align="left"><b>Sub Totale</b></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      dataOrderDetail.myOrders.map((item, index) => {
                        totalQuantity = totalQuantity + item.quantity;
                        return (
                          <StyledTableRow key={index}>
                            <TableCell align="left"><Avatar variant="rounded" className={style.productImage} src={item.product_image}></Avatar></TableCell>
                            <TableCell align="left"><Typography className={style.productName}>
                              {item.product_name}{item.is_preorder_item === 1 ? <span style={{ fontSize: "8px" }}><br />PREORDER</span> : ""}
                            </Typography></TableCell>
                            <TableCell align="left">{item.product_code}</TableCell>
                            <TableCell align="left">{item.size}</TableCell>
                            <TableCell align="left"><Avatar variant="rounded" src={item.color_image}></Avatar></TableCell>
                            <TableCell align="left">{item.color_name}</TableCell>
                            <TableCell align="left">{item.quantity}</TableCell>
                            <TableCell align="left">€{item.discount && item.discount !== '' ? item.original_price : item.product_price}</TableCell>
                            <TableCell align="left">{item.discount && item.discount !== '' ? `${item.discount}%` : ""}</TableCell>
                            <TableCell align="left">€{item.sub_total}</TableCell>
                          </StyledTableRow>
                        )
                      })
                    }
                    <StyledTableRow>
                      <TableCell colSpan={7} />
                      <TableCell align="left"><b>Sub Totale</b></TableCell>
                      <TableCell align="left">€{(dataOrderDetail.myOrders.reduce((a, b) => a + b.sub_total, 0)).toFixed(2)}</TableCell>
                    </StyledTableRow>
                    {dataMe.me.vat_number_nationality === 0 &&
                      <StyledTableRow>
                        <TableCell colSpan={7} />
                        <TableCell align="left"><b>Iva</b></TableCell>
                        <TableCell align="left">€{(Number((dataOrderDetail.myOrders.reduce((a, b) => a + b.total, 0)).toFixed(2)) - Number((dataOrderDetail.myOrders.reduce((a, b) => a + b.total, 0)).toFixed(2) / 1.22)).toFixed(2)}</TableCell>
                      </StyledTableRow>
                    }
                    <StyledTableRow>
                      <TableCell colSpan={7} />
                      <TableCell align="left"><b>Costo Totale</b></TableCell>
                      <TableCell align="left">€{(dataOrderDetail.myOrders.reduce((a, b) => a + b.total, 0)).toFixed(2)}</TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <TableCell colSpan={7} />
                      <TableCell align="left"><b>N. Pezzi</b></TableCell>
                      <TableCell align="left">{totalQuantity}</TableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <div style={{ marginTop: "10px" }}>
                <Typography variant="h6" gutterBottom>
                  Note:
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {dataOrderDetail.myOrders[0].note}
                </Typography>
              </div>

            </>
          ) : (<p>ordine inesistente</p>)
        }
      </div>
    </>
  );
};

export default Layout;
