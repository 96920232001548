import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  paperModal: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 4, 3),
    marginTop: theme.spacing(5),
    margin: 'auto',
  },
  modalButtonCloseIcon: {
    padding: 0,
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  modalActionContainer: {
    padding: theme.spacing(2, 0, 0),
  },
  sizeBySizeGroupWrapper: {
    margin: theme.spacing(4, 1, 0, 1),
    background: "#ffffff",
    border: "2px solid rgba(130,70,51,1)",
    color: "rgba(130,70,51,1)",
    fontWeight: "600",
    padding: "0.25rem 0.75rem !important",
    textAlign: "center",
    minWidth: "50px",
    cursor: "pointer",
  },
  sizeBySizeGroupWrapperClicked: {
    margin: theme.spacing(4, 1, 0, 1),
    background: "rgba(130,70,51,1)",
    border: "2px solid rgba(130,70,51,1)",
    color: "#ffffff",
    fontWeight: "600",
    padding: "0.25rem 0.75rem !important",
    textAlign: "center",
    minWidth: "50px",
    cursor: "pointer",
  },
  wrapperTitleSizeGroup: {
    background: "rgba(130,70,51,1)",
    borderRadius: "4px 4px 0 0",
    margin: theme.spacing(3, 0, 0),
    padding: theme.spacing(0.25, 1, 0.25),
    color: "#ffffff",
    fontSize: "18px",
  },
  wrapperSizes: {
    background: "rgba(0, 0, 0, 0.04)",
    margin: theme.spacing(0),
    padding: theme.spacing(0, 1, 2),
  },
  wrapperTextNoSizes: {
    margin: theme.spacing(2.5, 0, 0),
  },
  buttonAddNew :{
    margin: "1.2rem 1rem",
    [theme.breakpoints.down('xs')]: {
      margin: "0",
      width: "100%",
    },
  },
  textFieldAddNew: {
    [theme.breakpoints.down('xs')]: {
      width: "100%",
    },
  },
}));
