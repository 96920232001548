import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Style from "./style.js";

const Form = ({
  formValues,
  onChangeForm,
  errors,
  onPasswordResetChange,
  onGoToLogin,
  secondStep,
  tokenError,
}) => {
  const style = Style();
  return (
    <>
      <Container component="main" maxWidth="xs">
        <div className={style.paper}>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          {
            tokenError ? (
              <>
                <Typography variant="body2" color="textSecondary" align="center">
                  Il Token per ripristinare la tua password non è corretto.
                </Typography>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  className={style.submit}
                  onClick={onGoToLogin}
                >
                  Vai al Login
                </Button>
              </>
            ): (
              secondStep ? (
                <>
                  <Typography variant="body2" color="textSecondary" align="center">
                    Ottimo La tua password è stata ripristinata corretamente, ti è stata inviata una mail di conferma.
                  </Typography>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    className={style.submit}
                    onClick={onGoToLogin}
                  >
                    Vai al Login
                  </Button>
                </>
              ) : (
                <>
                  <TextField
                    className={style.textField}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Nuova Password"
                    type="password"
                    id="password"
                    value={formValues["password"]}
                    onChange={onChangeForm}
                  />
                  <TextField
                    className={style.textField}
                    error={errors ? true : false}
                    helperText={errors ? "Le password non corrispondono" : false}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password-confirmation"
                    label="Conferma Password"
                    type="password"
                    id="password-confirmation"
                    value={formValues["password-confirmation"]}
                    onChange={onChangeForm}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    className={style.submit}
                    onClick={onPasswordResetChange}
                  >
                    Conferma
                  </Button>
                </>
              )
            )
          }
        </div>
        <Box mt={8}>
          <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="https://material-ui.com/">
              Blu Cashmere
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Box>
      </Container>
    </>

  );
};

export default Form;
