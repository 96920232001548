import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionActions from "@material-ui/core/AccordionActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/Search";
import BackspaceIcon from "@material-ui/icons/Backspace";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ClearIcon from '@material-ui/icons/Clear';
import Style from "./style.js";
import { withStyles } from "@material-ui/core/styles";

const GreenCheckbox = withStyles({
  root: {
    "&$checked": {
      color: "green",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const YellowCheckbox = withStyles({
  root: {
    "&$checked": {
      color: "orange",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const VioletCheckbox = withStyles({
  root: {
    "&$checked": {
      color: "violet",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const productsNotFound = (style, onGoBack) => (
  <div className={style.paperNotFoundContent}>
    <p className={style.paperNotFoundText}>Opsss...</p>
    <span className={style.paperNotFoundEmoji} role="img" aria-label="emoji-thinking" aria-labelledby="emoji-thinking">🧐</span>
    <p style={{ marginBottom: "1.5rem" }}>Non ci sono prodotti per questa ricerca!</p>
    <Button
      className={style.resetFilterButton}
      size="small"
      variant="contained"
      color="secondary"
      onClick={onGoBack}
      startIcon={<BackspaceIcon />}
    >Torna indietro</Button>
  </div>
);

const productLoading = (style) => (
  <div className={style.productsSkeleton}>
    <Skeleton className={style.skeleton} variant="rect" height={419.5} />
    <Skeleton className={style.skeleton} variant="rect" height={419.5} />
    <Skeleton className={style.skeleton} variant="rect" height={419.5} />
    <Skeleton className={style.skeleton} variant="rect" height={419.5} />
  </div>
);

const Layout = ({
  formValues,
  onChangeSearch,
  onClickSearch,
  onChangeCheckboxGenders,
  onChangeCheckboxFineness,
  onChangeCheckboxSeason,
  onChangeCheckboxTag,
  onChangeCheckboxComposition,
  genderId,
  finenessId,
  seasonId,
  onResetFilter,
  dataProductsByCategory,
  loadingProductsByCategory,
  dataGenders,
  dataFineness,
  dataSeason,
  dataTag,
  dataComposition,
  productsBySearch,
  searchState,
  searchLoading,
  onGoBack,
  searchText,
  onFavorite,
  userFavorites,
  onViewProduct,
  onScrollToTop,
  searchOnHeader,
  resetHeaderSearch,
}) => {
  const style = Style();

  const [hidePrice, setHidePrice] = useState(localStorage.getItem("hidePricingAgent"));

  window.addEventListener('storage', () => {
    setHidePrice(!!localStorage.getItem("hidePricingAgent"));
  })

  return (
    <>
      <Grid container spacing={3} className={style.shop}>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={style.heading}>Filtri</Typography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails className={style.accordionDetails}>
              <div className={style.column}>
                <p className={style.columnTitle}>Genere</p>
                {
                  dataGenders ? (
                    dataGenders.genders.map((item, index) => {
                      return (
                        <div key={index + "genders"}>
                          <FormControlLabel
                            control={<Checkbox name={item.name} color="primary" checked={item.id === genderId ? true : false} onChange={() => onChangeCheckboxGenders(item.id)} />}
                            label={item.name}
                            labelPlacement="end"
                          />
                          <br />
                        </div>
                      );
                    })
                  ) : undefined
                }
              </div>
              <div key={"test" + 2} className={style.column} >
                <p className={style.columnTitle}>Stagione</p>
                {
                  dataSeason ? (
                    dataSeason.seasons.filter(item => !item.is_preorder).map((item, index) => {
                      if (!item.not_visible) {
                        return (
                          <div key={index + "seasons"}>
                            <FormControlLabel
                              control={<Checkbox name={item.name} color="secondary" checked={item.id === seasonId ? true : false} onChange={() => onChangeCheckboxSeason(item.id)} />}
                              label={item.name}
                              labelPlacement="end"
                            />
                            <br />
                          </div>
                        );
                      }
                    })
                  ) : undefined
                }
              </div>
              <div key={"test" + 3} className={style.column} >
                <p className={style.columnTitle}>Finezza</p>
                {
                  dataFineness ? (
                    dataFineness.fineness.map((item, index) => {
                      return (
                        <div key={index + "fineness"}>
                          <FormControlLabel
                            control={<GreenCheckbox name={item.name} color="primary" checked={item.id === finenessId ? true : false} onChange={() => onChangeCheckboxFineness(item.id)} />}
                            label={item.name}
                            labelPlacement="end"
                          />
                          <br />
                        </div>
                      );
                    })
                  ) : undefined
                }
              </div>
              <div key={"test" + 4} className={style.column} >
                <p className={style.columnTitle}>Composizione</p>
                {
                  dataComposition ? (
                    dataComposition.compositions.map((item, index) => {
                      return (
                        <div key={index + "compositions"}>
                          <FormControlLabel
                            control={<YellowCheckbox name={item.name} color="primary" checked={formValues[item.name] === true ? true : false} onChange={() => onChangeCheckboxComposition(item.id, item.name)} />}
                            label={item.name}
                            labelPlacement="end"
                          />
                          <br />
                        </div>
                      );
                    })
                  ) : undefined
                }
              </div>
              <div key={"test" + 5} className={style.column} >
                <p className={style.columnTitle}>Tag</p>
                {
                  dataTag ? (
                    dataTag.tags.map((item, index) => {
                      return (
                        <div key={index + "tags"}>
                          <FormControlLabel
                            control={<VioletCheckbox name={item.name} color="primary" checked={formValues[item.name] === true ? true : false} onChange={() => onChangeCheckboxTag(item.id, item.name)} />}
                            label={item.name}
                            labelPlacement="end"
                          />
                          <br />
                        </div>
                      );
                    })
                  ) : undefined
                }
              </div>
            </AccordionDetails>
            <Divider />
            <AccordionActions>
              <Button
                className={style.resetFilterButton}
                size="small"
                variant="contained"
                color="secondary"
                onClick={onResetFilter}
                startIcon={<BackspaceIcon />}
              >Resetta filtri</Button>
            </AccordionActions>
          </Accordion>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Paper component="form" className={style.paperSearch}>
            <InputBase
              className={style.input}
              placeholder="Cerca per nome o codice..."
              inputProps={{ "aria-label": "cerca dei prodottu" }}
              name={searchText}
              value={searchText}
              onChange={onChangeSearch}
              onKeyPress={event => { if (event.key === "Enter") onClickSearch(event); }}
            />
            {
              searchState || searchText !== "" ? (
                <IconButton onClick={resetHeaderSearch} className={style.iconButton} aria-label="search">
                  <ClearIcon />
                </IconButton>
              ) : (
                <IconButton onClick={event => onClickSearch(event)} className={style.iconButton} aria-label="search">
                  <SearchIcon />
                </IconButton>
              )
            }
          </Paper>
        </Grid>
        {
          searchState === true && searchOnHeader && (
            <Grid item xs={12} style={{ display: "ruby" }}>
              <div style={{ marginRight: "1rem", marginBottom: "1rem" }}>Stai ricercando per <b>{searchOnHeader}</b></div>
              <Button
                className={style.productButton}
                size="small"
                variant="contained"
                color="secondary"
                onClick={resetHeaderSearch}
                startIcon={<BackspaceIcon />}
              >Torna a tutti i prodotti</Button>
            </Grid>
          )
        }
      </Grid>
      <div className={style.productsContainer}>
        {
          searchState === true ? (
            searchLoading === true ? (productLoading(style)) : (
              productsBySearch && productsBySearch.length > 0 ? (
                <>
                  <div className={style.productsList}>
                    {
                      productsBySearch.map((item, index) => (
                        <Paper key={index + "searchProducts"} elevation={3} className={style.productCard}>
                          <img src={item.image} className={style.productImage} alt={item.name} onClick={() => onViewProduct(item.id)} />
                          {
                            item.stock ? (
                              <div className={style.stockWrapper}>
                                <span className={style.stockText}>Esaurito</span>
                              </div>
                            ) : (
                              <>
                                {item.bestseller === 1 && (
                                  <div className={style.bestsellerWrapper}>
                                    <span className={style.bestsellerText}>Made in Italy</span>
                                  </div>
                                )}
                                {item.luxury === 1 && (
                                  <div className={style.luxuryWrapper}>
                                  </div>
                                )}
                              </>
                            )
                          }
                          <div className={style.productNameContainer}>
                            <div className={style.productInfo}>
                              <p className={style.productName}>{item.name} {item.fineness ? item.fineness.name : null}</p>
                              <p className={style.productCode}>Cod: {item.code}</p>
                            </div>
                            {!hidePrice && (
                              item.sale && item.sale > 0 ?
                                (<div style={{ display: "flex" }}><p className={style.productPrice}>IN SALDO </p><p style={{ textDecoration: "line-through", margin: 0, marginRight: "1.2px" }}>€{item.price} </p> <b> €{item.sale}</b></div>)
                                : (item.percentage_discount && item.percentage_discount > 0 ?
                                  (<div style={{ display: "flex" }}><p className={style.productPrice}>SCONTO {item.percentage_discount}% </p><p style={{ textDecoration: "line-through", margin: 0, marginRight: "1.2px" }}>€{item.price} </p> <b> €{(item.price - item.price * (item.percentage_discount * 0.01)).toFixed(2)}</b></div>)
                                  : (item.discountColors ? (<div style={{ display: "flex", alignItems: "center" }}><p className={style.productPrice}>COLORI IN SCONTO </p><p>€{item.price}</p></div>) : <p>€{item.price}</p>))
                            )}
                          </div>
                          <div className={style.productFooter}>
                            <FormControlLabel
                              className={style.productHeart}
                              control={<Checkbox icon={<FavoriteBorder />} checkedIcon={<Favorite style={{ color: "#ff5b60" }} />} onClick={() => onFavorite(item.id)} checked={userFavorites.includes(item.id) ? true : false} />}
                            />
                            <Button
                              className={style.productButton}
                              size="small"
                              variant="contained"
                              color="primary"
                              onClick={() => onViewProduct(item.id)}
                              startIcon={<VisibilityIcon />}
                            >Visualizza</Button>
                          </div>
                        </Paper>
                      ))
                    }
                  </div>
                  <div className={style.scrollEndMessage}>
                    <p className={style.scrollEndMessageText}>Non ci sono altri prodotti da visualizzare</p>
                    <p><span className={style.paperNotFoundEmoji} role="img" aria-label="emoji-thinking" aria-labelledby="emoji-thinking">👀</span></p>
                    <Button
                      className={style.resetFilterButton}
                      size="small"
                      variant="contained"
                      color="secondary"
                      onClick={onScrollToTop}
                      startIcon={<ArrowUpwardIcon />}
                    >Torna in cima</Button>
                  </div>
                </>
              ) : (productsNotFound(style, onGoBack))
            )
          ) : (
            loadingProductsByCategory ? (productLoading(style)) : (
              dataProductsByCategory && dataProductsByCategory.getProductsByCategory.length > 0 ? (
                <>
                  <div className={style.productsList}>
                    {
                      dataProductsByCategory.getProductsByCategory.map((item, index) => (
                        <Paper key={index + "categoryProducts"} elevation={3} className={style.productCard}>
                          <img src={item.image} className={style.productImage} alt={item.name} onClick={() => onViewProduct(item.id)} />
                          {
                            item.stock ? (
                              <div className={style.stockWrapper}>
                                <span className={style.stockText}>Esaurito</span>
                              </div>
                            ) : (
                              <>
                                {item.bestseller === 1 && (
                                  <div className={style.bestsellerWrapper}>
                                    <span className={style.bestsellerText}>Made in Italy</span>
                                  </div>
                                )}
                                {item.luxury === 1 && (
                                  <div className={style.luxuryWrapper}>
                                  </div>
                                )}
                              </>
                            )
                          }
                          <div className={style.productNameContainer}>
                            <div className={style.productInfo}>
                              <p className={style.productName}>{item.name} {item.fineness ? item.fineness.name : null}</p>
                              <p className={style.productCode}>Cod: {item.code}</p>
                            </div>
                            {!hidePrice && (
                              item.sale && item.sale > 0 ?
                                (<div style={{ display: "flex" }}><p className={style.productPrice}>IN SALDO </p><p style={{ textDecoration: "line-through", margin: 0, marginRight: "1.2px" }}>€{item.price} </p> <b> €{item.sale}</b></div>)
                                : (item.percentage_discount && item.percentage_discount > 0 ?
                                  (<div style={{ display: "flex" }}><p className={style.productPrice}>SCONTO {item.percentage_discount}% </p><p style={{ textDecoration: "line-through", margin: 0, marginRight: "1.2px" }}>€{item.price} </p> <b> €{(item.price - item.price * (item.percentage_discount * 0.01)).toFixed(2)}</b></div>)
                                  : (item.discountColors ? (<div style={{ display: "flex", alignItems: "center" }}><p className={style.productPrice}>COLORI IN SCONTO </p><p>€{item.price}</p></div>) : <p>€{item.price}</p>))
                            )}
                          </div>
                          <div className={style.productFooter}>
                            <FormControlLabel
                              className={style.productHeart}
                              control={<Checkbox icon={<FavoriteBorder />} checkedIcon={<Favorite style={{ color: "#ff5b60" }} />} onClick={() => onFavorite(item.id)} checked={userFavorites.includes(item.id) ? true : false} />}
                            />
                            <Button
                              size="small"
                              variant="contained"
                              color="primary"
                              onClick={() => onViewProduct(item.id)}
                              startIcon={<VisibilityIcon />}
                            >Visualizza</Button>
                          </div>
                        </Paper>
                      ))
                    }
                  </div>
                  <div className={style.scrollEndMessage}>
                    <p className={style.scrollEndMessageText}>Non ci sono altri prodotti da visualizzare</p>
                    <p><span className={style.paperNotFoundEmoji} role="img" aria-label="emoji-thinking" aria-labelledby="emoji-thinking">👀</span></p>
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={onScrollToTop}
                      startIcon={<ArrowUpwardIcon />}
                    >Torna in cima</Button>
                  </div>
                </>
              ) : (productsNotFound(style, onGoBack))
            )
          )
        }
      </div>
      {/* {
        searchState === true && searchOnHeader && (
          <Grid item xs={12} style={{ display: "ruby" }}>
            <div style={{ marginRight: "1rem", marginBottom: "1rem" }}>Stai ricercando per <b>{searchOnHeader}</b></div>
            <Button
              className={style.productButton}
              size="small"
              variant="contained"
              color="secondary"
              onClick={resetHeaderSearch}
              startIcon={<BackspaceIcon />}
            >Torna a tutti i prodotti</Button>
          </Grid>
        )
      } */}
    </>
  );
};

export default Layout;
