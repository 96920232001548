import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  paperModal: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 4, 3),
    marginTop: theme.spacing(5),
    margin: 'auto',
  },
  modalButtonCloseIcon: {
    padding: 0,
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  modalActionContainer: {
    padding: theme.spacing(2, 0, 0),
  },
  loading: {
    textAlign: "center",
  },
  buttonAddNew :{
    margin: "1.2rem 1rem",
    [theme.breakpoints.down('xs')]: {
      margin: "0",
      width: "100%",
    },
  },
  textFieldAddNew: {
    [theme.breakpoints.down('xs')]: {
      width: "100%",
    },
  },
  buttonTypeWrapper: {
    display: "flex",
    width: "max-content",
    margin: "auto",
  }
}));
