import React from "react";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Style from "./style.js";

const Layout = ({
  dataMe,
  onChangePasswordForm,
  passwordFormValues,
  onUpdatePassword,
  successUpdatePassword,
  closeSuccessUpdatePassword,
  onGoToShop,
  onGoToOrders,
  onGoToFavorites,
}) => {
  const style = Style();
  let passwordError = false;

  if(passwordFormValues["password"] && passwordFormValues["passwordConfirmation"]){
    if(passwordFormValues["password"] === passwordFormValues["passwordConfirmation"]){
      passwordError = false;
    }else{
      passwordError = true;
    }
  }

  return (
    <div style={{ marginBottom: "1rem", marginTop: "1rem"}}>
      <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: "1rem"}}>
        <Typography color="inherit" className={style.breadCrumbsText} onClick={onGoToShop}>Shop</Typography>
        <Typography color="textPrimary">Il mio Account</Typography>
      </Breadcrumbs>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Paper elevation={3}>
            <div className={style.paperTitle}>
              <Typography variant="h6">Cambia Password</Typography>
            </div>

            {
              successUpdatePassword ? (
                <>
                  <div className={style.paperContent}>
                    <Typography variant="body2" style={{fontWeight:"bold", marginTop:"1rem"}}>Password modificata con successo</Typography>
                  </div>
                  <div className={style.paperFooter}>
                    <Button
                      className={style.buyButton}
                      size="medium"
                      variant="contained"
                      color="primary"
                      onClick={closeSuccessUpdatePassword}
                    >
                      Ho capito
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <div className={style.paperContent}>
                    <TextField
                      error={passwordError}
                      fullWidth
                      id="outlined-number"
                      size="small"
                      label="Password"
                      type="password"
                      name="password"
                      onChange={onChangePasswordForm}
                      value={passwordFormValues["password"]}
                      variant="outlined"
                    />
                    <TextField
                      error={passwordError}
                      style={{ marginTop: "1rem"}}
                      fullWidth
                      id="outlined-number"
                      size="small"
                      label="Conferma Password"
                      type="password"
                      name="passwordConfirmation"
                      onChange={onChangePasswordForm}
                      value={passwordFormValues["passwordConfirmation"]}
                      variant="outlined"
                    />
                  </div>
                  <div className={style.paperFooter}>
                    <Button
                      className={style.buyButton}
                      size="medium"
                      variant="contained"
                      color="primary"
                      disabled={passwordError === true  || passwordFormValues["password"] === "" || passwordFormValues["passwordConfirmation"] === "" ? true : false}
                      onClick={onUpdatePassword}
                    >
                      Cambia Password
                    </Button>
                  </div>
                </>
              )
            }

          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Paper elevation={3} style={{minHeight:"228.5px"}}>
            <div className={style.paperTitle}>
              <Typography variant="h6" >Il mio Account</Typography>
            </div>
            <div className={style.paperContent}>
              <Typography variant="body2" style={{fontWeight:"bold"}}>Email:</Typography>
              <Typography variant="body2">{dataMe.me.email}</Typography>
              <Typography variant="body2" style={{fontWeight:"bold", marginTop:"1rem"}}>Informazioni:</Typography>
              <Typography variant="body2" onClick={onGoToFavorites} className={style.infoText}>I miei Preferiti <b>{dataMe.me.favorites.length}</b></Typography>
              <Typography variant="body2" onClick={onGoToOrders} className={style.infoText}>I miei Ordini <b>{dataMe.me.orders.length}</b></Typography>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>

  );
};

export default Layout;
