/* eslint-disable array-callback-return */
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Layout from "./layout";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { useMutation } from "@apollo/react-hooks";
import { SORT_REGISTRATION_PERMISSION, PRICE_LIST, REGISTRATION } from "../../../query";
import { REMOVE_REGISTER, CREATE_REGISTER, EDIT_REGISTER } from "../../../mutation";
import LinearProgress from "@material-ui/core/LinearProgress";
import { FormatColorResetOutlined } from "@material-ui/icons";

const Permissions = ({ history }) => {
  // Error
  const [removeRegisterError, setRemoveRegisterError] = useState(false);
  const [registrationPermissionVatNumberError, setRegistrationPermissionVatNumberError] = useState(false);
  const [registrationPermissionCodeError, setRegistrationPermissionCodeError] = useState(false);

  // Search
  const [search, setSearch] = useState();

  // Loading
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [loadingRemoveAllPermission, setLoadingRemoveAllPermission] = useState(false);

  // Open Modal Edit User
  const [openModalAddNewRegistrationPermission, setOpenModalAddNewRegistrationPermission] = useState(false);
  const [openModalConfirmDeleteAllPermission, setOpenModalConfirmDeleteAllPermission] = useState(false);
  const [openModalEditRegistration, setOpenModalEditRegistration] = useState(false);

  // FormValues
  const [addRegistrationFormValues, setAddRegistrationFormValues] = useState({});
  const [editRegistrationFormValues, setEditRegistrationFormValues] = useState({
    permission_id: null,
    vatNumber: null,
    code: null,
    list_type_0: null,
    list_type_1: null,
  });
  const [checkboxMassiveDelete, setCheckboxMassiveDelete] = useState([]);
  const [allDeleteChecked, setAllDeleteChecked] = useState(false);

  const [sort, setSort] = useState(null);

  // REGISTRATION PERMISSION QUERY
  const { loading: loadingRegistrationPermission, error: errorRegistrationPermission, data: dataRegistrationPermission }= useQuery(SORT_REGISTRATION_PERMISSION, {
    variables: {
      sort: sort ? sort : "id-asc",
    },
  });

  const [registration, { loading: loadingRegistration, error: errorRegistration, data: dataRegistration }] = useLazyQuery(REGISTRATION, {
    fetchPolicy: "no-cache",
    onCompleted: (data => {
      setEditRegistrationFormValues({
        permission_id: data.registration.id,
        vatNumber: data.registration.vat_number,
        code: data.registration.code,
        list_type_0: data.registration.list_type_0,
        list_type_1: data.registration.list_type_1,
      })
    })
  })
  // PRICE LIST QUERY
  const { loading: loadingPriceLists, error: errorPriceLists, data: dataPriceLists } = useQuery(PRICE_LIST);
  // REGISTRATION PERMISSION MUTATION
  const [createRegister] = useMutation(CREATE_REGISTER);
  const [removeRegister] = useMutation(REMOVE_REGISTER);
  const [editRegister] = useMutation(EDIT_REGISTER);

  // MUTATION Add Registration permission
  const onAddRegistration = () => {
    setLoadingAdd(true);
    if( addRegistrationFormValues["vatNumber"] !== "" && addRegistrationFormValues["vatNumber"] !== undefined &&  /^[0-9a-zA-Z]+$/.test(addRegistrationFormValues["vatNumber"]) && addRegistrationFormValues["code"] !== "") {
      // Start the mutation for add Vat Number in registrations table for permission user registration
      createRegister({
        variables: {
          vatNumber: addRegistrationFormValues["vatNumber"],
          code: addRegistrationFormValues["code"],
          list_type_0: addRegistrationFormValues["list_type_0"],
          list_type_1: addRegistrationFormValues["list_type_1"],
        },
        refetchQueries: ["sortPermissionsAdmin"],
      }).then(response => {
        console.log(response);
        // If response of insertVatNumberPermission is 0 set error of vat number insert
        if(response.data.createRegister.status === 2){
          setRegistrationPermissionVatNumberError(true);
        }else if(response.data.createRegister.status === 0){
          setRegistrationPermissionCodeError(true);
        }else if(response.data.createRegister.status === 1){
          // else if user pass the insertVatNumberPermission with status 1 set error of vat number false
          setRegistrationPermissionVatNumberError(false);
          setRegistrationPermissionCodeError(false);
          setOpenModalAddNewRegistrationPermission(false);
          setAddRegistrationFormValues({vatNumber: "", list_type_0: "", list_type_1: "", code:""});
        }
      });
      setLoadingAdd(false);
    }else{
      setLoadingAdd(false);
    }
  };

  // MUTATION Remove Registration Permission by id
  const onRemoveRegistration = ($id) => {
    removeRegister({
      variables: { id: $id },
      refetchQueries: ["sortPermissionsAdmin"],
    }).then(response => {
      // If response of remove registration permission is 0 set error
      if(response.data.removeRegister.status === 0){
        setRemoveRegisterError(true);
      }else if(response.data.removeRegister.status === 1){
        setRemoveRegisterError(false);
        setCheckboxMassiveDelete([]);
        setAllDeleteChecked(false);
      }
    }).catch(err =>{
      // if get error of mutation set error of insert Vat number to true
      setRemoveRegisterError(true);
    });
  };

  // Mutation Delete List of permission
  const deleteAllSelected = () => {
    setLoadingRemoveAllPermission(true);
    checkboxMassiveDelete.map((item, index) => {
      removeRegister({
        variables: { id: item },
        refetchQueries: ["sortPermissionsAdmin"],
      }).then(response => {
        if(response.data.removeRegister.status === 0){
          setLoadingRemoveAllPermission(false);
        }else if(response.data.removeRegister.status === 1){
          if(index === checkboxMassiveDelete.length -1){
            setCheckboxMassiveDelete([]);
            setLoadingRemoveAllPermission(false);
            setAllDeleteChecked(false);
            setOpenModalConfirmDeleteAllPermission(false);
          }
        }
      }).catch(err =>{
        setLoadingRemoveAllPermission(false);
      });
    });
  };

  // onChange aggiungi nuovo permesso di registrazione
  const onChangeAddRegister = event => {
    const value = event.target.value;
    const name = event.target.name;
    setAddRegistrationFormValues({ ...addRegistrationFormValues, [name]: value });
    if(name === "vatNumber")setRegistrationPermissionVatNumberError(false);
    if(name === "code")setRegistrationPermissionCodeError(false);
  };
  // Apertura modale aggiungi nuovo permesso di registrazione
  const onOpenModalAddNewRegistrationPermission = () => {
    setOpenModalAddNewRegistrationPermission(true);
  };
  // Chiusura modale aggiungi nuovo permesso di registrazione
  const onCloseModalAddNewRegistrationPermission = () => {
    setOpenModalAddNewRegistrationPermission(false);
  };

  // Open Modal Confirm delete all selected permission
  const onOpenModalConfirmDeleteAllPermission = (item) => {
    setOpenModalConfirmDeleteAllPermission(true);
  };
  // Close Modal Confirm delete all selected permission
  const onCloseModalConfirmDeleteAllPermission = () => {
    setOpenModalConfirmDeleteAllPermission(false);
    setCheckboxMassiveDelete([]);
    setAllDeleteChecked(false);
  };

  // onChange Search
  const onChangeSearch = event => {
    setSearch(event.target.value);
  };

  const onChangeSort = event => {
    setSort(event.target.value);
  };

  // Verify query for registration permission
  if (loadingRegistrationPermission || loadingPriceLists || loadingRegistration) return <LinearProgress />;

  // onChange Massive Delete
  const onChangeMassiveDelete = event => {
    if (event.target.checked){
      if(event.target.name === "all") {
        setCheckboxMassiveDelete(undefined);
        setAllDeleteChecked(true);
        if(search && search !== null){
          dataRegistrationPermission.sortPermissionsAdmin.filter(item => item.vat_number.toLowerCase().includes(search.toLowerCase()) || item.code.toLowerCase().includes(search.toLowerCase())).map((item, index) => (
            checkboxMassiveDelete.push(item.id)
          ));
        }else{
          dataRegistrationPermission.sortPermissionsAdmin.map((item) => {
            checkboxMassiveDelete.push(item.id);
          });
        }
        setCheckboxMassiveDelete([...checkboxMassiveDelete]);
      }else{
        setCheckboxMassiveDelete([ ...checkboxMassiveDelete, event.target.name ]);
        if(checkboxMassiveDelete.length ===  dataRegistrationPermission.sortPermissionsAdmin.length - 1) {
          setAllDeleteChecked(true);
        }
      }
    } else {
      if(event.target.name === "all") {
        setCheckboxMassiveDelete([]);
        setAllDeleteChecked(false);
      }else{
        let index = checkboxMassiveDelete.indexOf(event.target.name);
        if(index >= 0) {
          checkboxMassiveDelete.splice(index, 1);
          setCheckboxMassiveDelete([...checkboxMassiveDelete]);
        }
        if(checkboxMassiveDelete.length === 0) {
          setAllDeleteChecked(false);
        }
      }
    }
  };

  // EDIT PERMISSION
  const onEditPermission = permissionId => {
    registration({
      variables: {permission_id : permissionId}
    })
    setOpenModalEditRegistration(true)
  }

  const onCloseModalEditRegistration = () => {
    setOpenModalEditRegistration(false)
    setEditRegistrationFormValues({
      permission_id: null,
      vatNumber: null,
      code: null,
      list_type_0: null,
      list_type_1: null,
    })
  }

  const onChangeEditRegister = (event) => {
    const name = event.target.name
    const value = event.target.value

    setEditRegistrationFormValues({...editRegistrationFormValues, [name] : value})
  }

  const onConfirmEditRegister = () => {
    editRegister({
      variables: { permission_id: editRegistrationFormValues['permission_id'], code: editRegistrationFormValues['code'], vat_number: editRegistrationFormValues['vatNumber'], list_type_0: editRegistrationFormValues['list_type_0'], list_type_1: editRegistrationFormValues['list_type_1'] },
      refetchQueries: ["sortPermissionsAdmin"],
    }).then(response => {
      // If response of remove registration permission is 0 set error
      if(response.data.editRegister.status === 1){
        setOpenModalEditRegistration(false)
        setEditRegistrationFormValues({
          permission_id: null,
          vatNumber: null,
          code: null,
          list_type_0: null,
          list_type_1: null,
        })
      }
    })
  }

  return (
    <Layout
      dataRegistrationPermission={dataRegistrationPermission}
      onRemoveRegistration={onRemoveRegistration}
      removeRegisterError={removeRegisterError}
      loadingAdd={loadingAdd}
      dataPriceLists={dataPriceLists}
      openModalAddNewRegistrationPermission={openModalAddNewRegistrationPermission}
      onCloseModalAddNewRegistrationPermission={onCloseModalAddNewRegistrationPermission}
      onOpenModalAddNewRegistrationPermission={onOpenModalAddNewRegistrationPermission}
      addRegistrationFormValues={addRegistrationFormValues}
      onChangeAddRegister={onChangeAddRegister}
      onAddRegistration={onAddRegistration}
      registrationPermissionVatNumberError={registrationPermissionVatNumberError}
      registrationPermissionCodeError={registrationPermissionCodeError}
      onChangeMassiveDelete={onChangeMassiveDelete}
      checkboxMassiveDelete={checkboxMassiveDelete}
      deleteAllSelected={deleteAllSelected}
      allDeleteChecked={allDeleteChecked}
      openModalConfirmDeleteAllPermission={openModalConfirmDeleteAllPermission}
      onCloseModalConfirmDeleteAllPermission={onCloseModalConfirmDeleteAllPermission}
      onOpenModalConfirmDeleteAllPermission={onOpenModalConfirmDeleteAllPermission}
      loadingRemoveAllPermission={loadingRemoveAllPermission}
      onChangeSearch={onChangeSearch}
      search={search}
      onChangeSort={onChangeSort}
      sort={sort}
      onEditPermission={onEditPermission}
      openModalEditRegistration={openModalEditRegistration}
      editRegistrationFormValues={editRegistrationFormValues}
      onCloseModalEditRegistration={onCloseModalEditRegistration}
      onChangeEditRegister={onChangeEditRegister}
      onConfirmEditRegister={onConfirmEditRegister}
    />
  );
};

export default withRouter(Permissions);
