import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  breadCrumbsText:{
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
  buyButton: {
    backgroundColor: "var(--mate-green)",
    color: "(--text-inverted)",
    "&:hover": {
      backgroundColor: "var(--mate-green)",
      opacity: 0.8,
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {

    },
    [theme.breakpoints.only("lg")]: {

    },
    [theme.breakpoints.only("xl")]: {

    },
  },
  paperTitle: {
    padding: "1rem 1rem",
    fontWeight: "bold",
  },
  paperContent:{
    padding: "0 1rem 1rem 1rem",
  },
  paperFooter:{
    textAlign: "right",
    padding: "0 1rem 1rem 1rem",
  },
  infoText: {
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
}));
