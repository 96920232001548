/* eslint-disable no-undef */
import React from "react";
import { withRouter } from "react-router-dom";
import { GET_ALL_PRODUCT_IMAGE } from "../../../query";
import { useQuery } from "@apollo/react-hooks";
import LinearProgress from "@material-ui/core/LinearProgress";
import Layout from "./layout";

const ProductImage = ({ history, match }) => {
  const { loading: loadingAllProductImage, error: errorAllProductImage, data: dataAllProductImage } = useQuery(GET_ALL_PRODUCT_IMAGE,{
    variables: {product_id: match.params.productId},
  });

  if(loadingAllProductImage){
    return <LinearProgress/>;
  }

  if(errorAllProductImage){
    console.log("err");
  }

  return (
    <Layout
      dataAllProductImage={dataAllProductImage}
    />
  );
};

export default withRouter(ProductImage);
