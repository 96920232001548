import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  modal:{
    overflowY: "initial !important",
  },
  paperModal: {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 4, 3),
    marginTop: theme.spacing(5),
    margin: "auto",
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
  },
  modalButtonCloseIcon: {
    padding: 0,
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  modalActionContainer: {
    padding: theme.spacing(2, 0, 0),
  },
  loading: {
    textAlign: "center",
  },
  searchField: {
    width: "auto",
    marginBottom: "1rem",
    float: "right",
    [theme.breakpoints.down('xs')]: {
      float: "left",
      width: "100%",
    },
  },
  buttonAddNew: {
    marginTop:"1.2rem",
    marginRight: "0.75rem",
    [theme.breakpoints.down('xs')]: {
      width: "100%",
      marginRight: "0",
    },
  },
  selectAllWrapper: {
    paddingLeft: "1.75rem",
    [theme.breakpoints.down('xs')]: {
      paddingLeft: "0",
      marginBottom: "1rem",
      verticalAlign: "top",
    },
  },
  selectSort: {
    width: "auto",
    float: "right",
    marginTop: "0",
    minWidth: "194px",
    [theme.breakpoints.down('xs')]: {
      width: "100%",
      marginBottom: "1rem",
    }
  },
}));
