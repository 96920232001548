
import * as JWT from "jwt-decode";

export const isLoggedIn = () => {
  if (localStorage.getItem("accessToken")) {
    return true;
  }else{
    return false;
  }
};

export const userRole = () => {
  var decoded = JWT(localStorage.getItem("accessToken"));
  //role
  return decoded.scopes[0];
};

export const accessTokenIsExpired = () => {
  var decoded = JWT(localStorage.getItem("accessToken"));
  if (Date.now() <= decoded.exp * 1000) {
    // token not expired
    return false;
  } else {
    // token expired
    return true;
  }
};

export const removeOnArrayByName = (arr, value) => {
  const filteredAry = arr.filter(e => e !== value);
  return filteredAry;
};
