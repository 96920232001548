import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isLoggedIn, userRole } from "../../utils";
import LayoutAdmin from "../layoutAdmin";

const PrivateRoute = ({component: Component, ...rest}) => {

  return (
    <Route {...rest} render={props => (
      isLoggedIn() && userRole() === "admin" ?
        rest.path.includes("order-detail") ?
          <Component {...props} />
        :
        <LayoutAdmin>
          <Component {...props} />
        </LayoutAdmin>
        : <Redirect to="/" />
    )}
    />
  );
};

export default PrivateRoute;
