import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./application";
import * as serviceWorker from "./serviceWorker";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "rgba(130,70,51,1)" // This is an orange looking color
    },
    secondary: {
      main: "#ffcc80" //Another orange-ish color
    }
  },
});

ReactDOM.render(<ThemeProvider theme={theme}><App /></ThemeProvider>,document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
