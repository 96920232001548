import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { LOGIN, PASSWORD_RESET_CREATE } from "../../../mutation";
import Form from "./form";

const Login = ({ history, match }) => {
  // Login mutation
  const [login] = useMutation(LOGIN);
  // Password reset request creation
  const [passwordResetCreate] = useMutation(PASSWORD_RESET_CREATE);
  // formValues of login
  const [formValues, setFormValues] = useState({});
  // Modal of password reset request
  const [modalForgotPassword, setModalForgotPassword] = useState(false);
  // Loading of Password Request when press the confirm button
  const [loadingPasswordReset, setLoadingPasswordReset] = useState(false);
  // Error of login form
  const [loginError, setLoginError] = useState(false);
  // Error email of password reset request
  const [passwordResetEmailError, setPasswordResetEmailError] = useState(false);
  // Response if request of password gone success show a message
  const [passwordResetEmailResponse, setPasswordResetEmailResponse] = useState(false);

  // When the input of login change prepare the form values
  const onChangeForm = event => {
    const value = event.target.value;
    const name = event.target.name;
    setFormValues({ ...formValues, [name]: value });
    setLoginError(false);
    setPasswordResetEmailError(false);
  };

  // When user press login button
  const onLogin = () => {
    // If email or password are undefined or "" set error of login true
    if( formValues["email"] === undefined || formValues["password"] === undefined || formValues["email"] === "" || formValues["password"] === "" ){
      setLoginError(true);
    }else{
      // Else start the mutation for login
      login({
        variables: { email: formValues["email"], password: formValues["password"] },
      }).then(response => {
        // If response of login is 0 set error of login
        if(response.data.login.status === 0){
          setLoginError(true);
        }else if(response.data.login.status === 1){
          // else if user pass the login with status 1 push on shop and set the token of access
          setLoginError(false);
          localStorage.setItem("accessToken", response.data.login.access_token);
          setFormValues({});
          window.location.pathname = "/";
        }
      }).catch(err =>{
        // if get error of mutation set error of login to true
        setLoginError(true);
      });
    }
  };

  // If user open modal
  const openModalForgotPassword = () => {
    setModalForgotPassword(true);
  };

  // If user close modal
  const closeModalForgotPassword = () => {
    setModalForgotPassword(false);
  };

  // When user press on password reset
  const onPasswordReset = () => {
    // set loading of password reset true
    setLoadingPasswordReset(true);
    // If the email for password reset is undefined or "" set error of password reset true
    if(formValues["password-reset-email"] === undefined || formValues["password-reset-email"] === "" ){
      setPasswordResetEmailError(true);
      setLoadingPasswordReset(false);
    }else{
      // else stat the mutation for password reset
      passwordResetCreate({
        variables: { email: formValues["password-reset-email"] },
      }).then(response => {
        // if the response of password reset mutation is 0 set error of email  does not exists
        if(response.data.passwordResetCreate.status === 0){
          setPasswordResetEmailError(true);
        }else{
          // else set the response 1 for show the message of success password reset request
          setPasswordResetEmailResponse(1);
        }
        // set loading to false
        setLoadingPasswordReset(false);
      }).catch(err => {
        // if get error of mutation set error of password reset request to true and loading stop to false
        setPasswordResetEmailError(true);
        setLoadingPasswordReset(false);
      });
    }
  };

  return (
    <>
      <Form
        formValues={formValues}
        onChangeForm={onChangeForm}
        onLogin={onLogin}
        loginError={loginError}
        passwordResetEmailError={passwordResetEmailError}
        passwordResetEmailResponse={passwordResetEmailResponse}
        loadingPasswordReset={loadingPasswordReset}
        onPasswordReset={onPasswordReset}
        openModalForgotPassword={openModalForgotPassword}
        closeModalForgotPassword={closeModalForgotPassword}
        modalForgotPassword={modalForgotPassword}
      />
    </>
  );
};

export default withRouter(Login);
