import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import FiberNewIcon from "@material-ui/icons/FiberNew";
import CloseIcon from "@material-ui/icons/Close";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Modal from "@material-ui/core/Modal";
import CircularProgress from "@material-ui/core/CircularProgress";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import Divider from "@material-ui/core/Divider";
import { DropzoneDialog } from "material-ui-dropzone";
import { withStyles } from "@material-ui/core/styles";
import Style from "./style.js";

// Style by status of order
const checkClassNameForStatus = (status, style) => {
  if (status === "In lavorazione") {
    return style.boxStatusInLavorazione;
  }
  if (status === "Confermato") {
    return style.boxStatusConfermato;
  }
  if (status === "Annullato") {
    return style.boxStatusAnnullato;
  }
  if (status === "Evaso") {
    return style.boxStatusEvaso;
  }
  if (status === "Ricevuto") {
    return style.boxStatusRicevuto;
  }
};

// Table responsive
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "rgba(130,70,51,1)",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const Layout = ({
  dataOrders,
  openModalEditStatus,
  onCloseModalEditStatus,
  onOpenModalEditStatus,
  orderToChangeStatus,
  onChangeRadioChangeStatus,
  onUpdateOrderStatus,
  openModalConfirmDeleteOrders,
  onCloseModalConfirmDeleteOrders,
  onOpenModalConfirmDeleteOrders,
  onConfirmOrdersDelete,
  onClickViewOrderDatail,
  search,
  onChangeSearch,
  loadingUpdateOrderStatus,
  onChangeSelectFilterStatus,
  filterStatus,
  loadingDeleteOrders,
  onOpenModalChangeAllStatus,
  onCloseModalChangeAllStatus,
  openModalChangeAllStatus,
  onUpdateAllOrdersStatus,
  onChangeRadioChangeAllStatus,
  statusToChangeAllOrders,
  onChangeMassiveDelete,
  checkboxMassiveDelete,
  allDeleteChecked,
  openDropzone,
  onCloseDropzone,
  onSavePDF,
  pdfFile,
  deletePdfUploaded,
  onChangeSort,
  sort,
  errorStock,
  year,
  setYear,
}) => {
  const style = Style();
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ paddingBottom: "0", display: "flex", gap: "30px" }}>
          <Typography variant="h4">
            Ordini
          </Typography>
          <FormControl variant="outlined" size="small">
            <InputLabel id="demo-simple-select-outlined-label">Anno</InputLabel>
          <Select
            name="orderYear"
            onChange={(e) => setYear(e.target.value)}
            label="Anno"
            value={year}
            variant="outlined"
          >
            <MenuItem value={2024}>2024</MenuItem>
            <MenuItem value={2023}>2023</MenuItem>
            <MenuItem value={2022}>2022</MenuItem>
            <MenuItem value={2021}>2021</MenuItem>
            <MenuItem value={2020}>2020</MenuItem>
          </Select>
          </FormControl>
        </Grid>
        <Grid container item xs={12} style={{ paddingBottom: "0", paddingTop: "0" }} spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              style={{ marginBottom: "1rem" }}
              variant="outlined"
              margin="normal"
              fullWidth
              id="search"
              label="Cerca Ordini"
              size="small"
              value={search}
              onChange={onChangeSearch}
              className={style.inputSearch}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl className={style.selectStatusOrderFilter} size="small" variant="outlined" margin="normal" fullWidth>
              <InputLabel>Filtra per</InputLabel>
              <Select
                name="orderStatus"
                onChange={onChangeSelectFilterStatus}
                label="Stato ordine"
                value={filterStatus}
              >
                <MenuItem value=""><em>Nessuno</em></MenuItem>
                <MenuItem value="Ricevuto">Ricevuto</MenuItem>
                <MenuItem value="Confermato">Confermato</MenuItem>
                <MenuItem value="In lavorazione">In lavorazione</MenuItem>
                <MenuItem value="Evaso">Evaso</MenuItem>
                <MenuItem value="Annullato">Annullato</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} >
        {
          dataOrders && dataOrders.sortOrdersAdmin.length > 0 ?
            (
              <>
                <Grid container item xs={12} style={{ paddingBottom: "1rem", paddingTop: "0.5rem" }} spacing={1}>
                  <Grid item className={style.wrapperSelectSort}>
                    <FormControl variant="outlined" margin="normal" fullWidth size="small" className={style.selectSort}>
                      <InputLabel>Ordina per</InputLabel>
                      <Select
                        name="list_type_0"
                        label="Listino Estivo"
                        onChange={onChangeSort}
                        value={sort}
                        size="small"
                      >
                        <div style={{ textAlign: "center" }}><b>Ascendente</b></div>
                        <MenuItem value="total-asc">Totale <ArrowUpwardIcon style={{ fontSize: "1.1rem" }} /></MenuItem>

                        <Divider />
                        <div style={{ textAlign: "center" }}><b>Discendente</b></div>
                        <MenuItem value="total-desc">Totale <ArrowDownwardIcon style={{ fontSize: "1.1rem" }} /></MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item >
                    <FormControlLabel
                      className={style.selectAllWrapper}
                      control={
                        <Checkbox
                          color="primary"
                          onChange={onChangeMassiveDelete}
                          name="all"
                          checked={allDeleteChecked}
                        />
                      }
                      label="Seleziona tutto"
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      onClick={onOpenModalConfirmDeleteOrders}
                      size="small"
                      disabled={checkboxMassiveDelete.length > 0 ? false : true}
                    >
                      Elimina
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      onClick={onOpenModalChangeAllStatus}
                      size="small"
                      disabled={checkboxMassiveDelete.length > 0 ? false : true}
                    >
                      Modifica
                    </Button>
                  </Grid>
                </Grid>
                <TableContainer>
                  <Table className={style.table} size="small" aria-label="a dense table">
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell align="left"></StyledTableCell>
                        <StyledTableCell align="center">Codice</StyledTableCell>
                        <StyledTableCell align="center">Email</StyledTableCell>
                        <StyledTableCell align="center">Partita IVA</StyledTableCell>
                        <StyledTableCell align="center">Codice cliente</StyledTableCell>
                        <StyledTableCell align="center">Stato</StyledTableCell>
                        <StyledTableCell align="center">Totale</StyledTableCell>
                        <StyledTableCell align="center">Data</StyledTableCell>
                        <StyledTableCell align="center">Azioni</StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {
                        filterStatus && search ? (
                          dataOrders.sortOrdersAdmin.filter(item => item.status.includes(filterStatus) && (item.id.toLowerCase().includes(search.toLowerCase()) || item.email.toLowerCase().includes(search.toLowerCase()) || item.code.toLowerCase().includes(search.toLowerCase()) || item.vat_number.toLowerCase().includes(search.toLowerCase()))).map((item, index) => (
                            <StyledTableRow key={index} >
                              <StyledTableCell align="left" className={item.read_status === 0 ? style.cellTableOrdersBold : ""} >
                                <Checkbox
                                  name={item.id}
                                  color="primary"
                                  onChange={onChangeMassiveDelete}
                                  checked={checkboxMassiveDelete.indexOf(item.id.toString()) >= 0 ? true : false}
                                />
                                <div className={style.newOrderIcon}>
                                  {item.read_status === 0 ? <FiberNewIcon fontSize={"large"} /> : ""}
                                </div>
                              </StyledTableCell>
                              <StyledTableCell align="center" className={item.read_status === 0 ? style.cellTableOrdersBold : ""} >
                                {"#" + item.id}
                              </StyledTableCell>
                              <StyledTableCell align="center" className={item.read_status === 0 ? style.cellTableOrdersBold : ""} >
                                {item.email}
                              </StyledTableCell>
                              <StyledTableCell align="center" className={item.read_status === 0 ? style.cellTableOrdersBold : ""} >
                                {item.vat_number}
                              </StyledTableCell>
                              <StyledTableCell align="center" className={item.read_status === 0 ? style.cellTableOrdersBold : ""} >
                                {item.code}
                              </StyledTableCell>
                              <StyledTableCell align="center" className={item.read_status === 0 ? style.cellTableOrdersBold : ""} >
                                <div className={checkClassNameForStatus(item.status, style)} >
                                  {item.status}
                                </div>
                                <IconButton key={index} color="primary" onClick={() => onOpenModalEditStatus(item)} >
                                  <EditIcon />
                                </IconButton>
                              </StyledTableCell>
                              <StyledTableCell align="center" className={item.read_status === 0 ? style.cellTableOrdersBold : ""} >
                                {item.total} €
                              </StyledTableCell>
                              <StyledTableCell align="center" className={item.read_status === 0 ? style.cellTableOrdersBold : ""} >
                                {item.time}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <IconButton key={index} color="primary" onClick={() => onClickViewOrderDatail(item.id)} >
                                  <VisibilityIcon />
                                </IconButton>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))
                        ) : (
                          filterStatus ? (
                            dataOrders.sortOrdersAdmin.filter(item => item.status.includes(filterStatus)).map((item, index) => (
                              <StyledTableRow key={index} >
                                <StyledTableCell align="left" className={item.read_status === 0 ? style.cellTableOrdersBold : ""} >
                                  <Checkbox
                                    name={item.id}
                                    color="primary"
                                    onChange={onChangeMassiveDelete}
                                    checked={checkboxMassiveDelete.indexOf(item.id.toString()) >= 0 ? true : false}
                                  />
                                  <div className={style.newOrderIcon}>
                                    {item.read_status === 0 ? <FiberNewIcon fontSize={"large"} /> : ""}
                                  </div>
                                </StyledTableCell>
                                <StyledTableCell align="center" className={item.read_status === 0 ? style.cellTableOrdersBold : ""} >
                                  {"#" + item.id}
                                </StyledTableCell>
                                <StyledTableCell align="center" className={item.read_status === 0 ? style.cellTableOrdersBold : ""} >
                                  {item.email}
                                </StyledTableCell>
                                <StyledTableCell align="center" className={item.read_status === 0 ? style.cellTableOrdersBold : ""} >
                                  {item.vat_number}
                                </StyledTableCell>
                                <StyledTableCell align="center" className={item.read_status === 0 ? style.cellTableOrdersBold : ""} >
                                  {item.code}
                                </StyledTableCell>
                                <StyledTableCell align="center" className={item.read_status === 0 ? style.cellTableOrdersBold : ""} >
                                  <div className={checkClassNameForStatus(item.status, style)} >
                                    {item.status}
                                  </div>
                                  <IconButton key={index} color="primary" onClick={() => onOpenModalEditStatus(item)} >
                                    <EditIcon />
                                  </IconButton>
                                </StyledTableCell>
                                <StyledTableCell align="center" className={item.read_status === 0 ? style.cellTableOrdersBold : ""} >
                                  {item.total} €
                                </StyledTableCell>
                                <StyledTableCell align="center" className={item.read_status === 0 ? style.cellTableOrdersBold : ""} >
                                  {item.time}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <IconButton key={index} color="primary" onClick={() => onClickViewOrderDatail(item.id)} >
                                    <VisibilityIcon />
                                  </IconButton>
                                </StyledTableCell>
                              </StyledTableRow>
                            ))
                          ) : (
                            search ? (
                              dataOrders.sortOrdersAdmin.filter(item => item.id.toLowerCase().includes(search.toLowerCase()) || item.email.toLowerCase().includes(search.toLowerCase()) || item.code.toLowerCase().includes(search.toLowerCase()) || item.vat_number.toLowerCase().includes(search.toLowerCase()) || item.status.toLowerCase().includes(search.toLowerCase())).map((item, index) => (
                                <StyledTableRow key={index} >
                                  <StyledTableCell align="left" className={item.read_status === 0 ? style.cellTableOrdersBold : ""} >
                                    <Checkbox
                                      name={item.id}
                                      color="primary"
                                      onChange={onChangeMassiveDelete}
                                      checked={checkboxMassiveDelete.indexOf(item.id.toString()) >= 0 ? true : false}
                                    />
                                    <div className={style.newOrderIcon}>
                                      {item.read_status === 0 ? <FiberNewIcon fontSize={"large"} /> : ""}
                                    </div>
                                  </StyledTableCell>
                                  <StyledTableCell align="center" className={item.read_status === 0 ? style.cellTableOrdersBold : ""} >
                                    {"#" + item.id}
                                  </StyledTableCell>
                                  <StyledTableCell align="center" className={item.read_status === 0 ? style.cellTableOrdersBold : ""} >
                                    {item.email}
                                  </StyledTableCell>
                                  <StyledTableCell align="center" className={item.read_status === 0 ? style.cellTableOrdersBold : ""} >
                                    {item.vat_number}
                                  </StyledTableCell>
                                  <StyledTableCell align="center" className={item.read_status === 0 ? style.cellTableOrdersBold : ""} >
                                    {item.code}
                                  </StyledTableCell>
                                  <StyledTableCell align="center" className={item.read_status === 0 ? style.cellTableOrdersBold : ""} >
                                    <div className={checkClassNameForStatus(item.status, style)} >
                                      {item.status}
                                    </div>
                                    <IconButton key={index} color="primary" onClick={() => onOpenModalEditStatus(item)} >
                                      <EditIcon />
                                    </IconButton>
                                  </StyledTableCell>
                                  <StyledTableCell align="center" className={item.read_status === 0 ? style.cellTableOrdersBold : ""} >
                                    {item.total} €
                                  </StyledTableCell>
                                  <StyledTableCell align="center" className={item.read_status === 0 ? style.cellTableOrdersBold : ""} >
                                    {item.time}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    <IconButton key={index} color="primary" onClick={() => onClickViewOrderDatail(item.id)} >
                                      <VisibilityIcon />
                                    </IconButton>
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))
                            ) : (
                              dataOrders.sortOrdersAdmin.map((item, index) => {
                                return (
                                  <StyledTableRow key={index} >
                                    <StyledTableCell align="left" className={item.read_status === 0 ? style.cellTableOrdersBold : ""} >
                                      <Checkbox
                                        name={item.id}
                                        color="primary"
                                        onChange={onChangeMassiveDelete}
                                        checked={checkboxMassiveDelete.indexOf(item.id.toString()) >= 0 ? true : false}
                                      />
                                      <div className={style.newOrderIcon}>
                                        {item.read_status === 0 ? <FiberNewIcon fontSize={"large"} /> : ""}
                                      </div>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" className={item.read_status === 0 ? style.cellTableOrdersBold : ""} >
                                      {"#" + item.id}
                                    </StyledTableCell>
                                    <StyledTableCell align="center" className={item.read_status === 0 ? style.cellTableOrdersBold : ""} >
                                      {item.email}
                                    </StyledTableCell>
                                    <StyledTableCell align="center" className={item.read_status === 0 ? style.cellTableOrdersBold : ""} >
                                      {item.vat_number}
                                    </StyledTableCell>
                                    <StyledTableCell align="center" className={item.read_status === 0 ? style.cellTableOrdersBold : ""} >
                                      {item.code}
                                    </StyledTableCell>
                                    <StyledTableCell align="center" className={item.read_status === 0 ? style.cellTableOrdersBold : ""} >
                                      <div className={checkClassNameForStatus(item.status, style)} >
                                        {item.status}
                                      </div>
                                      <IconButton key={index} color="primary" onClick={() => onOpenModalEditStatus(item)} >
                                        <EditIcon />
                                      </IconButton>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" className={item.read_status === 0 ? style.cellTableOrdersBold : ""} >
                                      {item.total} €
                                    </StyledTableCell>
                                    <StyledTableCell align="center" className={item.read_status === 0 ? style.cellTableOrdersBold : ""} >
                                      {item.time}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      <IconButton key={index} color="primary" onClick={() => onClickViewOrderDatail(item.id)} >
                                        <VisibilityIcon />
                                      </IconButton>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                );
                              })
                            )
                          )
                        )
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (<p style={{ margin: "0" }}>Non ci sono ordini</p>)
        }
      </Grid>
      <Modal
        open={openModalEditStatus}
        onClose={onCloseModalEditStatus}
      >
        <Grid container item xs={11} sm={11} md={5} lg={5} xl={5} className={style.paperModal}>
          <IconButton className={style.modalButtonCloseIcon} onClick={onCloseModalEditStatus}>
            <CloseIcon className={style.modalCloseIcon} fontSize={"large"} />
          </IconButton>
          <Typography variant="h5" color="primary" gutterBottom>
            Modifica stato ordine #{orderToChangeStatus.id}
          </Typography>
          <Grid container item xl={12}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="Stato Ordine"
                name="status"
                value={orderToChangeStatus.status}
                onChange={onChangeRadioChangeStatus}
              >
                <FormControlLabel className={style.radioTextChangeStatusModal} value="Ricevuto" control={<Radio />} label="Ricevuto" />
                <FormControlLabel className={style.radioTextChangeStatusModal} value="Confermato" control={<Radio />} label="Confermato" />
                <FormControlLabel className={style.radioTextChangeStatusModal} value="In lavorazione" control={<Radio />} label="In lavorazione" />
                <FormControlLabel className={style.radioTextChangeStatusModal} value="Evaso" control={<Radio />} label="Evaso" />
                <FormControlLabel className={style.radioTextChangeStatusModal} value="Annullato" control={<Radio />} label="Annullato" />
              </RadioGroup>
            </FormControl>
          </Grid>
          {
            orderToChangeStatus.status === "Confermato" ? (
              <DropzoneDialog
                open={openDropzone}
                onSave={onSavePDF}
                acceptedFiles={["application/pdf"]}
                showPreviews={true}
                maxFileSize={5000000}
                onClose={onCloseDropzone}
                showFileNames={true}
                showFileNamesInPreview={true}
                showAlerts={true}
                filesLimit={1}
              />
            ) : (
              <></>
            )
          }
          {
            pdfFile !== undefined && pdfFile.name !== undefined && (
              <div>
                <span>{pdfFile.name}</span>
                <CloseIcon className={style.closeIconImage} onClick={deletePdfUploaded} />
              </div>
            )
          }
          {
            loadingUpdateOrderStatus ? (
              <Grid className={style.modalActionContainer} container direction="row" justify="center" alignItems="center" spacing={2}>
                <CircularProgress />
              </Grid>
            ) : (
              <Grid className={style.modalActionContainer} container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                {
                  errorStock?.length > 0 && (<Grid item xs={12}><Typography variant="h5" color="primary" fullWidth align="left">I seguenti prodotti non sono disponibili:</Typography></Grid>)
                }
                {errorStock.map((error) => (
                  <Grid item xs={12}>
                    <Typography variant="caption" color="error" fullWidth>- {error}</Typography>
                  </Grid>
                ))
                }
                <Button
                  onClick={onUpdateOrderStatus}
                  //disabled={disabledSaveEditUser}
                  variant="contained"
                  size="large"
                  color="primary"
                >
                  Salva
                </Button>
              </Grid>
            )
          }
        </Grid>
      </Modal>
      <Modal
        open={openModalConfirmDeleteOrders}
        onClose={onCloseModalConfirmDeleteOrders}
      >
        <Grid container item xs={11} sm={11} md={5} lg={5} xl={5} className={style.paperModal}>
          <IconButton className={style.modalButtonCloseIcon} onClick={onCloseModalConfirmDeleteOrders}>
            <CloseIcon className={style.modalCloseIcon} fontSize={"large"} />
          </IconButton>
          <Typography variant="h5" color="primary" gutterBottom>
            Sei sicuro di volere eliminare {checkboxMassiveDelete.length > 1 ? checkboxMassiveDelete.length + " ordini" : checkboxMassiveDelete.length + " ordine"} ?
          </Typography>
          {
            loadingDeleteOrders ? (
              <Grid className={style.modalActionContainer} container direction="row" justify="center" alignItems="center" spacing={2}>
                <CircularProgress />
              </Grid>
            ) : (
              <Grid className={style.modalActionContainer} container direction="row" justify="center" alignItems="center" spacing={2}>
                <Grid item >
                  <Button
                    onClick={onCloseModalConfirmDeleteOrders}
                    //disabled={disabledSaveEditUser}
                    variant="contained"
                    size="large"
                    color="primary"
                  >
                    Annulla
                  </Button>
                </Grid>
                <Grid item >
                  <Button
                    onClick={onConfirmOrdersDelete}
                    //disabled={disabledSaveEditUser}
                    variant="contained"
                    size="large"
                    color="primary"
                  >
                    Conferma
                  </Button>
                </Grid>
              </Grid>
            )
          }
        </Grid>
      </Modal>
      <Modal
        open={openModalChangeAllStatus}
        onClose={onCloseModalChangeAllStatus}
      >
        <Grid container item xs={11} sm={11} md={5} lg={5} xl={5} className={style.paperModal}>
          <IconButton className={style.modalButtonCloseIcon} onClick={onCloseModalChangeAllStatus}>
            <CloseIcon className={style.modalCloseIcon} fontSize={"large"} />
          </IconButton>
          <Typography variant="h5" color="primary" gutterBottom>
            Cambia stato degli ordini
          </Typography>
          <Grid container item xl={12}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="Stato Ordine"
                name="status"
                value={orderToChangeStatus.status}
                onChange={onChangeRadioChangeAllStatus}
              >
                <FormControlLabel className={style.radioTextChangeStatusModal} value="Ricevuto" control={<Radio />} label="Ricevuto" />
                <FormControlLabel className={style.radioTextChangeStatusModal} value="In lavorazione" control={<Radio />} label="In lavorazione" />
                <FormControlLabel className={style.radioTextChangeStatusModal} value="Evaso" control={<Radio />} label="Evaso" />
                <FormControlLabel className={style.radioTextChangeStatusModal} value="Annullato" control={<Radio />} label="Annullato" />
              </RadioGroup>
            </FormControl>
          </Grid>
          {
            loadingUpdateOrderStatus ? (
              <Grid className={style.modalActionContainer} container direction="row" justify="center" alignItems="center" spacing={2}>
                <CircularProgress />
              </Grid>
            ) : (
              <Grid className={style.modalActionContainer} container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                <Button
                  onClick={onUpdateAllOrdersStatus}
                  disabled={statusToChangeAllOrders.length > 0 ? false : true}
                  variant="contained"
                  size="large"
                  color="primary"
                >
                  Salva
                </Button>
              </Grid>
            )
          }
        </Grid>
      </Modal>
    </>
  );
};

export default Layout;
