import { makeStyles } from "@material-ui/core/styles";
import HomeShop from "../../../../assets/images/b2b_home.jpg";
import HomeWoman from "../../../../assets/images/b2b_home_donna.jpg";
import HomeMan from "../../../../assets/images/b2b_home_uomo.jpg";
import Sale from "../../../../assets/images/b2b_home_prodotti_offerta.jpg";
import MadeInItaly from "../../../../assets/images/made_in_italy.jpg";
// import Bestseller from "../../../../assets/images/b2b_home_prodotti_bestellers.jpg";
// import IdeeRegalo from "../../../../assets/images/idee_regalo.jpg";
import Luxury from "../../../../assets/images/luxury_b2b.jpg";

export default makeStyles((theme) => ({
  productImage:{
    width: "100%",
    minHeight: "400px",
    maxHeight: "400px",
    borderRadius: "4px",
  },
  paperGrid:{
    marginTop: "-8rem",
  },
  titleCategory:{
    margin: "0",
    marginBottom:"0.15rem",
    color: "#ffffff",
  },
  titleCategoryOffer:{
    margin: "0",
    marginBottom:"0.15rem",
    color: "red",
  },
  textCategory:{
    margin:"0",
    marginBottom:"0.5rem",
    color: "#ffffff",
  },
  paperSvgMan:{
    padding:"10rem 1rem",
    backgroundImage: `url(${HomeMan})`,
    backgroundSize: "cover",
    backgroundPosition: "unset", // center
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down('md')]: {
      backgroundPosition: "center",
    },
  },
  paperSvgWoman:{
    padding:"10rem 1rem",
    backgroundImage: `url(${HomeWoman})`,
    backgroundSize: "cover",
    backgroundPosition: "unset", //center
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down('md')]: {
      backgroundPosition: "center",
    },
  },
  svgContainer:{
    textAlign:"right",
  },
  svg:{
    width:"100px",
    height:"100px",
  },
  paperShop:{
    padding:"10rem 1rem",
    backgroundImage: `url(${HomeShop})`,
    color: "#ffffff",
    backgroundSize: "cover",
    backgroundPosition: "right", //center
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down('md')]: {
      backgroundPosition: "center",
    },
  },
  paperSearchContainer:{
    display: "table",
    margin: "0 auto",
    paddingTop:"150px",
    textAlign:"center",
  },
  typewriterText:{
    fontSize:"2rem",
  },
  typewriterSecondText:{
    marginTop:"1rem",
    "&:hover": {
      cursor:"pointer",
      textDecoration: "underline",
    },
  },
  modal:{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalBody:{
    padding: "0.5rem 1rem",
    backgroundColor: "#f9f9f9",
    borderRadius: "5px",
    width: "450px",
  },
  modalTitle:{
    marginBottom: "1rem",
    position: "relative",
  },
  modalClose : {
    position: "absolute",
    top: "-0.25rem",
    display: "block",
    right: "0rem",
    fontSize: "1.75rem",
    cursor: "pointer",
  },
  modalFooter:{
    marginTop: "1rem",
    marginBottom: "0.5rem",
  },
  modalSubmit: {
    backgroundColor: "var(--blue)",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "var(--blue)",
      opacity: 0.8,
    },
  },
  select: {
    width: "100%",
  },
  paperSvgBest:{
    padding:"10rem 1rem",
    backgroundImage: `url(${MadeInItaly})`,
    backgroundSize: "cover",
    backgroundPosition: "unset", //center
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down('md')]: {
      backgroundPosition: "center",
    },
  },
  paperPreorder:{
    padding:"1rem 1rem",
    backgroundColor: "#dbd3c8",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "20px"
  },
  paperSvgSale:{
    padding:"10rem 1rem",
    backgroundImage: `url(${Sale})`,
    backgroundSize: "cover",
    backgroundPosition: "unset", //center
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down('md')]: {
      backgroundPosition: "center",
    },
  },
  paperSvgLuxury:{
    padding:"10rem 1rem",
    backgroundImage: `url(${Luxury})`,
    backgroundSize: "cover",
    backgroundPosition: "unset", //center
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down('md')]: {
      backgroundPosition: "center",
    },
  },
  wrapperButtonAddNewLink: {
    textAlign: "center",
    [theme.breakpoints.down('md')]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  buttonAddNewLink:{
    textDecoration: "none",
    background: "transparent",
    border: "1px solid rgba(130,70,51,1)",
    color: "rgba(130,70,51,1)",
    borderRadius: "4px",
    padding: "7px 20px 7px 20px",
    margin: "0 15px",
    display: "inline-flex",
    [theme.breakpoints.down('md')]: {
      padding: "5px 14px 5px 13px",
    },
  }
}));
