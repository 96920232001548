import React, { useState, useEffect } from "react";
import {withRouter} from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { useQuery } from "@apollo/react-hooks";
import { LOGOUT } from "../../mutation";
import { ME } from "../../query";
import { accessTokenIsExpired } from "../../utils";
import Layout from "./layout";
import LinearProgress from "@material-ui/core/LinearProgress";

const LayoutCustomer = ({ history, children }) => {
  // User Menu
  const [userMenu, setUserMenu] = useState(false);
  const [anchorMenu, setAnchorMenu] = useState(null);

  // Search Menu
  const [searchMenu, setSearchMenu] = useState(false);
  const [anchorSearchMenu, setAnchorSearchMenu] = useState(null);
  const [search, setSearch] = useState("")

  const [isMobile, setIsMobile] = useState(false);

  const [logout] = useMutation(LOGOUT);
  const { loading: loadingMe, error: errorMe, data: dataMe } = useQuery(ME, {pollInterval: 10000});

  useEffect(() => {
    if(window.innerWidth < 900){
      setIsMobile(true);
    }
  }, []);

  // User Menu
  const openUserMenu = event => {
    setAnchorMenu(event.currentTarget);
    setUserMenu(true);
  };

  const closeUserMenu = () => {
    setUserMenu(false);
  };

  // Search User Menu
  const openSearchMenu = event => {
    setAnchorSearchMenu(event.currentTarget);
    setSearchMenu(true);
  };

  const closeSearchMenu = () => {
    setSearchMenu(false);
  };

  const onChangeSearch = event =>{
    setSearch(event.target.value)
  }

  const onClickSearch = event =>{
    const actualPath = window.location.pathname
    var path = actualPath.split("/")
    event.preventDefault();
    if(search !== ""){
      if(path[1] === "shop"){
        window.location.pathname = `/shop/nullGenderIdSearch/nullSeasonIdSearch/${search}`;
      }else{
        history.push(`/shop/nullGenderIdSearch/nullSeasonIdSearch/${search}`);
      }
    }
  }

  const onLogout = () => {
    setUserMenu(false);
    logout({}).then(response => {
      // If response of login is 0 set error of login
      if(response.data.logout.status === 1){
        localStorage.removeItem("popupToken");
        localStorage.removeItem("accessToken");
        window.location.pathname = "/";
      }
    }).catch(err =>{});
  };

  const onGoToHome = () => {
    history.push("/home");
  };

  const onGoToCart = () => {
    history.push("/cart");
  };

  const onGoToFavorites = () => {
    history.push("/favorites");
  };

  const onGoToOrders = () => {
    history.push("/orders");
  };

  const onGoToMyAccount = () => {
    history.push("/my-account");
  };

  if(accessTokenIsExpired() === true){
    localStorage.removeItem("accessToken");
    window.location.pathname = "/";
  }

  if(loadingMe){
    return <LinearProgress />;
  }

  return (
    <Layout
      dataMe={dataMe}
      children={children}
      userMenu={userMenu}
      anchorMenu={anchorMenu}
      openUserMenu={openUserMenu}
      closeUserMenu={closeUserMenu}
      onLogout={onLogout}
      onGoToHome={onGoToHome}
      onGoToCart={onGoToCart}
      onGoToFavorites={onGoToFavorites}
      onGoToOrders={onGoToOrders}
      onGoToMyAccount={onGoToMyAccount}
      isMobile={isMobile}
      openSearchMenu={openSearchMenu}
      closeSearchMenu={closeSearchMenu}
      searchMenu={searchMenu}
      anchorSearchMenu={anchorSearchMenu}
      search={search}
      onChangeSearch={onChangeSearch}
      onClickSearch={onClickSearch}
    />
  );
};

export default withRouter(LayoutCustomer);
