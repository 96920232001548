/* eslint-disable max-len */

import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import GroupIcon from "@material-ui/icons/Group";
import StorefrontIcon from "@material-ui/icons/Storefront";
import IconButton from "@material-ui/core/IconButton";
import ListAltIcon from "@material-ui/icons/ListAlt";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Tooltip from "@material-ui/core/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment";
import CachedTwoToneIcon from "@material-ui/icons/CachedTwoTone";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import EuroIcon from '@material-ui/icons/Euro';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import Style from "./style.js";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    minWidth: 170,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);


const Layout = ({
  dataPriceLists,
  onAddRegistrationPermission,
  registrationPermissionFormValues,
  onChangeRegistrationPermission,
  registrationPermissionVatNumberError,
  registrationPermissionCodeError,
  loadingRegistrationPermission,
  registrationPermissionSuccess,
  onCloseRegistrationPermissionSuccess,
  dataAdminDashboard,
  history,
  onDeleteAbandonedCarts,
  dataShowBanner,
  onChangeShowBanner,
  showBanner,
  dataSeasons,
  onChangeHideSeasonsFormValues,
  hideSeasonsFormValues,
  onChangePreorderSeasonsFormValues,
  preorderSeasonsFormValues,
  onClickExportCsv
}) => {
  const style = Style();



  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">
            Dashboard
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
          <Paper elevation={3} className={style.paperDashboardOne} onClick={() => history.push("admin/users")}>
            <div className={style.paperDashboardContent}>
              <div>
                <GroupIcon style={{ height: "100%", fontSize: "2.5rem" }} />
              </div>
              <div>
                <Typography variant="h6">{dataAdminDashboard.adminDashboard.users_numbers}</Typography>
                <span className={style.paperDashboardInfo}>Utenti</span>
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
          <Paper elevation={3} className={style.paperDashboardTwo} onClick={() => history.push("admin/products")}>
            <div className={style.paperDashboardContent}>
              <div>
                <StorefrontIcon style={{ height: "100%", fontSize: "2.5rem" }} />
              </div>
              <div>
                <Typography variant="h6">{dataAdminDashboard.adminDashboard.products_numbers}</Typography>
                <span className={style.paperDashboardInfo}>Prodotti</span>
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
          <Paper elevation={3} className={style.paperDashboardThree} onClick={() => history.push("admin/lists")}>
            <div className={style.paperDashboardContent}>
              <div>
                <ListAltIcon style={{ height: "100%", fontSize: "2.5rem" }} />
              </div>
              <div>
                <Typography variant="h6">{dataAdminDashboard.adminDashboard.price_lists_numbers / 2}</Typography>
                <span className={style.paperDashboardInfo}>Listini</span>
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
          <HtmlTooltip
            arrow
            title={
              <>
                <Typography color="inherit">Ordini</Typography>
                <div>
                  <b>{dataAdminDashboard.adminDashboard.orders_annullato}{" - Annullato"}</b>
                </div>
                <div>
                  <b>{dataAdminDashboard.adminDashboard.orders_confermato}{" - Confermato"}</b>
                </div>
                <div>
                  <b>{dataAdminDashboard.adminDashboard.orders_in_lavorazione}{" - In lavorazione"}</b>
                </div>
                <div>
                  <b>{dataAdminDashboard.adminDashboard.orders_evaso}{" - Evaso"}</b>
                </div>
                <div>
                  <b>{dataAdminDashboard.adminDashboard.orders_ricevuto}{" - Ricevuto"}</b>
                </div>
              </>
            }
          >
            <Paper elevation={3} className={style.paperDashboardFour} onClick={() => history.push("admin/orders")}>
              <div className={style.paperDashboardContent}>
                <div>
                  <LocalShippingIcon style={{ height: "100%", fontSize: "2.5rem" }} />
                </div>
                <div>
                  <Typography variant="h6">{dataAdminDashboard.adminDashboard.orders_numbers}</Typography>
                  <span className={style.paperDashboardInfo}>Ordini</span>
                </div>
              </div>
            </Paper>
          </HtmlTooltip>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Paper elevation={3} className={style.paperDashboardOne} onClick={() => history.push("admin/permissions")}>
            <div className={style.paperDashboardContentBig}>
              <div>
                <ListAltIcon style={{ height: "100%", fontSize: "2.5rem" }} />
              </div>
              <div>
                <Typography variant="h6">{dataAdminDashboard.adminDashboard.registrations_permission_numbers}</Typography>
                <span className={style.paperDashboardInfo}>Permessi di registrazione</span>
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Paper elevation={3} className={style.paperDashboardFive}>
            <div className={style.paperDashboardContentBig}>
              <div>
                <ListAltIcon style={{ height: "100%", fontSize: "2.5rem" }} />
              </div>
              <div>
                <Typography variant="h6">{dataAdminDashboard.adminDashboard.abandoned_carts_numbers}</Typography>
                <span className={style.paperDashboardInfo}>Carrelli abbandonati da più di un mese</span>
                <Button className={style.emptyButton} onClick={onDeleteAbandonedCarts}>
                  Svuota
                </Button>
              </div>
            </div>
          </Paper>
        </Grid>
        {/* <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Paper elevation={3} className={style.paperDashboardFive}>
            <div className={style.paperDashboardContentBig}>
              <div>
                <Brightness7Icon style={{height: "100%", fontSize: "2.5rem"}}/>
              </div>
              <div>
                <Typography variant="h6"><b>Fatturato Prodotti P/E</b></Typography>
                <Typography variant="h6"><b>{dataAdminDashboard.adminDashboard.pe_products_revenue} €</b></Typography>
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Paper elevation={3} className={style.paperDashboardFive}>
            <div className={style.paperDashboardContentBig}>
              <div>
                <AcUnitIcon style={{height: "100%", fontSize: "2.5rem"}}/>
              </div>
              <div>
                <Typography variant="h6"><b>Fatturato Prodotti A/I</b></Typography>
                <Typography variant="h6"><b>{dataAdminDashboard.adminDashboard.ai_products_revenue} €</b></Typography>
              </div>
            </div>
          </Paper>
        </Grid> */}
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} className={style.paperRegister}>
            <div className={style.paperRegisterTitle}>
              <Typography variant="h6">Permesso di registrazione</Typography>
            </div>
            <div className={style.paperRegisterContent}>
              {
                registrationPermissionSuccess ? (
                  <>
                    <div style={{ marginTop: "1rem" }}>
                      <Typography variant="body2">Permesso di registrazione aggiunto con successo.</Typography>
                    </div>
                    <div style={{ textAlign: "left", marginTop: "1rem" }}>
                      <Button
                        type="submit"
                        variant="contained"
                        className={style.addButton}
                        size="small"
                        color="primary"
                        onClick={onCloseRegistrationPermissionSuccess}
                      >
                        Chiudi
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <div style={{ marginTop: "1rem" }}>
                      <TextField
                        style={{ width: "100%" }}
                        error={registrationPermissionVatNumberError}
                        onChange={onChangeRegistrationPermission}
                        value={registrationPermissionFormValues["vatNumber"]}
                        name="vatNumber"
                        label="P. IVA"
                        helperText={registrationPermissionVatNumberError ? "Partita IVA Errata o gia esistente" : undefined}
                        variant="outlined"
                        size="small"
                      />
                      <TextField
                        style={{ width: "100%", marginTop: "1rem" }}
                        error={registrationPermissionCodeError}
                        onChange={onChangeRegistrationPermission}
                        value={registrationPermissionFormValues["code"]}
                        helperText={registrationPermissionCodeError ? "Codice gia esistente" : undefined}
                        name="code"
                        label="Code"
                        variant="outlined"
                        size="small"
                      />
                    </div>
                    <div style={{ marginTop: "1rem" }}>
                      <FormControl variant="outlined" size="small" style={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-filled-label">Listino Estivo</InputLabel>
                        <Select
                          labelId="demo-simple-select-filled-label"
                          onChange={onChangeRegistrationPermission}
                          value={registrationPermissionFormValues["list_type_0"]}
                          name="list_type_0"
                          label="Listino Estivo"
                          required
                        >
                          {
                            dataPriceLists ? (
                              dataPriceLists.priceLists.filter(element => element.type === 0).map((item) => {
                                return (
                                  <MenuItem value={item.id}>{item.name}</MenuItem>
                                );
                              })
                            ) : (null)
                          }
                        </Select>
                      </FormControl>
                    </div>
                    <div style={{ marginTop: "1rem" }}>
                      <FormControl variant="outlined" size="small" style={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-filled-label">Listino Invernale</InputLabel>
                        <Select
                          labelId="demo-simple-select-filled-label"
                          onChange={onChangeRegistrationPermission}
                          value={registrationPermissionFormValues["list_type_1"]}
                          name="list_type_1"
                          label="Listino Invernale"
                          required
                        >
                          {
                            dataPriceLists ? (
                              dataPriceLists.priceLists.filter(element => element.type === 1).map((item) => {
                                return (
                                  <MenuItem value={item.id}>{item.name}</MenuItem>
                                );
                              })
                            ) : (null)
                          }
                        </Select>
                      </FormControl>
                    </div>
                    <div style={{ textAlign: "left", marginTop: "1rem" }}>
                      <Button
                        type="submit"
                        variant="contained"
                        className={style.buttonAddNew}
                        size="medium"
                        color="primary"
                        onClick={onAddRegistrationPermission}
                      >
                        {loadingRegistrationPermission ? (<CircularProgress />) : "Aggiungi"}
                      </Button>
                    </div>
                  </>
                )
              }
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} className={style.paperRegister}>
            <div className={style.paperRegisterTitle}>
              <Typography variant="h6">Mostra Banner</Typography>
            </div>
            <div className={style.paperRegisterContent}>
              {/* <Grid xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showBanner.luxury === undefined ? (dataShowBanner?.showBanner[dataShowBanner.showBanner.findIndex(item => item.name === 'luxury')].show === 0 ? false : true ) : (showBanner?.luxury === 0 ? false : true) }
                      name="luxury"
                      color="primary"
                      onChange={onChangeShowBanner}
                    />
                  }
                  label="Luxury"
                />
              </Grid> */}
              <Grid xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showBanner.bestseller === undefined ? (dataShowBanner.showBanner[dataShowBanner.showBanner.findIndex(item => item.name === 'bestseller')].show === 0 ? false : true) : (showBanner.bestseller === 0 ? false : true)}
                      name="bestseller"
                      color="primary"
                      onChange={onChangeShowBanner}
                    />
                  }
                  label="Made in Italy"
                />
              </Grid>
              <Grid xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showBanner.sale === undefined ? (dataShowBanner.showBanner[dataShowBanner.showBanner.findIndex(item => item.name === 'sale')].show === 0 ? false : true) : (showBanner.sale === 0 ? false : true)}
                      name="sale"
                      color="primary"
                      onChange={onChangeShowBanner}
                    />
                  }
                  label="Prodotti in offerta"
                />
              </Grid>
            </div>
          </Paper>
          <Paper elevation={3} className={style.paperRegister} style={{ marginTop: "1.5rem" }}>
            <div className={style.paperRegisterTitle}>
              <Typography variant="h6">Catalogo A.I. 24.25</Typography>
            </div>
            <Grid item xs={12}>
              {/* <a className={style.buttonAddNewLink} href="/catalogo-luxury" target="_blank" >Luxury</a> */}
              <a className={style.buttonAddNewLink} href="/catalogo-uomo" target="_blank" >Uomo</a>
              <a className={style.buttonAddNewLink} href="/catalogo-donna" target="_blank" >Donna</a>
            </Grid>
          </Paper>
          <Paper elevation={3} className={style.paperRegister} style={{ marginTop: "1.5rem" }}>
            <div className={style.paperRegisterTitle}>
              <Typography variant="h6">Export Prodotti</Typography>
            </div>
            <Grid item xs={12} style={{ marginTop: 3 }}>
              <Button
                variant="contained"
                className={style.buttonAddNew}
                size="medium"
                color="primary"
                onClick={onClickExportCsv}
              >
                Esporta CSV Prodotti
              </Button>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Paper elevation={3} className={style.paperRegister}>
            <div className={style.paperRegisterTitle}>
              <Typography variant="h6">Nascondi Stagioni</Typography>
            </div>
            {
              dataSeasons && dataSeasons.seasons.length > 0 && (
                dataSeasons.seasons.map((season, index) => (
                  <Grid item xs={12} key={`hideseason-${index}`}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={hideSeasonsFormValues[season.id] !== undefined ? (hideSeasonsFormValues[season.id] === 1 ? true : false) : (season.not_visible === 1 ? true : false)}
                          onChange={onChangeHideSeasonsFormValues}
                          name={season.id}
                          color="primary"
                          value={season.id}
                        />
                      }
                      label={<span style={{ fontWeight: "600" }}>{season.name} ({JSON.parse(dataAdminDashboard.adminDashboard.total_for_season)[(season.id).toString()]})</span>}
                    />
                  </Grid>
                ))
              )
            }
          </Paper>
        </Grid>
         <Grid item xs={12} sm={6}>
          <Paper elevation={3} className={style.paperRegister}>
            <div className={style.paperRegisterTitle}>
              <Typography variant="h6">Abilita stagioni in preorder</Typography>
            </div>
            {
              dataSeasons && dataSeasons.seasons.length > 0 && (
                dataSeasons.seasons.map((season, index) => (
                  <Grid item xs={12} key={`preorder-${index}`}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={preorderSeasonsFormValues[season.id] !== undefined ? (preorderSeasonsFormValues[season.id] === 1 ? true : false) : (season.is_preorder === 1 ? true : false)}
                          onChange={onChangePreorderSeasonsFormValues}
                          name={season.id}
                          color="primary"
                          value={season.id}
                        />
                      }
                      label={<span style={{ fontWeight: "600" }}>{season.name} ({JSON.parse(dataAdminDashboard.adminDashboard.total_for_season)[(season.id).toString()]})</span>}
                    />
                  </Grid>
                ))
              )
            }
          </Paper>
        </Grid>
        {/*
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} className={style.paperLink}>
            <div className={style.paperRegisterTitle}>
              <Typography variant="h6">Link di registrazione</Typography>
            </div>
            <div className={style.paperRegisterContent}>
              {
                registrationLinkSuccess ? (
                  <>
                    <div style={{ marginTop:"1rem"}}>
                      <Typography variant="body2">Link di registrazione aggiunto con successo.</Typography>
                    </div>
                    <div style={{ textAlign:"left", marginTop:"1rem"}}>
                      <Button
                        type="submit"
                        variant="contained"
                        className={style.addButton}
                        size="medium"
                        color="primary"
                        onClick={onCloseRegistrationLinkSuccess}
                      >
                        Chiudi
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <div style={{ marginTop:"1rem"}}>
                      <TextField
                        style={{width:"100%"}}
                        error={registrationLinkError}
                        onChange={onChangeRegistrationLink}
                        value={registrationLink ? process.env.REACT_APP_REGISTER_URL + registrationLink : dataRegistrationToken.registration_token ? process.env.REACT_APP_REGISTER_URL + dataRegistrationToken.registration_token.registration_token : "Non esiste un link di registrazione"}
                        name="registrationToken"
                        label="Link"
                        variant="outlined"
                        size="small"
                        InputProps={{
                          readOnly: true,
                          classes: {
                            input: style.generateRandomTextField,
                          },
                          endAdornment: (
                            <ClickAwayListener>
                              <div>
                                <Tooltip
                                  PopperProps={{
                                    disablePortal: true,
                                  }}
                                  open={openTooltipCopy}
                                  disableFocusListener
                                  disableHoverListener
                                  disableTouchListener
                                  title="Copiato"
                                >
                                  <InputAdornment>
                                    <IconButton className={style.generateRandom}>
                                      <CachedTwoToneIcon onClick={onChangeRegistrationLink}/>
                                    </IconButton>
                                  </InputAdornment>
                                </Tooltip>
                              </div>
                            </ClickAwayListener>
                          ),
                        }}
                      />
                    </div>
                    <div style={{ textAlign:"left", marginTop:"1rem"}}>
                      <Button
                        type="submit"
                        variant="contained"
                        className={style.buttonAddNew}
                        size="medium"
                        color="primary"
                        onClick={onAddRegistrationLink}
                        disabled={registrationLink !== undefined && registrationLink !== null && registrationLink !== ""  ? false : true}
                      >
                        {dataRegistrationToken.registration_token ? "Salva" : "Aggiungi"}
                      </Button>
                    </div>
                  </>
                )
              }
            </div>
          </Paper>
        </Grid>
        */}
      </Grid>
    </>
  );
};

export default Layout;
