import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  paperModal: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 4, 3),
    marginTop: theme.spacing(5),
    margin: 'auto',
  },
  modalButtonCloseIcon: {
    padding: 0,
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  modalActionContainer: {
    padding: theme.spacing(2, 0, 0),
  },
  cellTableOrdersBold: {
    fontWeight: "600",
  },
  boxStatusInLavorazione: {
    background: "#ffeb3b",
    borderRadius: "5px",
    padding: "0.5rem 0.5rem",
    fontSize: "13px",
    display: "unset",
  },
  boxStatusAnnullato: {
    background: "#f44336",
    borderRadius: "5px",
    padding: "0.5rem 0.25rem",
    fontSize: "13px",
    display: "unset",
  },
  boxStatusConfermato: {
    background: "#2189f5",
    borderRadius: "5px",
    padding: "0.5rem 0.25rem",
    fontSize: "13px",
    display: "unset",
  },
  boxStatusRicevuto: {
    background: "#64dd17",
    borderRadius: "5px",
    padding: "0.5rem 0.25rem",
    fontSize: "13px",
    display: "unset",
  },
  boxStatusEvaso: {
    background: "#ef2de9",
    borderRadius: "5px",
    padding: "0.5rem 0.25rem",
    fontSize: "13px",
    display: "unset",
  },
  newOrderIcon: {
    display: "inline-flex",
    verticalAlign: "middle",
  },
  selectStatusOrderFilter: {
    maxWidth: "200px",
    float: "right",
    [theme.breakpoints.down('xs')]: {
      float: "left",
      maxWidth: "unset",
      margin: "0 0 1rem 0",
    },
  },
  wrapperSelectSort : {
    position: "absolute",
    right: "calc(2rem + 4px)",
    [theme.breakpoints.down('xs')]: {
      width: "100%",
      marginBottom: "1rem",
      position: "inherit",
    }
  },
  selectSort: {
    marginTop: "0",
    minWidth: "198px",
    [theme.breakpoints.down('xs')]: {
      width: "100%",
      padding: "0 7px 0 0",
    }
  },
  inputSearch: {
    [theme.breakpoints.down('xs')]: {
      width: "100%",
      margin: "1rem 0 0rem 0 !important",
    }
  }
}));
