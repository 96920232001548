import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/react-hooks";
import Login from "../pages/auth/login";
import Register from "../pages/auth/register";
import PasswordReset from "../pages/auth/passwordReset";
import client from "../client";
import Dashboard from "../pages/admin/dashboard";
import Users from "../pages/admin/users";
import UsersEdit from "../pages/admin/usersEdit";
import Permissions from "../pages/admin/permissions";
import Products from "../pages/admin/products";
import ProductDetail from "../pages/admin/productDetail";
import Agents from "../pages/admin/agents";
import Orders from "../pages/admin/orders";
import OrderDetailAdmin from "../pages/admin/orderDetail";
import Lists from "../pages/admin/lists";
import PriceListProducts from "../pages/admin/priceListProducts";
import Categories from "../pages/admin/categories";
import Sizes from "../pages/admin/sizes";
import Colors from "../pages/admin/colors";
import Catalogo from "../pages/customer/catalogo";
import CatalogoUomo from "../pages/customer/catalogoUomo";
import CatalogoDonna from "../pages/customer/catalogoDonna";
import Shop from "../pages/customer/shop";
import MadeInItaly from "../pages/customer/madeInItaly";
import Luxury from "../pages/customer/luxury";
import Sale from "../pages/customer/sale";
import Product from "../pages/customer/product";
import Cart from "../pages/customer/cart";
import Home from "../pages/customer/home";
import Favorites from "../pages/customer/favorites";
import ProductImage from "../pages/customer/productImage";
import CustomerOrders from "../pages/customer/orders";
import OrderDetail from "../pages/customer/orderDetail";
import PrivateRouteAdmin from "../components/privateRouteAdmin";
import PrivateRouteCustomer from "../components/privateRouteCustomer";
import PublicRoute from "../components/publicRoute";
import myAccount from "../pages/customer/myAccount";
import RequestRegister from "../pages/auth/requestRegister";
import Maintenance from "../pages/customer/maintenance"
import Preorder from "../pages/customer/preorder";
require("dotenv").config();

function App() {
  return (
    <ApolloProvider client={client}>
      <BrowserRouter basename={process.env.REACT_APP_BASENAME_PATH}>
        <PublicRoute exact path="/" component={Login} />
        <PublicRoute exact path="/password-reset/:token" component={PasswordReset} />
        {/* <PublicRoute exact path="/register" component={RequestRegister} /> */}
        <PrivateRouteCustomer exact path="/home" component={Home} />
        <PrivateRouteCustomer exact path="/shop" component={Shop} />
        <PrivateRouteCustomer exact path="/shop/:genderId/:seasonId/:headerSearch" component={Shop} />
        <PrivateRouteCustomer exact path="/made-in-italy" component={MadeInItaly} />
        <PrivateRouteCustomer exact path="/preorder" component={Preorder} />
        <PrivateRouteCustomer exact path="/luxury" component={Luxury} />
        <PrivateRouteCustomer exact path="/sale" component={Sale} />
        <PrivateRouteCustomer exact path="/product/:productId" component={Product} />
        <PrivateRouteCustomer exact path="/product-image/:productId" component={ProductImage} />
        <PrivateRouteCustomer exact path="/cart" component={Cart} />
        <PrivateRouteCustomer exact path="/favorites" component={Favorites} />
        <PrivateRouteCustomer exact path="/orders" component={CustomerOrders} />
        <PrivateRouteCustomer exact path="/order-detail/:orderId" component={OrderDetail} />
        <PrivateRouteCustomer exact path="/my-account" component={myAccount} />
        <PrivateRouteCustomer exact path="/catalogo-luxury" component={Catalogo} />
        <PrivateRouteCustomer exact path="/catalogo-uomo" component={CatalogoUomo} />
        <PrivateRouteCustomer exact path="/catalogo-donna" component={CatalogoDonna} />
        <PrivateRouteAdmin exact path="/admin" component={Dashboard} />
        <PrivateRouteAdmin exact path="/admin/users" component={Users} />
        <PrivateRouteAdmin exact path="/admin/users-edit" component={UsersEdit} />
        <PrivateRouteAdmin exact path="/admin/permissions" component={Permissions} />
        <PrivateRouteAdmin exact path="/admin/products" component={Products} />
        <PrivateRouteAdmin exact path="/admin/product-detail/:productId" component={ProductDetail} />
        <PrivateRouteAdmin exact path="/admin/orders" component={Orders} />
        <PrivateRouteAdmin exact path="/admin/order-detail/:orderId" component={OrderDetailAdmin} />
        <PrivateRouteAdmin exact path="/admin/lists" component={Lists} />
        <PrivateRouteAdmin exact path="/admin/price-list-products/:listId" component={PriceListProducts} />
        <PrivateRouteAdmin exact path="/admin/categories" component={Categories} />
        <PrivateRouteAdmin exact path="/admin/sizes" component={Sizes} />
        <PrivateRouteAdmin exact path="/admin/colors" component={Colors} />
        <PrivateRouteAdmin exact path="/admin/agents" component={Agents} />
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
