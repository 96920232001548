import React from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import StorefrontIcon from "@material-ui/icons/Storefront";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { withStyles } from "@material-ui/core/styles";
import Style from "./style.js";

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const noOrders = (style, onGoToShop) => (
  <div className={style.paperNotFoundContent}>
    <p className={style.paperNotFoundText}>Opsss...</p>
    <span className={style.paperNotFoundEmoji} role="img" aria-label="emoji-thinking" aria-labelledby="emoji-thinking">📦</span>
    <p style={{ marginBottom:"1.5rem" }}>Non hai ancora effettuato alcun ordine!</p>
    <Button
      className={style.resetFilterButton}
      size="small"
      variant="contained"
      color="secondary"
      onClick={onGoToShop}
      startIcon={<StorefrontIcon />}
    >Vai al negozio</Button>
  </div>
);

const Layout = ({ dataMyOrders, onViewOrder, onGoToShop }) => {
  const style = Style();
  return (
    <>
      <div style={{ marginBottom: "1rem", marginTop: "1rem"}}>

        {
          dataMyOrders && dataMyOrders.me.orders.length > 0 ? (
            <>
              <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: "1rem"}}>
                <Typography color="inherit" className={style.breadCrumbsText} onClick={onGoToShop}>Shop</Typography>
                <Typography color="textPrimary">I Miei Ordini</Typography>
              </Breadcrumbs>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left"><b>Ordine</b></StyledTableCell>
                      <StyledTableCell align="left"><b>Data</b></StyledTableCell>
                      <StyledTableCell align="left"><b>Stato</b></StyledTableCell>
                      <StyledTableCell align="left"><b>Totale</b></StyledTableCell>
                      <StyledTableCell align="left"><b>Azioni</b></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      dataMyOrders.me.orders.map((item, index) =>(
                        <StyledTableRow key={index}>
                          <TableCell align="left"><b>#{item.id}</b></TableCell>
                          <TableCell align="left">{item.created_at}</TableCell>
                          <TableCell align="left">{item.status}</TableCell>
                          <TableCell align="left">€{item.total}</TableCell>
                          <TableCell align="left" padding="none">
                            <Tooltip title="Visualizza" placement="right">
                              <IconButton style={{ marginLeft: "0.75rem" }} aria-label="view" color="primary" className={style.deleteButton} onClick={() => onViewOrder(item.id)}>
                                <VisibilityIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </StyledTableRow>
                      ))
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (noOrders(style, onGoToShop))
        }
      </div>
    </>
  );
};

export default Layout;
