/* eslint-disable array-callback-return */
/* eslint-disable max-len */
/* eslint-disable no-undef */

import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { COLORS_FOLDER, PRICE_LIST, GET_PRODUCT_GALLERY, PRODUCT_ADMIN, GROUP_SIZE, COLORS, GENDER_CATEGORY, FINENESS_CATEGORY, SEASON_CATEGORY, TAG_CATEGORY, COMPOSITION_CATEGORY, ALL_PRICE_OF_PRODUCT } from "../../../query";
import { ADD_SIZE_COLOR_STOCK, UPDATE_PRODUCT_PRICE_LIST, UPDATE_PRODUCT_COMPOSITION, UPDATE_PRODUCT_TAG, REMOVE_PRODUCT_GALLERY_IMAGE, ADD_PRODUCT_GALLERY_IMAGE, UPDATE_PRODUCT, ADD_REMOVE_PRODUCT_GENDER, ADD_REMOVE_PRODUCT_FINENESS, ADD_REMOVE_PRODUCT_SEASON } from "../../../mutation";
import { useQuery } from "@apollo/react-hooks";
import { useMutation } from "@apollo/react-hooks";
import LinearProgress from "@material-ui/core/LinearProgress";
import Layout from "./layout";
import { isNullableType } from "graphql";


const productGalleryOrderChecker = (gallery, inputOrder) => {
  if (!inputOrder) return true

  // remove last comma if exist
  if (inputOrder.slice(-1) === ",") {
    inputOrder = inputOrder.substring(0, inputOrder.length - 1);
  }


  var input = inputOrder.split(",")

  if (input.length === gallery.length) {
    var check = []
    for (let i = 1; i <= input.length; i++) {
      if (input.includes(String(i))) {
        check.push(1)
      } else {
        check.push(0)
      }
    }
    if (check.includes(0)) return false

    return true

  }

  return false
}
const ProductDetail = ({ match }) => {

  // Form Values for single field
  const [editProductFormValues, setEditProductFormValues] = React.useState({});
  const [editPriceListFormValues, setEditPriceListFormValues] = React.useState({});
  const [genderFormValues, setGenderFormValues] = React.useState({});
  const [finenessFormValues, setFinenessFormValues] = React.useState({});
  const [seasonFormValues, setSeasonFormValues] = React.useState({});
  const [tagsFormValues, setTagsFormValues] = React.useState(undefined);
  const [compositionsFormValues, setCompositionsFormValues] = React.useState(undefined);
  const [fileMainImage, setFileMainImage] = React.useState(undefined);
  const [fileImagesGallery, setFileImagesGallery] = React.useState();
  const [imagesGalleryToRemove, setImagesGalleryToRemove] = React.useState([]);
  const [outOfStock, setOutOfStock] = React.useState({});
  const [discountColors, setDiscountColors] = React.useState({});
  const [colorDiscountFormValues, setColorDiscountFormValues] = React.useState({});
  const [defaultStocksValue, setDefaultStocksValue] = React.useState(undefined);
  const [discountBaseColor, setDiscountBaseColor] = React.useState(false);

  //Modal
  const [openSuccessEditModal, setOpenSuccessEditModal] = React.useState(false);
  // Dropzone
  const [openDropzone, setOpenDropzone] = React.useState(false);
  const [openDropzoneGallery, setOpenDropzoneGallery] = React.useState(false);
  //Loading
  const [loadingUpdateProduct, setLoadingUpdateProduct] = React.useState(false);
  const [loadingDeleteProductGallery, setLoadingDeleteProductGallery] = React.useState(false);
  const [loadingUpdateProductGallery, setLoadingUpdateProductGallery] = React.useState(false);
  const [loadingAddPriceList, setLoadingAddPriceList] = React.useState(false);
  const [loadingAddGender, setLoadingAddGender] = React.useState(false);
  const [loadingAddSeason, setLoadingAddSeason] = React.useState(false);
  const [loadingAddFineness, setLoadingAddFineness] = React.useState(false);
  const [loadingAddColor, setLoadingAddSizeColorStock] = React.useState(false);
  const [loadingAddTag, setLoadingAddTag] = React.useState(false);
  const [loadingAddComposition, setLoadingAddComposition] = React.useState(false);



  // Modal
  const [openModalConfirm, setOpenModalConfirm] = React.useState(false);

  // Error
  const [errorUpdateProductPriceListEmpty, setErrorUpdateProductPriceListEmpty] = React.useState(false);

  // Mutation
  const [updateProduct] = useMutation(UPDATE_PRODUCT);
  const [addProductGalleryImage] = useMutation(ADD_PRODUCT_GALLERY_IMAGE);
  const [removeProductGalleryImage] = useMutation(REMOVE_PRODUCT_GALLERY_IMAGE);
  const [updateProductTag] = useMutation(UPDATE_PRODUCT_TAG);
  const [updateProductComposition] = useMutation(UPDATE_PRODUCT_COMPOSITION);
  const [updateProductPriceList] = useMutation(UPDATE_PRODUCT_PRICE_LIST);
  const [addRemoveProductGender] = useMutation(ADD_REMOVE_PRODUCT_GENDER);
  const [addRemoveProductFineness] = useMutation(ADD_REMOVE_PRODUCT_FINENESS);
  const [addRemoveProductSeason] = useMutation(ADD_REMOVE_PRODUCT_SEASON);
  const [addSizeColorStock] = useMutation(ADD_SIZE_COLOR_STOCK);

  const [errorProductGalleryOrder, setErrorProductGalleryOrder] = useState(false)
  const productColors = [];
  const productTags = [];
  const productCompositions = [];

  // Query
  const { loading: loadingProduct, error: errorProduct, data: dataProduct } = useQuery(PRODUCT_ADMIN, {
    variables: { id: match.params.productId },
  });
  const { loading: loadingGroupSize, error: errorGroupSize, data: dataGroupSize } = useQuery(GROUP_SIZE);
  const { loading: loadingColorFolder, error: errorColorFolder, data: dataColorFolder } = useQuery(COLORS_FOLDER);
  const { loading: loadingColors, error: errorColors, data: dataColors } = useQuery(COLORS);
  const { loading: loadingGender, error: errorGender, data: dataGender } = useQuery(GENDER_CATEGORY);
  const { loading: loadingFineness, error: errorFineness, data: dataFineness } = useQuery(FINENESS_CATEGORY);
  const { loading: loadingSeason, error: errorSeason, data: dataSeason } = useQuery(SEASON_CATEGORY);
  const { loading: loadingTag, error: errorTag, data: dataTag } = useQuery(TAG_CATEGORY);
  const { loading: loadingComposition, error: errorComposition, data: dataComposition } = useQuery(COMPOSITION_CATEGORY);
  const { loading: loadingPriceLists, error: errorPriceLists, data: dataPriceLists } = useQuery(PRICE_LIST);
  const { loading: loadingAllPriceOfProduct, error: errorAllPriceOfProduct, data: dataAllPriceOfProduct } = useQuery(ALL_PRICE_OF_PRODUCT, {
    variables: { product_id: match.params.productId },
  });
  const { loading: loadingProductGallery, error: errorProductGallery, data: dataProductGallery } = useQuery(GET_PRODUCT_GALLERY, {
    variables: { product_id: match.params.productId },
  });

  // onChange Form Fields base product -> table product
  const onChangeEditProductFormValues = (event) => {
    setEditProductFormValues({ ...editProductFormValues, [event.target.name]: event.target.value });
  };
  // onChange Price List -> table price_list_product
  const onChangePriceList = (event) => {
    setErrorUpdateProductPriceListEmpty(false);
    if (event.target.value <= 999999) {
      setEditPriceListFormValues({ ...editPriceListFormValues, [event.target.name]: event.target.value });
    }
  };

  // onChange Gender Select
  const onChangeGender = event => {
    setGenderFormValues({ ...genderFormValues, gender: event.target.value });
  };
  // onChange Fineness Select
  const onChangeFineness = event => {
    setFinenessFormValues({ ...finenessFormValues, fineness: event.target.value });
  };
  // onChange Season Select
  const onChangeSeason = event => {
    setSeasonFormValues({ ...seasonFormValues, season: event.target.value });
  };
  // onChange Tag set array of id
  const onChangeTag = tag => {
    if (tag !== null) {
      let idArray = [];
      tag.map((item, index) => {
        idArray[index] = item.value;
      });
      setTagsFormValues(idArray);
    } else {
      setTagsFormValues([]);
    }
  };
  // onChange Composition set array of id
  const onChangeComposition = composition => {
    if (composition !== null) {
      let idArray = [];
      composition.map((item, index) => {
        idArray[index] = item.value;
      });
      setCompositionsFormValues(idArray);
    } else {
      setCompositionsFormValues([]);
    }
  };

  // onChange checkbox for luxury
  const onChangeLuxuryFormValues = event => {
    if (event.target.checked) {
      setEditProductFormValues({ ...editProductFormValues, [event.target.name]: 1 });
    } else {
      setEditProductFormValues({ ...editProductFormValues, [event.target.name]: 0 });
    }
  };

  // onChange checkbox for bestseller
  const onChangeBestsellerFormValues = event => {
    if (event.target.checked) {
      setEditProductFormValues({ ...editProductFormValues, [event.target.name]: 1 });
    } else {
      setEditProductFormValues({ ...editProductFormValues, [event.target.name]: 0 });
    }
  };

  // onChange checkbox for bestseller
  const onChangeNotVisibleFormValues = event => {
    if (event.target.checked) {
      setEditProductFormValues({ ...editProductFormValues, [event.target.name]: 1 });
    } else {
      setEditProductFormValues({ ...editProductFormValues, [event.target.name]: 0 });
    }
  };

  // onChange textfield salePrice
  const onChangeSaleFormValues = event => {
    if (event.target.value <= 999999) {
      setEditProductFormValues({ ...editProductFormValues, [event.target.name]: event.target.value, percentageDiscount: "" });
    }
    setDiscountColors({});
    setColorDiscountFormValues({});
  };

  // onChange textfield salePrice
  const onChangePercentageDiscountFormValues = event => {
    if (event.target.value <= 100) {
      setEditProductFormValues({ ...editProductFormValues, [event.target.name]: event.target.value, sale: "" });
    }
    setDiscountColors({});
    setColorDiscountFormValues({});
  };

  const onChangeColorDiscountFormValues = event => {
    setColorDiscountFormValues({ ...colorDiscountFormValues, [event.target.name]: event.target.value })
  };

  // onchange discount from color
  const onAddColorDiscountFormValues = event => {
    if (colorDiscountFormValues.colorDiscountPercentage && colorDiscountFormValues.colorDiscountPercentage > 0 && colorDiscountFormValues.colorDiscountId) {
      setDiscountColors({ ...discountColors, [colorDiscountFormValues.colorDiscountId]: colorDiscountFormValues.colorDiscountPercentage });
      setColorDiscountFormValues({});
      setEditProductFormValues({
        ...editProductFormValues,
        sale: null,
        percentageDiscount: null
      });

    }
  };

  // const onRemoveColorDiscount = (key) => {
  //   console.log(key);
  //   const {[key]:removeProductGalleryImage, ...rest} = discountColors;
  //   setDiscountColors(rest);
  // }

  const onChangeDiscountBaseColor = (checked) => {
    setDiscountBaseColor(checked);
  }




  // Open Dropzone Add Image
  const onOpenDropzone = () => {
    setOpenDropzone(true);
  };
  // Close Dropzone Add Image
  const onCloseDropzone = () => {
    setOpenDropzone(false);
  };
  // onSave Dropzone main image
  const onSaveMainImage = files => {
    setFileMainImage(files[0]);
    setOpenDropzone(false);
  };
  // onDelete main image uploaded
  const deleteMainImage = () => {
    setFileMainImage(undefined);
  };
  // Open Dropzone Gallery
  const onOpenDropzoneGallery = () => {
    setOpenDropzoneGallery(true);
  };
  // Close Dropzone Gallery
  const onCloseDropzoneGallery = () => {
    setOpenDropzoneGallery(false);
  };
  // onSave Dropzone Gallery
  const onSaveImagesGallery = files => {
    if (fileImagesGallery !== undefined && fileImagesGallery.length > 0) {
      setFileImagesGallery(fileImagesGallery.concat(files));
    } else {
      setFileImagesGallery(files);
    }
    setOpenDropzoneGallery(false);
  };
  // onDelete one image uploaded in gallery dropzone
  const deleteMainImageGalley = (index) => {
    fileImagesGallery.splice(index, 1);
    setFileImagesGallery([...fileImagesGallery]);
  };
  // onDelete one image uploaded in gallery dropzone
  const onDeleteImageOfGallery = (id) => {
    setImagesGalleryToRemove([...imagesGalleryToRemove, id]);
  };
  // onDelete main image uploaded
  const onCloseSuccessEditModal = () => {
    setOpenSuccessEditModal(false);
    window.history.back();
  };
  // onDelete main image uploaded
  const onCloseSuccessEditAndRedirectToProducts = () => {
    setOpenSuccessEditModal(false);
    window.location.pathname = "admin/products";
  };

  // onDelete main image uploaded
  const onOpenSuccessEditModal = () => {
    setOpenSuccessEditModal(true);
  };

  // onChange radio type product (season)
  const onChangeRadioSeason = (event) => {
    setEditPriceListFormValues(undefined);
    setErrorUpdateProductPriceListEmpty(false);
    setEditProductFormValues({ ...editProductFormValues, [event.target.name]: event.target.value });

  };

  // onChange radio type product (season)
  var newObj = outOfStock
  const addOutOfStock = (event) => {
    if (isNaN(parseInt(event.target.value))) {
      delete newObj[event.target.name]
    } else {
      newObj[event.target.name] = parseInt(event.target.value)
    }
    setOutOfStock(newObj);
  };

  // onSave Product
  const onSaveProduct = (groupSizeId, colorFolderId) => {
    if (Object.keys(outOfStock).length <= 0) {
      editProductFormValues['stock'] = 1
    } else {
      editProductFormValues['stock'] = 0
    }
    if (editPriceListFormValues === undefined && editProductFormValues.type !== dataProduct.productAdmin.type && editProductFormValues.type !== undefined) {
      setErrorUpdateProductPriceListEmpty(true);
    } else {
      if (productGalleryOrderChecker(dataProductGallery.getProductGallery, editProductFormValues.gallery_position)) {
        if (editProductFormValues && editProductFormValues !== undefined) {
          setLoadingUpdateProduct(true);
          // Mutation Add New Color
          updateProduct({
            variables: {
              id: match.params.productId,
              file: fileMainImage,
              name: editProductFormValues.name,
              code: editProductFormValues.code,
              fineness_description: editProductFormValues.fineness_description,
              weight_description: editProductFormValues.weight_description,
              description: editProductFormValues.description === '' ? "vuoto" : editProductFormValues.description,
              group_size_id: groupSizeId,
              color_folder_id: colorFolderId,
              stock: editProductFormValues.stock,
              type: editProductFormValues.type,
              luxury: editProductFormValues.luxury,
              not_visible: editProductFormValues.not_visible,
              bestseller: editProductFormValues.bestseller,
              sale: discountBaseColor ? 0 : editProductFormValues.sale === "" ? 0 : parseFloat(editProductFormValues.sale),
              percentage_discount: discountBaseColor ? 0 : editProductFormValues.percentageDiscount === "" ? 0 : parseFloat(editProductFormValues.percentageDiscount),
              gallery_position: editProductFormValues.gallery_position === "" ? null : editProductFormValues.gallery_position
            },
            context: {
              hasUpload: true,
            },
            refetchQueries: ["productAdmin"],
          }).then(response => {
            // If response of remove registration permission is 0 set error
            if (response.data.updateProduct.status === 0) {
              setLoadingUpdateProduct(false);
            } else if (response.data.updateProduct.status === 1) {
              setLoadingUpdateProduct(false);
              //setFileMainImage(undefined);
            }
          }).catch(err => {
            // if get error of mutation set error of insert Vat number to true
            setLoadingUpdateProduct(false);
          });
        }
        // Gallery Mutation Add new Image
        if (fileImagesGallery && fileImagesGallery !== undefined) {
          setLoadingUpdateProductGallery(true);
          fileImagesGallery.map((item, index) => {
            addProductGalleryImage({
              variables: { product_id: match.params.productId, file: item },
              context: {
                hasUpload: true,
              },
              refetchQueries: ["productAdmin", "getProductGallery"],
            }).then(response => {
              if (response.data.addProductGalleryImage.status === 0) {
                if (index === fileImagesGallery.length - 1) {
                  setLoadingUpdateProductGallery(false);
                  setFileImagesGallery();
                }
              } else if (response.data.addProductGalleryImage.status === 1) {
                if (index === fileImagesGallery.length - 1) {
                  setLoadingUpdateProductGallery(false);
                  setFileImagesGallery();
                }
              }
            });
          });
        }
        // Gallery Mutation Remove Image deleted
        if (imagesGalleryToRemove !== undefined && imagesGalleryToRemove.length > 0) {
          setLoadingDeleteProductGallery(true);
          imagesGalleryToRemove.map((item, index) => {
            removeProductGalleryImage({
              variables: { id: item },
              refetchQueries: ["productAdmin", "getProductGallery"],
            }).then(response => {
              if (response.data.removeProductGalleryImage.status === 0) {
                if (index === imagesGalleryToRemove.length - 1) {
                  setLoadingDeleteProductGallery(false);
                  setImagesGalleryToRemove([]);
                }
              } else if (response.data.removeProductGalleryImage.status === 1) {
                if (index === imagesGalleryToRemove.length - 1) {
                  setLoadingDeleteProductGallery(false);
                  setImagesGalleryToRemove([]);
                }
              }
            });
          });
        }
        // Gender Mutation
        if (genderFormValues.gender !== undefined) {
          setLoadingAddGender(true);
          addRemoveProductGender({
            variables: { product_id: match.params.productId, gender_id: genderFormValues.gender },
            refetchQueries: ["productAdmin"],
          }).then(response => {
            if (response.data.addRemoveProductGender.status === 0) {
              setLoadingAddGender(false);
              setGenderFormValues({});
            } else if (response.data.addRemoveProductGender.status === 1) {
              setLoadingAddGender(false);
              setGenderFormValues({});
            }
          });
        }
        // Season Mutation
        if (seasonFormValues.season !== undefined) {
          setLoadingAddSeason(true);
          addRemoveProductSeason({
            variables: { product_id: match.params.productId, season_id: seasonFormValues.season },
            refetchQueries: ["productAdmin"],
          }).then(response => {
            if (response.data.addRemoveProductSeason.status === 0) {
              setLoadingAddSeason(false);
              setSeasonFormValues({});
            } else if (response.data.addRemoveProductSeason.status === 1) {
              setLoadingAddSeason(false);
              setSeasonFormValues({});
            }
          });
        }
        // Fineness Mutation
        if (finenessFormValues.fineness !== undefined) {
          setLoadingAddFineness(true);
          addRemoveProductFineness({
            variables: { product_id: match.params.productId, fineness_id: finenessFormValues.fineness },
            refetchQueries: ["productAdmin"],
          }).then(response => {
            if (response.data.addRemoveProductFineness.status === 0) {
              setLoadingAddFineness(false);
              setFinenessFormValues({});
            } else if (response.data.addRemoveProductFineness.status === 1) {
              setLoadingAddFineness(false);
              setFinenessFormValues({});
            }
          });
        }
        // Tag Mutation
        if (tagsFormValues !== undefined) {
          setLoadingAddTag(true);
          updateProductTag({
            variables: { id: tagsFormValues, product_id: match.params.productId },
            refetchQueries: ["productAdmin"],
          }).then(response => {
            if (response.data.updateProductTag.status === 0) {
              setLoadingAddTag(false);
            } else if (response.data.updateProductTag.status === 1) {
              setLoadingAddTag(false);
            }
          });
        }
        // Composition Mutation
        if (compositionsFormValues !== undefined) {
          setLoadingAddComposition(true);
          updateProductComposition({
            variables: { id: compositionsFormValues, product_id: match.params.productId },
            refetchQueries: ["productAdmin"],
          }).then(response => {
            if (response.data.updateProductComposition.status === 0) {
              setLoadingAddComposition(false);
            } else if (response.data.updateProductComposition.status === 1) {
              setLoadingAddComposition(false);
            }
          });
        }
        // Out Of Stock Mutation
        setLoadingAddSizeColorStock(true);
        addSizeColorStock({
          variables: {
            stock: JSON.stringify(outOfStock), product_id: match.params.productId, color_discount: discountBaseColor ? JSON.stringify(discountColors) : undefined,
          },
          refetchQueries: ["productAdmin"],
        }).then(response => {
          if (response.data.addSizeColorStock.status === 0) {
            setLoadingAddSizeColorStock(false);
          } else if (response.data.addSizeColorStock.status === 1) {
            setLoadingAddSizeColorStock(false);
          }
        });
        // List Price Mutation
        if (editPriceListFormValues !== undefined && Object.keys(editPriceListFormValues).length > 0) {
          setLoadingAddPriceList(true);
          Object.keys(editPriceListFormValues).map((item) => {
            if (editPriceListFormValues[item] === "") editPriceListFormValues[item] = 0;
          });
          updateProductPriceList({
            variables: { list_id_price: JSON.stringify(editPriceListFormValues), product_id: match.params.productId },
            refetchQueries: ["productAdmin", "allPriceOfProduct"],
          }).then(response => {
            if (response.data.updateProductPriceList.status === 0) {
              setLoadingAddPriceList(false);
            } else if (response.data.updateProductPriceList.status === 1) {
              setLoadingAddPriceList(false);
            }
          });
        }
        setFileImagesGallery(undefined);
        setOpenModalConfirm(true);
      } else {
        setErrorProductGalleryOrder(true)
      }
    }

  };

  if (loadingColorFolder || loadingPriceLists || loadingProductGallery || loadingProduct || loadingGroupSize || loadingColors || loadingGender || loadingFineness || loadingSeason || loadingTag || loadingComposition || loadingAllPriceOfProduct || loadingUpdateProduct || loadingUpdateProductGallery || loadingDeleteProductGallery || loadingAddGender || loadingAddSeason || loadingAddFineness || loadingAddTag || loadingAddColor || loadingAddPriceList || loadingAddComposition) {
    return <LinearProgress />;
  }

  if (errorColorFolder || errorPriceLists || errorProductGallery || errorProduct || errorGroupSize || errorColors || errorGender || errorFineness || errorSeason || errorTag || errorComposition || errorAllPriceOfProduct) {
    return console.log("error");
  }

  dataProduct.productAdmin.tags.map((item) => {
    productTags.push(item.id);
  });

  dataProduct.productAdmin.compositions.map((item) => {
    productCompositions.push(item.id);
  });


  return (
    <Layout
      dataProduct={dataProduct}
      dataGroupSize={dataGroupSize}
      dataColors={dataColors}
      productColors={productColors}
      dataGender={dataGender}
      dataFineness={dataFineness}
      dataSeason={dataSeason}
      productTags={productTags}
      dataTag={dataTag}
      productCompositions={productCompositions}
      dataComposition={dataComposition}
      dataAllPriceOfProduct={dataAllPriceOfProduct}
      editProductFormValues={editProductFormValues}
      editPriceListFormValues={editPriceListFormValues}
      onSaveProduct={onSaveProduct}
      onChangeEditProductFormValues={onChangeEditProductFormValues}
      onChangeGender={onChangeGender}
      onChangeFineness={onChangeFineness}
      onChangeSeason={onChangeSeason}
      onChangeTag={onChangeTag}
      onChangeComposition={onChangeComposition}
      onChangePriceList={onChangePriceList}
      openDropzone={openDropzone}
      onOpenDropzone={onOpenDropzone}
      onCloseDropzone={onCloseDropzone}
      onSaveMainImage={onSaveMainImage}
      fileMainImage={fileMainImage}
      deleteMainImage={deleteMainImage}
      onCloseSuccessEditModal={onCloseSuccessEditModal}
      onOpenSuccessEditModal={onOpenSuccessEditModal}
      openSuccessEditModal={openSuccessEditModal}
      onCloseSuccessEditAndRedirectToProducts={onCloseSuccessEditAndRedirectToProducts}
      genderFormValues={genderFormValues}
      finenessFormValues={finenessFormValues}
      seasonFormValues={seasonFormValues}
      onChangeRadioSeason={onChangeRadioSeason}
      errorUpdateProductPriceListEmpty={errorUpdateProductPriceListEmpty}
      openDropzoneGallery={openDropzoneGallery}
      onOpenDropzoneGallery={onOpenDropzoneGallery}
      onCloseDropzoneGallery={onCloseDropzoneGallery}
      onSaveImagesGallery={onSaveImagesGallery}
      fileImagesGallery={fileImagesGallery}
      dataProductGallery={dataProductGallery}
      deleteMainImageGalley={deleteMainImageGalley}
      onDeleteImageOfGallery={onDeleteImageOfGallery}
      imagesGalleryToRemove={imagesGalleryToRemove}
      tagsFormValues={tagsFormValues}
      compositionsFormValues={compositionsFormValues}
      dataPriceLists={dataPriceLists}
      openModalConfirm={openModalConfirm}
      dataColorFolder={dataColorFolder}
      addOutOfStock={addOutOfStock}
      outOfStock={outOfStock}
      setOutOfStock={setOutOfStock}
      defaultStocksValue={defaultStocksValue}
      onChangeLuxuryFormValues={onChangeLuxuryFormValues}
      onChangeBestsellerFormValues={onChangeBestsellerFormValues}
      onChangeSaleFormValues={onChangeSaleFormValues}
      onChangePercentageDiscountFormValues={onChangePercentageDiscountFormValues}
      errorProductGalleryOrder={errorProductGalleryOrder}
      onChangeNotVisibleFormValues={onChangeNotVisibleFormValues}
      setDefaultStocksValue={setDefaultStocksValue}
      colorDiscountFormValues={colorDiscountFormValues}
      onAddColorDiscountFormValues={onAddColorDiscountFormValues}
      onChangeColorDiscountFormValues={onChangeColorDiscountFormValues}
      setDiscountColors={setDiscountColors}
      discountColors={discountColors}

      discountBaseColor={discountBaseColor}
      setDiscountBaseColor={setDiscountBaseColor}
      onChangeDiscountBaseColor={onChangeDiscountBaseColor}
      setColorDiscountFormValues={setColorDiscountFormValues}
    />
  );
};

export default withRouter(ProductDetail);
