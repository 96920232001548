/* eslint-disable no-undef */
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { MY_CART, SUMMARY_OF_PRODUCT_ON_CART, ME } from "../../../query";
import { REMOVE_ON_CART, EDIT_QUANTITY_ON_CART, CREATE_ORDER } from "../../../mutation";
import { useQuery } from "@apollo/react-hooks";
import { useMutation } from "@apollo/react-hooks";
import LinearProgress from "@material-ui/core/LinearProgress";
import Layout from "./layout";

const Shop = ({ history, match }) => {
  const [successRemoveOnCart, setSuccessRemoveOnCart] = useState(false);
  const { loading: loadingMyCart, error: errorMyCart, data: dataMyCart } = useQuery(MY_CART, { pollInterval: 500 });
  const { loading: loadingMe, error: errorMe, data: dataMe } = useQuery(ME);
  const [removeOnCart] = useMutation(REMOVE_ON_CART);
  const [createOrder] = useMutation(CREATE_ORDER);
  const [editQuantityOnCart] = useMutation(EDIT_QUANTITY_ON_CART);
  const [loadingCreateOrder, setLoadingCreateOrder] = useState(false);
  const [successCreateOrder, setSuccessCreateOrder] = useState(false);
  const [modalChangeQuantity, setModalChangeQuantity] = useState(false);
  const [orderNote, setOrderNote] = useState("");
  const [responseCreateOrder, setResponseCreateOrder] = useState();
  const [warningQuantity, setWarningQuantity] = useState(false);

  const [formChangeQuantity, setFormChangeQuantity] = useState({
    id: null,
    product_id: null,
    product_price: null,
    quantity: null,
    quantity_max: null,
  });

  const onRemoveOnCart = (cartId, productId) => {
    removeOnCart({
      variables: { id: cartId },
      refetchQueries: [{
        query: SUMMARY_OF_PRODUCT_ON_CART,
        variables: { product_id: productId },
      }, "me", "myCart"],
    }).then(response => {
      // If response of login is 0 set error of login
      if (response.data.removeOnCart.status === 1) {
        setSuccessRemoveOnCart(true);
      }
    });
  };

  const onCreateOrder = () => {
    setLoadingCreateOrder(true);
    createOrder({
      variables: { note: orderNote },
      refetchQueries: ["me", "getSummaryProductOnCart", "myCart", "product"],
    }).then(response => {
      // If response of login is 0 set error of login
      //window.location.pathname = "/order-detail/" + response.data.createOrder.order_id;
      setResponseCreateOrder(response.data.createOrder);
      //setLoadingCreateOrder(false);
      setSuccessCreateOrder(true);
      setLoadingCreateOrder(false);
    });
  };

  const onCloseSuccessRemoveOnCart = () => {
    setSuccessRemoveOnCart(false);
  };

  const openModalChangeQuantity = (rowId, productId, productPrice, quantity, quantity_max) => {

    setFormChangeQuantity({
      id: rowId,
      product_id: productId,
      product_price: productPrice,
      quantity: quantity,
      quantity_max: quantity_max,
    });
    setModalChangeQuantity(true);
  };

  const closeModalChangeQuantity = () => {
    setFormChangeQuantity({
      id: null,
      product_id: null,
      product_price: null,
      quantity: null,
      quantity_max: null,
    });
    setWarningQuantity(false)
    setModalChangeQuantity(false);
  };

  const onChangeQuantity = event => {
    if (Number(event.target.value) > formChangeQuantity.quantity_max) {
      setWarningQuantity(true)
    } else {
      setWarningQuantity(false)
    }
    setFormChangeQuantity({ ...formChangeQuantity, quantity: Number(event.target.value) });
  }

  const onConfirmChangeQuantity = () => {

    let quantity = formChangeQuantity.quantity > formChangeQuantity.quantity_max ? formChangeQuantity.quantity_max : formChangeQuantity.quantity
    editQuantityOnCart({
      variables: { id: formChangeQuantity.id, product_id: formChangeQuantity.product_id, product_price: formChangeQuantity.product_price, quantity: quantity },
      refetchQueries: [{
        query: SUMMARY_OF_PRODUCT_ON_CART,
        variables: { product_id: formChangeQuantity.product_id },
      }, "me", "myCart"],
    }).then(response => {
      // If response of login is 0 set error of login
      if (response.data.editQuantityOnCart.status === 1) {
        closeModalChangeQuantity();
      } else if (response.data.editQuantityOnCart.status === 0) {
        closeModalChangeQuantity();
      }
    });
  };

  const onChangeOrderNote = event => {
    setOrderNote(event.target.value);
  };

  const onGoToShop = () => {
    history.push("/shop");
  };

  const onGoToProduct = (productId) => {
    history.push("/product/" + productId);
  };

  if (loadingMyCart || loadingCreateOrder) {
    return <LinearProgress />;
  }

  if (errorMyCart) {
    return console.log("err");
  }

  console.log(dataMyCart)


  return (
    <Layout
      dataMe={dataMe}
      dataMyCart={dataMyCart}
      onRemoveOnCart={onRemoveOnCart}
      successRemoveOnCart={successRemoveOnCart}
      onCloseSuccessRemoveOnCart={onCloseSuccessRemoveOnCart}
      onGoToShop={onGoToShop}
      onGoToProduct={onGoToProduct}
      onCreateOrder={onCreateOrder}
      successCreateOrder={successCreateOrder}
      modalChangeQuantity={modalChangeQuantity}
      openModalChangeQuantity={openModalChangeQuantity}
      closeModalChangeQuantity={closeModalChangeQuantity}
      onChangeQuantity={onChangeQuantity}
      formChangeQuantity={formChangeQuantity}
      onConfirmChangeQuantity={onConfirmChangeQuantity}
      onChangeOrderNote={onChangeOrderNote}
      orderNote={orderNote}
      responseCreateOrder={responseCreateOrder}
      history={history}
      warningQuantity={warningQuantity}
    />
  );
};

export default withRouter(Shop);
