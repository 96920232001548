/* eslint-disable no-mixed-operators */

import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import TextField from "@material-ui/core/TextField";
import Modal from "@material-ui/core/Modal";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import Divider from "@material-ui/core/Divider";
import { DropzoneDialog } from "material-ui-dropzone";
import { withStyles } from "@material-ui/core/styles";
import Style from "./style.js";

// Table responsive
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "rgba(130,70,51,1)",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const Layout = ({
  dataProducts,
  search,
  onChangeSearch,
  onViewProduct,
  modalAddProduct,
  openModalAddProduct,
  closeModalAddProduct,
  newProductName,
  newProductCode,
  onChangeForm,
  onAddProduct,
  errorProductExistent,
  errorZeroPriceLists,
  openDropzone,
  onOpenDropzone,
  onCloseDropzone,
  onSaveImage,
  fileMainImage,
  deleteImageUploaded,
  onChangeRadioSeason,
  productSeason,
  onOpenModalConfirmDeleteProduct,
  onCloseModalConfirmDeleteProduct,
  openModalConfirmDeleteProduct,
  productToDelete,
  onRemoveProduct,
  loadingRemoveProduct,
  filterType,
  onChangeSelectFilterType,
  onChangeMassiveDelete,
  checkboxMassiveDelete,
  deleteAllSelected,
  allDeleteChecked,
  loadingRemoveAllProduct,
  onOpenModalConfirmDeleteAllProduct,
  onCloseModalConfirmDeleteAllProduct,
  openModalConfirmDeleteAllProduct,
  onChangeSort,
  sort,
  onChangeViewBestseller,
  viewBestseller,
  onChangeViewLuxury,
  viewLuxury,
  dataGenders,
  filterGender,
  onChangeSelectFilterGender,
  dataSeasons,
}) => {
  const style = Style();
  console.log(dataProducts);
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ paddingBottom: "0" }}>
          <Typography variant="h4">
            Prodotti
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8} md={8} lg={6} xl={6} style={{ paddingBottom: "0", paddingTop: "0" }}>
          <Button
            className={style.buttonAddNew}
            style={{ marginTop: "1.2rem" }}
            variant="contained"
            size="medium"
            color="primary"
            onClick={openModalAddProduct}
          >
            Aggiungi Prodotto
          </Button>
          <FormControl className={style.selectFilterType} size="small" variant="outlined" margin="normal">
            <InputLabel>Filtra stagione</InputLabel>
            <Select
              name="productType"
              onChange={onChangeSelectFilterType}
              label="Stato ordine"
              value={filterType}
            >
              <MenuItem value=""><em>Nessuno</em></MenuItem>
              {dataSeasons?.seasons.map((season, index) =>
                <MenuItem key={index} value={season.name}>{season.name}</MenuItem>
              )}
            </Select>
          </FormControl>
          <FormControl className={style.selectFilterType} size="small" variant="outlined" margin="normal">
            <InputLabel>Filtra genere</InputLabel>
            <Select
              name="productType"
              onChange={onChangeSelectFilterGender}
              label="Stato ordine"
              value={filterGender}
            >
              <MenuItem value=""><em>Nessuno</em></MenuItem>
              {dataGenders.genders.map((item, index) => {
                return(
                  <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <FormControlLabel
            className={style.selectAllWrapper}
            control={
              <Checkbox
                color="primary"
                onChange={onChangeViewBestseller}
                name="all"
                checked={viewBestseller && viewBestseller === 1}
              />
            }
            label="Made in Italy"
          />
          <FormControlLabel
            className={style.selectAllWrapper}
            control={
              <Checkbox
                color="primary"
                onChange={onChangeViewLuxury}
                name="all"
                checked={viewLuxury && viewLuxury === 1}
              />
            }
            label="NOOS"
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={6} xl={6} style={{ paddingBottom: "0", paddingTop: "0" }}>
          <TextField
            className={style.searchField}
            variant="outlined"
            margin="normal"
            id="search"
            label="Cerca Prodotto"
            name="Cerca Prodotto"
            size="small"
            value={search}
            onChange={onChangeSearch}
          />
        </Grid>
        <Grid item xs={12}>
          {
            dataProducts && dataProducts.sortProductsAdmin.length > 0 ? (
              <>
                <FormControl variant="outlined" margin="normal" fullWidth size="small" className={style.selectSort}>
                  <InputLabel>Ordina per</InputLabel>
                  <Select
                    name="list_type_0"
                    label="Listino Estivo"
                    onChange={onChangeSort}
                    value={sort}
                    size="small"
                  >
                    <div style={{ textAlign: "center" }}><b>Ascendente</b></div>
                    <MenuItem value="id-asc">ID <ArrowUpwardIcon style={{ fontSize: "1.1rem" }} /></MenuItem>
                    <MenuItem value="name-asc">Nome <ArrowUpwardIcon style={{ fontSize: "1.1rem" }} /></MenuItem>
                    <MenuItem value="code-asc">Codice <ArrowUpwardIcon style={{ fontSize: "1.1rem" }} /></MenuItem>
                    <Divider />
                    <div style={{ textAlign: "center" }}><b>Discendente</b></div>
                    <MenuItem value="id-desc">ID <ArrowDownwardIcon style={{ fontSize: "1.1rem" }} /></MenuItem>
                    <MenuItem value="name-desc">Nome <ArrowDownwardIcon style={{ fontSize: "1.1rem" }} /></MenuItem>
                    <MenuItem value="code-desc">Codice <ArrowDownwardIcon style={{ fontSize: "1.1rem" }} /></MenuItem>
                  </Select>
                </FormControl>
                <FormControlLabel
                  className={style.selectAllWrapper}
                  control={
                    <Checkbox
                      color="primary"
                      onChange={onChangeMassiveDelete}
                      name="all"
                      checked={allDeleteChecked}
                    />
                  }
                  label="Seleziona tutto"
                />
                <Button
                  variant="contained"
                  size="small"
                  disabled={checkboxMassiveDelete?.length > 0 ? false : true}
                  onClick={onOpenModalConfirmDeleteAllProduct}
                >
                  Elimina selezionati
                </Button>
                <TableContainer component={Paper}>
                  <Table className={style.table} size="small">
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell align="left"></StyledTableCell>
                        <StyledTableCell align="left">#</StyledTableCell>
                        <StyledTableCell align="center">Stock</StyledTableCell>
                        <StyledTableCell align="center">Quantità</StyledTableCell>
                        <StyledTableCell align="center">Immagine</StyledTableCell>
                        <StyledTableCell align="center">Nome</StyledTableCell>
                        <StyledTableCell align="center">Codice</StyledTableCell>
                        <StyledTableCell align="center">Stagione</StyledTableCell>
                        <StyledTableCell align="center">N. di Foto</StyledTableCell>
                        <StyledTableCell align="center">Azioni</StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {
                        filterType === "" ? (
                          search ? (
                            viewBestseller === 1 ? (
                              filterGender === "" ? (
                                viewLuxury === 1 ? (
                                  dataProducts.sortProductsAdmin.filter(item => (item.name?.toLowerCase().includes(search.toLowerCase()) || item.code.toLowerCase().includes(search.toLowerCase())) && item.bestseller === 1 && item.luxury === 1).map((item, index) => (
                                    <StyledTableRow key={`1-${index}`}>
                                      <StyledTableCell align="left" style={{ width: "50px" }}>
                                        <Checkbox
                                          color="primary"
                                          name={item.id}
                                          onChange={onChangeMassiveDelete}
                                          checked={checkboxMassiveDelete?.indexOf(item.id.toString()) >= 0 ? true : false}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                      <StyledTableCell align="center"><div className={item.stock === 0 ? style.pointGreen : style.pointRed}/></StyledTableCell>
                                      <StyledTableCell align="center">{item.total_quantity > 0 ? item.total_quantity : ""}</StyledTableCell>
                                      <StyledTableCell align="center"><img src={item.image} width="60" height="70" alt={item.name} /></StyledTableCell>
                                      <StyledTableCell align="center">{item.name}</StyledTableCell>
                                      <StyledTableCell align="center">{item.code}</StyledTableCell>
                                      <StyledTableCell align="center">{item.type === 0 ? "Estivo" : "Invernale"}</StyledTableCell>
                                      <StyledTableCell align="center">{item.number_of_media}</StyledTableCell>
                                      <StyledTableCell align="center">
                                        <IconButton key={index} onClick={() => onViewProduct(item.id)}>
                                          <EditIcon color="primary" />
                                        </IconButton>
                                        <IconButton key={index} onClick={() => onOpenModalConfirmDeleteProduct(item)}>
                                          <DeleteIcon color="error" />
                                        </IconButton>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))
                                ) : (
                                  dataProducts.sortProductsAdmin.filter(item => (item.name?.toLowerCase().includes(search.toLowerCase()) || item.code.toLowerCase().includes(search.toLowerCase())) && item.bestseller === 1).map((item, index) => (
                                    <StyledTableRow key={`2-${index}`}>
                                      <StyledTableCell align="left" style={{ width: "50px" }}>
                                        <Checkbox
                                          color="primary"
                                          name={item.id}
                                          onChange={onChangeMassiveDelete}
                                          checked={checkboxMassiveDelete?.indexOf(item.id.toString()) >= 0 ? true : false}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                      <StyledTableCell align="center"><div className={item.stock === 0 ? style.pointGreen : style.pointRed}/></StyledTableCell>
                                      <StyledTableCell align="center">{item.total_quantity > 0 ? item.total_quantity : ""}</StyledTableCell>
                                      <StyledTableCell align="center"><img src={item.image} width="60" height="70" alt={item.name} /></StyledTableCell>
                                      <StyledTableCell align="center">{item.name}</StyledTableCell>
                                      <StyledTableCell align="center">{item.code}</StyledTableCell>
                                      <StyledTableCell align="center">{item.type === 0 ? "Estivo" : "Invernale"}</StyledTableCell>
                                      <StyledTableCell align="center">{item.number_of_media}</StyledTableCell>
                                      <StyledTableCell align="center">
                                        <IconButton key={index} onClick={() => onViewProduct(item.id)}>
                                          <EditIcon color="primary" />
                                        </IconButton>
                                        <IconButton key={index} onClick={() => onOpenModalConfirmDeleteProduct(item)}>
                                          <DeleteIcon color="error" />
                                        </IconButton>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))
                                )
                              ) : (
                                viewLuxury === 1 ? (
                                  dataProducts.sortProductsAdmin.filter(item => (item.name?.toLowerCase().includes(search.toLowerCase()) || item.code.toLowerCase().includes(search.toLowerCase())) && item.bestseller === 1 && item.luxury === 1 && item?.gender?.id === filterGender).map((item, index) => (
                                    <StyledTableRow key={`3-${index}`}>
                                      <StyledTableCell align="left" style={{ width: "50px" }}>
                                        <Checkbox
                                          color="primary"
                                          name={item.id}
                                          onChange={onChangeMassiveDelete}
                                          checked={checkboxMassiveDelete?.indexOf(item.id.toString()) >= 0 ? true : false}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                      <StyledTableCell align="center"><div className={item.stock === 0 ? style.pointGreen : style.pointRed}/></StyledTableCell>
                                      <StyledTableCell align="center">{item.total_quantity > 0 ? item.total_quantity : ""}</StyledTableCell>
                                      <StyledTableCell align="center"><img src={item.image} width="60" height="70" alt={item.name} /></StyledTableCell>
                                      <StyledTableCell align="center">{item.name}</StyledTableCell>
                                      <StyledTableCell align="center">{item.code}</StyledTableCell>
                                      <StyledTableCell align="center">{item.type === 0 ? "Estivo" : "Invernale"}</StyledTableCell>
                                      <StyledTableCell align="center">{item.number_of_media}</StyledTableCell>
                                      <StyledTableCell align="center">
                                        <IconButton key={index} onClick={() => onViewProduct(item.id)}>
                                          <EditIcon color="primary" />
                                        </IconButton>
                                        <IconButton key={index} onClick={() => onOpenModalConfirmDeleteProduct(item)}>
                                          <DeleteIcon color="error" />
                                        </IconButton>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))
                                ) : (
                                  dataProducts.sortProductsAdmin.filter(item => (item.name?.toLowerCase().includes(search.toLowerCase()) || item.code.toLowerCase().includes(search.toLowerCase())) && item.bestseller === 1 && item?.gender?.id === filterGender).map((item, index) => (
                                    <StyledTableRow key={`4-${index}`}>
                                      <StyledTableCell align="left" style={{ width: "50px" }}>
                                        <Checkbox
                                          color="primary"
                                          name={item.id}
                                          onChange={onChangeMassiveDelete}
                                          checked={checkboxMassiveDelete?.indexOf(item.id.toString()) >= 0 ? true : false}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                      <StyledTableCell align="center"><div className={item.stock === 0 ? style.pointGreen : style.pointRed}/></StyledTableCell>
                                      <StyledTableCell align="center">{item.total_quantity > 0 ? item.total_quantity : ""}</StyledTableCell>
                                      <StyledTableCell align="center"><img src={item.image} width="60" height="70" alt={item.name} /></StyledTableCell>
                                      <StyledTableCell align="center">{item.name}</StyledTableCell>
                                      <StyledTableCell align="center">{item.code}</StyledTableCell>
                                      <StyledTableCell align="center">{item.type === 0 ? "Estivo" : "Invernale"}</StyledTableCell>
                                      <StyledTableCell align="center">{item.number_of_media}</StyledTableCell>
                                      <StyledTableCell align="center">
                                        <IconButton key={index} onClick={() => onViewProduct(item.id)}>
                                          <EditIcon color="primary" />
                                        </IconButton>
                                        <IconButton key={index} onClick={() => onOpenModalConfirmDeleteProduct(item)}>
                                          <DeleteIcon color="error" />
                                        </IconButton>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))
                                )
                              )
                            ) :(
                              filterGender === "" ? (
                                viewLuxury === 1 ? (
                                  dataProducts.sortProductsAdmin.filter(item => (item.name?.toLowerCase().includes(search.toLowerCase()) || item.code.toLowerCase().includes(search.toLowerCase())) && item.luxury === 1).map((item, index) => (
                                    <StyledTableRow key={`5-${index}`}>
                                      <StyledTableCell align="left" style={{ width: "50px" }}>
                                        <Checkbox
                                          color="primary"
                                          name={item.id}
                                          onChange={onChangeMassiveDelete}
                                          checked={checkboxMassiveDelete?.indexOf(item.id.toString()) >= 0 ? true : false}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                      <StyledTableCell align="center"><div className={item.stock === 0 ? style.pointGreen : style.pointRed}/></StyledTableCell>
                                      <StyledTableCell align="center">{item.total_quantity > 0 ? item.total_quantity : ""}</StyledTableCell>
                                      <StyledTableCell align="center"><img src={item.image} width="60" height="70" alt={item.name} /></StyledTableCell>
                                      <StyledTableCell align="center">{item.name}</StyledTableCell>
                                      <StyledTableCell align="center">{item.code}</StyledTableCell>
                                      <StyledTableCell align="center">{item.type === 0 ? "Estivo" : "Invernale"}</StyledTableCell>
                                      <StyledTableCell align="center">{item.number_of_media}</StyledTableCell>
                                      <StyledTableCell align="center">
                                        <IconButton key={index} onClick={() => onViewProduct(item.id)}>
                                          <EditIcon color="primary" />
                                        </IconButton>
                                        <IconButton key={index} onClick={() => onOpenModalConfirmDeleteProduct(item)}>
                                          <DeleteIcon color="error" />
                                        </IconButton>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))
                                ) : (
                                  dataProducts.sortProductsAdmin.filter(item => item.name?.toLowerCase().includes(search.toLowerCase()) || item.code.toLowerCase().includes(search.toLowerCase())).map((item, index) => (
                                    <StyledTableRow key={`6-${index}`}>
                                      <StyledTableCell align="left" style={{ width: "50px" }}>
                                        <Checkbox
                                          color="primary"
                                          name={item.id}
                                          onChange={onChangeMassiveDelete}
                                          checked={checkboxMassiveDelete?.indexOf(item.id.toString()) >= 0 ? true : false}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                      <StyledTableCell align="center"><div className={item.stock === 0 ? style.pointGreen : style.pointRed}/></StyledTableCell>
                                      <StyledTableCell align="center">{item.total_quantity > 0 ? item.total_quantity : ""}</StyledTableCell>
                                      <StyledTableCell align="center"><img src={item.image} width="60" height="70" alt={item.name} /></StyledTableCell>
                                      <StyledTableCell align="center">{item.name}</StyledTableCell>
                                      <StyledTableCell align="center">{item.code}</StyledTableCell>
                                      <StyledTableCell align="center">{item.type === 0 ? "Estivo" : "Invernale"}</StyledTableCell>
                                      <StyledTableCell align="center">{item.number_of_media}</StyledTableCell>
                                      <StyledTableCell align="center">
                                        <IconButton key={index} onClick={() => onViewProduct(item.id)}>
                                          <EditIcon color="primary" />
                                        </IconButton>
                                        <IconButton key={index} onClick={() => onOpenModalConfirmDeleteProduct(item)}>
                                          <DeleteIcon color="error" />
                                        </IconButton>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))
                                )
                              ): (
                                viewLuxury === 1 ? (
                                  dataProducts.sortProductsAdmin.filter(item => (item.name?.toLowerCase().includes(search.toLowerCase()) || item.code.toLowerCase().includes(search.toLowerCase())) && item.gender?.id === filterGender && item.luxury === 1).map((item, index) => (
                                    <StyledTableRow key={`7-${index}`}>
                                      <StyledTableCell align="left" style={{ width: "50px" }}>
                                        <Checkbox
                                          color="primary"
                                          name={item.id}
                                          onChange={onChangeMassiveDelete}
                                          checked={checkboxMassiveDelete?.indexOf(item.id.toString()) >= 0 ? true : false}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                      <StyledTableCell align="center"><div className={item.stock === 0 ? style.pointGreen : style.pointRed}/></StyledTableCell>
                                      <StyledTableCell align="center">{item.total_quantity > 0 ? item.total_quantity : ""}</StyledTableCell>
                                      <StyledTableCell align="center"><img src={item.image} width="60" height="70" alt={item.name} /></StyledTableCell>
                                      <StyledTableCell align="center">{item.name}</StyledTableCell>
                                      <StyledTableCell align="center">{item.code}</StyledTableCell>
                                      <StyledTableCell align="center">{item.type === 0 ? "Estivo" : "Invernale"}</StyledTableCell>
                                      <StyledTableCell align="center">{item.number_of_media}</StyledTableCell>
                                      <StyledTableCell align="center">
                                        <IconButton key={index} onClick={() => onViewProduct(item.id)}>
                                          <EditIcon color="primary" />
                                        </IconButton>
                                        <IconButton key={index} onClick={() => onOpenModalConfirmDeleteProduct(item)}>
                                          <DeleteIcon color="error" />
                                        </IconButton>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))
                                ) : (
                                  dataProducts.sortProductsAdmin.filter(item => (item.name?.toLowerCase().includes(search.toLowerCase()) || item.code.toLowerCase().includes(search.toLowerCase())) && item.gender?.id === filterGender).map((item, index) => (
                                    <StyledTableRow key={`8-${index}`}>
                                      <StyledTableCell align="left" style={{ width: "50px" }}>
                                        <Checkbox
                                          color="primary"
                                          name={item.id}
                                          onChange={onChangeMassiveDelete}
                                          checked={checkboxMassiveDelete?.indexOf(item.id.toString()) >= 0 ? true : false}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                      <StyledTableCell align="center"><div className={item.stock === 0 ? style.pointGreen : style.pointRed}/></StyledTableCell>
                                      <StyledTableCell align="center">{item.total_quantity > 0 ? item.total_quantity : ""}</StyledTableCell>
                                      <StyledTableCell align="center"><img src={item.image} width="60" height="70" alt={item.name} /></StyledTableCell>
                                      <StyledTableCell align="center">{item.name}</StyledTableCell>
                                      <StyledTableCell align="center">{item.code}</StyledTableCell>
                                      <StyledTableCell align="center">{item.type === 0 ? "Estivo" : "Invernale"}</StyledTableCell>
                                      <StyledTableCell align="center">{item.number_of_media}</StyledTableCell>
                                      <StyledTableCell align="center">
                                        <IconButton key={index} onClick={() => onViewProduct(item.id)}>
                                          <EditIcon color="primary" />
                                        </IconButton>
                                        <IconButton key={index} onClick={() => onOpenModalConfirmDeleteProduct(item)}>
                                          <DeleteIcon color="error" />
                                        </IconButton>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))
                                )
                              )
                            )
                          ) : (
                            viewBestseller === 1 ? (
                              filterGender === "" ? (
                                viewLuxury === 1 ? (
                                  dataProducts.sortProductsAdmin.filter(item => item.bestseller === 1 && item.luxury === 1).map((item, index) => (
                                    <StyledTableRow key={`9-${index}`}>
                                      <StyledTableCell align="left" style={{ width: "50px" }}>
                                        <Checkbox
                                          color="primary"
                                          name={item.id}
                                          onChange={onChangeMassiveDelete}
                                          checked={checkboxMassiveDelete?.indexOf(item.id.toString()) >= 0 ? true : false}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                      <StyledTableCell align="center"><div className={item.stock === 0 ? style.pointGreen : style.pointRed}/></StyledTableCell>
                                      <StyledTableCell align="center">{item.total_quantity > 0 ? item.total_quantity : ""}</StyledTableCell>
                                      <StyledTableCell align="center"><img src={item.image} width="60" height="70" alt={item.name} /></StyledTableCell>
                                      <StyledTableCell align="center">{item.name}</StyledTableCell>
                                      <StyledTableCell align="center">{item.code}</StyledTableCell>
                                      <StyledTableCell align="center">{item.type === 0 ? "Estivo" : "Invernale"}</StyledTableCell>
                                      <StyledTableCell align="center">{item.number_of_media}</StyledTableCell>
                                      <StyledTableCell align="center">
                                        <IconButton key={index} onClick={() => onViewProduct(item.id)}>
                                          <EditIcon color="primary" />
                                        </IconButton>
                                        <IconButton key={index} onClick={() => onOpenModalConfirmDeleteProduct(item)}>
                                          <DeleteIcon color="error" />
                                        </IconButton>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))
                                ) : (
                                  dataProducts.sortProductsAdmin.filter(item => item.bestseller === 1).map((item, index) => (
                                    <StyledTableRow key={`10-${index}`}>
                                      <StyledTableCell align="left" style={{ width: "50px" }}>
                                        <Checkbox
                                          color="primary"
                                          name={item.id}
                                          onChange={onChangeMassiveDelete}
                                          checked={checkboxMassiveDelete?.indexOf(item.id.toString()) >= 0 ? true : false}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                      <StyledTableCell align="center"><div className={item.stock === 0 ? style.pointGreen : style.pointRed}/></StyledTableCell>
                                      <StyledTableCell align="center">{item.total_quantity > 0 ? item.total_quantity : ""}</StyledTableCell>
                                      <StyledTableCell align="center"><img src={item.image} width="60" height="70" alt={item.name} /></StyledTableCell>
                                      <StyledTableCell align="center">{item.name}</StyledTableCell>
                                      <StyledTableCell align="center">{item.code}</StyledTableCell>
                                      <StyledTableCell align="center">{item.type === 0 ? "Estivo" : "Invernale"}</StyledTableCell>
                                      <StyledTableCell align="center">{item.number_of_media}</StyledTableCell>
                                      <StyledTableCell align="center">
                                        <IconButton key={index} onClick={() => onViewProduct(item.id)}>
                                          <EditIcon color="primary" />
                                        </IconButton>
                                        <IconButton key={index} onClick={() => onOpenModalConfirmDeleteProduct(item)}>
                                          <DeleteIcon color="error" />
                                        </IconButton>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))
                                )
                              ) : (
                                viewLuxury === 1 ? (
                                  dataProducts.sortProductsAdmin.filter(item => item.bestseller === 1 && item.gender?.id === filterGender && item.luxury === 1).map((item, index) => (
                                    <StyledTableRow key={`11-${index}`}>
                                      <StyledTableCell align="left" style={{ width: "50px" }}>
                                        <Checkbox
                                          color="primary"
                                          name={item.id}
                                          onChange={onChangeMassiveDelete}
                                          checked={checkboxMassiveDelete?.indexOf(item.id.toString()) >= 0 ? true : false}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                      <StyledTableCell align="center"><div className={item.stock === 0 ? style.pointGreen : style.pointRed}/></StyledTableCell>
                                      <StyledTableCell align="center">{item.total_quantity > 0 ? item.total_quantity : ""}</StyledTableCell>
                                      <StyledTableCell align="center"><img src={item.image} width="60" height="70" alt={item.name} /></StyledTableCell>
                                      <StyledTableCell align="center">{item.name}</StyledTableCell>
                                      <StyledTableCell align="center">{item.code}</StyledTableCell>
                                      <StyledTableCell align="center">{item.type === 0 ? "Estivo" : "Invernale"}</StyledTableCell>
                                      <StyledTableCell align="center">{item.number_of_media}</StyledTableCell>
                                      <StyledTableCell align="center">
                                        <IconButton key={index} onClick={() => onViewProduct(item.id)}>
                                          <EditIcon color="primary" />
                                        </IconButton>
                                        <IconButton key={index} onClick={() => onOpenModalConfirmDeleteProduct(item)}>
                                          <DeleteIcon color="error" />
                                        </IconButton>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))
                                ) : (
                                  dataProducts.sortProductsAdmin.filter(item => item.bestseller === 1 && item.gender?.id === filterGender).map((item, index) => (
                                    <StyledTableRow key={`12-${index}`}>
                                      <StyledTableCell align="left" style={{ width: "50px" }}>
                                        <Checkbox
                                          color="primary"
                                          name={item.id}
                                          onChange={onChangeMassiveDelete}
                                          checked={checkboxMassiveDelete?.indexOf(item.id.toString()) >= 0 ? true : false}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                      <StyledTableCell align="center"><div className={item.stock === 0 ? style.pointGreen : style.pointRed}/></StyledTableCell>
                                      <StyledTableCell align="center">{item.total_quantity > 0 ? item.total_quantity : ""}</StyledTableCell>
                                      <StyledTableCell align="center"><img src={item.image} width="60" height="70" alt={item.name} /></StyledTableCell>
                                      <StyledTableCell align="center">{item.name}</StyledTableCell>
                                      <StyledTableCell align="center">{item.code}</StyledTableCell>
                                      <StyledTableCell align="center">{item.type === 0 ? "Estivo" : "Invernale"}</StyledTableCell>
                                      <StyledTableCell align="center">{item.number_of_media}</StyledTableCell>
                                      <StyledTableCell align="center">
                                        <IconButton key={index} onClick={() => onViewProduct(item.id)}>
                                          <EditIcon color="primary" />
                                        </IconButton>
                                        <IconButton key={index} onClick={() => onOpenModalConfirmDeleteProduct(item)}>
                                          <DeleteIcon color="error" />
                                        </IconButton>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))
                                )
                              )
                            ) : (
                              filterGender === "" ? (
                                viewLuxury === 1 ? (
                                  dataProducts.sortProductsAdmin.filter(item => item.luxury === 1).map((item, index) => (
                                    <StyledTableRow key={`13-${index}`}>
                                      <StyledTableCell align="left" style={{ width: "50px" }}>
                                        <Checkbox
                                          color="primary"
                                          name={item.id}
                                          onChange={onChangeMassiveDelete}
                                          checked={checkboxMassiveDelete?.indexOf(item.id.toString()) >= 0 ? true : false}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                      <StyledTableCell align="center"><div className={item.stock === 0 ? style.pointGreen : style.pointRed}/></StyledTableCell>
                                      <StyledTableCell align="center">{item.total_quantity > 0 ? item.total_quantity : ""}</StyledTableCell>
                                      <StyledTableCell align="center"><img src={item.image} width="60" height="70" alt={item.name} /></StyledTableCell>
                                      <StyledTableCell align="center">{item.name}</StyledTableCell>
                                      <StyledTableCell align="center">{item.code}</StyledTableCell>
                                      <StyledTableCell align="center">{item.type === 0 ? "Estivo" : "Invernale"}</StyledTableCell>
                                      <StyledTableCell align="center">{item.number_of_media}</StyledTableCell>
                                      <StyledTableCell align="center">
                                        <IconButton key={index} onClick={() => onViewProduct(item.id)}>
                                          <EditIcon color="primary" />
                                        </IconButton>
                                        <IconButton key={index} onClick={() => onOpenModalConfirmDeleteProduct(item)}>
                                          <DeleteIcon color="error" />
                                        </IconButton>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))
                                ) : (
                                  dataProducts.sortProductsAdmin.map((item, index) => (
                                    <StyledTableRow key={`14-${index}`}>
                                      <StyledTableCell align="left" style={{ width: "50px" }}>
                                        <Checkbox
                                          color="primary"
                                          name={item.id}
                                          onChange={onChangeMassiveDelete}
                                          checked={checkboxMassiveDelete?.indexOf(item.id.toString()) >= 0 ? true : false}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                      <StyledTableCell align="center"><div className={item.stock === 0 ? style.pointGreen : style.pointRed}/></StyledTableCell>
                                      <StyledTableCell align="center">{item.total_quantity > 0 ? item.total_quantity : ""}</StyledTableCell>
                                      <StyledTableCell align="center"><img src={item.image} width="60" height="70" alt={item.name} /></StyledTableCell>
                                      <StyledTableCell align="center">{item.name}</StyledTableCell>
                                      <StyledTableCell align="center">{item.code}</StyledTableCell>
                                      <StyledTableCell align="center">{item.type === 0 ? "Estivo" : "Invernale"}</StyledTableCell>
                                      <StyledTableCell align="center">{item.number_of_media}</StyledTableCell>
                                      <StyledTableCell align="center">
                                        <IconButton key={index} onClick={() => onViewProduct(item.id)}>
                                          <EditIcon color="primary" />
                                        </IconButton>
                                        <IconButton key={index} onClick={() => onOpenModalConfirmDeleteProduct(item)}>
                                          <DeleteIcon color="error" />
                                        </IconButton>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))
                                )
                              ) : (
                                viewLuxury === 1 ? (
                                  dataProducts.sortProductsAdmin.filter(item => item.gender?.id === filterGender && item.luxury === 1).map((item, index) => (
                                    <StyledTableRow key={`15-${index}`}>
                                      <StyledTableCell align="left" style={{ width: "50px" }}>
                                        <Checkbox
                                          color="primary"
                                          name={item.id}
                                          onChange={onChangeMassiveDelete}
                                          checked={checkboxMassiveDelete?.indexOf(item.id.toString()) >= 0 ? true : false}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                      <StyledTableCell align="center"><div className={item.stock === 0 ? style.pointGreen : style.pointRed}/></StyledTableCell>
                                      <StyledTableCell align="center">{item.total_quantity > 0 ? item.total_quantity : ""}</StyledTableCell>
                                      <StyledTableCell align="center"><img src={item.image} width="60" height="70" alt={item.name} /></StyledTableCell>
                                      <StyledTableCell align="center">{item.name}</StyledTableCell>
                                      <StyledTableCell align="center">{item.code}</StyledTableCell>
                                      <StyledTableCell align="center">{item.type === 0 ? "Estivo" : "Invernale"}</StyledTableCell>
                                      <StyledTableCell align="center">{item.number_of_media}</StyledTableCell>
                                      <StyledTableCell align="center">
                                        <IconButton key={index} onClick={() => onViewProduct(item.id)}>
                                          <EditIcon color="primary" />
                                        </IconButton>
                                        <IconButton key={index} onClick={() => onOpenModalConfirmDeleteProduct(item)}>
                                          <DeleteIcon color="error" />
                                        </IconButton>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))
                                ) : (
                                  dataProducts.sortProductsAdmin.filter(item => item.gender?.id === filterGender).map((item, index) => (
                                    <StyledTableRow key={`16-${index}`}>
                                      <StyledTableCell align="left" style={{ width: "50px" }}>
                                        <Checkbox
                                          color="primary"
                                          name={item.id}
                                          onChange={onChangeMassiveDelete}
                                          checked={checkboxMassiveDelete?.indexOf(item.id.toString()) >= 0 ? true : false}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                      <StyledTableCell align="center"><div className={item.stock === 0 ? style.pointGreen : style.pointRed}/></StyledTableCell>
                                      <StyledTableCell align="center">{item.total_quantity > 0 ? item.total_quantity : ""}</StyledTableCell>
                                      <StyledTableCell align="center"><img src={item.image} width="60" height="70" alt={item.name} /></StyledTableCell>
                                      <StyledTableCell align="center">{item.name}</StyledTableCell>
                                      <StyledTableCell align="center">{item.code}</StyledTableCell>
                                      <StyledTableCell align="center">{item.type === 0 ? "Estivo" : "Invernale"}</StyledTableCell>
                                      <StyledTableCell align="center">{item.number_of_media}</StyledTableCell>
                                      <StyledTableCell align="center">
                                        <IconButton key={index} onClick={() => onViewProduct(item.id)}>
                                          <EditIcon color="primary" />
                                        </IconButton>
                                        <IconButton key={index} onClick={() => onOpenModalConfirmDeleteProduct(item)}>
                                          <DeleteIcon color="error" />
                                        </IconButton>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))
                                )
                              )
                            )
                          )
                        ) : (
                          search ? (
                            viewBestseller === 1 ? (
                              filterGender === "" ? (
                                viewLuxury === 1 ? (
                                  dataProducts.sortProductsAdmin.filter(item => (item.name?.toLowerCase().includes(search.toLowerCase()) || item.code.toLowerCase().includes(search.toLowerCase())) && item.season?.name === filterType && item.bestseller === 1 && item.luxury === 1).map((item, index) => (
                                    <StyledTableRow key={`17-${index}`}>
                                      <StyledTableCell align="left" style={{ width: "50px" }}>
                                        <Checkbox
                                          color="primary"
                                          name={item.id}
                                          onChange={onChangeMassiveDelete}
                                          checked={checkboxMassiveDelete?.indexOf(item.id.toString()) >= 0 ? true : false}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                      <StyledTableCell align="center"><div className={item.stock === 0 ? style.pointGreen : style.pointRed}/></StyledTableCell>
                                      <StyledTableCell align="center">{item.total_quantity > 0 ? item.total_quantity : ""}</StyledTableCell>
                                      <StyledTableCell align="center"><img src={item.image} width="60" height="70" alt={item.name} /></StyledTableCell>
                                      <StyledTableCell align="center">{item.name}</StyledTableCell>
                                      <StyledTableCell align="center">{item.code}</StyledTableCell>
                                      <StyledTableCell align="center">{item.type === 0 ? "Estivo" : "Invernale"}</StyledTableCell>
                                      <StyledTableCell align="center">{item.number_of_media}</StyledTableCell>
                                      <StyledTableCell align="center">
                                        <IconButton key={index} onClick={() => onViewProduct(item.id)}>
                                          <EditIcon color="primary" />
                                        </IconButton>
                                        <IconButton key={index} onClick={() => onOpenModalConfirmDeleteProduct(item)}>
                                          <DeleteIcon color="error" />
                                        </IconButton>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))
                                ) : (
                                  dataProducts.sortProductsAdmin.filter(item => (item.name?.toLowerCase().includes(search.toLowerCase()) || item.code.toLowerCase().includes(search.toLowerCase())) && item.season?.name === filterType && item.bestseller === 1).map((item, index) => (
                                    <StyledTableRow key={`18-${index}`}>
                                      <StyledTableCell align="left" style={{ width: "50px" }}>
                                        <Checkbox
                                          color="primary"
                                          name={item.id}
                                          onChange={onChangeMassiveDelete}
                                          checked={checkboxMassiveDelete?.indexOf(item.id.toString()) >= 0 ? true : false}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                      <StyledTableCell align="center"><div className={item.stock === 0 ? style.pointGreen : style.pointRed}/></StyledTableCell>
                                      <StyledTableCell align="center">{item.total_quantity > 0 ? item.total_quantity : ""}</StyledTableCell>
                                      <StyledTableCell align="center"><img src={item.image} width="60" height="70" alt={item.name} /></StyledTableCell>
                                      <StyledTableCell align="center">{item.name}</StyledTableCell>
                                      <StyledTableCell align="center">{item.code}</StyledTableCell>
                                      <StyledTableCell align="center">{item.type === 0 ? "Estivo" : "Invernale"}</StyledTableCell>
                                      <StyledTableCell align="center">{item.number_of_media}</StyledTableCell>
                                      <StyledTableCell align="center">
                                        <IconButton key={index} onClick={() => onViewProduct(item.id)}>
                                          <EditIcon color="primary" />
                                        </IconButton>
                                        <IconButton key={index} onClick={() => onOpenModalConfirmDeleteProduct(item)}>
                                          <DeleteIcon color="error" />
                                        </IconButton>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))
                                )
                              ) : (
                                viewLuxury === 1 ? (
                                  dataProducts.sortProductsAdmin.filter(item => (item.name?.toLowerCase().includes(search.toLowerCase()) || item.code.toLowerCase().includes(search.toLowerCase())) && item.season?.name === filterType && item.bestseller === 1 && item.gender?.id === filterGender && item.luxury === 1).map((item, index) => (
                                    <StyledTableRow key={`19-${index}`}>
                                      <StyledTableCell align="left" style={{ width: "50px" }}>
                                        <Checkbox
                                          color="primary"
                                          name={item.id}
                                          onChange={onChangeMassiveDelete}
                                          checked={checkboxMassiveDelete?.indexOf(item.id.toString()) >= 0 ? true : false}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                      <StyledTableCell align="center"><div className={item.stock === 0 ? style.pointGreen : style.pointRed}/></StyledTableCell>
                                      <StyledTableCell align="center">{item.total_quantity > 0 ? item.total_quantity : ""}</StyledTableCell>
                                      <StyledTableCell align="center"><img src={item.image} width="60" height="70" alt={item.name} /></StyledTableCell>
                                      <StyledTableCell align="center">{item.name}</StyledTableCell>
                                      <StyledTableCell align="center">{item.code}</StyledTableCell>
                                      <StyledTableCell align="center">{item.type === 0 ? "Estivo" : "Invernale"}</StyledTableCell>
                                      <StyledTableCell align="center">{item.number_of_media}</StyledTableCell>
                                      <StyledTableCell align="center">
                                        <IconButton key={index} onClick={() => onViewProduct(item.id)}>
                                          <EditIcon color="primary" />
                                        </IconButton>
                                        <IconButton key={index} onClick={() => onOpenModalConfirmDeleteProduct(item)}>
                                          <DeleteIcon color="error" />
                                        </IconButton>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))
                                ) : (
                                  dataProducts.sortProductsAdmin.filter(item => (item.name?.toLowerCase().includes(search.toLowerCase()) || item.code.toLowerCase().includes(search.toLowerCase())) && item.season?.name === filterType && item.bestseller === 1 && item.gender?.id === filterGender).map((item, index) => (
                                    <StyledTableRow key={`20-${index}`}>
                                      <StyledTableCell align="left" style={{ width: "50px" }}>
                                        <Checkbox
                                          color="primary"
                                          name={item.id}
                                          onChange={onChangeMassiveDelete}
                                          checked={checkboxMassiveDelete?.indexOf(item.id.toString()) >= 0 ? true : false}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                      <StyledTableCell align="center"><div className={item.stock === 0 ? style.pointGreen : style.pointRed}/></StyledTableCell>
                                      <StyledTableCell align="center">{item.total_quantity > 0 ? item.total_quantity : ""}</StyledTableCell>
                                      <StyledTableCell align="center"><img src={item.image} width="60" height="70" alt={item.name} /></StyledTableCell>
                                      <StyledTableCell align="center">{item.name}</StyledTableCell>
                                      <StyledTableCell align="center">{item.code}</StyledTableCell>
                                      <StyledTableCell align="center">{item.type === 0 ? "Estivo" : "Invernale"}</StyledTableCell>
                                      <StyledTableCell align="center">{item.number_of_media}</StyledTableCell>
                                      <StyledTableCell align="center">
                                        <IconButton key={index} onClick={() => onViewProduct(item.id)}>
                                          <EditIcon color="primary" />
                                        </IconButton>
                                        <IconButton key={index} onClick={() => onOpenModalConfirmDeleteProduct(item)}>
                                          <DeleteIcon color="error" />
                                        </IconButton>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))
                                )
                              )
                            ) : (
                              filterGender === "" ? (
                                viewLuxury === 1 ? (
                                  dataProducts.sortProductsAdmin.filter(item => (item.name?.toLowerCase().includes(search.toLowerCase()) || item.code.toLowerCase().includes(search.toLowerCase())) && item.season?.name === filterType && item.luxury === 1).map((item, index) => (
                                    <StyledTableRow key={`21-${index}`}>
                                      <StyledTableCell align="left" style={{ width: "50px" }}>
                                        <Checkbox
                                          color="primary"
                                          name={item.id}
                                          onChange={onChangeMassiveDelete}
                                          checked={checkboxMassiveDelete?.indexOf(item.id.toString()) >= 0 ? true : false}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                      <StyledTableCell align="center"><div className={item.stock === 0 ? style.pointGreen : style.pointRed}/></StyledTableCell>
                                      <StyledTableCell align="center">{item.total_quantity > 0 ? item.total_quantity : ""}</StyledTableCell>
                                      <StyledTableCell align="center"><img src={item.image} width="60" height="70" alt={item.name} /></StyledTableCell>
                                      <StyledTableCell align="center">{item.name}</StyledTableCell>
                                      <StyledTableCell align="center">{item.code}</StyledTableCell>
                                      <StyledTableCell align="center">{item.type === 0 ? "Estivo" : "Invernale"}</StyledTableCell>
                                      <StyledTableCell align="center">{item.number_of_media}</StyledTableCell>
                                      <StyledTableCell align="center">
                                        <IconButton key={index} onClick={() => onViewProduct(item.id)}>
                                          <EditIcon color="primary" />
                                        </IconButton>
                                        <IconButton key={index} onClick={() => onOpenModalConfirmDeleteProduct(item)}>
                                          <DeleteIcon color="error" />
                                        </IconButton>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))
                                ) : (
                                  viewLuxury === 1 ? (
                                    dataProducts.sortProductsAdmin.filter(item => (item.name?.toLowerCase().includes(search.toLowerCase()) || item.code.toLowerCase().includes(search.toLowerCase())) && item.season?.name === filterType && item.luxury === 1).map((item, index) => (
                                      <StyledTableRow key={`22-${index}`}>
                                        <StyledTableCell align="left" style={{ width: "50px" }}>
                                          <Checkbox
                                            color="primary"
                                            name={item.id}
                                            onChange={onChangeMassiveDelete}
                                            checked={checkboxMassiveDelete?.indexOf(item.id.toString()) >= 0 ? true : false}
                                          />
                                        </StyledTableCell>
                                        <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                        <StyledTableCell align="center"><div className={item.stock === 0 ? style.pointGreen : style.pointRed}/></StyledTableCell>
                                        <StyledTableCell align="center">{item.total_quantity > 0 ? item.total_quantity : ""}</StyledTableCell>
                                        <StyledTableCell align="center"><img src={item.image} width="60" height="70" alt={item.name} /></StyledTableCell>
                                        <StyledTableCell align="center">{item.name}</StyledTableCell>
                                        <StyledTableCell align="center">{item.code}</StyledTableCell>
                                        <StyledTableCell align="center">{item.type === 0 ? "Estivo" : "Invernale"}</StyledTableCell>
                                        <StyledTableCell align="center">{item.number_of_media}</StyledTableCell>
                                        <StyledTableCell align="center">
                                          <IconButton key={index} onClick={() => onViewProduct(item.id)}>
                                            <EditIcon color="primary" />
                                          </IconButton>
                                          <IconButton key={index} onClick={() => onOpenModalConfirmDeleteProduct(item)}>
                                            <DeleteIcon color="error" />
                                          </IconButton>
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    ))
                                  ) : (
                                    dataProducts.sortProductsAdmin.filter(item => (item.name?.toLowerCase().includes(search.toLowerCase()) || item.code.toLowerCase().includes(search.toLowerCase())) && item.season?.name === filterType).map((item, index) => (
                                      <StyledTableRow key={`23-${index}`}>
                                        <StyledTableCell align="left" style={{ width: "50px" }}>
                                          <Checkbox
                                            color="primary"
                                            name={item.id}
                                            onChange={onChangeMassiveDelete}
                                            checked={checkboxMassiveDelete?.indexOf(item.id.toString()) >= 0 ? true : false}
                                          />
                                        </StyledTableCell>
                                        <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                        <StyledTableCell align="center"><div className={item.stock === 0 ? style.pointGreen : style.pointRed}/></StyledTableCell>
                                        <StyledTableCell align="center">{item.total_quantity > 0 ? item.total_quantity : ""}</StyledTableCell>
                                        <StyledTableCell align="center"><img src={item.image} width="60" height="70" alt={item.name} /></StyledTableCell>
                                        <StyledTableCell align="center">{item.name}</StyledTableCell>
                                        <StyledTableCell align="center">{item.code}</StyledTableCell>
                                        <StyledTableCell align="center">{item.type === 0 ? "Estivo" : "Invernale"}</StyledTableCell>
                                        <StyledTableCell align="center">{item.number_of_media}</StyledTableCell>
                                        <StyledTableCell align="center">
                                          <IconButton key={index} onClick={() => onViewProduct(item.id)}>
                                            <EditIcon color="primary" />
                                          </IconButton>
                                          <IconButton key={index} onClick={() => onOpenModalConfirmDeleteProduct(item)}>
                                            <DeleteIcon color="error" />
                                          </IconButton>
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    ))
                                  )
                                )
                              ) : (
                                viewLuxury === 1 ? (
                                  dataProducts.sortProductsAdmin.filter(item => (item.name?.toLowerCase().includes(search.toLowerCase()) || item.code.toLowerCase().includes(search.toLowerCase())) && item.season?.name === filterType && item.gender?.id === filterGender && item.luxury === 1).map((item, index) => (
                                    <StyledTableRow key={`24-${index}`}>
                                      <StyledTableCell align="left" style={{ width: "50px" }}>
                                        <Checkbox
                                          color="primary"
                                          name={item.id}
                                          onChange={onChangeMassiveDelete}
                                          checked={checkboxMassiveDelete?.indexOf(item.id.toString()) >= 0 ? true : false}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                      <StyledTableCell align="center"><div className={item.stock === 0 ? style.pointGreen : style.pointRed}/></StyledTableCell>
                                      <StyledTableCell align="center">{item.total_quantity > 0 ? item.total_quantity : ""}</StyledTableCell>
                                      <StyledTableCell align="center"><img src={item.image} width="60" height="70" alt={item.name} /></StyledTableCell>
                                      <StyledTableCell align="center">{item.name}</StyledTableCell>
                                      <StyledTableCell align="center">{item.code}</StyledTableCell>
                                      <StyledTableCell align="center">{item.type === 0 ? "Estivo" : "Invernale"}</StyledTableCell>
                                      <StyledTableCell align="center">{item.number_of_media}</StyledTableCell>
                                      <StyledTableCell align="center">
                                        <IconButton key={index} onClick={() => onViewProduct(item.id)}>
                                          <EditIcon color="primary" />
                                        </IconButton>
                                        <IconButton key={index} onClick={() => onOpenModalConfirmDeleteProduct(item)}>
                                          <DeleteIcon color="error" />
                                        </IconButton>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))
                                ) : (
                                  dataProducts.sortProductsAdmin.filter(item => (item.name?.toLowerCase().includes(search.toLowerCase()) || item.code.toLowerCase().includes(search.toLowerCase())) && item.season?.name === filterType && item.gender?.id === filterGender).map((item, index) => (
                                    <StyledTableRow key={`25-${index}`}>
                                      <StyledTableCell align="left" style={{ width: "50px" }}>
                                        <Checkbox
                                          color="primary"
                                          name={item.id}
                                          onChange={onChangeMassiveDelete}
                                          checked={checkboxMassiveDelete?.indexOf(item.id.toString()) >= 0 ? true : false}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                      <StyledTableCell align="center"><div className={item.stock === 0 ? style.pointGreen : style.pointRed}/></StyledTableCell>
                                      <StyledTableCell align="center">{item.total_quantity > 0 ? item.total_quantity : ""}</StyledTableCell>
                                      <StyledTableCell align="center"><img src={item.image} width="60" height="70" alt={item.name} /></StyledTableCell>
                                      <StyledTableCell align="center">{item.name}</StyledTableCell>
                                      <StyledTableCell align="center">{item.code}</StyledTableCell>
                                      <StyledTableCell align="center">{item.type === 0 ? "Estivo" : "Invernale"}</StyledTableCell>
                                      <StyledTableCell align="center">{item.number_of_media}</StyledTableCell>
                                      <StyledTableCell align="center">
                                        <IconButton key={index} onClick={() => onViewProduct(item.id)}>
                                          <EditIcon color="primary" />
                                        </IconButton>
                                        <IconButton key={index} onClick={() => onOpenModalConfirmDeleteProduct(item)}>
                                          <DeleteIcon color="error" />
                                        </IconButton>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))
                                )
                              )
                            )
                          ) : (
                            viewBestseller === 1 ? (
                              filterGender === "" ? (
                                viewLuxury === 1 ? (
                                  dataProducts.sortProductsAdmin.filter(item => item.season?.name === filterType && item.bestseller === 1 && item.luxury === 1).map((item, index) => (
                                    <StyledTableRow key={`26-${index}`}>
                                      <StyledTableCell align="left" style={{ width: "50px" }}>
                                        <Checkbox
                                          color="primary"
                                          name={item.id}
                                          onChange={onChangeMassiveDelete}
                                          checked={checkboxMassiveDelete?.indexOf(item.id.toString()) >= 0 ? true : false}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                      <StyledTableCell align="center"><div className={item.stock === 0 ? style.pointGreen : style.pointRed}/></StyledTableCell>
                                      <StyledTableCell align="center">{item.total_quantity > 0 ? item.total_quantity : ""}</StyledTableCell>
                                      <StyledTableCell align="center"><img src={item.image} width="60" height="70" alt={item.name} /></StyledTableCell>
                                      <StyledTableCell align="center">{item.name}</StyledTableCell>
                                      <StyledTableCell align="center">{item.code}</StyledTableCell>
                                      <StyledTableCell align="center">{item.type === 0 ? "Estivo" : "Invernale"}</StyledTableCell>
                                      <StyledTableCell align="center">{item.number_of_media}</StyledTableCell>
                                      <StyledTableCell align="center">
                                        <IconButton key={index} onClick={() => onViewProduct(item.id)}>
                                          <EditIcon color="primary" />
                                        </IconButton>
                                        <IconButton key={index} onClick={() => onOpenModalConfirmDeleteProduct(item)}>
                                          <DeleteIcon color="error" />
                                        </IconButton>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))
                                ) : (
                                  dataProducts.sortProductsAdmin.filter(item => item.season?.name === filterType && item.bestseller === 1).map((item, index) => (
                                    <StyledTableRow key={`27-${index}`}>
                                      <StyledTableCell align="left" style={{ width: "50px" }}>
                                        <Checkbox
                                          color="primary"
                                          name={item.id}
                                          onChange={onChangeMassiveDelete}
                                          checked={checkboxMassiveDelete?.indexOf(item.id.toString()) >= 0 ? true : false}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                      <StyledTableCell align="center"><div className={item.stock === 0 ? style.pointGreen : style.pointRed}/></StyledTableCell>
                                      <StyledTableCell align="center">{item.total_quantity > 0 ? item.total_quantity : ""}</StyledTableCell>
                                      <StyledTableCell align="center"><img src={item.image} width="60" height="70" alt={item.name} /></StyledTableCell>
                                      <StyledTableCell align="center">{item.name}</StyledTableCell>
                                      <StyledTableCell align="center">{item.code}</StyledTableCell>
                                      <StyledTableCell align="center">{item.type === 0 ? "Estivo" : "Invernale"}</StyledTableCell>
                                      <StyledTableCell align="center">{item.number_of_media}</StyledTableCell>
                                      <StyledTableCell align="center">
                                        <IconButton key={index} onClick={() => onViewProduct(item.id)}>
                                          <EditIcon color="primary" />
                                        </IconButton>
                                        <IconButton key={index} onClick={() => onOpenModalConfirmDeleteProduct(item)}>
                                          <DeleteIcon color="error" />
                                        </IconButton>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))
                                )
                              ) : (
                                viewLuxury === 1 ? (
                                  dataProducts.sortProductsAdmin.filter(item => item.season?.name === filterType && item.bestseller === 1 && item.gender?.id === filterGender && item.luxury === 1).map((item, index) => (
                                    <StyledTableRow key={`28-${index}`}>
                                      <StyledTableCell align="left" style={{ width: "50px" }}>
                                        <Checkbox
                                          color="primary"
                                          name={item.id}
                                          onChange={onChangeMassiveDelete}
                                          checked={checkboxMassiveDelete?.indexOf(item.id.toString()) >= 0 ? true : false}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                      <StyledTableCell align="center"><div className={item.stock === 0 ? style.pointGreen : style.pointRed}/></StyledTableCell>
                                      <StyledTableCell align="center">{item.total_quantity > 0 ? item.total_quantity : ""}</StyledTableCell>
                                      <StyledTableCell align="center"><img src={item.image} width="60" height="70" alt={item.name} /></StyledTableCell>
                                      <StyledTableCell align="center">{item.name}</StyledTableCell>
                                      <StyledTableCell align="center">{item.code}</StyledTableCell>
                                      <StyledTableCell align="center">{item.type === 0 ? "Estivo" : "Invernale"}</StyledTableCell>
                                      <StyledTableCell align="center">{item.number_of_media}</StyledTableCell>
                                      <StyledTableCell align="center">
                                        <IconButton key={index} onClick={() => onViewProduct(item.id)}>
                                          <EditIcon color="primary" />
                                        </IconButton>
                                        <IconButton key={index} onClick={() => onOpenModalConfirmDeleteProduct(item)}>
                                          <DeleteIcon color="error" />
                                        </IconButton>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))
                                ) : (
                                  dataProducts.sortProductsAdmin.filter(item => item.season?.name === filterType && item.bestseller === 1 && item.gender?.id === filterGender).map((item, index) => (
                                    <StyledTableRow key={`29-${index}`}>
                                      <StyledTableCell align="left" style={{ width: "50px" }}>
                                        <Checkbox
                                          color="primary"
                                          name={item.id}
                                          onChange={onChangeMassiveDelete}
                                          checked={checkboxMassiveDelete?.indexOf(item.id.toString()) >= 0 ? true : false}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                      <StyledTableCell align="center"><div className={item.stock === 0 ? style.pointGreen : style.pointRed}/></StyledTableCell>
                                      <StyledTableCell align="center">{item.total_quantity > 0 ? item.total_quantity : ""}</StyledTableCell>
                                      <StyledTableCell align="center"><img src={item.image} width="60" height="70" alt={item.name} /></StyledTableCell>
                                      <StyledTableCell align="center">{item.name}</StyledTableCell>
                                      <StyledTableCell align="center">{item.code}</StyledTableCell>
                                      <StyledTableCell align="center">{item.type === 0 ? "Estivo" : "Invernale"}</StyledTableCell>
                                      <StyledTableCell align="center">{item.number_of_media}</StyledTableCell>
                                      <StyledTableCell align="center">
                                        <IconButton key={index} onClick={() => onViewProduct(item.id)}>
                                          <EditIcon color="primary" />
                                        </IconButton>
                                        <IconButton key={index} onClick={() => onOpenModalConfirmDeleteProduct(item)}>
                                          <DeleteIcon color="error" />
                                        </IconButton>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))
                                )
                              )
                            ) : (
                              filterGender === "" ? (
                                viewLuxury === 1 ? (
                                  dataProducts.sortProductsAdmin.filter(item => item.season?.name === filterType && item.luxury === 1).map((item, index) => (
                                    <StyledTableRow key={`30-${index}`}>
                                      <StyledTableCell align="left" style={{ width: "50px" }}>
                                        <Checkbox
                                          color="primary"
                                          name={item.id}
                                          onChange={onChangeMassiveDelete}
                                          checked={checkboxMassiveDelete?.indexOf(item.id.toString()) >= 0 ? true : false}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                      <StyledTableCell align="center"><div className={item.stock === 0 ? style.pointGreen : style.pointRed}/></StyledTableCell>
                                      <StyledTableCell align="center">{item.total_quantity > 0 ? item.total_quantity : ""}</StyledTableCell>
                                      <StyledTableCell align="center"><img src={item.image} width="60" height="70" alt={item.name} /></StyledTableCell>
                                      <StyledTableCell align="center">{item.name}</StyledTableCell>
                                      <StyledTableCell align="center">{item.code}</StyledTableCell>
                                      <StyledTableCell align="center">{item.type === 0 ? "Estivo" : "Invernale"}</StyledTableCell>
                                      <StyledTableCell align="center">{item.number_of_media}</StyledTableCell>
                                      <StyledTableCell align="center">
                                        <IconButton key={index} onClick={() => onViewProduct(item.id)}>
                                          <EditIcon color="primary" />
                                        </IconButton>
                                        <IconButton key={index} onClick={() => onOpenModalConfirmDeleteProduct(item)}>
                                          <DeleteIcon color="error" />
                                        </IconButton>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))
                                ) : (
                                  dataProducts.sortProductsAdmin.filter(item => item.season?.name === filterType).map((item, index) => (
                                    <StyledTableRow key={`31-${index}`}>
                                      <StyledTableCell align="left" style={{ width: "50px" }}>
                                        <Checkbox
                                          color="primary"
                                          name={item.id}
                                          onChange={onChangeMassiveDelete}
                                          checked={checkboxMassiveDelete?.indexOf(item.id.toString()) >= 0 ? true : false}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                      <StyledTableCell align="center"><div className={item.stock === 0 ? style.pointGreen : style.pointRed}/></StyledTableCell>
                                      <StyledTableCell align="center">{item.total_quantity > 0 ? item.total_quantity : ""}</StyledTableCell>
                                      <StyledTableCell align="center"><img src={item.image} width="60" height="70" alt={item.name} /></StyledTableCell>
                                      <StyledTableCell align="center">{item.name}</StyledTableCell>
                                      <StyledTableCell align="center">{item.code}</StyledTableCell>
                                      <StyledTableCell align="center">{item.type === 0 ? "Estivo" : "Invernale"}</StyledTableCell>
                                      <StyledTableCell align="center">{item.number_of_media}</StyledTableCell>
                                      <StyledTableCell align="center">
                                        <IconButton key={index} onClick={() => onViewProduct(item.id)}>
                                          <EditIcon color="primary" />
                                        </IconButton>
                                        <IconButton key={index} onClick={() => onOpenModalConfirmDeleteProduct(item)}>
                                          <DeleteIcon color="error" />
                                        </IconButton>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))
                                )
                              ) : (
                                viewLuxury === 1 ? (
                                  dataProducts.sortProductsAdmin.filter(item => item.season?.name === filterType && item.gender?.id === filterGender && item.luxury === 1).map((item, index) => (
                                    <StyledTableRow key={`32-${index}`}>
                                      <StyledTableCell align="left" style={{ width: "50px" }}>
                                        <Checkbox
                                          color="primary"
                                          name={item.id}
                                          onChange={onChangeMassiveDelete}
                                          checked={checkboxMassiveDelete?.indexOf(item.id.toString()) >= 0 ? true : false}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                      <StyledTableCell align="center"><div className={item.stock === 0 ? style.pointGreen : style.pointRed}/></StyledTableCell>
                                      <StyledTableCell align="center">{item.total_quantity > 0 ? item.total_quantity : ""}</StyledTableCell>
                                      <StyledTableCell align="center"><img src={item.image} width="60" height="70" alt={item.name} /></StyledTableCell>
                                      <StyledTableCell align="center">{item.name}</StyledTableCell>
                                      <StyledTableCell align="center">{item.code}</StyledTableCell>
                                      <StyledTableCell align="center">{item.type === 0 ? "Estivo" : "Invernale"}</StyledTableCell>
                                      <StyledTableCell align="center">{item.number_of_media}</StyledTableCell>
                                      <StyledTableCell align="center">
                                        <IconButton key={index} onClick={() => onViewProduct(item.id)}>
                                          <EditIcon color="primary" />
                                        </IconButton>
                                        <IconButton key={index} onClick={() => onOpenModalConfirmDeleteProduct(item)}>
                                          <DeleteIcon color="error" />
                                        </IconButton>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))
                                ) : (
                                  dataProducts.sortProductsAdmin.filter(item => item.season?.name === filterType && item.gender?.id === filterGender).map((item, index) => (
                                    <StyledTableRow key={`33-${index}`}>
                                      <StyledTableCell align="left" style={{ width: "50px" }}>
                                        <Checkbox
                                          color="primary"
                                          name={item.id}
                                          onChange={onChangeMassiveDelete}
                                          checked={checkboxMassiveDelete?.indexOf(item.id.toString()) >= 0 ? true : false}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                      <StyledTableCell align="center"><div className={item.stock === 0 ? style.pointGreen : style.pointRed}/></StyledTableCell>
                                      <StyledTableCell align="center">{item.total_quantity > 0 ? item.total_quantity : ""}</StyledTableCell>
                                      <StyledTableCell align="center"><img src={item.image} width="60" height="70" alt={item.name} /></StyledTableCell>
                                      <StyledTableCell align="center">{item.name}</StyledTableCell>
                                      <StyledTableCell align="center">{item.code}</StyledTableCell>
                                      <StyledTableCell align="center">{item.type === 0 ? "Estivo" : "Invernale"}</StyledTableCell>
                                      <StyledTableCell align="center">{item.number_of_media}</StyledTableCell>
                                      <StyledTableCell align="center">
                                        <IconButton key={index} onClick={() => onViewProduct(item.id)}>
                                          <EditIcon color="primary" />
                                        </IconButton>
                                        <IconButton key={index} onClick={() => onOpenModalConfirmDeleteProduct(item)}>
                                          <DeleteIcon color="error" />
                                        </IconButton>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))
                                )
                              )
                            )

                          )
                        )
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (<p style={{ margin: "0" }}>Non ci sono prodotti</p>)
          }
        </Grid>
      </Grid>
      <Modal
        open={modalAddProduct}
        onClose={closeModalAddProduct}
      >
        <Grid container item xs={11} sm={11} md={5} lg={5} xl={5} className={style.paperModal}>
          <IconButton className={style.modalButtonCloseIcon} onClick={closeModalAddProduct}>
            <CloseIcon className={style.modalCloseIcon} fontSize={"large"} />
          </IconButton>
          <Typography variant="h5" color="primary" gutterBottom>
            Aggiungi un nuovo prodotto
          </Typography>
          <TextField
            style={{ marginBottom: "1rem" }}
            error={errorProductExistent}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="search"
            label="Nome Prodotto"
            name="name"
            size="small"
            autoFocus
            value={newProductName}
            onChange={onChangeForm}
          />
          <TextField
            style={{ marginBottom: "1rem" }}
            error={errorProductExistent}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="code"
            label="Codice Prodotto"
            name="code"
            size="small"
            autoFocus
            value={newProductCode}
            onChange={onChangeForm}
          />
          <FormControl component="fieldset">
            <FormLabel component="legend">Stagione</FormLabel>
            <RadioGroup aria-label="gender" name="type" value={parseInt(productSeason)} onChange={onChangeRadioSeason}>
              <FormControlLabel value={0} control={<Radio />} label="Estate" />
              <FormControlLabel value={1} control={<Radio />} label="Inverno" />
            </RadioGroup>
          </FormControl>
          <Grid xs={12}>
            {
              fileMainImage !== undefined && fileMainImage.name !== undefined ? (
                <div>
                  <img src={URL.createObjectURL(fileMainImage)} className={style.productImage} width="80px" height="100px" alt={fileMainImage.name} />
                  <CloseIcon className={style.closeIconImage} onClick={deleteImageUploaded} />
                </div>
              ) : (
                <Button
                  variant="contained"
                  color="default"
                  className={style.button}
                  startIcon={<CloudUploadIcon />}
                  onClick={onOpenDropzone}
                >
                  Immagine
                </Button>
              )
            }
            <DropzoneDialog
              open={openDropzone}
              onSave={onSaveImage}
              acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
              showPreviews={true}
              maxFileSize={5000000}
              onClose={onCloseDropzone}
              showFileNames={true}
              showFileNamesInPreview={true}
              showAlerts={true}
              filesLimit={1}
            />
          </Grid>
          {
            errorProductExistent ? (
              <Typography component="body2" align="center" style={{ color: "red" }}>
                Prodotto gia esistente
              </Typography>
            ) : (<></>)
          }
          {
            errorZeroPriceLists ? (
              <Typography component="body2" align="center" style={{ color: "red" }}>
                Prima di creare un prodotto è necessario creare un listino
              </Typography>
            ) : (<></>)
          }
          <Grid className={style.modalActionContainer} container direction="row" justify="center" alignItems="center" spacing={2}>
            <Grid item >
              <Button
                type='submit'
                variant="contained"
                onClick={closeModalAddProduct}
                size="medium"
                color="primary"
              >
                Annulla
              </Button>
            </Grid>
            <Grid item >
              <Button
                variant="contained"
                size="medium"
                color="primary"
                disabled={productSeason && newProductName && newProductCode && fileMainImage !== undefined ? false : true}
                onClick={onAddProduct}
              >
                Salva
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <Modal
        open={openModalConfirmDeleteProduct}
        onClose={onCloseModalConfirmDeleteProduct}
      >
        <Grid container item xs={11} sm={11} md={5} lg={5} xl={5} className={style.paperModal}>
          <IconButton className={style.modalButtonCloseIcon} onClick={onCloseModalConfirmDeleteProduct}>
            <CloseIcon className={style.modalCloseIcon} fontSize={"large"} />
          </IconButton>
          <Grid container xs={12} direction="row" justify="center" alignItems="center" >
            <Typography variant="h4" color="error" align="center" gutterBottom >
              Attenzione
            </Typography>
          </Grid>
          <Grid container xs={12} direction="row" justify="center" alignItems="center" >
            <Typography variant="body1" align="center" gutterBottom >
              Sei sicuro di volere eliminare il prodotto <b>{productToDelete.name} ({productToDelete.code})</b> ?
            </Typography>
          </Grid>
          {
            loadingRemoveProduct ? (
              <Grid className={style.modalActionContainer} container direction="row" justify="center" alignItems="center" spacing={2}>
                <CircularProgress />
              </Grid>
            ) : (
              <Grid className={style.modalActionContainer} container direction="row" justify="center" alignItems="center" spacing={2}>
                <Grid item >
                  <Button
                    type='submit'
                    onClick={onCloseModalConfirmDeleteProduct}
                    variant="contained"
                    size="large"
                    color="primary"
                  >
                    Annulla
                  </Button>
                </Grid>
                <Grid item >
                  <Button
                    type='submit'
                    onClick={() => onRemoveProduct(productToDelete.id)}
                    variant="contained"
                    size="large"
                    color="primary"
                  >
                    Conferma
                  </Button>
                </Grid>
              </Grid>
            )
          }
        </Grid>
      </Modal>
      <Modal
        open={openModalConfirmDeleteAllProduct}
        onClose={onCloseModalConfirmDeleteAllProduct}
      >
        <Grid container item xs={11} sm={11} md={5} lg={5} xl={5} className={style.paperModal}>
          <IconButton className={style.modalButtonCloseIcon} onClick={onCloseModalConfirmDeleteAllProduct}>
            <CloseIcon className={style.modalCloseIcon} fontSize={"large"} />
          </IconButton>
          <Grid container xs={12} direction="row" justify="center" alignItems="center" >
            <Typography variant="h4" color="error" align="center" gutterBottom >
              Attenzione
            </Typography>
          </Grid>
          <Grid container xs={12} direction="row" justify="center" alignItems="center" >
            <Typography variant="body1" align="center" gutterBottom >
              Sei sicuro di volere eliminare <b>{checkboxMassiveDelete?.length}</b> {checkboxMassiveDelete?.length === 1 ? "prodotto " : "prodotti "} ?
            </Typography>
          </Grid>
          {
            loadingRemoveAllProduct ? (
              <Grid className={style.modalActionContainer} container direction="row" justify="center" alignItems="center" spacing={2}>
                <CircularProgress />
              </Grid>
            ) : (
              <Grid className={style.modalActionContainer} container direction="row" justify="center" alignItems="center" spacing={2}>
                <Grid item >
                  <Button
                    type='submit'
                    onClick={onCloseModalConfirmDeleteAllProduct}
                    variant="contained"
                    size="large"
                    color="primary"
                  >
                    Annulla
                  </Button>
                </Grid>
                <Grid item >
                  <Button
                    type='submit'
                    onClick={deleteAllSelected}
                    variant="contained"
                    size="large"
                    color="primary"
                  >
                    Conferma
                  </Button>
                </Grid>
              </Grid>
            )
          }
        </Grid>
      </Modal>
    </>
  );
};

export default Layout;
