import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { useMutation } from "@apollo/react-hooks";
import { PRICE_LIST, ADMIN_DASHBOARD, SHOW_BANNER, SEASON_CATEGORY, PRODUCTS_EXPORT } from "../../../query";
import { CREATE_REGISTER, DELETE_ABANDONED_CARTS, UPDATE_SHOW_BANNER, HIDE_SEASON, PREORDER_SEASON } from "../../../mutation";
import LinearProgress from "@material-ui/core/LinearProgress";

import Layout from "./layout";

const Dashboard = ({ history }) => {
  // Login mutation
  const [createRegister] = useMutation(CREATE_REGISTER);
  const [deleteAbandonedCarts] = useMutation(DELETE_ABANDONED_CARTS);
  const [updateShowBanner] = useMutation(UPDATE_SHOW_BANNER);
  const [hideSeason] = useMutation(HIDE_SEASON);
  const [preorderSeason] = useMutation(PREORDER_SEASON);
  // formValues of add vat number
  const [registrationPermissionFormValues, setRegistrationPermissionFormValues] = useState({});
  const [hideSeasonsFormValues, setHideSeasonsFormValues] = useState({});
  const [preorderSeasonsFormValues, setPreorderSeasonsFormValues] = useState({});
  const [loadingRegistrationPermission, setLoadingRegistrationPermission] = useState(false);
  const [registrationPermissionSuccess, setRegistrationPermissionSuccess] = useState(false);
  const [registrationPermissionVatNumberError, setRegistrationPermissionVatNumberError] = useState(false);
  const [registrationPermissionCodeError, setRegistrationPermissionCodeError] = useState(false);
  const [showBanner, setShowBanner] = useState({});

  // PRICE LIST QUERY
  const { loading: loadingPriceLists, error: errorPriceLists, data: dataPriceLists } = useQuery(PRICE_LIST);
  const { loading: loadingShowBanner, error: errorShowBanner, data: dataShowBanner } = useQuery(SHOW_BANNER);
  const { loading: loadingSeasons, data: dataSeasons } = useQuery(SEASON_CATEGORY);
  const { loading: loadingAdminDashboard, error: errorAdminDashboard, data: dataAdminDashboard } = useQuery(ADMIN_DASHBOARD, {
    pollInterval: 30000,
  });
  const [getProductsExport, { loading: loadingProductsExport, error: errorProductsExport, data: dataProductsExport }] = useLazyQuery(PRODUCTS_EXPORT, {
    variables: { skip: false },
    onCompleted: (result) => exportCSV(result),
  });


  const onChangeShowBanner = event => {
    const name = event.target.name
    if (event.target.checked === true) {
      setShowBanner({ ...showBanner, [name]: 1 })
      updateShowBanner({
        variables: {
          name: name,
          show: 1,
        }
      })
    } else {
      setShowBanner({ ...showBanner, [name]: 0 })
      updateShowBanner({
        variables: {
          name: name,
          show: 0,
        }
      })
    }
  };

  // EXPORT CSV
  function exportCSV(data) {

    if (loadingProductsExport) return <LinearProgress />;
    if (errorProductsExport) { console.log("err"); }
    if (data && data.productsExport) {
      const csvDataArray = [];
      csvDataArray.push(['SKU', 'NOME', 'GRUPPO TAGLIE', 'TAGLIA', 'CARTELLA COLORI', 'COLORE', "QUANTITÀ'"]);
      data.productsExport.map(item => (
        csvDataArray.push([item.code, item.product_name, item.group_size_name, item.size_name, item.color_folder_name, item.hex, item.quantity])
      ));
      console.log(dataProductsExport);
      const csvString = csvDataArray.map(row => row.join(';')).join('\n');
      const csvData = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

      // create a temporary anchor element
      const link = document.createElement('a');
      const url = URL.createObjectURL(csvData);
      link.setAttribute('href', url);
      link.setAttribute('download', 'prodotti');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  function onClickExportCsv() {
    getProductsExport({ variables: { skip: true } })
  }

  // Add new registration permission for vaat_number
  const onAddRegistrationPermission = () => {
    setLoadingRegistrationPermission(true);
    if (registrationPermissionFormValues["code"] !== "" && registrationPermissionFormValues["vatNumber"] !== "" && registrationPermissionFormValues["vatNumber"] !== undefined && /^[0-9a-zA-Z]+$/.test(registrationPermissionFormValues["vatNumber"])) {
      // Start the mutation for add Vat Number in registrations table for permission user registration
      createRegister({
        variables: {
          vatNumber: registrationPermissionFormValues["vatNumber"],
          code: registrationPermissionFormValues["code"],
          list_type_0: registrationPermissionFormValues["list_type_0"],
          list_type_1: registrationPermissionFormValues["list_type_1"],
        },
      }).then(response => {
        // If response of insertVatNumberPermission is 0 set error of vat number insert
        if (response.data.createRegister.status === 2) {
          setRegistrationPermissionVatNumberError(true);
        } else if (response.data.createRegister.status === 0) {
          setRegistrationPermissionCodeError(true);
        } else if (response.data.createRegister.status === 1) {
          // else if user pass the insertVatNumberPermission with status 1 set error of vat number false
          setRegistrationPermissionVatNumberError(false);
          setRegistrationPermissionCodeError(false);
          setRegistrationPermissionSuccess(true);
          setRegistrationPermissionFormValues({ vatNumber: "", listNumber: "" });
        }
      });
      setLoadingRegistrationPermission(false);
    } else {
      setLoadingRegistrationPermission(false);
    }
  };

  // MUTATION for delete abandoned carts
  const onDeleteAbandonedCarts = () => {
    deleteAbandonedCarts({}).then(response => {
      if (response.data.deleteAbandonedCarts.status === 1) {
        console.log("ci sono carrelli");
      } else if (response.data.deleteAbandonedCarts.status === 2) {
        console.log("non ci sono carrelli");
      }
    });
  };

  // When the input of add vat number form change prepare the form values
  const onChangeRegistrationPermission = event => {
    const value = event.target.value;
    const name = event.target.name;
    setRegistrationPermissionFormValues({ ...registrationPermissionFormValues, [name]: value });
    if (name === "vatNumber") setRegistrationPermissionVatNumberError(false);
    if (name === "code") setRegistrationPermissionCodeError(false);
    setRegistrationPermissionSuccess(false);
  };

  // When click on x the success message vat number insert hidden
  const onCloseRegistrationPermissionSuccess = () => {
    setRegistrationPermissionSuccess(false);
  };

  // Hide Seasons
  const onChangeHideSeasonsFormValues = event => {

    if (event.target.checked) {
      setHideSeasonsFormValues({ ...hideSeasonsFormValues, [event.target.name]: 1 });
    } else {
      setHideSeasonsFormValues({ ...hideSeasonsFormValues, [event.target.name]: 0 });
    }

    hideSeason({
      variables: {
        season_id: event.target.name,
        value: event.target.checked ? 1 : 0,
      }
    }
    )
  };

    // Preorder Seasons
    const onChangePreorderSeasonsFormValues = event => {

      if (event.target.checked) {
        setPreorderSeasonsFormValues({ ...preorderSeasonsFormValues, [event.target.name]: 1 });
      } else {
        setPreorderSeasonsFormValues({ ...preorderSeasonsFormValues, [event.target.name]: 0 });
      }

      preorderSeason({
        variables: {
          season_id: event.target.name,
          value: event.target.checked ? 1 : 0,
        }
      }
      )
    };

  if (loadingPriceLists || loadingAdminDashboard || loadingShowBanner || loadingSeasons || loadingProductsExport) return <LinearProgress />;
  if (errorPriceLists || errorAdminDashboard || errorShowBanner || errorProductsExport) { console.log("err"); }

  return (
    <Layout
      dataAdminDashboard={dataAdminDashboard}
      dataPriceLists={dataPriceLists}
      onAddRegistrationPermission={onAddRegistrationPermission}
      registrationPermissionFormValues={registrationPermissionFormValues}
      onChangeRegistrationPermission={onChangeRegistrationPermission}
      registrationPermissionVatNumberError={registrationPermissionVatNumberError}
      registrationPermissionCodeError={registrationPermissionCodeError}
      loadingRegistrationPermission={loadingRegistrationPermission}
      registrationPermissionSuccess={registrationPermissionSuccess}
      onCloseRegistrationPermissionSuccess={onCloseRegistrationPermissionSuccess}
      history={history}
      onDeleteAbandonedCarts={onDeleteAbandonedCarts}
      dataShowBanner={dataShowBanner}
      onChangeShowBanner={onChangeShowBanner}
      showBanner={showBanner}
      dataSeasons={dataSeasons}
      onChangeHideSeasonsFormValues={onChangeHideSeasonsFormValues}
      hideSeasonsFormValues={hideSeasonsFormValues}
      onChangePreorderSeasonsFormValues={onChangePreorderSeasonsFormValues}
      preorderSeasonsFormValues={preorderSeasonsFormValues}
      onClickExportCsv={onClickExportCsv}
    />
  );
};

export default withRouter(Dashboard);
