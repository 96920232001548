import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  breadCrumbsText:{
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
  productImage: {
    width: "70px",
    height: "70px",
  },
}));
