import React from "react";
import Select, { components }  from "react-select";
import Avatar from "@material-ui/core/Avatar";

const MultiSelect = ({ options, onChange , defaultValue, getOptionLabel, getOptionValue, isMulti, selectOption, name, label, placeholder, value}) => {
  const Option = props => {
    if (props.data.colorSrc){
      return (
        <div style={{ display: "flex" }}>
          <Avatar style={{ width: "40px", height: "40px"}} variant="rounded" src={props.data.colorSrc}/>
          <components.Option {...props}/>
        </div>
      );
    }else{
      return(
        <>
          <components.Option {...props}/>
        </>
      );
    }
  };
  return (
    <Select
      isMulti={isMulti}
      onChange={onChange}
      options={options}
      defaultValue={defaultValue}
      components={{Option}}
      isClearable={true}
      selectOption={selectOption ?? null}
      name={name}
      label={label}
      placeholder={placeholder}
      value={value}
    />
  );
};

export default MultiSelect;
