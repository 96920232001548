import React, { useState } from 'react';
import Grid from "@material-ui/core/Grid";
import { withRouter } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
import PDF from "./catalogo_donna.pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function CatalogoDonna() {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }


  return (
    <Grid container xs={12} direction="row" justify="center" alignItems="center">
        <Document
          file={PDF}
          onLoadSuccess={onDocumentLoadSuccess}
          onContextMenu={(e) => e.preventDefault()}
          style={{display: "none"}}
        >
           {Array.apply(null, Array(numPages))
          .map((x, i)=>i+1)
          .map(page => <Page pageNumber={page}/>)}
      </Document>
   </Grid>
  );
}

export default withRouter(CatalogoDonna);
