import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Regioni from "../../../../data/regioni.json";
import Style from "./style.js";

const Form = ({
  formValues,
  onChangeForm,
  onChangeRegion,
  loadingRegistration,
  onRegister,
  registerError,
  passwordConfirmationError,
  vatNumberError,
  emailError,
  registrationErrorConfirm,
  successRegistration,
  onGoToLogin,
  onChangeVatNumberNationality,
  vatNumberNationality,
  emailConfirmationError,
}) => {
  const style = Style();
  const regioni = Regioni;
  const handleChange = (e) => {
    e.preventDefault();
  };
  return (
    <>
      <Container component="main" maxWidth="xs">
        <div className={style.paper}>
          {
            successRegistration ? (
              <>
                <Typography component="h1" variant="h5">
                  Complimenti la tua registrazione è avvenuta con successo
                </Typography>
                <Typography component="h3" variant="h5">
                  Controlla la tua casella di posta elettronica
                </Typography>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  className={style.submit}
                  onClick={onGoToLogin}
                >
                  Vai al Login
                </Button>
              </>
            ) : (
              <>
                <Typography component="h1" variant="h5">
                  Registrati
                </Typography>
                <Grid container xs={12}>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={vatNumberNationality === 0 ? true : false }
                          onChange={onChangeVatNumberNationality}
                          name="vatNumberIt"
                          color="primary"
                          value={0}
                        />
                      }
                      label={<span style={{ fontWeight: "600" }}>Italia</span>}
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={vatNumberNationality === 1 ? true : false }
                          onChange={onChangeVatNumberNationality}
                          name="vatNumberEx"
                          color="primary"
                          value={1}
                        />
                      }
                      label={<span style={{ fontWeight: "600" }}>Estero</span>}
                    />
                  </Grid>
                </Grid>
                <TextField
                  className={style.textField}
                  error={registerError ? true : false}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="businessName"
                  label="Ragione Sociale"
                  name="businessName"
                  autoComplete="businessName"
                  autoFocus
                  value={formValues["businessName"]}
                  onChange={onChangeForm}
                />
                <TextField
                  className={style.textField}
                  error={registerError || vatNumberError ? true : false}
                  helperText={vatNumberError ? "La Partita IVA o Codice Fiscale non corretto" : false}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="vatNumber"
                  label="Partita IVA / Codice Fiscale"
                  name="vatNumber"
                  autoComplete="vatNumber"
                  autoFocus
                  value={formValues["vatNumber"]}
                  onChange={onChangeForm}
                />
                {
                  vatNumberNationality === 1 ? (
                    <></>
                  ) : (
                    <FormControl variant="outlined" fullWidth >
                      <InputLabel id="demo-simple-select-outlined-label">Regione</InputLabel>
                      <Select
                        name="region"
                        error={registerError ? true : false}
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={formValues["region"]}
                        onChange={onChangeRegion}
                        label="Regione"
                        required
                      >
                        <MenuItem key={"none"} value={""}><em>Nessuno</em></MenuItem>
                        {
                          regioni.map((item, index) => {
                            return(
                              <MenuItem key={index} value={item.regione}>{item.regione}</MenuItem>
                            );
                          })
                        }
                      </Select>
                    </FormControl>
                  )
                }
                <TextField
                  className={style.textField}
                  error={registerError || emailError ? true : false}
                  helperText={emailError ? "Inserire indirizzo email valido" : false}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="email"
                  label="Email"
                  type="email"
                  id="email"
                  autoComplete="email"
                  value={formValues["email"]}
                  onChange={onChangeForm}
                />
                <TextField
                  className={style.textField}
                  error={registerError || emailConfirmationError ? true : false}
                  helperText={emailConfirmationError ? "Le email non corrispondono" : false}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="email-confirmation"
                  label="Conferma Email"
                  type="email"
                  id="email"
                  autoComplete="email"
                  value={formValues["email-confirmation"]}
                  onChange={onChangeForm}
                  onPaste={handleChange}
                />
                <TextField
                  className={style.textField}
                  error={passwordConfirmationError || registerError ? true : false}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  value={formValues["password"]}
                  onChange={onChangeForm}
                />
                <TextField
                  className={style.textField}
                  error={passwordConfirmationError || registerError ? true : false}
                  helperText={passwordConfirmationError ? "Le password non corrispondono" : false}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="passwordConfirmation"
                  label="Conferma Password"
                  type="password"
                  id="passwordConfirmation"
                  value={formValues["passwordConfirmation"]}
                  onChange={onChangeForm}
                />
                {
                  registerError ?
                    (
                      <Typography align="left" color="error" variant="caption" className={style.registerErrorMessage}>
                        * Compilare tutti i campi per procedere alla registrazione
                      </Typography>
                    ) : (
                      <></>
                    )
                }
                {
                  registrationErrorConfirm ?
                    (
                      <Typography align="left" color="error" variant="caption" className={style.registerErrorMessage}>
                        * Si prega di contattare l'amministrazione per richiedere il permesso di registrazione
                      </Typography>
                    ) : (
                      <></>
                    )
                }
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  className={style.submit}
                  onClick={onRegister}
                  size="large"
                >
                  {
                    loadingRegistration ? <CircularProgress className={style.loading} /> : "Registrati"
                  }
                </Button>
              </>
            )
          }
        </div>
        <Box mt={8}>
          <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="https://material-ui.com/">
              Blu Cashmere
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default Form;
